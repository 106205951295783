export const objSchmot = [
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Вот имена сынов Израиля, которые пришли в Египет; они прибыли вместе с Яаковом, каждый со своей семьей, —',
    verse_ivrit: 'וְאֵלֶּה שְׁמוֹת בְּנֵי יִשְׂרָאֵל הַבָּאִים מִצְרָיְמָה אֵת יַעֲקֹב אִישׁ וּבֵיתוֹ בָּאוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Реувен, Шимон, Леви и Йеѓуда,',
    verse_ivrit: 'רְאוּבֵן שִׁמְעוֹן לֵוִי וִיהוּדָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Иссахар, Звулун и Биньямин',
    verse_ivrit: 'יִשָּׂשכָר זְבוּלֻן וּבִנְיָמִן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Дан и Нафтали, Гад и Ашер —',
    verse_ivrit: 'דָּן וְנַפְתָּלִי גָּד וְאָשֵׁר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'всего семьдесят человек, происшедших от Яакова. Йосеф же к тому времени был в Египте.',
    verse_ivrit: 'וַיְהִי כָּל-נֶפֶשׁ יֹצְאֵי יֶרֶךְ-יַעֲקֹב שִׁבְעִים נָפֶשׁ וְיוֹסֵף הָיָה בְמִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Йосеф умер. Умерли все его братья и все то поколение.',
    verse_ivrit: 'וַיָּמָת יוֹסֵף וְכָל-אֶחָיו וְכֹל הַדּוֹר הַהוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Но сыны Израиля умножались, их становилось все больше. Они размножались и усиливались, так что страна переполнилась ими. ',
    verse_ivrit: 'וּבְנֵי יִשְׂרָאֵל פָּרוּ וַיִּשְׁרְצוּ וַיִּרְבּוּ וַיַּעַצְמוּ בִּמְאֹד מְאֹד וַתִּמָּלֵא הָאָרֶץ אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'В Египте воцарился новый царь, который не знал Йосефа.',
    verse_ivrit: 'וַיָּקָם מֶלֶךְ-חָדָשׁ עַל-מִצְרָיִם אֲשֶׁר לֹא-יָדַע אֶת-יוֹסֵף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Он сказал своему народу: — Смотрите, сыны Израиля многочисленнее и сильнее нас.',
    verse_ivrit: 'וַיֹּאמֶר אֶל-עַמּוֹ הִנֵּה עַם בְּנֵי יִשְׂרָאֵל רַב וְעָצוּם מִמֶּנּוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Давайте поступим с ними мудро, чтобы их не стало еще больше. Не то, если случится война, они примкнут к нашим врагам, будут воевать с нами, а [потом] уйдут из страны.',
    verse_ivrit: 'הָבָה נִתְחַכְּמָה לוֹ פֶּן-יִרְבֶּה וְהָיָה כִּי-תִקְרֶאנָה מִלְחָמָה וְנוֹסַף גַּם-הוּא עַל-שֹׂנְאֵינוּ וְנִלְחַם-בָּנוּ וְעָלָה מִן-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Над народом Израиля были назначены надсмотрщики, чтобы угнетать его тяжелыми работами. И [народ Израиля] построил для фараона города-хранилища — Питом и Раамсес.',
    verse_ivrit: 'וַיָּשִׂימוּ עָלָיו שָׂרֵי מִסִּים לְמַעַן עַנֹּתוֹ בְּסִבְלֹתָם וַיִּבֶן עָרֵי מִסְכְּנוֹת לְפַרְעֹה אֶת-פִּתֹם וְאֶת-רַעַמְסֵס',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Но чем больше угнетали [этот народ], тем больше он размножался и распространялся, и возненавидели [египтяне] сынов Израиля.',
    verse_ivrit: 'וְכַאֲשֶׁר יְעַנּוּ אֹתוֹ כֵּן יִרְבֶּה וְכֵן יִפְרֹץ וַיָּקֻצוּ מִפְּנֵי בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Египтяне безжалостно принуждали сынов Израиля к работам.',
    verse_ivrit: 'וַיַּעֲבִדוּ מִצְרַיִם אֶת-בְּנֵי יִשְׂרָאֵל בְּפָרֶךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Тяжелая работа с глиной и кирпичами, все виды работы в поле и вся другая работа, к которой их безжалостно принуждали, сделала их жизнь невыносимой.',
    verse_ivrit: 'וַיְמָרְרוּ אֶת-חַיֵּיהֶם בַּעֲבֹדָה קָשָׁה בְּחֹמֶר וּבִלְבֵנִים וּבְכָל-עֲבֹדָה בַּשָּׂדֶה אֵת כָּל-עֲבֹדָתָם אֲשֶׁר-עָבְדוּ בָהֶם בְּפָרֶךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Властелин Египта повелел [двум] повитухам-еврейкам, которых звали Шифра и Пуа:',
    verse_ivrit: 'וַיֹּאמֶר מֶלֶךְ מִצְרַיִם לַמְיַלְּדֹת הָעִבְרִיֹּת אֲשֶׁר שֵׁם הָאַחַת שִׁפְרָה וְשֵׁם הַשֵּׁנִית פּוּעָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: '— Когда вы принимаете роды у евреек, то наблюдайте у родильных камней: если это мальчик, то убивайте его, а если девочка, то пусть живет.',
    verse_ivrit: 'וַיֹּאמֶר בְּיַלֶּדְכֶן אֶת-הָעִבְרִיּוֹת וּרְאִיתֶן עַל-הָאָבְנָיִם אִם-בֵּן הוּא וַהֲמִתֶּן אֹתוֹ וְאִם-בַּת הִוא וָחָיָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Но повитухи боялись Бога. И они не делали так, как говорил им властелин Египта, они оставляли детей в живых.',
    verse_ivrit: 'וַתִּירֶאןָ הַמְיַלְּדֹת אֶת-הָאֱלֹהִים וְלֹא עָשׂוּ כַּאֲשֶׁר דִּבֶּר אֲלֵיהֶן מֶלֶךְ מִצְרָיִם וַתְּחַיֶּיןָ אֶת-הַיְלָדִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Царь Египта позвал повитух и спросил их: — [Что] вы делаете, почему оставляете детей в живых?',
    verse_ivrit: 'וַיִּקְרָא מֶלֶךְ-מִצְרַיִם לַמְיַלְּדֹת וַיֹּאמֶר לָהֶן מַדּוּעַ עֲשִׂיתֶן הַדָּבָר הַזֶּה וַתְּחַיֶּיןָ אֶת-הַיְלָדִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Повитухи ответили фараону: — Еврейки отличаются от египетских женщин, они живучие: рожают до того, как придет к ним повитуха.',
    verse_ivrit: 'וַתֹּאמַרְןָ הַמְיַלְּדֹת אֶל-פַּרְעֹה כִּי לֹא כַנָּשִׁים הַמִּצְרִיֹּת הָעִבְרִיֹּת כִּי-חָיוֹת הֵנָּה בְּטֶרֶם תָּבוֹא אֲלֵהֶן הַמְיַלֶּדֶת וְיָלָדוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Бог воздал добром повитухам, а народ все размножался и чрезвычайно усилился.',
    verse_ivrit: 'וַיֵּיטֶב אֱלֹהִים לַמְיַלְּדֹת וַיִּרֶב הָעָם וַיַּעַצְמוּ מְאֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'За то, что повитухи боялись Бога, Он сделал их [родоначальницами] кланов.',
    verse_ivrit: 'וַיְהִי כִּי-יָרְאוּ הַמְיַלְּדֹת אֶת-הָאֱלֹהִים וַיַּעַשׂ לָהֶם בָּתִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 1,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Фараон же дал всему своему народу такое повеление: — Всякого их новорожденного мальчика бросайте в Нил, а девочек оставляйте в живых.',
    verse_ivrit: 'וַיְצַו פַּרְעֹה לְכָל-עַמּוֹ לֵאמֹר כָּל-הַבֵּן הַיִּלּוֹד הַיְאֹרָה תַּשְׁלִיכֻהוּ וְכָל-הַבַּת תְּחַיּוּן',
    comment: '',
  },
  ////////////////////////////////        22222222222222222222222222222222222222222        \\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Мужчина из рода Леви пошел и взял себе жену из рода Леви.',
    verse_ivrit: 'וַיֵּלֶךְ אִישׁ מִבֵּית לֵוִי וַיִּקַּח אֶת-בַּת-לֵוִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Эта женщина зачала и родила сына. Увидев, как он хорош, она прятала его три месяца.',
    verse_ivrit: 'וַתַּהַר הָאִשָּׁה וַתֵּלֶד בֵּן וַתֵּרֶא אֹתוֹ כִּי-טוֹב הוּא וַתִּצְפְּנֵהוּ שְׁלֹשָׁה יְרָחִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'А [когда] она уже не могла больше его прятать, то взяла короб из папируса, обмазала его глиной и смолой и, положив в него ребенка, оставила в тростнике на берегу реки.',
    verse_ivrit: 'וְלֹא-יָכְלָה עוֹד הַצְּפִינוֹ וַתִּקַּח-לוֹ תֵּבַת גֹּמֶא וַתַּחְמְרָה בַחֵמָר וּבַזָּפֶת וַתָּשֶׂם בָּהּ אֶת-הַיֶּלֶד וַתָּשֶׂם בַּסּוּף עַל-שְׂפַת הַיְאֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'А сестра мальчика стала поодаль, чтобы узнать, что с ним будет.',
    verse_ivrit: 'וַתֵּתַצַּב אֲחֹתוֹ מֵרָחֹק לְדֵעָה מַה-יֵּעָשֶׂה לוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '[Тем временем] дочь фараона пришла к реке омыться. Ее служанки ходили по берегу. Увидев в тростнике короб, она послала свою рабыню взять его.',
    verse_ivrit: 'וַתֵּרֶד בַּת-פַּרְעֹה לִרְחֹץ עַל-הַיְאֹר וְנַעֲרֹתֶיהָ הֹלְכֹת עַל-יַד הַיְאֹר וַתֵּרֶא אֶת-הַתֵּבָה בְּתוֹךְ הַסּוּף וַתִּשְׁלַח אֶת-אֲמָתָהּ וַתִּקָּחֶהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Открыв [короб], дочь фараона увидела плачущего ребенка и, пожалев его, сказала: — [Наверно,] он из еврейских детей.',
    verse_ivrit: 'וַתִּפְתַּח וַתִּרְאֵהוּ אֶת-הַיֶּלֶד וְהִנֵּה-נַעַר בֹּכֶה וַתַּחְמֹל עָלָיו וַתֹּאמֶר מִיַּלְדֵי הָעִבְרִים זֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Тогда сестра [мальчика] спросила у дочери фараона: — Может быть, мне пойти и позвать кормилицу из евреек, чтобы она вскормила для тебя это дитя?',
    verse_ivrit: 'וַתֹּאמֶר אֲחֹתוֹ אֶל-בַּת-פַּרְעֹה הַאֵלֵךְ וְקָרָאתִי לָךְ אִשָּׁה מֵינֶקֶת מִן הָעִבְרִיֹּת וְתֵינִק לָךְ אֶת-הַיָּלֶד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: '— Ступай, — отвечала ей дочь фараона. Девушка пошла и позвала мать ребенка.',
    verse_ivrit: 'וַתֹּאמֶר-לָהּ בַּת-פַּרְעֹה לֵכִי וַתֵּלֶךְ הָעַלְמָה וַתִּקְרָא אֶת-אֵם הַיָּלֶד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: '— Возьми ребенка и вскорми его для меня, — велела ей дочь фараона, — а я тебя вознагражу. Женщина взяла ребенка и выкормила его.',
    verse_ivrit: 'וַתֹּאמֶר לָהּ בַּת-פַּרְעֹה הֵילִיכִי אֶת-הַיֶּלֶד הַזֶּה וְהֵינִקִהוּ לִי וַאֲנִי אֶתֵּן אֶת-שְׂכָרֵךְ וַתִּקַּח הָאִשָּׁה הַיֶּלֶד וַתְּנִיקֵהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Когда ребенок вырос, мать привела его к дочери фараона, и для той он стал как сын. Она нарекла его Моше, сказав: “Ведь я достала его из воды”.',
    verse_ivrit: 'וַיִּגְדַּל הַיֶּלֶד וַתְּבִאֵהוּ לְבַת-פַּרְעֹה וַיְהִי-לָהּ לְבֵן וַתִּקְרָא שְׁמוֹ מֹשֶׁה וַתֹּאמֶר כִּי מִן-הַמַּיִם מְשִׁיתִהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Когда Моше вырос, он пришел к своим соплеменникам и увидел их тяжкий труд. Однажды увидел он, как египтянин бьет его соплеменника-еврея.',
    verse_ivrit: 'וַיְהִי בַּיָּמִים הָהֵם וַיִּגְדַּל מֹשֶׁה וַיֵּצֵא אֶל-אֶחָיו וַיַּרְא בְּסִבְלֹתָם וַיַּרְא אִישׁ מִצְרִי מַכֶּה אִישׁ-עִבְרִי מֵאֶחָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Он огляделся по сторонам и, убедившись, что [рядом] никого нет, убил египтянина и зарыл его в песок.',
    verse_ivrit: 'וַיִּפֶן כֹּה וָכֹה וַיַּרְא כִּי אֵין אִישׁ וַיַּךְ אֶת-הַמִּצְרִי וַיִּטְמְנֵהוּ בַּחוֹל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'На следующий день он [вновь] пришел [туда] и видит — два еврея дерутся. — Зачем ты бьешь своего ближнего? — спросил он у зачинщика.',
    verse_ivrit: 'וַיֵּצֵא בַּיּוֹם הַשֵּׁנִי וְהִנֵּה שְׁנֵי-אֲנָשִׁים עִבְרִים נִצִּים וַיֹּאמֶר לָרָשָׁע לָמָּה תַכֶּה רֵעֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'А тот ответил: — Кто поставил тебя начальником и судьей над нами? Уж не собираешься ли ты убить меня, как убил того египтянина? “Значит, все стало известно”, — подумал Моше в страхе.',
    verse_ivrit: 'וַיֹּאמֶר מִי שָׂמְךָ לְאִישׁ שַׂר וְשֹׁפֵט עָלֵינוּ הַלְהָרְגֵנִי אַתָּה אֹמֵר כַּאֲשֶׁר הָרַגְתָּ אֶת-הַמִּצְרִי וַיִּירָא מֹשֶׁה וַיֹּאמַר אָכֵן נוֹדַע הַדָּבָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Фараон услышал о происшедшем и вознамерился казнить Моше. Но Моше бежал от фараона и, остановившись в стране Мидьян, сел у колодца.',
    verse_ivrit: 'וַיִּשְׁמַע פַּרְעֹה אֶת-הַדָּבָר הַזֶּה וַיְבַקֵּשׁ לַהֲרֹג אֶת-מֹשֶׁה וַיִּבְרַח מֹשֶׁה מִפְּנֵי פַרְעֹה וַיֵּשֶׁב בְּאֶרֶץ-מִדְיָן וַיֵּשֶׁב עַל-הַבְּאֵר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'У мидьянского жреца было семь дочерей. Они пришли, начерпали воды и наполнили поилки, чтобы напоить отцовских овец.',
    verse_ivrit: 'וּלְכֹהֵן מִדְיָן שֶׁבַע בָּנוֹת וַתָּבֹאנָה וַתִּדְלֶנָה וַתְּמַלֶּאנָה אֶת-הָרְהָטִים לְהַשְׁקוֹת צֹאן אֲבִיהֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Пришли пастухи и стали гнать их прочь. Моше защитил их, а потом напоил их овец.',
    verse_ivrit: 'וַיָּבֹאוּ הָרֹעִים וַיְגָרְשׁוּם וַיָּקָם מֹשֶׁה וַיּוֹשִׁעָן וַיַּשְׁקְ אֶת-צֹאנָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Вернулись они к своему отцу Реуэлю, и тот спросил: — Отчего вы сегодня так рано вернулись?',
    verse_ivrit: 'וַתָּבֹאנָה אֶל-רְעוּאֵל אֲבִיהֶן וַיֹּאמֶר מַדּוּעַ מִהַרְתֶּן בֹּא הַיּוֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: '— Какой-то египтянин защитил нас от пастухов, — отвечали они. — Он даже начерпал для нас воды и напоил [наших] овец.',
    verse_ivrit: 'וַתֹּאמַרְןָ אִישׁ מִצְרִי הִצִּילָנוּ מִיַּד הָרֹעִים וְגַם-דָּלֹה דָלָה לָנוּ וַיַּשְׁקְ אֶת-הַצֹּאן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: '— Так где же он? — спросил [Реуэль] у дочерей. — Почему вы оставили его? Позовите его, пусть он поест [c нами].',
    verse_ivrit: 'וַיֹּאמֶר אֶל-בְּנֹתָיו וְאַיּוֹ לָמָּה זֶּה עֲזַבְתֶּן אֶת-הָאִישׁ קִרְאֶן לוֹ וְיֹאכַל לָחֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Моше согласился остаться у этого человека, и тот выдал за него свою дочь Ципору.',
    verse_ivrit: 'וַיּוֹאֶל מֹשֶׁה לָשֶׁבֶת אֶת-הָאִישׁ וַיִּתֵּן אֶת-צִפֹּרָה בִתּוֹ לְמֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Она родила сына, и Моше нарек его именем Гершом [“Изгнание”], сказав: “Я стал пришельцем в чужой стране”.',
    verse_ivrit: 'וַתֵּלֶד בֵּן וַיִּקְרָא אֶת-שְׁמוֹ גֵּרְשֹׁם כִּי אָמַר גֵּר הָיִיתִי בְּאֶרֶץ נָכְרִיָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Спустя долгое время умер египетский властелин. Сыны же Израиля стенали от работы, взывали к Богу, и вопль [измученных тяжким] трудом дошел до Него.',
    verse_ivrit: 'וַיְהִי בַיָּמִים הָרַבִּים הָהֵם וַיָּמָת מֶלֶךְ מִצְרַיִם וַיֵּאָנְחוּ בְנֵי-יִשְׂרָאֵל מִן-הָעֲבֹדָה וַיִּזְעָקוּ וַתַּעַל שַׁוְעָתָם אֶל-הָאֱלֹהִים מִן-הָעֲבֹדָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Бог услышал их стон, и вспомнил Бог Свой союз с Авраѓамом, Ицхаком и Яаковом.',
    verse_ivrit: 'וַיִּשְׁמַע אֱלֹהִים אֶת-נַאֲקָתָם וַיִּזְכֹּר אֱלֹהִים אֶת-בְּרִיתוֹ אֶת-אַבְרָהָם אֶת-יִצְחָק וְאֶת-יַעֲקֹב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 2,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Увидел Бог [страдания] сынов Израиля, признал [их] Бог.',
    verse_ivrit: 'וַיַּרְא אֱלֹהִים אֶת-בְּנֵי יִשְׂרָאֵל וַיֵּדַע אֱלֹהִים',
    comment: '',
  },
  ////////////////////////////////        3333333333333333333333333333333      \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Моше пас скот своего тестя, мидьянского жреца Итро. Однажды он погнал стадо за пустыню и пришел к Божьей горе, Хореву.',
    verse_ivrit: 'וּמֹשֶׁה הָיָה רֹעֶה אֶת-צֹאן יִתְרוֹ חֹתְנוֹ כֹּהֵן מִדְיָן וַיִּנְהַג אֶת-הַצֹּאן אַחַר הַמִּדְבָּר וַיָּבֹא אֶל-הַר הָאֱלֹהִים חֹרֵבָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'И явился ему ангел Господа — в пламени, внутри тернового куста. [Моше] увидел, что куст горит, но не сгорает.',
    verse_ivrit: 'וַיֵּרָא מַלְאַךְ יְהוָה אֵלָיו בְּלַבַּת-אֵשׁ מִתּוֹךְ הַסְּנֶה וַיַּרְא וְהִנֵּה הַסְּנֶה בֹּעֵר בָּאֵשׁ וְהַסְּנֶה אֵינֶנּוּ אֻכָּל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Моше подумал: “Сверну и посмотрю на это великое явление — отчего не сгорает куст?”',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אָסֻרָה-נָּא וְאֶרְאֶה אֶת-הַמַּרְאֶה הַגָּדֹל הַזֶּה מַדּוּעַ לֹא-יִבְעַר הַסְּנֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Увидел Господь, что Моше свернул посмотреть [на чудо]. Воззвал к нему Бог из [горящего] куста: — Моше! Моше! Тот ответил: — Вот я.',
    verse_ivrit: 'וַיַּרְא יְהוָה כִּי סָר לִרְאוֹת וַיִּקְרָא אֵלָיו אֱלֹהִים מִתּוֹךְ הַסְּנֶה וַיֹּאמֶר מֹשֶׁה מֹשֶׁה וַיֹּאמֶר הִנֵּנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '— Не приближайся! — сказал [Господь]. — Сними обувь с ног, ведь место, на котором ты стоишь, — [это] святая земля!',
    verse_ivrit: 'וַיֹּאמֶר אַל-תִּקְרַב הֲלֹם שַׁל-נְעָלֶיךָ מֵעַל רַגְלֶיךָ כִּי הַמָּקוֹם אֲשֶׁר אַתָּה עוֹמֵד עָלָיו אַדְמַת-קֹדֶשׁ הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: '— Я Бог твоего отца, Бог Авраѓама, Бог Ицхака и Бог Яакова, — возвестил [Господь]. Моше закрыл лицо, ибо он боялся взглянуть на Бога.',
    verse_ivrit: 'וַיֹּאמֶר אָנֹכִי אֱלֹהֵי אָבִיךָ אֱלֹהֵי אַבְרָהָם אֱלֹהֵי יִצְחָק וֵאלֹהֵי יַעֲקֹב וַיַּסְתֵּר מֹשֶׁה פָּנָיו כִּי יָרֵא מֵהַבִּיט אֶל-הָאֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: '— Я увидел унижения Моего народа в Египте, — сказал Господь, — Я услышал его стенания под [гнетом] надсмотрщиков. Я знаю, [каковы] его страдания.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה רָאֹה רָאִיתִי אֶת-עֳנִי עַמִּי אֲשֶׁר בְּמִצְרָיִם וְאֶת-צַעֲקָתָם שָׁמַעְתִּי מִפְּנֵי נֹגְשָׂיו כִּי יָדַעְתִּי אֶת-מַכְאֹבָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'И вот Я сошел, чтобы спасти его от египтян и увести из той страны в [другую] страну, хорошую и просторную, — в страну, источающую молоко и мед, в землю ханаанеев, хеттов, амореев, перизеев, хивеев и евусеев.',
    verse_ivrit: 'וָאֵרֵד לְהַצִּילוֹ מִיַּד מִצְרַיִם וּלְהַעֲלֹתוֹ מִן-הָאָרֶץ הַהִוא אֶל-אֶרֶץ טוֹבָה וּרְחָבָה אֶל-אֶרֶץ זָבַת חָלָב וּדְבָשׁ אֶל-מְקוֹם הַכְּנַעֲנִי וְהַחִתִּי וְהָאֱמֹרִי וְהַפְּרִזִּי וְהַחִוִּי וְהַיְבוּסִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Дошли до Меня стенания сынов Израиля, увидел Я, как угнетают их египтяне.',
    verse_ivrit: 'וְעַתָּה הִנֵּה צַעֲקַת בְּנֵי-יִשְׂרָאֵל בָּאָה אֵלָי וְגַם-רָאִיתִי אֶת-הַלַּחַץ אֲשֶׁר מִצְרַיִם לֹחֲצִים אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'А теперь ступай: Я посылаю тебя к фараону, чтобы ты увел Мой народ, сынов Израиля, из Египта!',
    verse_ivrit: 'וְעַתָּה לְכָה וְאֶשְׁלָחֲךָ אֶל-פַּרְעֹה וְהוֹצֵא אֶת-עַמִּי בְנֵי-יִשְׂרָאֵל מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: '— Кто я [такой], чтобы пойти к фараону и увести сынов Израиля из Египта? — сказал Моше.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-הָאֱלֹהִים מִי אָנֹכִי כִּי אֵלֵךְ אֶל-פַּרְעֹה וְכִי אוֹצִיא אֶת-בְּנֵי יִשְׂרָאֵל מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: '— Я буду с тобой, — ответил [Господь], — и вот тебе знак, что это Я послал тебя: когда ты уведешь народ из Египта, вы будете служить Богу на этой горе.',
    verse_ivrit: 'וַיֹּאמֶר כִּי-אֶהְיֶה עִמָּךְ וְזֶה-לְּךָ הָאוֹת כִּי אָנֹכִי שְׁלַחְתִּיךָ בְּהוֹצִיאֲךָ אֶת-הָעָם מִמִּצְרַיִם תַּעַבְדוּן אֶת-הָאֱלֹהִים עַל הָהָר הַזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Моше сказал Богу: — Приду я к сынам Израиля и скажу им: “Меня послал к вам Бог ваших предков”. А они меня спросят: “Как Его Имя?” Что я им отвечу?',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-הָאֱלֹהִים הִנֵּה אָנֹכִי בָא אֶל-בְּנֵי יִשְׂרָאֵל וְאָמַרְתִּי לָהֶם אֱלֹהֵי אֲבוֹתֵיכֶם שְׁלָחַנִי אֲלֵיכֶם וְאָמְרוּ-לִי מַה-שְּׁמוֹ מָה אֹמַר אֲלֵהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: '— Я-Тот-Кто-Я-Есмь, — сказал Моше Бог. И добавил: — Скажи сынам Израиля: “Я-Есмь послал меня к вам”.',
    verse_ivrit: 'וַיֹּאמֶר אֱלֹהִים אֶל-מֹשֶׁה אֶהְיֶה אֲשֶׁר אֶהְיֶה וַיֹּאמֶר כֹּה תֹאמַר לִבְנֵי יִשְׂרָאֵל אֶהְיֶה שְׁלָחַנִי אֲלֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'И снова Бог обратился к Моше: — Скажи сынам Израиля: “Меня послал к вам Господь, Бог ваших предков, Бог Авраѓама, Бог Ицхака и Бог Яакова”. Таково Имя Мое навеки, так будут называть Меня все поколения.',
    verse_ivrit: 'וַיֹּאמֶר עוֹד אֱלֹהִים אֶל-מֹשֶׁה כֹּה-תֹאמַר אֶל-בְּנֵי יִשְׂרָאֵל יְהוָה אֱלֹהֵי אֲבֹתֵיכֶם אֱלֹהֵי אַבְרָהָם אֱלֹהֵי יִצְחָק וֵאלֹהֵי יַעֲקֹב שְׁלָחַנִי אֲלֵיכֶם זֶה-שְּׁמִי לְעֹלָם וְזֶה זִכְרִי לְדֹר דֹּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Иди же, собери старейшин Израиля и возвести им: “Мне явился Господь, Бог ваших предков, Бог Авраѓама, Ицхака и Яакова, чтобы поведать [вам]: ‘Я вспомнил о вас и о том, как поступают с вами в Египте,',
    verse_ivrit: 'לֵךְ וְאָסַפְתָּ אֶת-זִקְנֵי יִשְׂרָאֵל וְאָמַרְתָּ אֲלֵהֶם יְהוָה אֱלֹהֵי אֲבֹתֵיכֶם נִרְאָה אֵלַי אֱלֹהֵי אַבְרָהָם יִצְחָק וְיַעֲקֹב לֵאמֹר פָּקֹד פָּקַדְתִּי אֶתְכֶם וְאֶת-הֶעָשׂוּי לָכֶם בְּמִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'и решил избавить вас от страданий в Египте и увести вас в страну ханаанеев, хеттов, амореев, перизеев, хивеев и евусеев — в страну, источающую молоко и мед’ ”.',
    verse_ivrit: 'וָאֹמַר אַעֲלֶה אֶתְכֶם מֵעֳנִי מִצְרַיִם אֶל-אֶרֶץ הַכְּנַעֲנִי וְהַחִתִּי וְהָאֱמֹרִי וְהַפְּרִזִּי וְהַחִוִּי וְהַיְבוּסִי אֶל-אֶרֶץ זָבַת חָלָב וּדְבָשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Они послушают тебя. [Вместе] со старейшинами Израиля ты придешь к египетскому властелину и скажешь ему: “Господь, Бог евреев, посетил нас. Позволь же нам теперь уйти в пустыню на три дня пути, чтобы принести там жертвы Господу, нашему Богу”.',
    verse_ivrit: 'וְשָׁמְעוּ לְקֹלֶךָ וּבָאתָ אַתָּה וְזִקְנֵי יִשְׂרָאֵל אֶל-מֶלֶךְ מִצְרַיִם וַאֲמַרְתֶּם אֵלָיו יְהוָה אֱלֹהֵי הָעִבְרִיִּים נִקְרָה עָלֵינוּ וְעַתָּה נֵלְכָה-נָּא דֶּרֶךְ שְׁלֹשֶׁת יָמִים בַּמִּדְבָּר וְנִזְבְּחָה לַיהוָה אֱלֹהֵינוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Мне уже ведомо, что царь Египта не позволит вам уйти, если не [принудить его] силой.',
    verse_ivrit: 'וַאֲנִי יָדַעְתִּי כִּי לֹא-יִתֵּן אֶתְכֶם מֶלֶךְ מִצְרַיִם לַהֲלֹךְ וְלֹא בְּיָד חֲזָקָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: '[Поэтому] Я простру руку Свою и поражу Египет, сотворив там разные чудеса. И тогда он вас отпустит.',
    verse_ivrit: 'וְשָׁלַחְתִּי אֶת-יָדִי וְהִכֵּיתִי אֶת-מִצְרַיִם בְּכֹל נִפְלְאֹתַי אֲשֶׁר אֶעֱשֶׂה בְּקִרְבּוֹ וְאַחֲרֵי-כֵן יְשַׁלַּח אֶתְכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'И внушу я египтянам приязнь к этому народу, так что вы, уходя [оттуда], уйдете не с пустыми руками.',
    verse_ivrit: 'וְנָתַתִּי אֶת-חֵן הָעָם-הַזֶּה בְּעֵינֵי מִצְרָיִם וְהָיָה כִּי תֵלֵכוּן לֹא תֵלְכוּ רֵיקָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 3,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Каждая женщина возьмет у своей соседки и у жилиц в своем доме серебряные и золотые вещи, а также одежду: вы оденете [в это] ваших сыновей и дочерей. Так вы опустошите Египет.',
    verse_ivrit: 'וְשָׁאֲלָה אִשָּׁה מִשְּׁכֶנְתָּהּ וּמִגָּרַת בֵּיתָהּ כְּלֵי-כֶסֶף וּכְלֵי זָהָב וּשְׂמָלֹת וְשַׂמְתֶּם עַל-בְּנֵיכֶם וְעַל-בְּנֹתֵיכֶם וְנִצַּלְתֶּם אֶת-מִצְרָיִם',
    comment: '',
  },
  ///////////////////////////////////////      4444444444444444444444444444444         \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Моше сказал: — А что, если они мне не поверят и не послушают меня? Что, если скажут: “Не являлся тебе Господь”?',
    verse_ivrit: 'וַיַּעַן מֹשֶׁה וַיֹּאמֶר וְהֵן לֹא-יַאֲמִינוּ לִי וְלֹא יִשְׁמְעוּ בְּקֹלִי כִּי יֹאמְרוּ לֹא-נִרְאָה אֵלֶיךָ יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '— Что у тебя в руке? — спросил его Господь. — Посох, — ответил тот.',
    verse_ivrit: 'וַיֹּאמֶר אֵלָיו יְהוָה מזה (מַה-זֶּה) בְיָדֶךָ וַיֹּאמֶר מַטֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: '— Брось его на землю, — повелел [Господь]. Тот бросил [посох] на землю, и [посох] превратился в змея. Моше отпрянул.',
    verse_ivrit: 'וַיֹּאמֶר הַשְׁלִיכֵהוּ אַרְצָה וַיַּשְׁלִכֵהוּ אַרְצָה וַיְהִי לְנָחָשׁ וַיָּנָס מֹשֶׁה מִפָּנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: '— Протяни руку и схвати его за хвост, — сказал Господь Моше. Тот протянул руку, схватил [змея], и в его руке [змей вновь] стал посохом.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה שְׁלַח יָדְךָ וֶאֱחֹז בִּזְנָבוֹ וַיִּשְׁלַח יָדוֹ וַיַּחֲזֶק בּוֹ וַיְהִי לְמַטֶּה בְּכַפּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '— Это чтобы они поверили, что тебе явился Господь, Бог их предков, Бог Авраѓама, Бог Ицхака и Бог Яакова.',
    verse_ivrit: 'לְמַעַן יַאֲמִינוּ כִּי-נִרְאָה אֵלֶיךָ יְהוָה אֱלֹהֵי אֲבֹתָם אֱלֹהֵי אַבְרָהָם אֱלֹהֵי יִצְחָק וֵאלֹהֵי יַעֲקֹב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: '— Сунь-ка руку за пазуху, — снова велел [Моше] Господь. [Моше] сунул руку за пазуху, а когда он вынул ее, она была покрыта проказой, как снегом.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה לוֹ עוֹד הָבֵא-נָא יָדְךָ בְּחֵיקֶךָ וַיָּבֵא יָדוֹ בְּחֵיקוֹ וַיּוֹצִאָהּ וְהִנֵּה יָדוֹ מְצֹרַעַת כַּשָּׁלֶג',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: '[Господь] сказал: — Снова сунь руку за пазуху. [Моше] вновь сунул руку за пазуху, а когда вынул, она опять была такой же, как [и все] его тело.',
    verse_ivrit: 'וַיֹּאמֶר הָשֵׁב יָדְךָ אֶל-חֵיקֶךָ וַיָּשֶׁב יָדוֹ אֶל-חֵיקוֹ וַיּוֹצִאָהּ מֵחֵיקוֹ וְהִנֵּה-שָׁבָה כִּבְשָׂרוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: '— Если они не поверят тебе и не послушаются первого знамения, то поверят другому знамению.',
    verse_ivrit: 'וְהָיָה אִם-לֹא יַאֲמִינוּ לָךְ וְלֹא יִשְׁמְעוּ לְקֹל הָאֹת הָרִאשׁוֹן וְהֶאֱמִינוּ לְקֹל הָאֹת הָאַחֲרוֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'А если [они] не поверят обоим этим знамениям и не послушают тебя, то зачерпни воды из реки и вылей [ее] на сушу — и вода, которую ты зачерпнешь из реки, превратится на суше в кровь.',
    verse_ivrit: 'וְהָיָה אִם-לֹא יַאֲמִינוּ גַּם לִשְׁנֵי הָאֹתוֹת הָאֵלֶּה וְלֹא יִשְׁמְעוּן לְקֹלֶךָ וְלָקַחְתָּ מִמֵּימֵי הַיְאֹר וְשָׁפַכְתָּ הַיַּבָּשָׁה וְהָיוּ הַמַּיִם אֲשֶׁר תִּקַּח מִן-הַיְאֹר וְהָיוּ לְדָם בַּיַּבָּשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: '— О Господь! — сказал Моше Господу. — Я не умею красиво говорить, и никогда не умел, и [не научился] даже с тех пор, как Ты обратился к рабу Своему. Я не красноречив и косноязычен.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-יְהוָה בִּי אֲדֹנָי לֹא אִישׁ דְּבָרִים אָנֹכִי גַּם מִתְּמוֹל גַּם מִשִּׁלְשֹׁם גַּם מֵאָז דַּבֶּרְךָ אֶל-עַבְדֶּךָ כִּי כְבַד-פֶּה וּכְבַד לָשׁוֹן אָנֹכִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: '— Кто дает уста человеку? — сказал Господь. — Кто делает [его] немым или глухим, зрячим или слепым? Не Я ли — Господь?',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֵלָיו מִי שָׂם פֶּה לָאָדָם אוֹ מִי-יָשׂוּם אִלֵּם אוֹ חֵרֵשׁ אוֹ פִקֵּחַ אוֹ עִוֵּר הֲלֹא אָנֹכִי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'А теперь ступай! Я буду при устах твоих, и Я научу тебя, что говорить.',
    verse_ivrit: 'וְעַתָּה לֵךְ וְאָנֹכִי אֶהְיֶה עִם-פִּיךָ וְהוֹרֵיתִיךָ אֲשֶׁר תְּדַבֵּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Но Моше сказал: — О Господь! Пошли кого-нибудь другого!',
    verse_ivrit: 'וַיֹּאמֶר בִּי אֲדֹנָי שְׁלַח-נָא בְּיַד-תִּשְׁלָח',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Тогда разгневался Господь на Моше и сказал: — А как же твой брат, левит Аѓарон? Я знаю, что он умеет говорить. Он скоро выйдет тебе навстречу, и когда увидит тебя, то обрадуется [всем] сердцем.',
    verse_ivrit: 'וַיִּחַר-אַף יְהוָה בְּמֹשֶׁה וַיֹּאמֶר הֲלֹא אַהֲרֹן אָחִיךָ הַלֵּוִי יָדַעְתִּי כִּי-דַבֵּר יְדַבֵּר הוּא וְגַם הִנֵּה-הוּא יֹצֵא לִקְרָאתֶךָ וְרָאֲךָ וְשָׂמַח בְּלִבּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Ты станешь говорить и вкладывать слова в его уста, а Я буду с вами обоими и научу вас, что вам делать.',
    verse_ivrit: 'וְדִבַּרְתָּ אֵלָיו וְשַׂמְתָּ אֶת-הַדְּבָרִים בְּפִיו וְאָנֹכִי אֶהְיֶה עִם-פִּיךָ וְעִם-פִּיהוּ וְהוֹרֵיתִי אֶתְכֶם אֵת אֲשֶׁר תַּעֲשׂוּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Он будет говорить с народом вместо тебя. Он станет твоими устами, а ты будешь ему господином.',
    verse_ivrit: 'וְדִבֶּר-הוּא לְךָ אֶל-הָעָם וְהָיָה הוּא יִהְיֶה-לְּךָ לְפֶה וְאַתָּה תִּהְיֶה-לּוֹ לֵאלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'И возьми этот посох, которым ты будешь творить знамения.',
    verse_ivrit: 'וְאֶת-הַמַּטֶּה הַזֶּה תִּקַּח בְּיָדֶךָ אֲשֶׁר תַּעֲשֶׂה-בּוֹ אֶת-הָאֹתֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Моше ушел, вернулся к своему тестю Итро и сказал ему: — Позволь мне пойти, вернуться к моим соплеменникам в Египет и посмотреть, живы ли они еще. — Ступай с миром, — ответил Итро.',
    verse_ivrit: 'וַיֵּלֶךְ מֹשֶׁה וַיָּשָׁב אֶל-יֶתֶר חֹתְנוֹ וַיֹּאמֶר לוֹ אֵלְכָה נָּא וְאָשׁוּבָה אֶל-אַחַי אֲשֶׁר-בְּמִצְרַיִם וְאֶרְאֶה הַעוֹדָם חַיִּים וַיֹּאמֶר יִתְרוֹ לְמֹשֶׁה לֵךְ לְשָׁלוֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Еще в Мидьяне Господь сказал Моше: “Иди, вернись в Египет. Все те, кто желал тебе смерти, уже умерли”.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה בְּמִדְיָן לֵךְ שֻׁב מִצְרָיִם כִּי-מֵתוּ כָּל-הָאֲנָשִׁים הַמְבַקְשִׁים אֶת-נַפְשֶׁךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Взял Моше жену и сыновей, посадил их на осла и отправился в землю египетскую. А посох Божий Моше держал в руке.',
    verse_ivrit: 'וַיִּקַּח מֹשֶׁה אֶת-אִשְׁתּוֹ וְאֶת-בָּנָיו וַיַּרְכִּבֵם עַל-הַחֲמֹר וַיָּשָׁב אַרְצָה מִצְרָיִם וַיִּקַּח מֹשֶׁה אֶת-מַטֵּה הָאֱלֹהִים בְּיָדוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Господь сказал Моше: — Знай: когда ты вернешься в Египет, то сотворишь перед фараоном все чудеса, которые Я поручил тебе, но Я ожесточу его, и он не отпустит народ.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה בְּלֶכְתְּךָ לָשׁוּב מִצְרַיְמָה רְאֵה כָּל-הַמֹּפְתִים אֲשֶׁר-שַׂמְתִּי בְיָדֶךָ וַעֲשִׂיתָם לִפְנֵי פַרְעֹה וַאֲנִי אֲחַזֵּק אֶת-לִבּוֹ וְלֹא יְשַׁלַּח אֶת-הָעָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: '[Тогда] ты скажешь фараону: “Так говорит Господь: ‘Израиль — Мой сын, Мой первенец.',
    verse_ivrit: 'וְאָמַרְתָּ אֶל-פַּרְעֹה כֹּה אָמַר יְהוָה בְּנִי בְכֹרִי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Я сказал тебе: отпусти Моего сына, чтобы он служил Мне. Но ты отказался его отпустить. [За это] Я убью твоего сына, твоего первенца!’”',
    verse_ivrit: 'וָאֹמַר אֵלֶיךָ שַׁלַּח אֶת-בְּנִי וְיַעַבְדֵנִי וַתְּמָאֵן לְשַׁלְּחוֹ הִנֵּה אָנֹכִי הֹרֵג אֶת-בִּנְךָ בְּכֹרֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'В пути, на ночлеге, Господь настиг [Моше] и хотел умертвить его.',
    verse_ivrit: 'וַיְהִי בַדֶּרֶךְ בַּמָּלוֹן וַיִּפְגְּשֵׁהוּ יְהוָה וַיְבַקֵּשׁ הֲמִיתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Ципора взяла кремень, обрезала своему сыну крайнюю плоть и, положив к ногам [Моше], сказала: “Ты мне — жених крови!”',
    verse_ivrit: 'וַתִּקַּח צִפֹּרָה צֹר וַתִּכְרֹת אֶת-עָרְלַת בְּנָהּ וַתַּגַּע לְרַגְלָיו וַתֹּאמֶר כִּי חֲתַן-דָּמִים אַתָּה לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: '[Господь] отступил от него, и тогда она добавила: “Жених крови — из-за обрезания”.',
    verse_ivrit: 'וַיִּרֶף מִמֶּנּוּ אָז אָמְרָה חֲתַן דָּמִים לַמּוּלֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Аѓарону же Господь повелел: — Иди в пустыню, навстречу Моше. Тот пошел, встретил [Моше] у горы Божьей и расцеловал.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-אַהֲרֹן לֵךְ לִקְרַאת מֹשֶׁה הַמִּדְבָּרָה וַיֵּלֶךְ וַיִּפְגְּשֵׁהוּ בְּהַר הָאֱלֹהִים וַיִּשַּׁק-לוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Моше передал Аѓарону все слова Господа, Который послал его, и [поведал обо] всех знамениях, которые Он повелел ему [совершить].',
    verse_ivrit: 'וַיַּגֵּד מֹשֶׁה לְאַהֲרֹן אֵת כָּל-דִּבְרֵי יְהוָה אֲשֶׁר שְׁלָחוֹ וְאֵת כָּל-הָאֹתֹת אֲשֶׁר צִוָּהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Моше с Аѓароном пошли и собрали всех старейшин Израиля.',
    verse_ivrit: 'וַיֵּלֶךְ מֹשֶׁה וְאַהֲרֹן וַיַּאַסְפוּ אֶת-כָּל-זִקְנֵי בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Аѓарон пересказал все те слова, которые Господь говорил Моше, а [Моше] сотворил на глазах у народа знамения.',
    verse_ivrit: 'וַיְדַבֵּר אַהֲרֹן אֵת כָּל-הַדְּבָרִים אֲשֶׁר-דִּבֶּר יְהוָה אֶל-מֹשֶׁה וַיַּעַשׂ הָאֹתֹת לְעֵינֵי הָעָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 4,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'И поверил народ. Когда они убедились, что Господь вспомнил о сынах Израиля и увидел страдания их, то склонились и простерлись ниц.',
    verse_ivrit: 'וַיַּאֲמֵן הָעָם וַיִּשְׁמְעוּ כִּי-פָקַד יְהוָה אֶת-בְּנֵי יִשְׂרָאֵל וְכִי רָאָה אֶת-עָנְיָם וַיִּקְּדוּ וַיִּשְׁתַּחֲווּ',
    comment: '',
  },
  //////////////////////////////////////////       55555555555555555555555555   \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'После этого пришли Моше и Аѓарон к фараону и сказали: — Так говорит Господь, Бог Израиля: “Отпусти Мой народ, чтобы они устроили Мне праздник в пустыне”.',
    verse_ivrit: 'וְאַחַר בָּאוּ מֹשֶׁה וְאַהֲרֹן וַיֹּאמְרוּ אֶל-פַּרְעֹה כֹּה-אָמַר יְהוָה אֱלֹהֵי יִשְׂרָאֵל שַׁלַּח אֶת-עַמִּי וְיָחֹגּוּ לִי בַּמִּדְבָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '— Кто этот Господь, чтобы я послушал Его и отпустил Израиль? — спросил фараон. — Не знаю я Господа, не отпущу Израиль.',
    verse_ivrit: 'וַיֹּאמֶר פַּרְעֹה מִי יְהוָה אֲשֶׁר אֶשְׁמַע בְּקֹלוֹ לְשַׁלַּח אֶת-יִשְׂרָאֵל לֹא יָדַעְתִּי אֶת-יְהוָה וְגַם אֶת-יִשְׂרָאֵל לֹא אֲשַׁלֵּחַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: '— Нам явился Бог евреев, — сказали они. — Позволь нам уйти на три дня пути в пустыню и принести жертву Господу, Богу нашему. Иначе Он поразит нас мором или мечом.',
    verse_ivrit: 'וַיֹּאמְרוּ אֱלֹהֵי הָעִבְרִים נִקְרָא עָלֵינוּ נֵלְכָה נָּא דֶּרֶךְ שְׁלֹשֶׁת יָמִים בַּמִּדְבָּר וְנִזְבְּחָה לַיהוָה אֱלֹהֵינוּ פֶּן-יִפְגָּעֵנוּ בַּדֶּבֶר אוֹ בֶחָרֶב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Сказал им царь Египта: — Зачем вы, Моше и Аѓарон, отвлекаете народ от работы? Займитесь своим делом!',
    verse_ivrit: 'וַיֹּאמֶר אֲלֵהֶם מֶלֶךְ מִצְרַיִם לָמָּה מֹשֶׁה וְאַהֲרֹן תַּפְרִיעוּ אֶת-הָעָם מִמַּעֲשָׂיו לְכוּ לְסִבְלֹתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '— Смотрите, как многочислен ныне этот народ! — прибавил фараон. — И вы хотите, чтобы он перестал работать?',
    verse_ivrit: 'וַיֹּאמֶר פַּרְעֹה הֵן-רַבִּים עַתָּה עַם הָאָרֶץ וְהִשְׁבַּתֶּם אֹתָם מִסִּבְלֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'В тот же день фараон велел надсмотрщикам над народом и надзирателям:',
    verse_ivrit: 'וַיְצַו פַּרְעֹה בַּיּוֹם הַהוּא אֶת-הַנֹּגְשִׂים בָּעָם וְאֶת-שֹׁטְרָיו לֵאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: '— Не давайте больше этому народу соломы для изготовления кирпичей, как [делали] прежде. Пусть сами идут и собирают себе солому.',
    verse_ivrit: 'לֹא תֹאסִפוּן לָתֵת תֶּבֶן לָעָם לִלְבֹּן הַלְּבֵנִים כִּתְמוֹל שִׁלְשֹׁם הֵם יֵלְכוּ וְקֹשְׁשׁוּ לָהֶם תֶּבֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Норму же кирпичей, которую они вырабатывают, оставьте прежней, не уменьшайте ее. Они ленятся, а потому кричат и требуют: “Отпусти нас принести жертву Богу нашему!”',
    verse_ivrit: 'וְאֶת-מַתְכֹּנֶת הַלְּבֵנִים אֲשֶׁר הֵם עֹשִׂים תְּמוֹל שִׁלְשֹׁם תָּשִׂימוּ עֲלֵיהֶם לֹא תִגְרְעוּ מִמֶּנּוּ כִּי-נִרְפִּים הֵם עַל-כֵּן הֵם צֹעֲקִים לֵאמֹר נֵלְכָה נִזְבְּחָה לֵאלֹהֵינוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Пусть [еще более] тяжким станет труд этих людей, чтобы они работали и не обращали внимания на лживые речи!',
    verse_ivrit: 'תִּכְבַּד הָעֲבֹדָה עַל-הָאֲנָשִׁים וְיַעֲשׂוּ-בָהּ וְאַל-יִשְׁעוּ בְּדִבְרֵי-שָׁקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Надсмотрщики и надзиратели вышли и сказали народу: — Так говорит фараон: “Не дам вам больше соломы!',
    verse_ivrit: 'וַיֵּצְאוּ נֹגְשֵׂי הָעָם וְשֹׁטְרָיו וַיֹּאמְרוּ אֶל-הָעָם לֵאמֹר כֹּה אָמַר פַּרְעֹה אֵינֶנִּי נֹתֵן לָכֶם תֶּבֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Идите и собирайте солому, где сможете. И от работы вашей ничего не убавилось”.',
    verse_ivrit: 'אַתֶּם לְכוּ קְחוּ לָכֶם תֶּבֶן מֵאֲשֶׁר תִּמְצָאוּ כִּי אֵין נִגְרָע מֵעֲבֹדַתְכֶם דָּבָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Рассеялся народ по всей земле египетской собирать жнивье вместо соломы,',
    verse_ivrit: 'וַיָּפֶץ הָעָם בְּכָל-אֶרֶץ מִצְרָיִם לְקֹשֵׁשׁ קַשׁ לַתֶּבֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'а надсмотрщики [всё] подгоняют [их], приговаривая: — Делайте свою ежедневную рабочую [норму], как и тогда, когда [вам] давали солому!',
    verse_ivrit: 'וְהַנֹּגְשִׂים אָצִים לֵאמֹר כַּלּוּ מַעֲשֵׂיכֶם דְּבַר-יוֹם בְּיוֹמוֹ כַּאֲשֶׁר בִּהְיוֹת הַתֶּבֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Надзирателей-евреев, которых поставили надсмотрщики фараона, побили и сказали им: — Почему ни вчера, ни сегодня вы не сделали установленного для вас прежде количества кирпичей?',
    verse_ivrit: 'וַיֻּכּוּ שֹׁטְרֵי בְּנֵי יִשְׂרָאֵל אֲשֶׁר-שָׂמוּ עֲלֵהֶם נֹגְשֵׂי פַרְעֹה לֵאמֹר מַדּוּעַ לֹא כִלִּיתֶם חָקְכֶם לִלְבֹּן כִּתְמוֹל שִׁלְשֹׁם גַּם-תְּמוֹל גַּם-הַיּוֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Явились надзиратели-евреи к фараону и, жалуясь, воззвали к нему:',
    verse_ivrit: 'וַיָּבֹאוּ שֹׁטְרֵי בְּנֵי יִשְׂרָאֵל וַיִּצְעֲקוּ אֶל-פַּרְעֹה לֵאמֹר לָמָּה תַעֲשֶׂה כֹה לַעֲבָדֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: '— Для чего ты так поступаешь с рабами своими? Не выдают [нам], рабам твоим, солому, но говорят: “Делайте кирпичи!” Смотри, рабов твоих избивают, и винят [в этом] твой же народ.',
    verse_ivrit: 'תֶּבֶן אֵין נִתָּן לַעֲבָדֶיךָ וּלְבֵנִים אֹמְרִים לָנוּ עֲשׂוּ וְהִנֵּה עֲבָדֶיךָ מֻכִּים וְחָטָאת עַמֶּךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Но [фараон] ответил: — Вы обленились! Поэтому вы и говорите: “Мы пойдем и принесем жертву Господу”.',
    verse_ivrit: 'וַיֹּאמֶר נִרְפִּים אַתֶּם נִרְפִּים עַל-כֵּן אַתֶּם אֹמְרִים נֵלְכָה נִזְבְּחָה לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Ступайте же, работайте! Соломы вы не получите, но [положенное] число кирпичей делайте.',
    verse_ivrit: 'וְעַתָּה לְכוּ עִבְדוּ וְתֶבֶן לֹא-יִנָּתֵן לָכֶם וְתֹכֶן לְבֵנִים תִּתֵּנוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Надзиратели-евреи поняли, что им несдобровать, так как было им сказано: “Не убавляйте от дневной нормы кирпичей”.',
    verse_ivrit: 'וַיִּרְאוּ שֹׁטְרֵי בְנֵי-יִשְׂרָאֵל אֹתָם בְּרָע לֵאמֹר לֹא-תִגְרְעוּ מִלִּבְנֵיכֶם דְּבַר-יוֹם בְּיוֹמוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Выйдя от фараона, они встретили Моше и Аѓарона',
    verse_ivrit: 'וַיִּפְגְּעוּ אֶת-מֹשֶׁה וְאֶת-אַהֲרֹן נִצָּבִים לִקְרָאתָם בְּצֵאתָם מֵאֵת פַּרְעֹה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'и сказали им: — Пусть увидит Господь ваши [дела] и накажет вас! Из-за вас фараон и его слуги духа нашего не переносят. Это вы дали им повод расправиться с нами!',
    verse_ivrit: 'וַיֹּאמְרוּ אֲלֵהֶם יֵרֶא יְהוָה עֲלֵיכֶם וְיִשְׁפֹּט אֲשֶׁר הִבְאַשְׁתֶּם אֶת-רֵיחֵנוּ בְּעֵינֵי פַרְעֹה וּבְעֵינֵי עֲבָדָיו לָתֶת-חֶרֶב בְּיָדָם לְהָרְגֵנוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Тогда снова обратился Моше к Господу: — О Господь! Зачем Ты навел беду на этот народ? Зачем только Ты послал меня [к нему]?',
    verse_ivrit: 'וַיָּשָׁב מֹשֶׁה אֶל-יְהוָה וַיֹּאמַר אֲדֹנָי לָמָה הֲרֵעֹתָה לָעָם הַזֶּה לָמָּה זֶּה שְׁלַחְתָּנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 1,
    id_chapter: 5,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Ведь с тех пор, как я пришел к фараону, чтобы говорить от Твоего имени, только хуже стало этому народу. А Ты так и не избавил народ Свой!',
    verse_ivrit: 'וּמֵאָז בָּאתִי אֶל-פַּרְעֹה לְדַבֵּר בִּשְׁמֶךָ הֵרַע לָעָם הַזֶּה וְהַצֵּל לֹא-הִצַּלְתָּ אֶת-עַמֶּךָ',
    comment: '',
  },
  ////////////////////////////////////////       666666666666666666666666666666              \\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь ответил Моше: — Скоро ты увидишь, что Я сделаю с фараоном. [Благодаря Моей] силе он отпустит их, силой изгонит он их из своей страны!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה עַתָּה תִרְאֶה אֲשֶׁר אֶעֱשֶׂה לְפַרְעֹה כִּי בְיָד חֲזָקָה יְשַׁלְּחֵם וּבְיָד חֲזָקָה יְגָרְשֵׁם מֵאַרְצוֹ',
    comment: '',
  },
  ////////////////////////////////////    66666666666666666666666666666666666666              \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 2,
    verse: 'Бог сказал Моше: “Я — Господь!',
    verse_ivrit: 'וַיְדַבֵּר אֱלֹהִים אֶל-מֹשֶׁה וַיֹּאמֶר אֵלָיו אֲנִי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 3,
    verse: 'Я являлся Авраѓаму, Ицхаку и Яакову как Бог Всемогущий, но под Именем Моим ‘Господь’ Я не открылся им.',
    verse_ivrit: 'וָאֵרָא אֶל-אַבְרָהָם אֶל-יִצְחָק וְאֶל-יַעֲקֹב בְּאֵל שַׁדָּי וּשְׁמִי יְהוָה לֹא נוֹדַעְתִּי לָהֶם',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 4,
    verse: 'Я заключил с ними союз, [пообещав] отдать им страну Ханаан, где они жили как переселенцы.',
    verse_ivrit: 'וְגַם הֲקִמֹתִי אֶת-בְּרִיתִי אִתָּם לָתֵת לָהֶם אֶת-אֶרֶץ כְּנָעַן אֵת אֶרֶץ מְגֻרֵיהֶם אֲשֶׁר-גָּרוּ בָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 5,
    verse: 'Услышал Я стенания сынов Израиля, угнетенных египтянами, и вспомнил Свой союз [с ними].',
    verse_ivrit: 'וְגַם אֲנִי שָׁמַעְתִּי אֶת-נַאֲקַת בְּנֵי יִשְׂרָאֵל אֲשֶׁר מִצְרַיִם מַעֲבִדִים אֹתָם וָאֶזְכֹּר אֶת-בְּרִיתִי',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 6,
    verse: 'Поэтому скажи сынам Израиля: Я — Господь! Я выведу вас из-под египетского бремени, избавлю вас от порабощения! Я спасу вас [мощью Моей] простертой руки и великими карами.',
    verse_ivrit: 'לָכֵן אֱמֹר לִבְנֵי-יִשְׂרָאֵל אֲנִי יְהוָה וְהוֹצֵאתִי אֶתְכֶם מִתַּחַת סִבְלֹת מִצְרַיִם וְהִצַּלְתִּי אֶתְכֶם מֵעֲבֹדָתָם וְגָאַלְתִּי אֶתְכֶם בִּזְרוֹעַ נְטוּיָה וּבִשְׁפָטִים גְּדֹלִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 7,
    verse: 'Я сделаю вас Своим народом и буду вашим Богом, и вы узнаете, что Я — Господь, Бог ваш, выведший вас из-под египетского бремени.',
    verse_ivrit: 'וְלָקַחְתִּי אֶתְכֶם לִי לְעָם וְהָיִיתִי לָכֶם לֵאלֹהִים וִידַעְתֶּם כִּי אֲנִי יְהוָה אֱלֹהֵיכֶם הַמּוֹצִיא אֶתְכֶם מִתַּחַת סִבְלוֹת מִצְרָיִם',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 8,
    verse: 'Я приведу вас в страну, которую, подняв [в клятве] Мою руку, [обещал] отдать Авраѓаму, Ицхаку и Яакову. Я отдам ее вам во владение. Я — Господь!”',
    verse_ivrit: 'וְהֵבֵאתִי אֶתְכֶם אֶל-הָאָרֶץ אֲשֶׁר נָשָׂאתִי אֶת-יָדִי לָתֵת אֹתָהּ לְאַבְרָהָם לְיִצְחָק וּלְיַעֲקֹב וְנָתַתִּי אֹתָהּ לָכֶם מוֹרָשָׁה אֲנִי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 9,
    verse: 'Моше передал все это сынам Израиля, но те не слушали его, обессиленные тяжким трудом.',
    verse_ivrit: 'וַיְדַבֵּר מֹשֶׁה כֵּן אֶל-בְּנֵי יִשְׂרָאֵל וְלֹא שָׁמְעוּ אֶל-מֹשֶׁה מִקֹּצֶר רוּחַ וּמֵעֲבֹדָה קָשָׁה',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 10,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 11,
    verse: '— Иди к фараону, властелину египетскому, и скажи ему, чтобы он отпустил сынов Израиля из своей страны.',
    verse_ivrit: 'בֹּא דַבֵּר אֶל-פַּרְעֹה מֶלֶךְ מִצְרָיִם וִישַׁלַּח אֶת-בְּנֵי-יִשְׂרָאֵל מֵאַרְצוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 12,
    verse: 'Моше ответил Господу: — Сыны Израиля не слушают меня — как же послушает меня фараон? Ведь я косноязычен!',
    verse_ivrit: 'וַיְדַבֵּר מֹשֶׁה לִפְנֵי יְהוָה לֵאמֹר הֵן בְּנֵי-יִשְׂרָאֵל לֹא-שָׁמְעוּ אֵלַי וְאֵיךְ יִשְׁמָעֵנִי פַרְעֹה וַאֲנִי עֲרַל שְׂפָתָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 13,
    verse: 'Господь, говоря с Моше и Аѓароном, дал им повеления о сынах Израиля и о фараоне, египетском царе, чтобы вывести сынов Израиля из земли египетской.',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה וְאֶל-אַהֲרֹן וַיְצַוֵּם אֶל-בְּנֵי יִשְׂרָאֵל וְאֶל-פַּרְעֹה מֶלֶךְ מִצְרָיִם לְהוֹצִיא אֶת-בְּנֵי-יִשְׂרָאֵל מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 14,
    verse: 'Вот главы родов: Сыны Реувена, первенца Израиля: Ханох, Палу, Хецрон и Карми. Это семейства Реувена.',
    verse_ivrit: 'אֵלֶּה רָאשֵׁי בֵית-אֲבֹתָם בְּנֵי רְאוּבֵן בְּכֹר יִשְׂרָאֵל חֲנוֹךְ וּפַלּוּא חֶצְרֹן וְכַרְמִי אֵלֶּה מִשְׁפְּחֹת רְאוּבֵן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 15,
    verse: 'Сыны Шимона: Йемуэль, Ямин, Оѓад, Яхин, Цохар и Шауль, сын ханаанеянки. Это семейства Шимона.',
    verse_ivrit: 'וּבְנֵי שִׁמְעוֹן יְמוּאֵל וְיָמִין וְאֹהַד וְיָכִין וְצֹחַר וְשָׁאוּל בֶּן-הַכְּנַעֲנִית אֵלֶּה מִשְׁפְּחֹת שִׁמְעוֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 16,
    verse: 'Вот имена сынов Леви по их родам: Гершон, Кеѓат и Мерари. Годы жизни Леви — сто тридцать семь лет.',
    verse_ivrit: 'וְאֵלֶּה שְׁמוֹת בְּנֵי-לֵוִי לְתֹלְדֹתָם גֵּרְשׁוֹן וּקְהָת וּמְרָרִי וּשְׁנֵי חַיֵּי לֵוִי שֶׁבַע וּשְׁלֹשִׁים וּמְאַת שָׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 17,
    verse: 'Сыны Гершона: Ливни и Шими, по их семействам.',
    verse_ivrit: 'בְּנֵי גֵרְשׁוֹן לִבְנִי וְשִׁמְעִי לְמִשְׁפְּחֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 18,
    verse: 'Сыны Кеѓата: Амрам, Ицѓар, Хеврон и Узиэль. Годы жизни Кеѓата — сто тридцать три года.',
    verse_ivrit: 'וּבְנֵי קְהָת עַמְרָם וְיִצְהָר וְחֶבְרוֹן וְעֻזִּיאֵל וּשְׁנֵי חַיֵּי קְהָת שָׁלֹשׁ וּשְׁלֹשִׁים וּמְאַת שָׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 19,
    verse: 'Сыны Мерари: Махли и Муши. Это семейства Леви, по их родам.',
    verse_ivrit: 'וּבְנֵי מְרָרִי מַחְלִי וּמוּשִׁי אֵלֶּה מִשְׁפְּחֹת הַלֵּוִי לְתֹלְדֹתָם',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 20,
    verse: 'Амрам взял в жены Йохевед, свою тетку, и она родила ему Аѓарона и Моше. Амрам прожил сто тридцать семь лет.',
    verse_ivrit: 'וַיִּקַּח עַמְרָם אֶת-יוֹכֶבֶד דֹּדָתוֹ לוֹ לְאִשָּׁה וַתֵּלֶד לוֹ אֶת-אַהֲרֹן וְאֶת-מֹשֶׁה וּשְׁנֵי חַיֵּי עַמְרָם שֶׁבַע וּשְׁלֹשִׁים וּמְאַת שָׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 21,
    verse: 'Сыны Ицѓара: Корах, Нефег и Зихри.',
    verse_ivrit: 'וּבְנֵי יִצְהָר קֹרַח וָנֶפֶג וְזִכְרִי',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 22,
    verse: 'Сыны Узиэля: Мишаэль, Эльцафан и Ситри.',
    verse_ivrit: 'וּבְנֵי עֻזִּיאֵל מִישָׁאֵל וְאֶלְצָפָן וְסִתְרִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 23,
    verse: 'Аѓарон взял в жены Элишеву, дочь Аминадава, сестру Нахшона, и она родила ему Надава, Авиѓу, Эльазара и Итамара.',
    verse_ivrit: 'וַיִּקַּח אַהֲרֹן אֶת-אֱלִישֶׁבַע בַּת-עַמִּינָדָב אֲחוֹת נַחְשׁוֹן לוֹ לְאִשָּׁה וַתֵּלֶד לוֹ אֶת-נָדָב וְאֶת-אֲבִיהוּא אֶת-אֶלְעָזָר וְאֶת-אִיתָמָר',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 24,
    verse: 'Сыны Кораха: Асир, Элькана и Авиасаф. Это семейства Кораха.',
    verse_ivrit: 'וּבְנֵי קֹרַח אַסִּיר וְאֶלְקָנָה וַאֲבִיאָסָף אֵלֶּה מִשְׁפְּחֹת הַקָּרְחִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 25,
    verse: 'Эльазар, сын Аѓарона, взял себе жену из дочерей Путиэля, и она родила ему Пинхаса. Это главы родов левитов, по семействам.',
    verse_ivrit: 'וְאֶלְעָזָר בֶּן-אַהֲרֹן לָקַח-לוֹ מִבְּנוֹת פּוּטִיאֵל לוֹ לְאִשָּׁה וַתֵּלֶד לוֹ אֶת-פִּינְחָס אֵלֶּה רָאשֵׁי אֲבוֹת הַלְוִיִּם לְמִשְׁפְּחֹתָם',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 26,
    verse: 'Это те Аѓарон и Моше, которым сказал Господь: “Уведите сынов Израиля из земли египетской, по воинствам их”.',
    verse_ivrit: 'הוּא אַהֲרֹן וּמֹשֶׁה אֲשֶׁר אָמַר יְהוָה לָהֶם הוֹצִיאוּ אֶת-בְּנֵי יִשְׂרָאֵל מֵאֶרֶץ מִצְרַיִם עַל-צִבְאֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 27,
    verse: 'Это они говорили с египетским царем, фараоном, чтобы увести сынов Израиля из Египта, — они, Моше и Аѓарон.',
    verse_ivrit: 'הֵם הַמְדַבְּרִים אֶל-פַּרְעֹה מֶלֶךְ-מִצְרַיִם לְהוֹצִיא אֶת-בְּנֵי-יִשְׂרָאֵל מִמִּצְרָיִם הוּא מֹשֶׁה וְאַהֲרֹן',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 28,
    verse: 'В тот день, когда говорил Господь с Моше в земле египетской,',
    verse_ivrit: 'וַיְהִי בְּיוֹם דִּבֶּר יְהוָה אֶל-מֹשֶׁה בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 29,
    verse: 'Господь сказал Моше: — Я Господь! Передай фараону, египетскому царю, все то, что Я говорю тебе.',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר אֲנִי יְהוָה דַּבֵּר אֶל-פַּרְעֹה מֶלֶךְ מִצְרַיִם אֵת כָּל-אֲשֶׁר אֲנִי דֹּבֵר אֵלֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 6,
    id_chapter_two: 2,
    poemNumber: 30,
    verse: 'Но Моше сказал Господу: — Ведь я косноязычен! Как же послушает меня фараон? ',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה לִפְנֵי יְהוָה הֵן אֲנִי עֲרַל שְׂפָתַיִם וְאֵיךְ יִשְׁמַע אֵלַי פַּרְעֹה',
    comment: '',
  },
  ///////////////////////////////////////////////       7777777777777777777777777          \\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Смотри, Я поставил тебя богом для фараона, а твой брат Аѓарон будет твоим пророком.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה רְאֵה נְתַתִּיךָ אֱלֹהִים לְפַרְעֹה וְאַהֲרֹן אָחִיךָ יִהְיֶה נְבִיאֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Ты будешь говорить все, что Я повелю тебе, а твой брат Аѓарон передаст фараону, чтобы тот отпустил сынов Израиля из своей страны.',
    verse_ivrit: 'אַתָּה תְדַבֵּר אֵת כָּל-אֲשֶׁר אֲצַוֶּךָּ וְאַהֲרֹן אָחִיךָ יְדַבֵּר אֶל-פַּרְעֹה וְשִׁלַּח אֶת-בְּנֵי-יִשְׂרָאֵל מֵאַרְצוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Я заставлю фараона упорствовать, дабы умножились Мои знамения и чудеса в земле египетской.',
    verse_ivrit: 'וַאֲנִי אַקְשֶׁה אֶת-לֵב פַּרְעֹה וְהִרְבֵּיתִי אֶת-אֹתֹתַי וְאֶת-מוֹפְתַי בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Фараон не послушает вас, и Я вознесу Свою руку над Египтом. Я выведу Мои воинства, Мой народ, сынов Израиля, из земли египетской, [наказав египтян] великими карами.',
    verse_ivrit: 'וְלֹא-יִשְׁמַע אֲלֵכֶם פַּרְעֹה וְנָתַתִּי אֶת-יָדִי בְּמִצְרָיִם וְהוֹצֵאתִי אֶת-צִבְאֹתַי אֶת-עַמִּי בְנֵי-יִשְׂרָאֵל מֵאֶרֶץ מִצְרַיִם בִּשְׁפָטִים גְּדֹלִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'И узнают египтяне, что Я — Господь, когда Я простру Мою руку над Египтом и выведу сынов Израиля оттуда.',
    verse_ivrit: 'וְיָדְעוּ מִצְרַיִם כִּי-אֲנִי יְהוָה בִּנְטֹתִי אֶת-יָדִי עַל-מִצְרָיִם וְהוֹצֵאתִי אֶת-בְּנֵי-יִשְׂרָאֵל מִתּוֹכָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Так Моше и Аѓарон и поступили: как повелел им Господь, так они и сделали.',
    verse_ivrit: 'וַיַּעַשׂ מֹשֶׁה וְאַהֲרֹן כַּאֲשֶׁר צִוָּה יְהוָה אֹתָם כֵּן עָשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Когда они говорили с фараоном, Моше было восемьдесят лет, а Аѓарону — восемьдесят три года.',
    verse_ivrit: 'וּמֹשֶׁה בֶּן-שְׁמֹנִים שָׁנָה וְאַהֲרֹן בֶּן-שָׁלֹשׁ וּשְׁמֹנִים שָׁנָה בְּדַבְּרָם אֶל-פַּרְעֹה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: '',
    verse_ivrit: '',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Господь сказал Моше и Аѓарону:',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה וְאֶל-אַהֲרֹן לֵאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: '— Когда фараон потребует от вас: “Дайте знамение о себе!” — то скажи Аѓарону: “Возьми свой посох и брось перед фараоном”, — и посох превратится в змея.',
    verse_ivrit: 'כִּי יְדַבֵּר אֲלֵכֶם פַּרְעֹה לֵאמֹר תְּנוּ לָכֶם מוֹפֵת וְאָמַרְתָּ אֶל-אַהֲרֹן קַח אֶת-מַטְּךָ וְהַשְׁלֵךְ לִפְנֵי-פַרְעֹה יְהִי לְתַנִּין',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Пришли Моше и Аѓарон к фараону и сделали так, как повелел Господь. Бросил Аѓарон свой посох перед фараоном и его слугами, и посох превратился в змея.',
    verse_ivrit: 'וַיָּבֹא מֹשֶׁה וְאַהֲרֹן אֶל-פַּרְעֹה וַיַּעֲשׂוּ כֵן כַּאֲשֶׁר צִוָּה יְהוָה וַיַּשְׁלֵךְ אַהֲרֹן אֶת-מַטֵּהוּ לִפְנֵי פַרְעֹה וְלִפְנֵי עֲבָדָיו וַיְהִי לְתַנִּין',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Тогда фараон призвал мудрецов и колдунов, и они, волхвы египетские, сделали своими чарами то же самое.',
    verse_ivrit: 'וַיִּקְרָא גַּם-פַּרְעֹה לַחֲכָמִים וְלַמְכַשְּׁפִים וַיַּעֲשׂוּ גַם-הֵם חַרְטֻמֵּי מִצְרַיִם בְּלַהֲטֵיהֶם כֵּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Каждый из них бросил свой посох, и их посохи стали змеями, но посох Аѓарона поглотил их посохи.',
    verse_ivrit: 'וַיַּשְׁלִיכוּ אִישׁ מַטֵּהוּ וַיִּהְיוּ לְתַנִּינִם וַיִּבְלַע מַטֵּה-אַהֲרֹן אֶת-מַטֹּתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Однако фараон ожесточился и не послушал [Моше и Аѓарона], как и предвещал Господь.',
    verse_ivrit: 'וַיֶּחֱזַק לֵב פַּרְעֹה וְלֹא שָׁמַע אֲלֵהֶם כַּאֲשֶׁר דִּבֶּר יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Господь сказал Моше: — Упрям фараон. Он отказывается отпустить народ.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה כָּבֵד לֵב פַּרְעֹה מֵאֵן לְשַׁלַּח הָעָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Пойди к фараону поутру; когда он будет спускаться к воде, стань перед ним на берегу реки, а посох, который превращался в змея, пусть будет у тебя в руке.',
    verse_ivrit: 'לֵךְ אֶל-פַּרְעֹה בַּבֹּקֶר הִנֵּה יֹצֵא הַמַּיְמָה וְנִצַּבְתָּ לִקְרָאתוֹ עַל-שְׂפַת הַיְאֹר וְהַמַּטֶּה אֲשֶׁר-נֶהְפַּךְ לְנָחָשׁ תִּקַּח בְּיָדֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Скажи ему: “Господь, Бог евреев, посылал меня сказать тебе: ‘Отпусти Мой народ, чтобы они служили Мне в пустыне’, — но ты до сих пор не послушался.',
    verse_ivrit: 'וְאָמַרְתָּ אֵלָיו יְהוָה אֱלֹהֵי הָעִבְרִים שְׁלָחַנִי אֵלֶיךָ לֵאמֹר שַׁלַּח אֶת-עַמִּי וְיַעַבְדֻנִי בַּמִּדְבָּר וְהִנֵּה לֹא-שָׁמַעְתָּ עַד-כֹּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Так говорит Господь: ‘Вот как ты узнаешь, что Я — Господь!’ Сейчас я ударю по водам Нила этим посохом, что у меня в руке, и вода обратится в кровь.',
    verse_ivrit: 'כֹּה אָמַר יְהוָה בְּזֹאת תֵּדַע כִּי אֲנִי יְהוָה הִנֵּה אָנֹכִי מַכֶּה בַּמַּטֶּה אֲשֶׁר-בְּיָדִי עַל-הַמַּיִם אֲשֶׁר בַּיְאֹר וְנֶהֶפְכוּ לְדָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Рыбы, [живущие] в реке, умрут. Река засмердит, и египтяне не смогут пить речную воду”.',
    verse_ivrit: 'וְהַדָּגָה אֲשֶׁר-בַּיְאֹר תָּמוּת וּבָאַשׁ הַיְאֹר וְנִלְאוּ מִצְרַיִם לִשְׁתּוֹת מַיִם מִן-הַיְאֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Господь сказал Моше: — Скажи Аѓарону: “Возьми посох и простри руку над водами Египта — над реками и каналами, озерами и всевозможными водоемами — и они превратятся в кровь”. По всей земле египетской будет кровь — [даже] на деревьях и на камнях!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה אֱמֹר אֶל-אַהֲרֹן קַח מַטְּךָ וּנְטֵה-יָדְךָ עַל-מֵימֵי מִצְרַיִם עַל-נַהֲרֹתָם עַל-יְאֹרֵיהֶם וְעַל-אַגְמֵיהֶם וְעַל כָּל-מִקְוֵה מֵימֵיהֶם וְיִהְיוּ-דָם וְהָיָה דָם בְּכָל-אֶרֶץ מִצְרַיִם וּבָעֵצִים וּבָאֲבָנִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Моше и Аѓарон сделали так, как повелел Господь. [Аѓарон] поднял посох и на глазах у фараона и его слуг ударил по водам Нила, и вся вода в реке превратилась в кровь.',
    verse_ivrit: 'וַיַּעֲשׂוּ-כֵן מֹשֶׁה וְאַהֲרֹן כַּאֲשֶׁר צִוָּה יְהוָה וַיָּרֶם בַּמַּטֶּה וַיַּךְ אֶת-הַמַּיִם אֲשֶׁר בַּיְאֹר לְעֵינֵי פַרְעֹה וּלְעֵינֵי עֲבָדָיו וַיֵּהָפְכוּ כָּל-הַמַּיִם אֲשֶׁר-בַּיְאֹר לְדָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Рыба в реке погибла, река засмердела, и египтяне не могли пить речную воду; кровь была по всей земле египетской.',
    verse_ivrit: 'וְהַדָּגָה אֲשֶׁר-בַּיְאֹר מֵתָה וַיִּבְאַשׁ הַיְאֹר וְלֹא-יָכְלוּ מִצְרַיִם לִשְׁתּוֹת מַיִם מִן-הַיְאֹר וַיְהִי הַדָּם בְּכָל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Но египетские волхвы своими чарами сделали то же самое. Фараон ожесточился и не послушал [Моше и Аѓарона], как и предвещал Господь.',
    verse_ivrit: 'וַיַּעֲשׂוּ-כֵן חַרְטֻמֵּי מִצְרַיִם בְּלָטֵיהֶם וַיֶּחֱזַק לֵב-פַּרְעֹה וְלֹא-שָׁמַע אֲלֵהֶם כַּאֲשֶׁר דִּבֶּר יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Повернулся фараон и пошел в свой дворец, не коснулось это его сердца.',
    verse_ivrit: 'וַיִּפֶן פַּרְעֹה וַיָּבֹא אֶל-בֵּיתוֹ וְלֹא-שָׁת לִבּוֹ גַּם-לָזֹאת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'А египтяне рыли [колодцы] вдоль реки, [чтобы добыть себе] питьевую воду, потому что не могли пить воду из реки.',
    verse_ivrit: 'וַיַּחְפְּרוּ כָל-מִצְרַיִם סְבִיבֹת הַיְאֹר מַיִם לִשְׁתּוֹת כִּי לֹא יָכְלוּ לִשְׁתֹּת מִמֵּימֵי הַיְאֹר',
    comment: '',
  }, {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'После того как Господь поразил реку, минуло семь дней.',
    verse_ivrit: 'וַיִּמָּלֵא שִׁבְעַת יָמִים אַחֲרֵי הַכּוֹת-יְהוָה אֶת-הַיְאֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Господь сказал Моше: — Иди к фараону и скажи ему: “Так говорит Господь: Отпусти Мой народ, чтобы они служили Мне.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה בֹּא אֶל-פַּרְעֹה וְאָמַרְתָּ אֵלָיו כֹּה אָמַר יְהוָה שַׁלַּח אֶת-עַמִּי וְיַעַבְדֻנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'А если ты откажешься отпустить [их], то Я поражу всю твою страну лягушками.',
    verse_ivrit: 'וְאִם-מָאֵן אַתָּה לְשַׁלֵּחַ הִנֵּה אָנֹכִי נֹגֵף אֶת-כָּל-גְּבוּלְךָ בַּצְפַרְדְּעִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Река будет кишеть лягушками, они вылезут [из реки] и заполнят твой дворец, твою спальню, твою постель, дома твоих слуг и [всего] твоего народа, [залезут] в ваши печи и квашни.',
    verse_ivrit: 'וְשָׁרַץ הַיְאֹר צְפַרְדְּעִים וְעָלוּ וּבָאוּ בְּבֵיתֶךָ וּבַחֲדַר מִשְׁכָּבְךָ וְעַל-מִטָּתֶךָ וּבְבֵית עֲבָדֶיךָ וּבְעַמֶּךָ וּבְתַנּוּרֶיךָ וּבְמִשְׁאֲרוֹתֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 7,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'К тебе самому, к твоему народу, ко всем твоим слугам придут лягушки”.',
    verse_ivrit: 'וּבְכָה וּבְעַמְּךָ וּבְכָל-עֲבָדֶיךָ יַעֲלוּ הַצְפַרְדְּעִים',
    comment: '',
  },
  ////////////////////////////////////////            888888888888888888888888888888888888      \ \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Скажи Аѓарону: “Простри руку с посохом над реками, каналами и озерами и приведи лягушек на землю египетскую”.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה אֱמֹר אֶל-אַהֲרֹן נְטֵה אֶת-יָדְךָ בְּמַטֶּךָ עַל-הַנְּהָרֹת עַל-הַיְאֹרִים וְעַל-הָאֲגַמִּים וְהַעַל אֶת-הַצְפַרְדְּעִים עַל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Аѓарон простер руку над водами Египта — и лягушки выползли [из реки] и заполнили землю египетскую.',
    verse_ivrit: 'וַיֵּט אַהֲרֹן אֶת-יָדוֹ עַל מֵימֵי מִצְרָיִם וַתַּעַל הַצְּפַרְדֵּעַ וַתְּכַס אֶת-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Но и волхвы своими чарами сделали то же самое — наслали лягушек на землю египетскую.',
    verse_ivrit: 'וַיַּעֲשׂוּ-כֵן הַחַרְטֻמִּים בְּלָטֵיהֶם וַיַּעֲלוּ אֶת-הַצְפַרְדְּעִים עַל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Фараон призвал Моше и Аѓарона. — Попросите Господа, — сказал он, — чтобы Он избавил меня и мой народ от лягушек, и тогда я отпущу народ [Израиля] принести жертвы Господу.',
    verse_ivrit: 'וַיִּקְרָא פַרְעֹה לְמֹשֶׁה וּלְאַהֲרֹן וַיֹּאמֶר הַעְתִּירוּ אֶל-יְהוָה וְיָסֵר הַצְפַרְדְּעִים מִמֶּנִּי וּמֵעַמִּי וַאֲשַׁלְּחָה אֶת-הָעָם וְיִזְבְּחוּ לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '— В величии твоем назначь мне, — сказал Моше фараону, — к какому сроку мне просить за тебя, за твоих рабов и твой народ, чтобы исчезли лягушки, покинув тебя и ваши дома. Они останутся только в реке.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה לְפַרְעֹה הִתְפָּאֵר עָלַי לְמָתַי אַעְתִּיר לְךָ וְלַעֲבָדֶיךָ וּלְעַמְּךָ לְהַכְרִית הַצְפַרְדְּעִים מִמְּךָ וּמִבָּתֶּיךָ רַק בַּיְאֹר תִּשָּׁאַרְנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: '— К завтрашнему [утру], — ответил [фараон]. — [Я сделаю так] по твоему слову, — сказал [Моше], — чтобы ты узнал, что нет подобного Господу, Богу нашему.',
    verse_ivrit: 'וַיֹּאמֶר לְמָחָר וַיֹּאמֶר כִּדְבָרְךָ לְמַעַן תֵּדַע כִּי-אֵין כַּיהוָה אֱלֹהֵינוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Лягушки покинут тебя, твои дома, твоих рабов и твой народ; они останутся только в реке.',
    verse_ivrit: 'וְסָרוּ הַצְפַרְדְּעִים מִמְּךָ וּמִבָּתֶּיךָ וּמֵעֲבָדֶיךָ וּמֵעַמֶּךָ רַק בַּיְאֹר תִּשָּׁאַרְנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Моше и Аѓарон вышли от фараона, и Моше попросил Господа, чтобы лягушки, которых Он навел на фараона, [исчезли].',
    verse_ivrit: 'וַיֵּצֵא מֹשֶׁה וְאַהֲרֹן מֵעִם פַּרְעֹה וַיִּצְעַק מֹשֶׁה אֶל-יְהוָה עַל-דְּבַר הַצְפַרְדְּעִים אֲשֶׁר-שָׂם לְפַרְעֹה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Господь сделал по слову Моше: лягушки в домах, во дворах и на полях издохли.',
    verse_ivrit: 'וַיַּעַשׂ יְהוָה כִּדְבַר מֹשֶׁה וַיָּמֻתוּ הַצְפַרְדְּעִים מִן-הַבָּתִּים מִן-הַחֲצֵרֹת וּמִן-הַשָּׂדֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Собирали их многими кучами, и на земле стоял смрад.',
    verse_ivrit: 'וַיִּצְבְּרוּ אֹתָם חֳמָרִם חֳמָרִם וַתִּבְאַשׁ הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Фараон, увидев, что снова все спокойно, стал упорствовать и не послушал [Моше и Аѓарона], как и предвещал Господь.',
    verse_ivrit: 'וַיַּרְא פַּרְעֹה כִּי הָיְתָה הָרְוָחָה וְהַכְבֵּד אֶת-לִבּוֹ וְלֹא שָׁמַע אֲלֵהֶם כַּאֲשֶׁר דִּבֶּר יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Господь сказал Моше: — Вели Аѓарону: “Занеси свой посох и ударь по пыли, [что лежит] на земле, и она превратится во вшей по всей земле египетской”.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה אֱמֹר אֶל-אַהֲרֹן נְטֵה אֶת-מַטְּךָ וְהַךְ אֶת-עֲפַר הָאָרֶץ וְהָיָה לְכִנִּם בְּכָל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Так они и сделали. Аѓарон занес руку с посохом и ударил по пыли, [что лежала] на земле, — и вши покрыли людей и скот. Вся пыль земная превратилась во вшей, по всей земле египетской.',
    verse_ivrit: 'וַיַּעֲשׂוּ-כֵן וַיֵּט אַהֲרֹן אֶת-יָדוֹ בְמַטֵּהוּ וַיַּךְ אֶת-עֲפַר הָאָרֶץ וַתְּהִי הַכִּנָּם בָּאָדָם וּבַבְּהֵמָה כָּל-עֲפַר הָאָרֶץ הָיָה כִנִּים בְּכָל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Волхвы своими чарами пытались сделать подобное, чтобы произвести вшей, но не смогли: вши остались и на людях, и на скоте.',
    verse_ivrit: 'וַיַּעֲשׂוּ-כֵן הַחַרְטֻמִּים בְּלָטֵיהֶם לְהוֹצִיא אֶת-הַכִּנִּים וְלֹא יָכֹלוּ וַתְּהִי הַכִּנָּם בָּאָדָם וּבַבְּהֵמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Волхвы сказали фараону: “Это перст Божий!” Но [еще сильнее] ожесточился фараон и не послушал их, как и предвещал Господь.',
    verse_ivrit: 'וַיֹּאמְרוּ הַחַרְטֻמִּם אֶל-פַּרְעֹה אֶצְבַּע אֱלֹהִים הִוא וַיֶּחֱזַק לֵב-פַּרְעֹה וְלֹא-שָׁמַע אֲלֵהֶם כַּאֲשֶׁר דִּבֶּר יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Господь сказал Моше: — Рано утром, когда фараон выйдет к воде, стань перед ним и скажи ему: “Так говорит Господь: Отпусти Мой народ, чтобы [они] служили Мне.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה הַשְׁכֵּם בַּבֹּקֶר וְהִתְיַצֵּב לִפְנֵי פַרְעֹה הִנֵּה יוֹצֵא הַמָּיְמָה וְאָמַרְתָּ אֵלָיו כֹּה אָמַר יְהוָה שַׁלַּח עַמִּי וְיַעַבְדֻנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'А если ты не отпустишь Мой народ, то Я нашлю на тебя, на твоих рабов, на твой народ и на твои дома сонмища тварей. Дома египтян наполнятся ими, как и сама земля, на которой живут египтяне.',
    verse_ivrit: 'כִּי אִם-אֵינְךָ מְשַׁלֵּחַ אֶת-עַמִּי הִנְנִי מַשְׁלִיחַ בְּךָ וּבַעֲבָדֶיךָ וּבְעַמְּךָ וּבְבָתֶּיךָ אֶת-הֶעָרֹב וּמָלְאוּ בָּתֵּי מִצְרַיִם אֶת-הֶעָרֹב וְגַם הָאֲדָמָה אֲשֶׁר-הֵם עָלֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Но Я сделаю так, что в земле Гошен, где пребывает Мой народ, в отличие [от всей страны] в тот день не будет тварей, чтобы ты знал, что Я, Господь, — в этой стране.',
    verse_ivrit: 'וְהִפְלֵיתִי בַיּוֹם הַהוּא אֶת-אֶרֶץ גֹּשֶׁן אֲשֶׁר עַמִּי עֹמֵד עָלֶיהָ לְבִלְתִּי הֱיוֹת-שָׁם עָרֹב לְמַעַן תֵּדַע כִּי אֲנִי יְהוָה בְּקֶרֶב הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Я сотворю избавление Моему народу, а твоему — нет. Это знамение будет завтра”.',
    verse_ivrit: 'וְשַׂמְתִּי פְדֻת בֵּין עַמִּי וּבֵין עַמֶּךָ לְמָחָר יִהְיֶה הָאֹת הַזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Господь сделал так, и явились несметные сонмища тварей во дворец фараона и в дома его рабов; по всему Египту земля гибла от нашествия тварей.',
    verse_ivrit: 'וַיַּעַשׂ יְהוָה כֵּן וַיָּבֹא עָרֹב כָּבֵד בֵּיתָה פַרְעֹה וּבֵית עֲבָדָיו וּבְכָל-אֶרֶץ מִצְרַיִם תִּשָּׁחֵת הָאָרֶץ מִפְּנֵי הֶעָרֹב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Фараон призвал Моше и Аѓарона и сказал: — Идите, принесите жертвы вашему Богу здесь, в этой стране.',
    verse_ivrit: 'וַיִּקְרָא פַרְעֹה אֶל-מֹשֶׁה וּלְאַהֲרֹן וַיֹּאמֶר לְכוּ זִבְחוּ לֵאלֹהֵיכֶם בָּאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: '— Неправильно так поступать, — ответил Моше, — ведь для египтян наше жертвоприношение Господу, Богу нашему, — мерзость. Если мы станем на глазах у египтян приносить жертву, которая для них — мерзость, то не побьют ли они нас камнями?',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה לֹא נָכוֹן לַעֲשׂוֹת כֵּן כִּי תּוֹעֲבַת מִצְרַיִם נִזְבַּח לַיהוָה אֱלֹהֵינוּ הֵן נִזְבַּח אֶת-תּוֹעֲבַת מִצְרַיִם לְעֵינֵיהֶם וְלֹא יִסְקְלֻנוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Мы должны уйти в пустыню на три дня пути и [там] принести жертвы Господу, Богу нашему, как Он нам и велит.',
    verse_ivrit: 'דֶּרֶךְ שְׁלֹשֶׁת יָמִים נֵלֵךְ בַּמִּדְבָּר וְזָבַחְנוּ לַיהוָה אֱלֹהֵינוּ כַּאֲשֶׁר יֹאמַר אֵלֵינוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: '— Я отпущу вас, — согласился фараон, — чтобы вы принесли в пустыне жертвы Господу, Богу вашему. Только далеко не уходите. И попросите за меня!',
    verse_ivrit: 'וַיֹּאמֶר פַּרְעֹה אָנֹכִי אֲשַׁלַּח אֶתְכֶם וּזְבַחְתֶּם לַיהוָה אֱלֹהֵיכֶם בַּמִּדְבָּר רַק הַרְחֵק לֹא-תַרְחִיקוּ לָלֶכֶת הַעְתִּירוּ בַּעֲדִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: '— Как только я выйду от тебя, я попрошу Господа, — ответил Моше. — Завтра же сонмища тварей оставят фараона, его рабов и его народ. Но пусть фараон не обманывает больше, пусть не мешает народу принести жертвы Господу!',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה הִנֵּה אָנֹכִי יוֹצֵא מֵעִמָּךְ וְהַעְתַּרְתִּי אֶל-יְהוָה וְסָר הֶעָרֹב מִפַּרְעֹה מֵעֲבָדָיו וּמֵעַמּוֹ מָחָר רַק אַל-יֹסֵף פַּרְעֹה הָתֵל לְבִלְתִּי שַׁלַּח אֶת-הָעָם לִזְבֹּחַ לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Моше вышел от фараона и попросил Господа.',
    verse_ivrit: 'וַיֵּצֵא מֹשֶׁה מֵעִם פַּרְעֹה וַיֶּעְתַּר אֶל-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Господь сделал по слову Моше и удалил от фараона, его рабов и его народа сонмища тварей — ничего не осталось.',
    verse_ivrit: 'וַיַּעַשׂ יְהוָה כִּדְבַר מֹשֶׁה וַיָּסַר הֶעָרֹב מִפַּרְעֹה מֵעֲבָדָיו וּמֵעַמּוֹ לֹא נִשְׁאַר אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 8,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Но и на этот раз фараон ожесточился и не отпустил народ. ',
    verse_ivrit: 'וַיַּכְבֵּד פַּרְעֹה אֶת-לִבּוֹ גַּם בַּפַּעַם הַזֹּאת וְלֹא שִׁלַּח אֶת-הָעָם',
    comment: '',
  },
  ////////////////////////////////////////            999999999999999999999999999999999999999999      \ \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Иди к фараону и скажи ему: “Так говорит Господь, Бог евреев: ‘Отпусти Мой народ, чтобы они служили Мне’.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה בֹּא אֶל-פַּרְעֹה וְדִבַּרְתָּ אֵלָיו כֹּה-אָמַר יְהוָה אֱלֹהֵי הָעִבְרִים שַׁלַּח אֶת-עַמִּי וְיַעַבְדֻנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Ведь если ты откажешься их отпустить, будешь и дальше удерживать их,',
    verse_ivrit: 'כִּי אִם-מָאֵן אַתָּה לְשַׁלֵּחַ וְעוֹדְךָ מַחֲזִיק בָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'то рука Господа будет на твоем скоте, который в поле, — на конях, ослах, верблюдах, крупном и мелком скоте — [начнется] очень тяжелый мор.',
    verse_ivrit: 'הִנֵּה יַד-יְהוָה הוֹיָה בְּמִקְנְךָ אֲשֶׁר בַּשָּׂדֶה בַּסּוּסִים בַּחֲמֹרִים בַּגְּמַלִּים בַּבָּקָר וּבַצֹּאן דֶּבֶר כָּבֵד מְאֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Но Господь сделает так, что из всего [скота] сынов Израиля ни одно [животное] не падет — в отличие от скота египтян”.',
    verse_ivrit: 'וְהִפְלָה יְהוָה בֵּין מִקְנֵה יִשְׂרָאֵל וּבֵין מִקְנֵה מִצְרָיִם וְלֹא יָמוּת מִכָּל-לִבְנֵי יִשְׂרָאֵל דָּבָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'И Господь назначил срок, сказав: — Завтра Господь сделает так в этой стране.',
    verse_ivrit: 'וַיָּשֶׂם יְהוָה מוֹעֵד לֵאמֹר מָחָר יַעֲשֶׂה יְהוָה הַדָּבָר הַזֶּה בָּאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'На следующий день Господь так и сделал, и пал весь скот египтян, а из скота сынов Израиля ни одно [животное] не погибло.',
    verse_ivrit: 'וַיַּעַשׂ יְהוָה אֶת-הַדָּבָר הַזֶּה מִמָּחֳרָת וַיָּמָת כֹּל מִקְנֵה מִצְרָיִם וּמִמִּקְנֵה בְנֵי-יִשְׂרָאֵל לֹא-מֵת אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Фараон послал [проверить] — и оказалось, что из скота [сынов] Израиля ни одно [животное] не пало. Но ожесточился фараон и не отпустил народ. ',
    verse_ivrit: 'וַיִּשְׁלַח פַּרְעֹה וְהִנֵּה לֹא-מֵת מִמִּקְנֵה יִשְׂרָאֵל עַד-אֶחָד וַיִּכְבַּד לֵב פַּרְעֹה וְלֹא שִׁלַּח אֶת-הָעָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Господь сказал Моше и Аѓарону: — Наберите полные пригоршни пепла из печи, и пусть Моше подбросит [пепел] к небу на глазах у фараона.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה וְאֶל-אַהֲרֹן קְחוּ לָכֶם מְלֹא חָפְנֵיכֶם פִּיחַ כִּבְשָׁן וּזְרָקוֹ מֹשֶׁה הַשָּׁמַיְמָה לְעֵינֵי פַרְעֹה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'И станет [пепел] пылью по всей земле египетской, и появятся на людях и на скоте воспаления, переходящие в нарывы: [так будет] по всей земле египетской.',
    verse_ivrit: 'וְהָיָה לְאָבָק עַל כָּל-אֶרֶץ מִצְרָיִם וְהָיָה עַל-הָאָדָם וְעַל-הַבְּהֵמָה לִשְׁחִין פֹּרֵחַ אֲבַעְבֻּעֹת בְּכָל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Они взяли пепел из печи и стали перед фараоном. Моше подбросил [пепел] к небу, и появились на людях и на скоте воспаления с нарывами.',
    verse_ivrit: 'וַיִּקְחוּ אֶת-פִּיחַ הַכִּבְשָׁן וַיַּעַמְדוּ לִפְנֵי פַרְעֹה וַיִּזְרֹק אֹתוֹ מֹשֶׁה הַשָּׁמָיְמָה וַיְהִי שְׁחִין אֲבַעְבֻּעֹת פֹּרֵחַ בָּאָדָם וּבַבְּהֵמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Волхвы не могли [больше] соперничать с Моше из-за язв: на них самих, как и на всех египтянах, были язвы.',
    verse_ivrit: 'וְלֹא-יָכְלוּ הַחַרְטֻמִּים לַעֲמֹד לִפְנֵי מֹשֶׁה מִפְּנֵי הַשְּׁחִין כִּי-הָיָה הַשְּׁחִין בַּחַרְטֻמִּם וּבְכָל-מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Но Господь заставил фараона упорствовать, и тот [вновь] не послушал [Моше и Аѓарона], как Господь и предвещал Моше.',
    verse_ivrit: 'וַיְחַזֵּק יְהוָה אֶת-לֵב פַּרְעֹה וְלֹא שָׁמַע אֲלֵהֶם כַּאֲשֶׁר דִּבֶּר יְהוָה אֶל-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Господь сказал Моше: — Рано утром стань перед фараоном и скажи ему: “Так говорит Господь, Бог евреев: Отпусти Мой народ, чтобы они служили Мне.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה הַשְׁכֵּם בַּבֹּקֶר וְהִתְיַצֵּב לִפְנֵי פַרְעֹה וְאָמַרְתָּ אֵלָיו כֹּה-אָמַר יְהוָה אֱלֹהֵי הָעִבְרִים שַׁלַּח אֶת-עַמִּי וְיַעַבְדֻנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Ибо на сей раз Я пошлю на тебя самого, на твоих рабов и на твой народ все Мои [самые страшные] казни, чтобы ты узнал, что нет подобного Мне на всей земле.',
    verse_ivrit: 'כִּי בַּפַּעַם הַזֹּאת אֲנִי שֹׁלֵחַ אֶת-כָּל-מַגֵּפֹתַי אֶל-לִבְּךָ וּבַעֲבָדֶיךָ וּבְעַמֶּךָ בַּעֲבוּר תֵּדַע כִּי אֵין כָּמֹנִי בְּכָל-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Если бы Я сейчас простер руку Мою и поразил тебя и твой народ мором, то не стало бы тебя на земле!',
    verse_ivrit: 'כִּי עַתָּה שָׁלַחְתִּי אֶת-יָדִי וָאַךְ אוֹתְךָ וְאֶת-עַמְּךָ בַּדָּבֶר וַתִּכָּחֵד מִן-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Но Я оставлю тебя — лишь для того, чтобы показать тебе Мою силу и возвестить Имя Мое по всей земле.',
    verse_ivrit: 'וְאוּלָם בַּעֲבוּר זֹאת הֶעֱמַדְתִּיךָ בַּעֲבוּר הַרְאֹתְךָ אֶת-כֹּחִי וּלְמַעַן סַפֵּר שְׁמִי בְּכָל-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Раз ты по-прежнему превозносишься над Моим народом и отказываешься отпустить его,',
    verse_ivrit: 'עוֹדְךָ מִסְתּוֹלֵל בְּעַמִּי לְבִלְתִּי שַׁלְּחָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'то завтра, в этот же час, Я обрушу [на Египет] очень сильный град, подобного которому не было в Египте со дня его возникновения и доныне.',
    verse_ivrit: 'הִנְנִי מַמְטִיר כָּעֵת מָחָר בָּרָד כָּבֵד מְאֹד אֲשֶׁר לֹא-הָיָה כָמֹהוּ בְּמִצְרַיִם לְמִן-הַיּוֹם הִוָּסְדָה וְעַד-עָתָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Итак, вели собрать под кров весь твой скот и все, что есть у тебя в поле. Град обрушится на людей и на скот, которые останутся в поле и не будут собраны под кров, и они умрут”.',
    verse_ivrit: 'וְעַתָּה שְׁלַח הָעֵז אֶת-מִקְנְךָ וְאֵת כָּל-אֲשֶׁר לְךָ בַּשָּׂדֶה כָּל-הָאָדָם וְהַבְּהֵמָה אֲשֶׁר-יִמָּצֵא בַשָּׂדֶה וְלֹא יֵאָסֵף הַבַּיְתָה וְיָרַד עֲלֵהֶם הַבָּרָד וָמֵתוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Те из слуг фараона, которые убоялись слова Господа, увели своих рабов и стада под кров,',
    verse_ivrit: 'הַיָּרֵא אֶת-דְּבַר יְהוָה מֵעַבְדֵי פַּרְעֹה הֵנִיס אֶת-עֲבָדָיו וְאֶת-מִקְנֵהוּ אֶל-הַבָּתִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'а те, что пренебрегли словом Господа, оставили своих рабов и скот в поле.',
    verse_ivrit: 'וַאֲשֶׁר לֹא-שָׂם לִבּוֹ אֶל-דְּבַר יְהוָה וַיַּעֲזֹב אֶת-עֲבָדָיו וְאֶת-מִקְנֵהוּ בַּשָּׂדֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Господь сказал Моше: — Воздень руку к небу, и падет град на всю землю египетскую — на людей, на скот и на всю полевую траву, по всей земле египетской.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה נְטֵה אֶת-יָדְךָ עַל-הַשָּׁמַיִם וִיהִי בָרָד בְּכָל-אֶרֶץ מִצְרָיִם עַל-הָאָדָם וְעַל-הַבְּהֵמָה וְעַל כָּל-עֵשֶׂב הַשָּׂדֶה בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Моше простер посох к небу, и послал Господь раскаты грома и град, и огонь пал на землю. Так обрушил Господь град на землю египетскую!',
    verse_ivrit: 'וַיֵּט מֹשֶׁה אֶת-מַטֵּהוּ עַל-הַשָּׁמַיִם וַיהוָה נָתַן קֹלֹת וּבָרָד וַתִּהֲלַךְ אֵשׁ אָרְצָה וַיַּמְטֵר יְהוָה בָּרָד עַל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Град был очень сильный, и огонь пылал внутри градин — не бывало такого во всей земле египетской, с тех пор как египтяне стали народом.',
    verse_ivrit: 'וַיְהִי בָרָד וְאֵשׁ מִתְלַקַּחַת בְּתוֹךְ הַבָּרָד כָּבֵד מְאֹד אֲשֶׁר לֹא-הָיָה כָמֹהוּ בְּכָל-אֶרֶץ מִצְרַיִם מֵאָז הָיְתָה לְגוֹי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'По всему Египту град побил все, что было в поле, — и людей, и скот. Побил он и всю траву полевую, поломал все деревья в поле.',
    verse_ivrit: 'וַיַּךְ הַבָּרָד בְּכָל-אֶרֶץ מִצְרַיִם אֵת כָּל-אֲשֶׁר בַּשָּׂדֶה מֵאָדָם וְעַד-בְּהֵמָה וְאֵת כָּל-עֵשֶׂב הַשָּׂדֶה הִכָּה הַבָּרָד וְאֶת-כָּל-עֵץ הַשָּׂדֶה שִׁבֵּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Только в земле Гошен, где жили сыны Израиля, не было града.',
    verse_ivrit: 'רַק בְּאֶרֶץ גֹּשֶׁן אֲשֶׁר-שָׁם בְּנֵי יִשְׂרָאֵל לֹא הָיָה בָּרָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Фараон послал [слуг своих] и призвал Моше и Аѓарона. — На этот раз я согрешил, — сказал он им. — Господь прав, а я и мой народ виноваты.',
    verse_ivrit: 'וַיִּשְׁלַח פַּרְעֹה וַיִּקְרָא לְמֹשֶׁה וּלְאַהֲרֹן וַיֹּאמֶר אֲלֵהֶם חָטָאתִי הַפָּעַם יְהוָה הַצַּדִּיק וַאֲנִי וְעַמִּי הָרְשָׁעִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Попросите Господа, ибо довольно было Божьего грома и града. И я отпущу вас, больше вы [здесь] не задержитесь!',
    verse_ivrit: 'הַעְתִּירוּ אֶל-יְהוָה וְרַב מִהְיֹת קֹלֹת אֱלֹהִים וּבָרָד וַאֲשַׁלְּחָה אֶתְכֶם וְלֹא תֹסִפוּן לַעֲמֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Моше ответил ему: — Как только я выйду из города и воздену руки к Господу, гром прекратится и града больше не будет — чтобы ты узнал, что [вся] земля принадлежит Господу!',
    verse_ivrit: 'וַיֹּאמֶר אֵלָיו מֹשֶׁה כְּצֵאתִי אֶת-הָעִיר אֶפְרֹשׂ אֶת-כַּפַּי אֶל-יְהוָה הַקֹּלוֹת יֶחְדָּלוּן וְהַבָּרָד לֹא יִהְיֶה-עוֹד לְמַעַן תֵּדַע כִּי לַיהוָה הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Знаю я: ты и твои рабы все еще не боитесь Господа Бога.',
    verse_ivrit: 'וְאַתָּה וַעֲבָדֶיךָ יָדַעְתִּי כִּי טֶרֶם תִּירְאוּן מִפְּנֵי יְהוָה אֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Лен и ячмень были побиты, потому что ячмень колосился, а лен был в стеблях.',
    verse_ivrit: 'וְהַפִּשְׁתָּה וְהַשְּׂעֹרָה נֻכָּתָה כִּי הַשְּׂעֹרָה אָבִיב וְהַפִּשְׁתָּה גִּבְעֹל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Пшеница же и полба не пострадали, потому что они созревают позже.',
    verse_ivrit: 'וְהַחִטָּה וְהַכֻּסֶּמֶת לֹא נֻכּוּ כִּי אֲפִילֹת הֵנָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Моше оставил фараона, [вышел] из города и простер руки к Господу. Гром и град [немедленно] прекратились, и дождь перестал литься на землю.',
    verse_ivrit: 'וַיֵּצֵא מֹשֶׁה מֵעִם פַּרְעֹה אֶת-הָעִיר וַיִּפְרֹשׂ כַּפָּיו אֶל-יְהוָה וַיַּחְדְּלוּ הַקֹּלוֹת וְהַבָּרָד וּמָטָר לֹא-נִתַּךְ אָרְצָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Увидел фараон, что прекратились ливень и град с громом, — и продолжил грешить.',
    verse_ivrit: 'וַיַּרְא פַּרְעֹה כִּי-חָדַל הַמָּטָר וְהַבָּרָד וְהַקֹּלֹת וַיֹּסֶף לַחֲטֹא וַיַּכְבֵּד לִבּוֹ הוּא וַעֲבָדָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(И явился Я)',
    id_book: 1,
    id_chapter: 9,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Упорствовал и он, и рабы его. Ожесточился фараон и не отпустил сынов Израиля, как и предвещал Господь через Моше.',
    verse_ivrit: 'וַיֶּחֱזַק לֵב פַּרְעֹה וְלֹא שִׁלַּח אֶת-בְּנֵי יִשְׂרָאֵל כַּאֲשֶׁר דִּבֶּר יְהוָה בְּיַד-מֹשֶׁה',
    comment: '',
  },
  ////////////////////////////////////////            101010101010101010101010101010101010101      \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Иди к фараону, ибо Я заставил упорствовать и его самого, и его рабов, чтобы явить среди них Мои знамения,',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה בֹּא אֶל-פַּרְעֹה כִּי-אֲנִי הִכְבַּדְתִּי אֶת-לִבּוֹ וְאֶת-לֵב עֲבָדָיו לְמַעַן שִׁתִי אֹתֹתַי אֵלֶּה בְּקִרְבּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'чтобы вы рассказывали своим сыновьям и внукам о том, как Я покарал египтян, и о Моих знамениях, которые Я свершил среди них, и чтобы вы знали, что Я — Господь!',
    verse_ivrit: 'וּלְמַעַן תְּסַפֵּר בְּאָזְנֵי בִנְךָ וּבֶן-בִּנְךָ אֵת אֲשֶׁר הִתְעַלַּלְתִּי בְּמִצְרַיִם וְאֶת-אֹתֹתַי אֲשֶׁר-שַׂמְתִּי בָם וִידַעְתֶּם כִּי-אֲנִי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Моше и Аѓарон пришли к фараону и сказали ему: — Так говорит Господь, Бог евреев: “Когда же ты покоришься Мне? Отпусти Мой народ, чтобы они служили Мне.',
    verse_ivrit: 'וַיָּבֹא מֹשֶׁה וְאַהֲרֹן אֶל-פַּרְעֹה וַיֹּאמְרוּ אֵלָיו כֹּה-אָמַר יְהוָה אֱלֹהֵי הָעִבְרִים עַד-מָתַי מֵאַנְתָּ לֵעָנֹת מִפָּנָי שַׁלַּח עַמִּי וְיַעַבְדֻנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'А если ты откажешься отпустить Мой народ, то завтра Я наведу на твою страну саранчу.',
    verse_ivrit: 'כִּי אִם-מָאֵן אַתָּה לְשַׁלֵּחַ אֶת-עַמִּי הִנְנִי מֵבִיא מָחָר אַרְבֶּה בִּגְבֻלֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Саранча покроет всю поверхность земли, так что и земли видно не будет. Она пожрет то немногое, что уцелело после града, пожрет всякое дерево, растущее у вас в поле.',
    verse_ivrit: 'וְכִסָּה אֶת-עֵין הָאָרֶץ וְלֹא יוּכַל לִרְאֹת אֶת-הָאָרֶץ וְאָכַל אֶת-יֶתֶר הַפְּלֵטָה הַנִּשְׁאֶרֶת לָכֶם מִן-הַבָּרָד וְאָכַל אֶת-כָּל-הָעֵץ הַצֹּמֵחַ לָכֶם מִן-הַשָּׂדֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Ею наполнятся твои дворцы, дома твоих рабов и жилища всех египтян. Такого не видали ни твои отцы, ни твои деды — с тех пор, как живут они на этой земле, и до сего дня”. И, повернувшись, [Моше] вышел от фараона.',
    verse_ivrit: 'וּמָלְאוּ בָתֶּיךָ וּבָתֵּי כָל-עֲבָדֶיךָ וּבָתֵּי כָל-מִצְרַיִם אֲשֶׁר לֹא-רָאוּ אֲבֹתֶיךָ וַאֲבוֹת אֲבֹתֶיךָ מִיּוֹם הֱיוֹתָם עַל-הָאֲדָמָה עַד הַיּוֹם הַזֶּה וַיִּפֶן וַיֵּצֵא מֵעִם פַּרְעֹה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Тогда сказали слуги фараона: — Сколько еще будем мы попадать в эту ловушку? Отпусти этих людей, пусть они служат Господу, своему Богу. Разве ты не видишь, что Египет гибнет?',
    verse_ivrit: 'וַיֹּאמְרוּ עַבְדֵי פַרְעֹה אֵלָיו עַד-מָתַי יִהְיֶה זֶה לָנוּ לְמוֹקֵשׁ שַׁלַּח אֶת-הָאֲנָשִׁים וְיַעַבְדוּ אֶת-יְהוָה אֱלֹהֵיהֶם הֲטֶרֶם תֵּדַע כִּי אָבְדָה מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Моше и Аѓарона вернули к фараону, и он сказал им: — Идите, служите Господу, вашему Богу! Кто же именно пойдет?',
    verse_ivrit: 'וַיּוּשַׁב אֶת-מֹשֶׁה וְאֶת-אַהֲרֹן אֶל-פַּרְעֹה וַיֹּאמֶר אֲלֵהֶם לְכוּ עִבְדוּ אֶת-יְהוָה אֱלֹהֵיכֶם מִי וָמִי הַהֹלְכִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: '— Мы пойдем с нашими юношами и стариками, — ответил Моше, — мы пойдем с сыновьями и дочерьми, с мелким и крупным скотом. Ведь у нас праздник Господень!',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה בִּנְעָרֵינוּ וּבִזְקֵנֵינוּ נֵלֵךְ בְּבָנֵינוּ וּבִבְנוֹתֵנוּ בְּצֹאנֵנוּ וּבִבְקָרֵנוּ נֵלֵךְ כִּי חַג-יְהוָה לָנוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Но фараон сказал им: — Пусть Господь так будет с вами, как я отпущу вас с вашими детьми! Ибо ваши намерения коварны!',
    verse_ivrit: 'וַיֹּאמֶר אֲלֵהֶם יְהִי כֵן יְהוָה עִמָּכֶם כַּאֲשֶׁר אֲשַׁלַּח אֶתְכֶם וְאֶת-טַפְּכֶם רְאוּ כִּי רָעָה נֶגֶד פְּנֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Не бывать этому! Пусть идут лишь мужчины и служат Господу, как вы и просили. И велел фараон прогнать их от себя прочь.',
    verse_ivrit: 'לֹא כֵן לְכוּ-נָא הַגְּבָרִים וְעִבְדוּ אֶת-יְהוָה כִּי אֹתָהּ אַתֶּם מְבַקְשִׁים וַיְגָרֶשׁ אֹתָם מֵאֵת פְּנֵי פַרְעֹה',
    comment: '',
  },
  ////////////////////////////////////////            101010101010101010101010101010101010101010101010      \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 12,
    verse: 'Господь сказал Моше: — Простри руку над землей египетской, чтобы привести саранчу, и прилетит саранча на землю египетскую, и пожрет всю траву на земле, все, что уцелело от града.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה נְטֵה יָדְךָ עַל-אֶרֶץ מִצְרַיִם בָּאַרְבֶּה וְיַעַל עַל-אֶרֶץ מִצְרָיִם וְיֹאכַל אֶת-כָּל-עֵשֶׂב הָאָרֶץ אֵת כָּל-אֲשֶׁר הִשְׁאִיר הַבָּרָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 13,
    verse: 'Моше простер свой посох над землей египетской, и весь тот день и всю ночь Господь насылал восточный ветер на землю. Настало утро, и восточный ветер принес саранчу.',
    verse_ivrit: 'וַיֵּט מֹשֶׁה אֶת-מַטֵּהוּ עַל-אֶרֶץ מִצְרַיִם וַיהוָה נִהַג רוּחַ-קָדִים בָּאָרֶץ כָּל-הַיּוֹם הַהוּא וְכָל-הַלָּיְלָה הַבֹּקֶר הָיָה וְרוּחַ הַקָּדִים נָשָׂא אֶת-הָאַרְבֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 14,
    verse: 'Саранча пришла на всю землю египетскую. Ее было очень много по всей стране. Не было такого нашествия саранчи ранее и не будет впредь!',
    verse_ivrit: 'וַיַּעַל הָאַרְבֶּה עַל כָּל-אֶרֶץ מִצְרַיִם וַיָּנַח בְּכֹל גְּבוּל מִצְרָיִם כָּבֵד מְאֹד לְפָנָיו לֹא-הָיָה כֵן אַרְבֶּה כָּמֹהוּ וְאַחֲרָיו לֹא יִהְיֶה-כֵּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 15,
    verse: 'Саранча покрыла всю землю, так что земля стала черной; она съела всю траву на земле и все плоды на деревьях, которые остались после града. По всей земле египетской не осталось ни зелени на деревьях, ни полевой травы.',
    verse_ivrit: 'וַיְכַס אֶת-עֵין כָּל-הָאָרֶץ וַתֶּחְשַׁךְ הָאָרֶץ וַיֹּאכַל אֶת-כָּל-עֵשֶׂב הָאָרֶץ וְאֵת כָּל-פְּרִי הָעֵץ אֲשֶׁר הוֹתִיר הַבָּרָד וְלֹא-נוֹתַר כָּל-יֶרֶק בָּעֵץ וּבְעֵשֶׂב הַשָּׂדֶה בְּכָל-אֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 16,
    verse: 'Фараон спешно призвал Моше и Аѓарона и сказал: — Согрешил я пред Господом, Богом вашим, и перед вами.',
    verse_ivrit: 'וַיְמַהֵר פַּרְעֹה לִקְרֹא לְמֹשֶׁה וּלְאַהֲרֹן וַיֹּאמֶר חָטָאתִי לַיהוָה אֱלֹהֵיכֶם וְלָכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 17,
    verse: 'Простите же мое прегрешение и на этот раз. Попросите Господа, Бога вашего, чтобы Он отвратил от меня эту погибель!',
    verse_ivrit: 'וְעַתָּה שָׂא נָא חַטָּאתִי אַךְ הַפַּעַם וְהַעְתִּירוּ לַיהוָה אֱלֹהֵיכֶם וְיָסֵר מֵעָלַי רַק אֶת-הַמָּוֶת הַזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 18,
    verse: '[Моше] вышел от фараона и попросил Господа.',
    verse_ivrit: 'וַיֵּצֵא מֵעִם פַּרְעֹה וַיֶּעְתַּר אֶל-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 19,
    verse: 'Господь переменил ветер на очень сильный западный [ветер], и тот унес саранчу, смел ее в Тростниковое море. Во всех пределах Египта не осталось ни одной саранчи.',
    verse_ivrit: 'וַיַּהֲפֹךְ יְהוָה רוּחַ-יָם חָזָק מְאֹד וַיִּשָּׂא אֶת-הָאַרְבֶּה וַיִּתְקָעֵהוּ יָמָּה סּוּף לֹא נִשְׁאַר אַרְבֶּה אֶחָד בְּכֹל גְּבוּל מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 20,
    verse: 'Но Господь заставил фараона упорствовать, и тот не отпустил сынов Израиля.',
    verse_ivrit: 'וַיְחַזֵּק יְהוָה אֶת-לֵב פַּרְעֹה וְלֹא שִׁלַּח אֶת-בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 21,
    verse: '[Тогда] Господь сказал Моше: — Воздень руку к небу, и тьма настанет в земле египетской. Эта тьма будет осязаема!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה נְטֵה יָדְךָ עַל-הַשָּׁמַיִם וִיהִי חֹשֶׁךְ עַל-אֶרֶץ מִצְרָיִם וְיָמֵשׁ חֹשֶׁךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 22,
    verse: 'Моше воздел руку к небу, и настала густая тьма по всей земле египетской на три дня.',
    verse_ivrit: 'וַיֵּט מֹשֶׁה אֶת-יָדוֹ עַל-הַשָּׁמָיִם וַיְהִי חֹשֶׁךְ-אֲפֵלָה בְּכָל-אֶרֶץ מִצְרַיִם שְׁלֹשֶׁת יָמִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 23,
    verse: '[Египтяне] не видели друг друга, и три дня никто не мог встать со своего места. А в жилищах всех сынов Израиля был свет.',
    verse_ivrit: 'לֹא-רָאוּ אִישׁ אֶת-אָחִיו וְלֹא-קָמוּ אִישׁ מִתַּחְתָּיו שְׁלֹשֶׁת יָמִים וּלְכָל-בְּנֵי יִשְׂרָאֵל הָיָה אוֹר בְּמוֹשְׁבֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 24,
    verse: 'Фараон призвал Моше и сказал: — Идите, служите Господу. Пусть только ваш мелкий и крупный скот остается, а ваши дети пусть идут с вами.',
    verse_ivrit: 'וַיִּקְרָא פַרְעֹה אֶל-מֹשֶׁה וַיֹּאמֶר לְכוּ עִבְדוּ אֶת-יְהוָה רַק צֹאנְכֶם וּבְקַרְכֶם יֻצָּג גַּם-טַפְּכֶם יֵלֵךְ עִמָּכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 25,
    verse: 'Но Моше ответил: — Ты должен отдать нам животных для жертв и всесожжений, которые мы принесем Господу, нашему Богу.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה גַּם-אַתָּה תִּתֵּן בְּיָדֵנוּ זְבָחִים וְעֹלֹת וְעָשִׂינוּ לַיהוָה אֱלֹהֵינוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 26,
    verse: 'Наш скот также пойдет с нами, весь без остатка, ведь из него мы отберем [жертвы] для служения Господу, нашему Богу. А пока мы не придем туда, мы не узнаем, что именно мы должны принести в жертву Господу.',
    verse_ivrit: 'וְגַם-מִקְנֵנוּ יֵלֵךְ עִמָּנוּ לֹא תִשָּׁאֵר פַּרְסָה כִּי מִמֶּנּוּ נִקַּח לַעֲבֹד אֶת-יְהוָה אֱלֹהֵינוּ וַאֲנַחְנוּ לֹא-נֵדַע מַה-נַּעֲבֹד אֶת-יְהוָה עַד-בֹּאֵנוּ שָׁמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 27,
    verse: 'Но Господь заставил фараона упорствовать, и тот не согласился их отпустить.',
    verse_ivrit: 'וַיְחַזֵּק יְהוָה אֶת-לֵב פַּרְעֹה וְלֹא אָבָה לְשַׁלְּחָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 28,
    verse: '— Уходи от меня, — сказал фараон [Моше], — и остерегайся, чтобы не попадаться мне больше. В тот день, когда ты меня увидишь, ты умрешь!',
    verse_ivrit: 'וַיֹּאמֶר-לוֹ פַרְעֹה לֵךְ מֵעָלָי הִשָּׁמֶר לְךָ אַל-תֹּסֶף רְאוֹת פָּנַי כִּי בְּיוֹם רְאֹתְךָ פָנַי תָּמוּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЭРА(Пойди)',
    id_book: 1,
    id_chapter: 10,
    id_chapter_two: 2,
    poemNumber: 29,
    verse: '— Ты не ошибся, — ответил Моше, — больше я тебя не увижу!',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה כֵּן דִּבַּרְתָּ לֹא-אֹסִף עוֹד רְאוֹת פָּנֶיךָ',
    comment: '',
  },
  ////////////////////////////////////////            1111111111111111111111111111111111111111      \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Еще одну казнь наведу Я на фараона и на Египет, и после этого он отпустит вас отсюда. И не просто отпустит, а прогонит вас отсюда прочь!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה עוֹד נֶגַע אֶחָד אָבִיא עַל-פַּרְעֹה וְעַל-מִצְרַיִם אַחֲרֵי-כֵן יְשַׁלַּח אֶתְכֶם מִזֶּה כְּשַׁלְּחוֹ כָּלָה גָּרֵשׁ יְגָרֵשׁ אֶתְכֶם מִזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Скажи народу, чтобы каждый [мужчина] взял взаймы серебряные и золотые вещи у своего знакомого [египтянина], а каждая [женщина] — у своей знакомой [египтянки].',
    verse_ivrit: 'דַּבֶּר-נָא בְּאָזְנֵי הָעָם וְיִשְׁאֲלוּ אִישׁ מֵאֵת רֵעֵהוּ וְאִשָּׁה מֵאֵת רְעוּתָהּ כְּלֵי-כֶסֶף וּכְלֵי זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Господь внушил египтянам приязнь к народу [Израиля]. И сам Моше тоже был весьма велик в земле египетской — в глазах слуг фараона и в глазах [египетского] народа.',
    verse_ivrit: 'וַיִּתֵּן יְהוָה אֶת-חֵן הָעָם בְּעֵינֵי מִצְרָיִם גַּם הָאִישׁ מֹשֶׁה גָּדוֹל מְאֹד בְּאֶרֶץ מִצְרַיִם בְּעֵינֵי עַבְדֵי-פַרְעֹה וּבְעֵינֵי הָעָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Моше сказал: — Так говорит Господь: “Около полуночи Я пройду по Египту —',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה כֹּה אָמַר יְהוָה כַּחֲצֹת הַלַּיְלָה אֲנִי יוֹצֵא בְּתוֹךְ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'и каждый первенец в земле египетской умрет, от первенца фараона, восседающего на престоле, до первенца рабыни при жерновах, и всякий первенец скота.',
    verse_ivrit: 'וּמֵת כָּל-בְּכוֹר בְּאֶרֶץ מִצְרַיִם מִבְּכוֹר פַּרְעֹה הַיֹּשֵׁב עַל-כִּסְאוֹ עַד בְּכוֹר הַשִּׁפְחָה אֲשֶׁר אַחַר הָרֵחָיִם וְכֹל בְּכוֹר בְּהֵמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'По всей земле египетской будет великий вопль, какого не было прежде и не будет более.',
    verse_ivrit: 'וְהָיְתָה צְעָקָה גְדֹלָה בְּכָל-אֶרֶץ מִצְרָיִם אֲשֶׁר כָּמֹהוּ לֹא נִהְיָתָה וְכָמֹהוּ לֹא תֹסִף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'А на сынов Израиля [даже] пес не осмелится залаять — ни на человека, ни на скот — чтобы вы знали, что Господь разделяет Египет и Израиль”.',
    verse_ivrit: 'וּלְכֹל בְּנֵי יִשְׂרָאֵל לֹא יֶחֱרַץ-כֶּלֶב לְשֹׁנוֹ לְמֵאִישׁ וְעַד-בְּהֵמָה לְמַעַן תֵּדְעוּן אֲשֶׁר יַפְלֶה יְהוָה בֵּין מִצְרַיִם וּבֵין יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Придут ко мне все твои рабы, склонятся передо мной и скажут: “Уходи вместе со всеми людьми, которые следуют за тобой”, — и [лишь] после этого я уйду. И [Моше] в гневе вышел от фараона.',
    verse_ivrit: 'וְיָרְדוּ כָל-עֲבָדֶיךָ אֵלֶּה אֵלַי וְהִשְׁתַּחֲווּ-לִי לֵאמֹר צֵא אַתָּה וְכָל-הָעָם אֲשֶׁר-בְּרַגְלֶיךָ וְאַחֲרֵי-כֵן אֵצֵא וַיֵּצֵא מֵעִם-פַּרְעֹה בָּחֳרִי-אָף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Господь сказал Моше: — Не послушает вас фараон, чтобы умножились Мои чудеса в земле египетской.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה לֹא-יִשְׁמַע אֲלֵיכֶם פַּרְעֹה לְמַעַן רְבוֹת מוֹפְתַי בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 11,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Моше и Аѓарон совершили перед фараоном все эти чудеса. Но Господь ожесточил фараона, и тот не отпускал сынов Израиля из своей страны.',
    verse_ivrit: 'וּמֹשֶׁה וְאַהֲרֹן עָשׂוּ אֶת-כָּל-הַמֹּפְתִים הָאֵלֶּה לִפְנֵי פַרְעֹה וַיְחַזֵּק יְהוָה אֶת-לֵב פַּרְעֹה וְלֹא-שִׁלַּח אֶת-בְּנֵי-יִשְׂרָאֵל מֵאַרְצוֹ',
    comment: '',
  },
  //////////////////////////////////////           12121212121121212112121121211212121       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше и Аѓарону в земле египетской:',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה וְאֶל-אַהֲרֹן בְּאֶרֶץ מִצְרַיִם לֵאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '“Этот месяц — начало месяцев, [пусть будет он] у вас первым из месяцев года.',
    verse_ivrit: 'הַחֹדֶשׁ הַזֶּה לָכֶם רֹאשׁ חֳדָשִׁים רִאשׁוֹן הוּא לָכֶם לְחָדְשֵׁי הַשָּׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Скажите всей общине Израиля, чтобы в десятый день этого месяца каждый взял себе по одному ягненку на род, по одному ягненку на семью.',
    verse_ivrit: 'דַּבְּרוּ אֶל-כָּל-עֲדַת יִשְׂרָאֵל לֵאמֹר בֶּעָשֹׂר לַחֹדֶשׁ הַזֶּה וְיִקְחוּ לָהֶם אִישׁ שֶׂה לְבֵית-אָבֹת שֶׂה לַבָּיִת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'А если мала семья, то пусть возьмут ягненка вместе с ближайшими соседями по числу душ. Исходя из того, кто сколько съест, рассчитайте [число] людей на ягненка.',
    verse_ivrit: 'וְאִם-יִמְעַט הַבַּיִת מִהְיוֹת מִשֶּׂה וְלָקַח הוּא וּשְׁכֵנוֹ הַקָּרֹב אֶל-בֵּיתוֹ בְּמִכְסַת נְפָשֹׁת אִישׁ לְפִי אָכְלוֹ תָּכֹסּוּ עַל-הַשֶּׂה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Ягненок у вас должен быть без порока, самец, годовалый. Можно взять ягненка или козленка.',
    verse_ivrit: 'שֶׂה תָמִים זָכָר בֶּן-שָׁנָה יִהְיֶה לָכֶם מִן-הַכְּבָשִׂים וּמִן-הָעִזִּים תִּקָּחוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Держите его до четырнадцатого дня этого месяца, а [потом] пусть все собрание общины Израиля зарежет его с наступлением сумерек.',
    verse_ivrit: 'וְהָיָה לָכֶם לְמִשְׁמֶרֶת עַד אַרְבָּעָה עָשָׂר יוֹם לַחֹדֶשׁ הַזֶּה וְשָׁחֲטוּ אֹתוֹ כֹּל קְהַל עֲדַת-יִשְׂרָאֵל בֵּין הָעַרְבָּיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Пусть возьмут [его] кровь и помажут оба косяка и притолоку в тех домах, в которых будут его есть.',
    verse_ivrit: 'וְלָקְחוּ מִן-הַדָּם וְנָתְנוּ עַל-שְׁתֵּי הַמְּזוּזֹת וְעַל-הַמַּשְׁקוֹף עַל הַבָּתִּים אֲשֶׁר-יֹאכְלוּ אֹתוֹ בָּהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'В ту же ночь пусть съедят [его] мясо, изжаренное на огне. Это мясо надо есть с мацой и горькими травами.',
    verse_ivrit: 'וְאָכְלוּ אֶת-הַבָּשָׂר בַּלַּיְלָה הַזֶּה צְלִי-אֵשׁ וּמַצּוֹת עַל-מְרֹרִים יֹאכְלֻהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Не ешьте его сырым или сваренным в воде, а лишь изжаренным на огне, вместе с головой, ногами и внутренностями.',
    verse_ivrit: 'אַל-תֹּאכְלוּ מִמֶּנּוּ נָא וּבָשֵׁל מְבֻשָּׁל בַּמָּיִם כִּי אִם-צְלִי-אֵשׁ רֹאשׁוֹ עַל-כְּרָעָיו וְעַל-קִרְבּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Не оставляйте ничего до утра — еще до наступления утра сожгите на огне все, что от него осталось.',
    verse_ivrit: 'וְלֹא-תוֹתִירוּ מִמֶּנּוּ עַד-בֹּקֶר וְהַנֹּתָר מִמֶּנּוּ עַד-בֹּקֶר בָּאֵשׁ תִּשְׂרֹפוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Ешьте его так: бедра ваши должны быть препоясаны, ноги обуты, посох в руке. Ешьте его поспешно — это пасхальная жертва Господу!',
    verse_ivrit: 'וְכָכָה תֹּאכְלוּ אֹתוֹ מָתְנֵיכֶם חֲגֻרִים נַעֲלֵיכֶם בְּרַגְלֵיכֶם וּמַקֶּלְכֶם בְּיֶדְכֶם וַאֲכַלְתֶּם אֹתוֹ בְּחִפָּזוֹן פֶּסַח הוּא לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'А Я пройду в эту ночь по земле египетской и поражу в ней всякого первенца, от человека до скота, и над всеми египетскими богами совершу Я суд. Я — Господь!',
    verse_ivrit: 'וְעָבַרְתִּי בְאֶרֶץ-מִצְרַיִם בַּלַּיְלָה הַזֶּה וְהִכֵּיתִי כָל-בְּכוֹר בְּאֶרֶץ מִצְרַיִם מֵאָדָם וְעַד-בְּהֵמָה וּבְכָל-אֱלֹהֵי מִצְרַיִם אֶעֱשֶׂה שְׁפָטִים אֲנִי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Кровь на домах будет знаком, что вы там находитесь: когда Я буду поражать землю египетскую, то увижу кровь и миную вас, и не будет среди вас губительного мора.',
    verse_ivrit: 'וְהָיָה הַדָּם לָכֶם לְאֹת עַל הַבָּתִּים אֲשֶׁר אַתֶּם שָׁם וְרָאִיתִי אֶת-הַדָּם וּפָסַחְתִּי עֲלֵכֶם וְלֹא-יִהְיֶה בָכֶם נֶגֶף לְמַשְׁחִית בְּהַכֹּתִי בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Пусть этот день будет для вас памятным, отмечайте его как праздник Господу из поколения в поколение. Праздновать его — вечный закон для вас.',
    verse_ivrit: 'וְהָיָה הַיּוֹם הַזֶּה לָכֶם לְזִכָּרוֹן וְחַגֹּתֶם אֹתוֹ חַג לַיהוָה לְדֹרֹתֵיכֶם חֻקַּת עוֹלָם תְּחָגֻּהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Семь дней ешьте мацу. В первый же день [праздника] уберите закваску из ваших домов, ибо душа того, кто станет есть квасное с первого до седьмого дня [праздника], будет отторгнута от [народа] Израиля.',
    verse_ivrit: 'שִׁבְעַת יָמִים מַצּוֹת תֹּאכֵלוּ אַךְ בַּיּוֹם הָרִאשׁוֹן תַּשְׁבִּיתוּ שְּׂאֹר מִבָּתֵּיכֶם כִּי כָּל-אֹכֵל חָמֵץ וְנִכְרְתָה הַנֶּפֶשׁ הַהִוא מִיִּשְׂרָאֵל מִיּוֹם הָרִאשֹׁן עַד-יוֹם הַשְּׁבִעִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Пусть в первый день [праздника будет] у вас священное собрание, и в седьмой день — священное собрание: никакая работа не должна делаться [в эти дни]. Лишь то, что необходимо для [приготовления] пищи, можно вам делать [в эти дни].',
    verse_ivrit: 'וּבַיּוֹם הָרִאשׁוֹן מִקְרָא-קֹדֶשׁ וּבַיּוֹם הַשְּׁבִיעִי מִקְרָא-קֹדֶשׁ יִהְיֶה לָכֶם כָּל-מְלָאכָה לֹא-יֵעָשֶׂה בָהֶם אַךְ אֲשֶׁר יֵאָכֵל לְכָל-נֶפֶשׁ הוּא לְבַדּוֹ יֵעָשֶׂה לָכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Соблюдайте праздник мацы, ведь в этот самый день Я вывел ваши воинства из земли египетской. Соблюдайте же этот день из поколения в поколение, [как] вечный закон.',
    verse_ivrit: 'וּשְׁמַרְתֶּם אֶת-הַמַּצּוֹת כִּי בְּעֶצֶם הַיּוֹם הַזֶּה הוֹצֵאתִי אֶת-צִבְאוֹתֵיכֶם מֵאֶרֶץ מִצְרָיִם וּשְׁמַרְתֶּם אֶת-הַיּוֹם הַזֶּה לְדֹרֹתֵיכֶם חֻקַּת עוֹלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'С четырнадцатого дня первого месяца, [начиная] с вечера, до вечера двадцать первого дня того же месяца — ешьте мацу.',
    verse_ivrit: 'בָּרִאשֹׁן בְּאַרְבָּעָה עָשָׂר יוֹם לַחֹדֶשׁ בָּעֶרֶב תֹּאכְלוּ מַצֹּת עַד יוֹם הָאֶחָד וְעֶשְׂרִים לַחֹדֶשׁ בָּעָרֶב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Семь дней не должно быть в ваших домах закваски: душа всякого, кто станет есть квасное [в эти дни], будет отторгнута от общины Израиля — переселенец ли он или уроженец страны.',
    verse_ivrit: 'שִׁבְעַת יָמִים שְׂאֹר לֹא יִמָּצֵא בְּבָתֵּיכֶם כִּי כָּל-אֹכֵל מַחְמֶצֶת וְנִכְרְתָה הַנֶּפֶשׁ הַהִוא מֵעֲדַת יִשְׂרָאֵל בַּגֵּר וּבְאֶזְרַח הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Ничего квасного не ешьте. Где бы вы ни жили, ешьте мацу”.',
    verse_ivrit: 'כָּל-מַחְמֶצֶת לֹא תֹאכֵלוּ בְּכֹל מוֹשְׁבֹתֵיכֶם תֹּאכְלוּ מַצּוֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Моше созвал всех старейшин Израиля и сказал им: “Выведите мелкий скот, выберите ягнят для вас и ваших семей и зарежьте пасхальную жертву.',
    verse_ivrit: 'וַיִּקְרָא מֹשֶׁה לְכָל-זִקְנֵי יִשְׂרָאֵל וַיֹּאמֶר אֲלֵהֶם מִשְׁכוּ וּקְחוּ לָכֶם צֹאן לְמִשְׁפְּחֹתֵיכֶם וְשַׁחֲטוּ הַפָּסַח',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Затем возьмите пучок иссопа, обмакните его в чашу с кровью и помажьте притолоку и оба дверных косяка кровью из чаши. И пусть никто не выходит за двери своего дома до утра.',
    verse_ivrit: 'וּלְקַחְתֶּם אֲגֻדַּת אֵזוֹב וּטְבַלְתֶּם בַּדָּם אֲשֶׁר-בַּסַּף וְהִגַּעְתֶּם אֶל-הַמַּשְׁקוֹף וְאֶל-שְׁתֵּי הַמְּזוּזֹת מִן-הַדָּם אֲשֶׁר בַּסָּף וְאַתֶּם לֹא תֵצְאוּ אִישׁ מִפֶּתַח-בֵּיתוֹ עַד-בֹּקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: '[Когда] пойдет Господь поражать египтян, то увидит Он кровь на притолоке и на обоих косяках — и минует Господь эту дверь, не дав ангелу-губителю войти в ваши дома и поразить вас.',
    verse_ivrit: 'וְעָבַר יְהוָה לִנְגֹּף אֶת-מִצְרַיִם וְרָאָה אֶת-הַדָּם עַל-הַמַּשְׁקוֹף וְעַל שְׁתֵּי הַמְּזוּזֹת וּפָסַח יְהוָה עַל-הַפֶּתַח וְלֹא יִתֵּן הַמַּשְׁחִית לָבֹא אֶל-בָּתֵּיכֶם לִנְגֹּף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Храните это как закон для себя и своих потомков навеки.',
    verse_ivrit: 'וּשְׁמַרְתֶּם אֶת-הַדָּבָר הַזֶּה לְחָק-לְךָ וּלְבָנֶיךָ עַד-עוֹלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'А когда вы придете в страну, которую Господь отдаст вам, как Он и обещал, то соблюдайте это служение.',
    verse_ivrit: 'וְהָיָה כִּי-תָבֹאוּ אֶל-הָאָרֶץ אֲשֶׁר יִתֵּן יְהוָה לָכֶם כַּאֲשֶׁר דִּבֵּר וּשְׁמַרְתֶּם אֶת-הָעֲבֹדָה הַזֹּאת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Когда спросят вас ваши дети: ‘Что это за служение у вас?’ —',
    verse_ivrit: 'וְהָיָה כִּי-יֹאמְרוּ אֲלֵיכֶם בְּנֵיכֶם מָה הָעֲבֹדָה הַזֹּאת לָכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'ответьте им: ‘Это пасхальная жертва Господу, ибо Он миновал дома сынов Израиля в Египте — поразил египтян, а наши дома спас’ ”. Склонился народ и пал ниц.',
    verse_ivrit: 'וַאֲמַרְתֶּם זֶבַח-פֶּסַח הוּא לַיהוָה אֲשֶׁר פָּסַח עַל-בָּתֵּי בְנֵי-יִשְׂרָאֵל בְּמִצְרַיִם בְּנָגְפּוֹ אֶת-מִצְרַיִם וְאֶת-בָּתֵּינוּ הִצִּיל וַיִּקֹּד הָעָם וַיִּשְׁתַּחֲווּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Сыны Израиля пошли и совершили все это. Как повелел Господь Моше и Аѓарону, так они и сделали.',
    verse_ivrit: 'וַיֵּלְכוּ וַיַּעֲשׂוּ בְּנֵי יִשְׂרָאֵל כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה וְאַהֲרֹן כֵּן עָשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'В полночь поразил Господь всех первенцев в земле египетской, от первенца фараона, восседающего на престоле, до первенца пленника в тюремной яме, и всех первенцев скота.',
    verse_ivrit: 'וַיְהִי בַּחֲצִי הַלַּיְלָה וַיהוָה הִכָּה כָל-בְּכוֹר בְּאֶרֶץ מִצְרַיִם מִבְּכֹר פַּרְעֹה הַיֹּשֵׁב עַל-כִּסְאוֹ עַד בְּכוֹר הַשְּׁבִי אֲשֶׁר בְּבֵית הַבּוֹר וְכֹל בְּכוֹר בְּהֵמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Фараон встал той ночью — он сам, все его рабы и все египтяне, — и поднялся великий вопль по [всему] Египту, так как не было дома, где бы не было мертвеца.',
    verse_ivrit: 'וַיָּקָם פַּרְעֹה לַיְלָה הוּא וְכָל-עֲבָדָיו וְכָל-מִצְרַיִם וַתְּהִי צְעָקָה גְדֹלָה בְּמִצְרָיִם כִּי-אֵין בַּיִת אֲשֶׁר אֵין-שָׁם מֵת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Ночью же позвал [фараон] Моше и Аѓарона и сказал: — Скорее покиньте мой народ — и вы оба, и все сыны Израиля — и идите служить Господу, как вы просили.',
    verse_ivrit: 'וַיִּקְרָא לְמֹשֶׁה וּלְאַהֲרֹן לַיְלָה וַיֹּאמֶר קוּמוּ צְּאוּ מִתּוֹךְ עַמִּי גַּם-אַתֶּם גַּם-בְּנֵי יִשְׂרָאֵל וּלְכוּ עִבְדוּ אֶת-יְהוָה כְּדַבֶּרְכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Можете взять ваш скот, мелкий и крупный, как вы и просили, — и ступайте; и меня тоже благословите.',
    verse_ivrit: 'גַּם-צֹאנְכֶם גַּם-בְּקַרְכֶם קְחוּ כַּאֲשֶׁר דִּבַּרְתֶּם וָלֵכוּ וּבֵרַכְתֶּם גַּם-אֹתִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Египтяне торопили народ Израиля, чтобы те поскорее ушли из страны, потому что думали [египтяне]: “[Иначе] мы все умрем!”',
    verse_ivrit: 'וַתֶּחֱזַק מִצְרַיִם עַל-הָעָם לְמַהֵר לְשַׁלְּחָם מִן-הָאָרֶץ כִּי אָמְרוּ כֻּלָּנוּ מֵתִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: '[Пришлось] народу унести свое тесто, прежде чем оно заквасилось: они [несли] свои увязанные в одежду квашни на плечах.',
    verse_ivrit: 'וַיִּשָּׂא הָעָם אֶת-בְּצֵקוֹ טֶרֶם יֶחְמָץ מִשְׁאֲרֹתָם צְרֻרֹת בְּשִׂמְלֹתָם עַל-שִׁכְמָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Сыны Израиля сделали по слову Моше — выпросили у египтян серебряные и золотые вещи и одежду.',
    verse_ivrit: 'וּבְנֵי-יִשְׂרָאֵל עָשׂוּ כִּדְבַר מֹשֶׁה וַיִּשְׁאֲלוּ מִמִּצְרַיִם כְּלֵי-כֶסֶף וּכְלֵי זָהָב וּשְׂמָלֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Господь внушил египтянам приязнь к народу [Израиля]: они отдавали им [все, что те просили], и так [сыны Израиля] опустошили Египет. ',
    verse_ivrit: 'וַיהוָה נָתַן אֶת-חֵן הָעָם בְּעֵינֵי מִצְרַיִם וַיַּשְׁאִלוּם וַיְנַצְּלוּ אֶת-מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'Сыны Израиля отправились из Раамсеса в Суккот — около шестисот тысяч пеших мужчин, не считая [женщин и] детей.',
    verse_ivrit: 'וַיִּסְעוּ בְנֵי-יִשְׂרָאֵל מֵרַעְמְסֵס סֻכֹּתָה כְּשֵׁשׁ-מֵאוֹת אֶלֶף רַגְלִי הַגְּבָרִים לְבַד מִטָּף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 38,
    verse: 'К ним присоединилась многочисленная смешанная толпа [иноплеменников. Вышло с ними] также очень много скота, мелкого и крупного.',
    verse_ivrit: 'וְגַם-עֵרֶב רַב עָלָה אִתָּם וְצֹאן וּבָקָר מִקְנֶה כָּבֵד מְאֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 39,
    verse: 'Из теста, которое [сыны Израиля] унесли из Египта, они испекли лепешки — мацу. [Тесто] не успело закваситься, ведь они были изгнаны из Египта и не могли медлить — даже еду себе [в дорогу] не приготовили.',
    verse_ivrit: 'וַיֹּאפוּ אֶת-הַבָּצֵק אֲשֶׁר הוֹצִיאוּ מִמִּצְרַיִם עֻגֹת מַצּוֹת כִּי לֹא חָמֵץ כִּי-גֹרְשׁוּ מִמִּצְרַיִם וְלֹא יָכְלוּ לְהִתְמַהְמֵהַּ וְגַם-צֵדָה לֹא-עָשׂוּ לָהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 40,
    verse: '[Всего же] времени пребывания сынов Израиля в Египте — четыреста тридцать лет.',
    verse_ivrit: 'וּמוֹשַׁב בְּנֵי יִשְׂרָאֵל אֲשֶׁר יָשְׁבוּ בְּמִצְרָיִם שְׁלֹשִׁים שָׁנָה וְאַרְבַּע מֵאוֹת שָׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 41,
    verse: 'По прошествии четырехсот тридцати лет — в тот самый день — все воинства Господа вышли из земли египетской.',
    verse_ivrit: 'וַיְהִי מִקֵּץ שְׁלֹשִׁים שָׁנָה וְאַרְבַּע מֵאוֹת שָׁנָה וַיְהִי בְּעֶצֶם הַיּוֹם הַזֶּה יָצְאוּ כָּל-צִבְאוֹת יְהוָה מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 42,
    verse: 'Ночь бдения Господа, когда Он выводил [сынов Израиля] из Египта, — это та самая ночь Господа, ночь бдения для всех сынов Израиля из поколения в поколение.',
    verse_ivrit: 'לֵיל שִׁמֻּרִים הוּא לַיהוָה לְהוֹצִיאָם מֵאֶרֶץ מִצְרָיִם הוּא-הַלַּיְלָה הַזֶּה לַיהוָה שִׁמֻּרִים לְכָל-בְּנֵי יִשְׂרָאֵל לְדֹרֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 43,
    verse: 'Господь сказал Моше и Аѓарону: “Вот закон о пасхальной жертве: никакой иноплеменник не должен есть ее.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה וְאַהֲרֹן זֹאת חֻקַּת הַפָּסַח כָּל-בֶּן-נֵכָר לֹא-יֹאכַל בּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 44,
    verse: 'А всякий раб, купленный за серебро, может есть ее, после того как ты сделаешь ему обрезание.',
    verse_ivrit: 'וְכָל-עֶבֶד אִישׁ מִקְנַת-כָּסֶף וּמַלְתָּה אֹתוֹ אָז יֹאכַל בּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 45,
    verse: 'Поселенец и наемный работник [из иноземцев] не может ее есть.',
    verse_ivrit: 'תּוֹשָׁב וְשָׂכִיר לֹא-יֹאכַל בּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 46,
    verse: '[Жертва] должна быть съедена в одном доме. Не выноси ее мясо из дома наружу, и костей [ее] не ломайте.',
    verse_ivrit: 'בְּבַיִת אֶחָד יֵאָכֵל לֹא-תוֹצִיא מִן-הַבַּיִת מִן-הַבָּשָׂר חוּצָה וְעֶצֶם לֹא תִשְׁבְּרוּ-בוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 47,
    verse: 'Пусть приносит ее вся община Израиля.',
    verse_ivrit: 'כָּל-עֲדַת יִשְׂרָאֵל יַעֲשׂוּ אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 48,
    verse: 'А если будет жить у вас переселенец и захочет он принести пасхальную жертву Господу, то пусть у него в семье каждый мужчина сделает обрезание — и тогда он сможет приблизиться и принести [жертву], и станет он как уроженец страны. Но ни один необрезанный не должен есть [эту жертву].',
    verse_ivrit: 'וְכִי-יָגוּר אִתְּךָ גֵּר וְעָשָׂה פֶסַח לַיהוָה הִמּוֹל לוֹ כָל-זָכָר וְאָז יִקְרַב לַעֲשֹׂתוֹ וְהָיָה כְּאֶזְרַח הָאָרֶץ וְכָל-עָרֵל לֹא-יֹאכַל בּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 49,
    verse: 'Пусть будет один закон и для уроженца [страны], и для переселенца, живущего среди вас”.',
    verse_ivrit: 'תּוֹרָה אַחַת יִהְיֶה לָאֶזְרָח וְלַגֵּר הַגָּר בְּתוֹכְכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 50,
    verse: 'Так и сделали сыны Израиля: как повелел Моше и Аѓарону Господь — так они и сделали.',
    verse_ivrit: 'וַיַּעֲשׂוּ כָּל-בְּנֵי יִשְׂרָאֵל כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה וְאֶת-אַהֲרֹן כֵּן עָשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 12,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'В тот самый день Господь увел из земли египетской сынов Израиля, по воинствам их.',
    verse_ivrit: 'וַיְהִי בְּעֶצֶם הַיּוֹם הַזֶּה הוֹצִיא יְהוָה אֶת-בְּנֵי יִשְׂרָאֵל מֵאֶרֶץ מִצְרַיִם עַל-צִבְאֹתָם',
    comment: '',
  },
  //////////////////////////////////////           13131313131313131313131313131313131313131313131313       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '— Посвящайте Мне всякого первенца из сынов Израиля. Все, что первым выходит из материнского лона, у людей или скота, — Мое.',
    verse_ivrit: 'קַדֶּשׁ-לִי כָל-בְּכוֹר פֶּטֶר כָּל-רֶחֶם בִּבְנֵי יִשְׂרָאֵל בָּאָדָם וּבַבְּהֵמָה לִי הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Моше сказал народу: “Помните этот день, в который вы ушли из Египта, из дома рабства, ибо силою [Своей] руки вывел вас Господь отсюда. Не ешьте квасное [в этот день].',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-הָעָם זָכוֹר אֶת-הַיּוֹם הַזֶּה אֲשֶׁר יְצָאתֶם מִמִּצְרַיִם מִבֵּית עֲבָדִים כִּי בְּחֹזֶק יָד הוֹצִיא יְהוָה אֶתְכֶם מִזֶּה וְלֹא יֵאָכֵל חָמֵץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Сегодня вы уходите [из Египта] — в месяце авив.',
    verse_ivrit: 'הַיּוֹם אַתֶּם יֹצְאִים בְּחֹדֶשׁ הָאָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Когда Господь приведет тебя в страну ханаанеев, хеттов, амореев, хивеев и евусеев, [в страну], о которой Он поклялся твоим предкам, что отдаст [ее] тебе, в землю, источающую молоко и мед, — то совершай это служение в этот месяц.',
    verse_ivrit: 'וְהָיָה כִי-יְבִיאֲךָ יְהוָה אֶל-אֶרֶץ הַכְּנַעֲנִי וְהַחִתִּי וְהָאֱמֹרִי וְהַחִוִּי וְהַיְבוּסִי אֲשֶׁר נִשְׁבַּע לַאֲבֹתֶיךָ לָתֶת לָךְ אֶרֶץ זָבַת חָלָב וּדְבָשׁ וְעָבַדְתָּ אֶת-הָעֲבֹדָה הַזֹּאת בַּחֹדֶשׁ הַזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Семь дней ешь мацу, а седьмой день — праздник Господень.',
    verse_ivrit: 'שִׁבְעַת יָמִים תֹּאכַל מַצֹּת וּבַיּוֹם הַשְּׁבִיעִי חַג לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Все эти семь дней надо есть мацу. Пусть не появляется у вас квасного, пусть не появляется у вас закваски во всех пределах ваших.',
    verse_ivrit: 'מַצּוֹת יֵאָכֵל אֵת שִׁבְעַת הַיָּמִים וְלֹא-יֵרָאֶה לְךָ חָמֵץ וְלֹא-יֵרָאֶה לְךָ שְׂאֹר בְּכָל-גְּבֻלֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Сыну своему в тот день скажи: ‘Вот ради чего сотворил со мной Господь [чудеса], когда я уходил из Египта!’',
    verse_ivrit: 'וְהִגַּדְתָּ לְבִנְךָ בַּיּוֹם הַהוּא לֵאמֹר בַּעֲבוּר זֶה עָשָׂה יְהוָה לִי בְּצֵאתִי מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Пусть это будет тебе знаком на руке и напоминанием между глазами, чтобы учение Господа было у тебя на устах, ибо Господь Своей сильной рукой вывел тебя из Египта!',
    verse_ivrit: 'וְהָיָה לְךָ לְאוֹת עַל-יָדְךָ וּלְזִכָּרוֹן בֵּין עֵינֶיךָ לְמַעַן תִּהְיֶה תּוֹרַת יְהוָה בְּפִיךָ כִּי בְּיָד חֲזָקָה הוֹצִאֲךָ יְהוָה מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Соблюдай этот закон в [назначенный] срок, из года в год.',
    verse_ivrit: 'וְשָׁמַרְתָּ אֶת-הַחֻקָּה הַזֹּאת לְמוֹעֲדָהּ מִיָּמִים יָמִימָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Когда приведет тебя Господь в землю ханаанскую, как Он и поклялся тебе и твоим предкам, и отдаст ее тебе,',
    verse_ivrit: 'וְהָיָה כִּי-יְבִאֲךָ יְהוָה אֶל-אֶרֶץ הַכְּנַעֲנִי כַּאֲשֶׁר נִשְׁבַּע לְךָ וְלַאֲבֹתֶיךָ וּנְתָנָהּ לָךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'тогда отдай Господу все, что первым выходит из материнского лона. Всех перворожденных самцов приплода [чистого] скота, который будет у тебя, — [отдай] Господу.',
    verse_ivrit: 'וְהַעֲבַרְתָּ כָל-פֶּטֶר-רֶחֶם לַיהוָה וְכָל-פֶּטֶר שֶׁגֶר בְּהֵמָה אֲשֶׁר יִהְיֶה לְךָ הַזְּכָרִים לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'А всякого первенца ослицы выкупи, [отдав за него] ягненка. Если не выкупишь, то сломай ему шею. А за всех человеческих первенцев, за своих детей, отдавай выкуп.',
    verse_ivrit: 'וְכָל-פֶּטֶר חֲמֹר תִּפְדֶּה בְשֶׂה וְאִם-לֹא תִפְדֶּה וַעֲרַפְתּוֹ וְכֹל בְּכוֹר אָדָם בְּבָנֶיךָ תִּפְדֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Если завтра твой сын спросит тебя: ‘Что это значит?’ — то ответь ему: ‘Силой руки Своей вывел нас Господь из Египта, из дома рабства.',
    verse_ivrit: 'וְהָיָה כִּי-יִשְׁאָלְךָ בִנְךָ מָחָר לֵאמֹר מַה-זֹּאת וְאָמַרְתָּ אֵלָיו בְּחֹזֶק יָד הוֹצִיאָנוּ יְהוָה מִמִּצְרַיִם מִבֵּית עֲבָדִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Когда фараон упрямился, не хотел отпускать нас, то Господь умертвил всех первенцев в земле египетской, от первенца человека до первенца скота. Поэтому я приношу в жертву Господу всех первородных самцов [скота], а за всех первенцев моего народа отдаю выкуп.',
    verse_ivrit: 'וַיְהִי כִּי-הִקְשָׁה פַרְעֹה לְשַׁלְּחֵנוּ וַיַּהֲרֹג יְהוָה כָּל-בְּכוֹר בְּאֶרֶץ מִצְרַיִם מִבְּכֹר אָדָם וְעַד-בְּכוֹר בְּהֵמָה עַל-כֵּן אֲנִי זֹבֵחַ לַיהוָה כָּל-פֶּטֶר רֶחֶם הַזְּכָרִים וְכָל-בְּכוֹר בָּנַי אֶפְדֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БО(Пойди)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Пусть это будет знаком на твоей руке и напоминанием между глазами твоими, ибо силой руки Своей вывел нас Господь из Египта’ ”. ',
    verse_ivrit: 'וְהָיָה לְאוֹת עַל-יָדְכָה וּלְטוֹטָפֹת בֵּין עֵינֶיךָ כִּי בְּחֹזֶק יָד הוֹצִיאָנוּ יְהוָה מִמִּצְרָיִם',
    comment: '',
  },
  //////////////////////////////////////           131313131311313131131313131131311313       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 2,
    poemNumber: 1,
    verse: 'Когда фараон отпустил народ, Бог не повел их через страну филистимлян, хотя этот путь ближе, ибо сказал Бог: “Не передумал бы народ, увидев, [что ему предстоит] война, и не вернулся бы в Египет”.',
    verse_ivrit: 'וַיְהִי בְּשַׁלַּח פַּרְעֹה אֶת-הָעָם וְלֹא-נָחָם אֱלֹהִים דֶּרֶךְ אֶרֶץ פְּלִשְׁתִּים כִּי קָרוֹב הוּא כִּי אָמַר אֱלֹהִים פֶּן-יִנָּחֵם הָעָם בִּרְאֹתָם מִלְחָמָה וְשָׁבוּ מִצְרָיְמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 2,
    poemNumber: 2,
    verse: 'Бог повел народ через пустыню, что у моря Суф. Сыны Израиля вышли из земли египетской вооруженными.',
    verse_ivrit: 'וַיַּסֵּב אֱלֹהִים אֶת-הָעָם דֶּרֶךְ הַמִּדְבָּר יַם-סוּף וַחֲמֻשִׁים עָלוּ בְנֵי-יִשְׂרָאֵל מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 2,
    poemNumber: 3,
    verse: 'А кости Йосефа Моше взял с собой, ибо тот связал сынов Израиля клятвой, сказав: “Бог обязательно вспомнит о вас, и тогда унесите отсюда с собой мои кости”.',
    verse_ivrit: 'וַיִּקַּח מֹשֶׁה אֶת-עַצְמוֹת יוֹסֵף עִמּוֹ כִּי הַשְׁבֵּעַ הִשְׁבִּיעַ אֶת-בְּנֵי יִשְׂרָאֵל לֵאמֹר פָּקֹד יִפְקֹד אֱלֹהִים אֶתְכֶם וְהַעֲלִיתֶם אֶת-עַצְמֹתַי מִזֶּה אִתְּכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 2,
    poemNumber: 4,
    verse: 'Они двинулись из Суккота и расположились станом в Этаме, на краю пустыни.',
    verse_ivrit: 'וַיִּסְעוּ מִסֻּכֹּת וַיַּחֲנוּ בְאֵתָם בִּקְצֵה הַמִּדְבָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 2,
    poemNumber: 5,
    verse: 'Господь шел перед ними, днем — в облачном столпе, указывая им дорогу, а ночью — в огненном столпе, освещая им [путь], чтобы они шли днем и ночью.',
    verse_ivrit: 'וַיהוָה הֹלֵךְ לִפְנֵיהֶם יוֹמָם בְּעַמּוּד עָנָן לַנְחֹתָם הַדֶּרֶךְ וְלַיְלָה בְּעַמּוּד אֵשׁ לְהָאִיר לָהֶם לָלֶכֶת יוֹמָם וָלָיְלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 13,
    id_chapter_two: 2,
    poemNumber: 6,
    verse: 'Не удалялся облачный столп днем и огненный столп ночью от народа [Израиля]. ',
    verse_ivrit: 'לֹא-יָמִישׁ עַמּוּד הֶעָנָן יוֹמָם וְעַמּוּד הָאֵשׁ לָיְלָה לִפְנֵי הָעָם',
    comment: '',
  },
  //////////////////////////////////////           14141414141414114141414141414141414411414       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '— Скажи сынам Израиля, чтобы они повернули назад и разбили стан у Пи-ѓа-Хирота, между Мигдолем и морем: расположитесь станом у моря, близ Бааль-Цефона.',
    verse_ivrit: 'דַּבֵּר אֶל-בְּנֵי יִשְׂרָאֵל וְיָשֻׁבוּ וְיַחֲנוּ לִפְנֵי פִּי הַחִירֹת בֵּין מִגְדֹּל וּבֵין הַיָּם לִפְנֵי בַּעַל צְפֹן נִכְחוֹ תַחֲנוּ עַל-הַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Фараон подумает о сынах Израиля: “Они заблудились, пустыня стала у них на пути”.',
    verse_ivrit: 'וְאָמַר פַּרְעֹה לִבְנֵי יִשְׂרָאֵל נְבֻכִים הֵם בָּאָרֶץ סָגַר עֲלֵיהֶם הַמִּדְבָּר',
    comment: '',
  },

  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Я ожесточу сердце фараона, и он погонится за ними. Тогда покажу Я свое величие над фараоном и над всем его войском, и узнают египтяне, что Я — Господь! Так они и сделали.',
    verse_ivrit: 'וְחִזַּקְתִּי אֶת-לֵב-פַּרְעֹה וְרָדַף אַחֲרֵיהֶם וְאִכָּבְדָה בְּפַרְעֹה וּבְכָל-חֵילוֹ וְיָדְעוּ מִצְרַיִם כִּי-אֲנִי יְהוָה וַיַּעֲשׂוּ-כֵן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Царю Египта сообщили, что народ [Израиля] бежал. Фараон и его слуги пожалели, что [отпустили] народ, и сказали: “Что же мы наделали, [зачем] мы отпустили Израиль, который работал на нас?”',
    verse_ivrit: 'וַיֻּגַּד לְמֶלֶךְ מִצְרַיִם כִּי בָרַח הָעָם וַיֵּהָפֵךְ לְבַב פַּרְעֹה וַעֲבָדָיו אֶל-הָעָם וַיֹּאמְרוּ מַה-זֹּאת עָשִׂינוּ כִּי-שִׁלַּחְנוּ אֶת-יִשְׂרָאֵל מֵעָבְדֵנוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: '[Фараон] запряг свою колесницу и взял с собой свой народ,',
    verse_ivrit: 'וַיֶּאְסֹר אֶת-רִכְבּוֹ וְאֶת-עַמּוֹ לָקַח עִמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'взял шестьсот отборных колесниц и все [прочие] колесницы Египта, по три человека на каждой.',
    verse_ivrit: 'וַיִּקַּח שֵׁשׁ-מֵאוֹת רֶכֶב בָּחוּר וְכֹל רֶכֶב מִצְרָיִם וְשָׁלִשִׁם עַל-כֻּלּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Господь ожесточил фараона, царя египетского, и тот погнался за сынами Израиля. А сыны Израиля шли не скрываясь.',
    verse_ivrit: 'וַיְחַזֵּק יְהוָה אֶת-לֵב פַּרְעֹה מֶלֶךְ מִצְרַיִם וַיִּרְדֹּף אַחֲרֵי בְּנֵי יִשְׂרָאֵל וּבְנֵי יִשְׂרָאֵל יֹצְאִים בְּיָד רָמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'И вот египтяне — вся конница фараона, колесницы, всадники и войско — пустились за ними в погоню и настигли их, когда они расположились станом у моря, у Пи-ѓа-Хирота, близ Бааль-Цефона.',
    verse_ivrit: 'וַיִּרְדְּפוּ מִצְרַיִם אַחֲרֵיהֶם וַיַּשִּׂיגוּ אוֹתָם חֹנִים עַל-הַיָּם כָּל-סוּס רֶכֶב פַּרְעֹה וּפָרָשָׁיו וְחֵילוֹ עַל-פִּי הַחִירֹת לִפְנֵי בַּעַל צְפֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Фараон приближался. Посмотрели сыны Израиля и видят: за ними гонятся египтяне! Ужаснулись сыны Израиля и воззвали к Господу.',
    verse_ivrit: 'וּפַרְעֹה הִקְרִיב וַיִּשְׂאוּ בְנֵי-יִשְׂרָאֵל אֶת-עֵינֵיהֶם וְהִנֵּה מִצְרַיִם נֹסֵעַ אַחֲרֵיהֶם וַיִּירְאוּ מְאֹד וַיִּצְעֲקוּ בְנֵי-יִשְׂרָאֵל אֶל-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: '— Разве нет могил в Египте, — сказали они Моше, — что ты привел нас погибать в пустыню? Что ты сделал с нами, [зачем] увел нас из Египта?',
    verse_ivrit: 'וַיֹּאמְרוּ אֶל-מֹשֶׁה הֲמִבְּלִי אֵין-קְבָרִים בְּמִצְרַיִם לְקַחְתָּנוּ לָמוּת בַּמִּדְבָּר מַה-זֹּאת עָשִׂיתָ לָּנוּ לְהוֹצִיאָנוּ מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Мы же говорили тебе в Египте: “Оставь нас, мы будем работать на египтян!” Уж лучше бы нам служить египтянам, чем умереть в пустыне.',
    verse_ivrit: 'הֲלֹא-זֶה הַדָּבָר אֲשֶׁר דִּבַּרְנוּ אֵלֶיךָ בְמִצְרַיִם לֵאמֹר חֲדַל מִמֶּנּוּ וְנַעַבְדָה אֶת-מִצְרָיִם כִּי טוֹב לָנוּ עֲבֹד אֶת-מִצְרַיִם מִמֻּתֵנוּ בַּמִּדְבָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: '— Не бойтесь! — сказал Моше народу. — Стойте и смотрите, как Господь спасет вас сегодня. Египтян, которых вы видите ныне, вы больше не увидите никогда.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-הָעָם אַל-תִּירָאוּ הִתְיַצְּבוּ וּרְאוּ אֶת-יְשׁוּעַת יְהוָה אֲשֶׁר-יַעֲשֶׂה לָכֶם הַיּוֹם כִּי אֲשֶׁר רְאִיתֶם אֶת-מִצְרַיִם הַיּוֹם לֹא תֹסִפוּ לִרְאֹתָם עוֹד עַד-עוֹלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Господь будет воевать за вас, а вы должны хранить молчание. ',
    verse_ivrit: 'יְהוָה יִלָּחֵם לָכֶם וְאַתֶּם תַּחֲרִשׁוּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: '— Что ты взываешь ко Мне? — сказал Господь Моше. — Скажи сынам Израиля, чтобы они отправлялись в путь.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה מַה-תִּצְעַק אֵלָי דַּבֵּר אֶל-בְּנֵי-יִשְׂרָאֵל וְיִסָּעוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'А ты подними свой посох, простри руку над морем и заставь [море] расступиться, чтобы сыны Израиля прошли по дну моря, как по суше.',
    verse_ivrit: 'וְאַתָּה הָרֵם אֶת-מַטְּךָ וּנְטֵה אֶת-יָדְךָ עַל-הַיָּם וּבְקָעֵהוּ וְיָבֹאוּ בְנֵי-יִשְׂרָאֵל בְּתוֹךְ הַיָּם בַּיַּבָּשָׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Я же ожесточу египтян, и они бросятся за вами следом. И тут покажу Я величие Мое над фараоном и над всем его войском с колесницами и всадниками.',
    verse_ivrit: 'וַאֲנִי הִנְנִי מְחַזֵּק אֶת-לֵב מִצְרַיִם וְיָבֹאוּ אַחֲרֵיהֶם וְאִכָּבְדָה בְּפַרְעֹה וּבְכָל-חֵילוֹ בְּרִכְבּוֹ וּבְפָרָשָׁיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Когда явлю Я величие Мое над фараоном с его колесницами и всадниками, узнают египтяне, что Я — Господь!',
    verse_ivrit: 'וְיָדְעוּ מִצְרַיִם כִּי-אֲנִי יְהוָה בְּהִכָּבְדִי בְּפַרְעֹה בְּרִכְבּוֹ וּבְפָרָשָׁיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Ангел Бога, шедший перед станом Израиля, переместился и пошел позади; облачный столп, [шедший] перед ними, переместился и встал позади них.',
    verse_ivrit: 'וַיִּסַּע מַלְאַךְ הָאֱלֹהִים הַהֹלֵךְ לִפְנֵי מַחֲנֵה יִשְׂרָאֵל וַיֵּלֶךְ מֵאַחֲרֵיהֶם וַיִּסַּע עַמּוּד הֶעָנָן מִפְּנֵיהֶם וַיַּעֲמֹד מֵאַחֲרֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: '[Столп] встал между войском египтян и войском Израиля, сделавшись темным облаком — а ночью он светился. Всю ночь одно [войско] не [могло] приблизиться к другому.',
    verse_ivrit: 'וַיָּבֹא בֵּין מַחֲנֵה מִצְרַיִם וּבֵין מַחֲנֵה יִשְׂרָאֵל וַיְהִי הֶעָנָן וְהַחֹשֶׁךְ וַיָּאֶר אֶת-הַלָּיְלָה וְלֹא-קָרַב זֶה אֶל-זֶה כָּל-הַלָּיְלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Моше простер руку над морем, а Господь всю ночь гнал [воду] морскую сильным восточным ветром, обращая море в сушу, — и расступились воды.',
    verse_ivrit: 'וַיֵּט מֹשֶׁה אֶת-יָדוֹ עַל-הַיָּם וַיּוֹלֶךְ יְהוָה אֶת-הַיָּם בְּרוּחַ קָדִים עַזָּה כָּל-הַלַּיְלָה וַיָּשֶׂם אֶת-הַיָּם לֶחָרָבָה וַיִּבָּקְעוּ הַמָּיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Сыны Израиля пошли по дну моря, как по суше, а воды [стояли] стеной, справа и слева от них.',
    verse_ivrit: 'וַיָּבֹאוּ בְנֵי-יִשְׂרָאֵל בְּתוֹךְ הַיָּם בַּיַּבָּשָׁה וְהַמַּיִם לָהֶם חוֹמָה מִימִינָם וּמִשְּׂמֹאלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Все египтяне — конница фараона, его колесницы и всадники — погнались за ними и вошли в море.',
    verse_ivrit: 'וַיִּרְדְּפוּ מִצְרַיִם וַיָּבֹאוּ אַחֲרֵיהֶם כֹּל סוּס פַּרְעֹה רִכְבּוֹ וּפָרָשָׁיו אֶל-תּוֹךְ הַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'И вот на рассвете взглянул Господь на египетское войско из столпа огня и тьмы — и привел войско египтян в смятение.',
    verse_ivrit: 'וַיְהִי בְּאַשְׁמֹרֶת הַבֹּקֶר וַיַּשְׁקֵף יְהוָה אֶל-מַחֲנֵה מִצְרַיִם בְּעַמּוּד אֵשׁ וְעָנָן וַיָּהָם אֵת מַחֲנֵה מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'У колесниц застопорились колеса, и египтяне с трудом их тащили. “Бежим от Израиля! — сказали египтяне. — Это Господь воюет с Египтом за них!” ',
    verse_ivrit: 'וַיָּסַר אֵת אֹפַן מַרְכְּבֹתָיו וַיְנַהֲגֵהוּ בִּכְבֵדֻת וַיֹּאמֶר מִצְרַיִם אָנוּסָה מִפְּנֵי יִשְׂרָאֵל כִּי יְהוָה נִלְחָם לָהֶם בְּמִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: '— Простри руку над морем, — велел Господь Моше, — и обратятся воды на египтян, на их колесницы и всадников.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה נְטֵה אֶת-יָדְךָ עַל-הַיָּם וְיָשֻׁבוּ הַמַּיִם עַל-מִצְרַיִם עַל-רִכְבּוֹ וְעַל-פָּרָשָׁיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Моше простер руку над морем, и поутру море начало возвращаться на свое место; египтяне бежали ему навстречу, и Господь низверг египтян в морскую пучину.',
    verse_ivrit: 'וַיֵּט מֹשֶׁה אֶת-יָדוֹ עַל-הַיָּם וַיָּשָׁב הַיָּם לִפְנוֹת בֹּקֶר לְאֵיתָנוֹ וּמִצְרַיִם נָסִים לִקְרָאתוֹ וַיְנַעֵר יְהוָה אֶת-מִצְרַיִם בְּתוֹךְ הַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Вернувшаяся вода поглотила все колесницы, а также всадников фараонова войска, вошедших в море вслед за ними; ни одного из них не осталось.',
    verse_ivrit: 'וַיָּשֻׁבוּ הַמַּיִם וַיְכַסּוּ אֶת-הָרֶכֶב וְאֶת-הַפָּרָשִׁים לְכֹל חֵיל פַּרְעֹה הַבָּאִים אַחֲרֵיהֶם בַּיָּם לֹא-נִשְׁאַר בָּהֶם עַד-אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'А сыны Израиля шли по дну моря, как по суше; справа и слева от них стеной [стояла] вода.',
    verse_ivrit: 'וּבְנֵי יִשְׂרָאֵל הָלְכוּ בַיַּבָּשָׁה בְּתוֹךְ הַיָּם וְהַמַּיִם לָהֶם חֹמָה מִימִינָם וּמִשְּׂמֹאלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'В тот день Господь спас Израиль от рук египтян, и увидел Израиль мертвых египтян на берегу моря.',
    verse_ivrit: 'וַיּוֹשַׁע יְהוָה בַּיּוֹם הַהוּא אֶת-יִשְׂרָאֵל מִיַּד מִצְרָיִם וַיַּרְא יִשְׂרָאֵל אֶת-מִצְרַיִם מֵת עַל-שְׂפַת הַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 14,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Израиль увидел великое деяние Господа над египтянами, и народ устрашился Господа, поверив Господу и рабу Его Моше. ',
    verse_ivrit: 'וַיַּרְא יִשְׂרָאֵל אֶת-הַיָּד הַגְּדֹלָה אֲשֶׁר עָשָׂה יְהוָה בְּמִצְרַיִם וַיִּירְאוּ הָעָם אֶת-יְהוָה וַיַּאֲמִינוּ בַּיהוָה וּבְמֹשֶׁה עַבְדּוֹ',
    comment: '',
  },
  //////////////////////////////////////           1515151515151515151151515151515151515151515151515       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Тогда Моше и сыны Израиля воспели Господу песнь с такими словами: “Воспою Господу, высоко вознесся Он, Коней и всадников Он в море низверг!',
    verse_ivrit: 'אָז יָשִׁיר-מֹשֶׁה וּבְנֵי יִשְׂרָאֵל אֶת-הַשִּׁירָה הַזֹּאת לַיהוָה וַיֹּאמְרוּ לֵאמֹר אָשִׁירָה לַיהוָה כִּי-גָאֹה גָּאָה סוּס וְרֹכְבוֹ רָמָה בַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Господь — моя сила и мощь; Он — мое спасенье! Это мой Бог — прославлю Его, Бог моих отцов — превознесу Его!',
    verse_ivrit: 'עָזִּי וְזִמְרָת יָהּ וַיְהִי-לִי לִישׁוּעָה זֶה אֵלִי וְאַנְוֵהוּ אֱלֹהֵי אָבִי וַאֲרֹמְמֶנְהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Господь — воитель, Господь — Его Имя!',
    verse_ivrit: 'יְהוָה אִישׁ מִלְחָמָה יְהוָה שְׁמוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Колесницы фараона и рать его низверг Он в море, В Тростниковом море утонули лучшие воители!',
    verse_ivrit: 'מַרְכְּבֹת פַּרְעֹה וְחֵילוֹ יָרָה בַיָּם וּמִבְחַר שָׁלִשָׁיו טֻבְּעוּ בְיַם-סוּף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Бездна поглотила их — они камнем пошли ко дну!',
    verse_ivrit: 'תְּהֹמֹת יְכַסְיֻמוּ יָרְדוּ בִמְצוֹלֹת כְּמוֹ אָבֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Десница Твоя, Господь, славна мощью, Десница Твоя, Господь, повергает врага!',
    verse_ivrit: 'יְמִינְךָ יְהוָה נֶאְדָּרִי בַּכֹּחַ יְמִינְךָ יְהוָה תִּרְעַץ אוֹיֵב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'В Своем величии сокрушаешь Ты недругов, Посылаешь Свою ярость, что пожирает их, как солому!',
    verse_ivrit: 'וּבְרֹב גְּאוֹנְךָ תַּהֲרֹס קָמֶיךָ תְּשַׁלַּח חֲרֹנְךָ יֹאכְלֵמוֹ כַּקַּשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'От дыхания ноздрей Твоих вздыбились воды, Волны стали стеною, В сердце моря застыли пучины!',
    verse_ivrit: 'וּבְרוּחַ אַפֶּיךָ נֶעֶרְמוּ מַיִם נִצְּבוּ כְמוֹ-נֵד נֹזְלִים קָפְאוּ תְהֹמֹת בְּלֶב-יָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: '‘Догоню их, настигну, — подумал враг, — Разделю добычу, утолю свою алчность! Обнажу свой меч, истреблю их!’',
    verse_ivrit: 'אָמַר אוֹיֵב אֶרְדֹּף אַשִּׂיג אֲחַלֵּק שָׁלָל תִּמְלָאֵמוֹ נַפְשִׁי אָרִיק חַרְבִּי תּוֹרִישֵׁמוֹ יָדִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Но Ты повеял Своим дыханьем — и море покрыло их! Как свинец, погрузились они в могучие воды!',
    verse_ivrit: 'נָשַׁפְתָּ בְרוּחֲךָ כִּסָּמוֹ יָם צָלְלוּ כַּעוֹפֶרֶת בְּמַיִם אַדִּירִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Кто подобен Тебе среди сильных, Господь? Кто подобен Тебе, о Возвеличенный в святости, Грозный в хваленьях, Творец чудес!',
    verse_ivrit: 'מִי-כָמֹכָה בָּאֵלִם יְהוָה מִי כָּמֹכָה נֶאְדָּר בַּקֹּדֶשׁ נוֹרָא תְהִלֹּת עֹשֵׂה פֶלֶא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Простер Ты десницу — и земля поглотила их!',
    verse_ivrit: 'נָטִיתָ יְמִינְךָ תִּבְלָעֵמוֹ אָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Ты избавил народ и вел их Своею милостью, Привел их в обитель святости мощью Своей!',
    verse_ivrit: 'נָחִיתָ בְחַסְדְּךָ עַם-זוּ גָּאָלְתָּ נֵהַלְתָּ בְעָזְּךָ אֶל-נְוֵה קָדְשֶׁךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Услыхали народы и затрепетали, Страх объял жителей Филистии!',
    verse_ivrit: 'שָׁמְעוּ עַמִּים יִרְגָּזוּן חִיל אָחַז יֹשְׁבֵי פְּלָשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Тогда смутились князья Эдома, Ужас объял вождей Моава, Перепугались жители Ханаана!',
    verse_ivrit: 'אָז נִבְהֲלוּ אַלּוּפֵי אֱדוֹם אֵילֵי מוֹאָב יֹאחֲזֵמוֹ רָעַד נָמֹגוּ כֹּל יֹשְׁבֵי כְנָעַן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Охватили их страх и трепет, От мощи Твоей они были немы, как камень, — Пока проходил Твой народ, о Господь, Пока проходил народ, который Ты обрел!',
    verse_ivrit: 'תִּפֹּל עֲלֵיהֶם אֵימָתָה וָפַחַד בִּגְדֹל זְרוֹעֲךָ יִדְּמוּ כָּאָבֶן עַד יַעֲבֹר עַמְּךָ יְהוָה סעַד-יַעֲבֹר עַם-זוּ קָנִיתָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Ты приведешь их и поселишь на горе удела Твоего, В месте Твоего пребывания, что сотворил Ты, Господь, В Святилище, воздвигнутом Твоими руками, о Владыка!',
    verse_ivrit: 'תְּבִאֵמוֹ וְתִטָּעֵמוֹ בְּהַר נַחֲלָתְךָ מָכוֹן לְשִׁבְתְּךָ פָּעַלְתָּ יְהוָה מִקְּדָשׁ אֲדֹנָי כּוֹנְנוּ יָדֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Господь будет царствовать во веки веков!”',
    verse_ivrit: 'יְהוָה יִמְלֹךְ לְעֹלָם וָעֶד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Когда конница фараона, его колесницы и всадники вошли в море, то Господь обратил на них морские воды, а сыны Израиля прошли по дну моря, как по суше. ',
    verse_ivrit: 'כִּי בָא סוּס פַּרְעֹה בְּרִכְבּוֹ וּבְפָרָשָׁיו בַּיָּם וַיָּשֶׁב יְהוָה עֲלֵהֶם אֶת-מֵי הַיָּם וּבְנֵי יִשְׂרָאֵל הָלְכוּ בַיַּבָּשָׁה בְּתוֹךְ הַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Сестра Аѓарона, пророчица Мирьям, взяла бубен, и все женщины пошли вслед за нею, танцуя с бубнами в хороводе.',
    verse_ivrit: 'וַתִּקַּח מִרְיָם הַנְּבִיאָה אֲחוֹת אַהֲרֹן אֶת-הַתֹּף בְּיָדָהּ וַתֵּצֶאןָ כָל-הַנָּשִׁים אַחֲרֶיהָ בְּתֻפִּים וּבִמְחֹלֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: '“Воспойте Господу! — пела им Мирьям. — Высоко вознесся Он, коней и всадников низверг Он в море!”',
    verse_ivrit: 'וַתַּעַן לָהֶם מִרְיָם שִׁירוּ לַיהוָה כִּי-גָאֹה גָּאָה סוּס וְרֹכְבוֹ רָמָה בַיָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Моше повел Израиль от Тростникового моря, и они достигли пустыни Шур. Три дня шли они по пустыне и не находили воды.',
    verse_ivrit: 'וַיַּסַּע מֹשֶׁה אֶת-יִשְׂרָאֵל מִיַּם-סוּף וַיֵּצְאוּ אֶל-מִדְבַּר-שׁוּר וַיֵּלְכוּ שְׁלֹשֶׁת-יָמִים בַּמִּדְבָּר וְלֹא-מָצְאוּ מָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'А когда пришли в Мару, то не смогли пить там воду, ибо вода была горькой. Потому и назвали [это место] Мара.',
    verse_ivrit: 'וַיָּבֹאוּ מָרָתָה וְלֹא יָכְלוּ לִשְׁתֹּת מַיִם מִמָּרָה כִּי מָרִים הֵם עַל-כֵּן קָרָא-שְׁמָהּ מָרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Народ роптал на Моше. “Что нам пить?” — говорили они.',
    verse_ivrit: 'וַיִּלֹּנוּ הָעָם עַל-מֹשֶׁה לֵּאמֹר מַה-נִּשְׁתֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Моше воззвал к Господу, и Господь указал ему дерево. [Моше] бросил [кусок дерева] в воду, и вода стала пресной. Там [Господь] установил им закон и порядок, и там же Он подверг их испытанию.',
    verse_ivrit: 'וַיִּצְעַק אֶל-יְהוָה וַיּוֹרֵהוּ יְהוָה עֵץ וַיַּשְׁלֵךְ אֶל-הַמַּיִם וַיִּמְתְּקוּ הַמָּיִם שָׁם שָׂם לוֹ חֹק וּמִשְׁפָּט וְשָׁם נִסָּהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: '— Если вы будете слушаться Господа, вашего Бога, — сказал [Господь], — и делать угодное Ему, внимать Его заповедям и соблюдать все Его законы, то ни одна из болезней, которые Я послал на Египет, не падет на вас, ибо Я — Господь, ваш Целитель.',
    verse_ivrit: 'וַיֹּאמֶר אִם-שָׁמוֹעַ תִּשְׁמַע לְקוֹל יְהוָה אֱלֹהֶיךָ וְהַיָּשָׁר בְּעֵינָיו תַּעֲשֶׂה וְהַאֲזַנְתָּ לְמִצְו‍ֹתָיו וְשָׁמַרְתָּ כָּל-חֻקָּיו כָּל-הַמַּחֲלָה אֲשֶׁר-שַׂמְתִּי בְמִצְרַיִם לֹא-אָשִׂים עָלֶיךָ כִּי אֲנִי יְהוָה רֹפְאֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 15,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: '[Сыны Израиля] пришли в Элим, где есть двенадцать родников и семьдесят финиковых пальм. Там расположились они станом у воды.',
    verse_ivrit: 'וַיָּבֹאוּ אֵילִמָה וְשָׁם שְׁתֵּים עֶשְׂרֵה עֵינֹת מַיִם וְשִׁבְעִים תְּמָרִים וַיַּחֲנוּ-שָׁם עַל-הַמָּיִם',
    comment: '',
  },
  //////////////////////////////////////           1616161616161616161616116166116       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Вся община сынов Израиля двинулась из Элима, и в пятнадцатый день второго месяца после ухода из земли египетской они пришли в пустыню Син, что между Элимом и Синаем.',
    verse_ivrit: 'וַיִּסְעוּ מֵאֵילִם וַיָּבֹאוּ כָּל-עֲדַת בְּנֵי-יִשְׂרָאֵל אֶל-מִדְבַּר-סִין אֲשֶׁר בֵּין-אֵילִם וּבֵין סִינָי בַּחֲמִשָּׁה עָשָׂר יוֹם לַחֹדֶשׁ הַשֵּׁנִי לְצֵאתָם מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'В пустыне вся община сынов Израиля возроптала на Моше и Аѓарона.',
    verse_ivrit: 'וילינו (וַיִּלּוֹנוּ) כָּל-עֲדַת בְּנֵי-יִשְׂרָאֵל עַל-מֹשֶׁה וְעַל-אַהֲרֹן בַּמִּדְבָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: '— Лучше бы нам было умереть от руки Господа в земле египетской, — сказали им сыны Израиля, — когда сидели мы у горшка с мясом и ели хлеб досыта! А вы привели нас в эту пустыню, чтобы уморить весь народ голодом!',
    verse_ivrit: 'וַיֹּאמְרוּ אֲלֵהֶם בְּנֵי יִשְׂרָאֵל מִי-יִתֵּן מוּתֵנוּ בְיַד-יְהוָה בְּאֶרֶץ מִצְרַיִם בְּשִׁבְתֵּנוּ עַל-סִיר הַבָּשָׂר בְּאָכְלֵנוּ לֶחֶם לָשֹׂבַע כִּי-הוֹצֵאתֶם אֹתָנוּ אֶל-הַמִּדְבָּר הַזֶּה לְהָמִית אֶת-כָּל-הַקָּהָל הַזֶּה בָּרָעָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: '— Я пошлю вам хлеб с неба, — сказал Господь Моше, — и пусть народ выходит и собирает, сколько нужно на день. Я проверю, будут ли они соблюдать Мое постановление.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה הִנְנִי מַמְטִיר לָכֶם לֶחֶם מִן-הַשָּׁמָיִם וְיָצָא הָעָם וְלָקְטוּ דְּבַר-יוֹם בְּיוֹמוֹ לְמַעַן אֲנַסֶּנּוּ הֲיֵלֵךְ בְּתוֹרָתִי אִם-לֹא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'А в шестой день пусть они приготовят собранное, и это будет вдвое больше того, что они собирают каждый день.',
    verse_ivrit: 'וְהָיָה בַּיּוֹם הַשִּׁשִּׁי וְהֵכִינוּ אֵת אֲשֶׁר-יָבִיאוּ וְהָיָה מִשְׁנֶה עַל אֲשֶׁר-יִלְקְטוּ יוֹם יוֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Моше и Аѓарон сказали всем сынам Израиля: — Будет вечер — и вы поймете, что это Господь увел вас из земли египетской.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה וְאַהֲרֹן אֶל-כָּל-בְּנֵי יִשְׂרָאֵל עֶרֶב וִידַעְתֶּם כִּי יְהוָה הוֹצִיא אֶתְכֶם מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: '— Господь даст вам мяса вечером и вдоволь хлеба утром, — сказал Моше, — ибо Господь услышал, что вы ропщете на Него. А мы кто такие? Не на нас вы ропщете, а на Господа!',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה בְּתֵת יְהוָה לָכֶם בָּעֶרֶב בָּשָׂר לֶאֱכֹל וְלֶחֶם בַּבֹּקֶר לִשְׂבֹּעַ בִּשְׁמֹעַ יְהוָה אֶת-תְּלֻנֹּתֵיכֶם אֲשֶׁר-אַתֶּם מַלִּינִם עָלָיו וְנַחְנוּ מָה לֹא-עָלֵינוּ תְלֻנֹּתֵיכֶם כִּי עַל-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Моше сказал Аѓарону: — Передай всей общине сынов Израиля: “Приблизьтесь к Господу, ибо Он услышал ваши жалобы”.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-אַהֲרֹן אֱמֹר אֶל-כָּל-עֲדַת בְּנֵי יִשְׂרָאֵל קִרְבוּ לִפְנֵי יְהוָה כִּי שָׁמַע אֵת תְּלֻנֹּתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Аѓарон сказал это всей общине сынов Израиля. Повернулись они в сторону пустыни и видят: Слава Господа явилась в облаке.',
    verse_ivrit: 'וַיְהִי כְּדַבֵּר אַהֲרֹן אֶל-כָּל-עֲדַת בְּנֵי-יִשְׂרָאֵל וַיִּפְנוּ אֶל-הַמִּדְבָּר וְהִנֵּה כְּבוֹד יְהוָה נִרְאָה בֶּעָנָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: '— Услышал Я ропот сынов Израиля. Скажи им: сегодня вечером вы будете есть мясо, а поутру насытитесь хлебом, и тогда вы узнаете, что Я — Господь, ваш Бог!',
    verse_ivrit: 'שָׁמַעְתִּי אֶת-תְּלוּנֹּת בְּנֵי יִשְׂרָאֵל דַּבֵּר אֲלֵהֶם לֵאמֹר בֵּין הָעַרְבַּיִם תֹּאכְלוּ בָשָׂר וּבַבֹּקֶר תִּשְׂבְּעוּ-לָחֶם וִידַעְתֶּם כִּי אֲנִי יְהוָה אֱלֹהֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'С наступлением вечера прилетели перепела и покрыли весь стан, а поутру вокруг стана выпала роса.',
    verse_ivrit: 'וַיְהִי בָעֶרֶב וַתַּעַל הַשְּׂלָו וַתְּכַס אֶת-הַמַּחֲנֶה וּבַבֹּקֶר הָיְתָה שִׁכְבַת הַטַּל סָבִיב לַמַּחֲנֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Испарилась роса, и оказалось, что пустыня покрыта мелкой крошкой — мелкой, как иней, выпавший на землю.',
    verse_ivrit: 'וַתַּעַל שִׁכְבַת הַטָּל וְהִנֵּה עַל-פְּנֵי הַמִּדְבָּר דַּק מְחֻסְפָּס דַּק כַּכְּפֹר עַל-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Сыны Израиля посмотрели и сказали друг другу: “Что это?” — ведь они не знали, что это. И сказал им Моше: “Это хлеб, который дал вам Господь в пищу.',
    verse_ivrit: 'וַיִּרְאוּ בְנֵי-יִשְׂרָאֵל וַיֹּאמְרוּ אִישׁ אֶל-אָחִיו מָן הוּא כִּי לֹא יָדְעוּ מַה-הוּא וַיֹּאמֶר מֹשֶׁה אֲלֵהֶם הוּא הַלֶּחֶם אֲשֶׁר נָתַן יְהוָה לָכֶם לְאָכְלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Вот что повелел Господь: ‘Собирайте его, сколько съест каждый: по омеру на человека, согласно числу людей. Пусть каждый возьмет на тех, кто в его шатре’”.',
    verse_ivrit: 'זֶה הַדָּבָר אֲשֶׁר צִוָּה יְהוָה לִקְטוּ מִמֶּנּוּ אִישׁ לְפִי אָכְלוֹ עֹמֶר לַגֻּלְגֹּלֶת מִסְפַּר נַפְשֹׁתֵיכֶם אִישׁ לַאֲשֶׁר בְּאָהֳלוֹ תִּקָּחוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Сыны Израиля так и сделали; кто собрал больше, кто меньше.',
    verse_ivrit: 'וַיַּעֲשׂוּ-כֵן בְּנֵי יִשְׂרָאֵל וַיִּלְקְטוּ הַמַּרְבֶּה וְהַמַּמְעִיט',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Но когда мерили [собранное] омером, то не было излишка у того, кто собрал много, а у собравшего мало не было недостатка. Каждый собрал столько, сколько ему было надо.',
    verse_ivrit: 'וַיָּמֹדּוּ בָעֹמֶר וְלֹא הֶעְדִּיף הַמַּרְבֶּה וְהַמַּמְעִיט לֹא הֶחְסִיר אִישׁ לְפִי-אָכְלוֹ לָקָטוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Моше сказал им: “Ничего не оставляйте до утра”.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֲלֵהֶם אִישׁ אַל-יוֹתֵר מִמֶּנּוּ עַד-בֹּקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Но люди не послушались Моше. Часть они сохранили до утра; [в оставленном] завелись черви, и оно протухло. Моше разгневался на них.',
    verse_ivrit: 'וְלֹא-שָׁמְעוּ אֶל-מֹשֶׁה וַיּוֹתִרוּ אֲנָשִׁים מִמֶּנּוּ עַד-בֹּקֶר וַיָּרֻם תּוֹלָעִים וַיִּבְאַשׁ וַיִּקְצֹף עֲלֵהֶם מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Они выходили и собирали каждое утро, кому сколько нужно.',
    verse_ivrit: 'וַיִּלְקְטוּ אֹתוֹ בַּבֹּקֶר בַּבֹּקֶר אִישׁ כְּפִי אָכְלוֹ וְחַם הַשֶּׁמֶשׁ וְנָמָס',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Когда же пригревало солнце, [оставшееся] таяло. В шестой день они собрали хлеба вдвое больше, по два омера на каждого. Все главы общины пришли к Моше и сообщили ему об этом.',
    verse_ivrit: 'וַיְהִי בַּיּוֹם הַשִּׁשִּׁי לָקְטוּ לֶחֶם מִשְׁנֶה שְׁנֵי הָעֹמֶר לָאֶחָד וַיָּבֹאוּ כָּל-נְשִׂיאֵי הָעֵדָה וַיַּגִּידוּ לְמֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Он ответил им: — Об этом и говорил Господь: завтра — святая суббота Господня, [день] покоя. Что [хотите] испечь — пеките, что [хотите] сварить — варите, а все оставшееся отложите до утра.',
    verse_ivrit: 'וַיֹּאמֶר אֲלֵהֶם הוּא אֲשֶׁר דִּבֶּר יְהוָה שַׁבָּתוֹן שַׁבַּת-קֹדֶשׁ לַיהוָה מָחָר אֵת אֲשֶׁר-תֹּאפוּ אֵפוּ וְאֵת אֲשֶׁר-תְּבַשְּׁלוּ בַּשֵּׁלוּ וְאֵת כָּל-הָעֹדֵף הַנִּיחוּ לָכֶם לְמִשְׁמֶרֶת עַד-הַבֹּקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Они отложили оставшееся до утра, как велел Моше, и оно не протухло и не сгнило.',
    verse_ivrit: 'וַיַּנִּיחוּ אֹתוֹ עַד-הַבֹּקֶר כַּאֲשֶׁר צִוָּה מֹשֶׁה וְלֹא הִבְאִישׁ וְרִמָּה לֹא-הָיְתָה בּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: '— Съешьте это сегодня, — сказал Моше. — Ведь сегодня — суббота Господня. Сегодня вы ничего не найдете в поле.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אִכְלֻהוּ הַיּוֹם כִּי-שַׁבָּת הַיּוֹם לַיהוָה הַיּוֹם לֹא תִמְצָאֻהוּ בַּשָּׂדֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Вы можете собирать шесть дней, а на седьмой день — суббота, и ничего уже не будет.',
    verse_ivrit: 'שֵׁשֶׁת יָמִים תִּלְקְטֻהוּ וּבַיּוֹם הַשְּׁבִיעִי שַׁבָּת לֹא יִהְיֶה-בּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'На седьмой день некоторые пошли собирать, но ничего не нашли.',
    verse_ivrit: 'וַיְהִי בַּיּוֹם הַשְּׁבִיעִי יָצְאוּ מִן-הָעָם לִלְקֹט וְלֹא מָצָאוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Господь сказал Моше: — До каких пор вы не будете соблюдать Мои заповеди и установления?',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה עַד-אָנָה מֵאַנְתֶּם לִשְׁמֹר מִצְו‍ֹתַי וְתוֹרֹתָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Смотрите: Господь дал вам субботу, и поэтому в шестой день Он посылает вам хлеба на два дня. Оставайтесь каждый у себя дома, пусть в седьмой день никто не покидает своего жилища.',
    verse_ivrit: 'רְאוּ כִּי-יְהוָה נָתַן לָכֶם הַשַּׁבָּת עַל-כֵּן הוּא נֹתֵן לָכֶם בַּיּוֹם הַשִּׁשִּׁי לֶחֶם יוֹמָיִם שְׁבוּ אִישׁ תַּחְתָּיו אַל-יֵצֵא אִישׁ מִמְּקֹמוֹ בַּיּוֹם הַשְּׁבִיעִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'И в седьмой день народ пребывал в покое.',
    verse_ivrit: 'וַיִּשְׁבְּתוּ הָעָם בַּיּוֹם הַשְּׁבִעִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Община Израиля нарекла [этому хлебу] имя ман; он был белым, как семя кориандра, а на вкус — как медовая лепешка.',
    verse_ivrit: 'וַיִּקְרְאוּ בֵית-יִשְׂרָאֵל אֶת-שְׁמוֹ מָן וְהוּא כְּזֶרַע גַּד לָבָן וְטַעְמוֹ כְּצַפִּיחִת בִּדְבָשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Сказал [им] Моше: — Вот что повелел Господь: “Наполните омер [маном], чтобы сохранить его для ваших потомков, чтобы увидели они хлеб, которым Я кормил вас в пустыне, когда уводил вас из земли египетской”.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה זֶה הַדָּבָר אֲשֶׁר צִוָּה יְהוָה מְלֹא הָעֹמֶר מִמֶּנּוּ לְמִשְׁמֶרֶת לְדֹרֹתֵיכֶם לְמַעַן יִרְאוּ אֶת-הַלֶּחֶם אֲשֶׁר הֶאֱכַלְתִּי אֶתְכֶם בַּמִּדְבָּר בְּהוֹצִיאִי אֶתְכֶם מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: '— Возьми сосуд, — сказал Моше Аѓарону, — положи туда целый омер мана и поставь его пред Господом, чтобы хранить из поколения в поколение.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-אַהֲרֹן קַח צִנְצֶנֶת אַחַת וְתֶן-שָׁמָּה מְלֹא-הָעֹמֶר מָן וְהַנַּח אֹתוֹ לִפְנֵי יְהוָה לְמִשְׁמֶרֶת לְדֹרֹתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'И Аѓарон поставил [сосуд с маном] на хранение перед [ковчегом] свидетельства, как и повелел Моше Господь.',
    verse_ivrit: 'כַּאֲשֶׁר צִוָּה יְהוָה אֶל-מֹשֶׁה וַיַּנִּיחֵהוּ אַהֲרֹן לִפְנֵי הָעֵדֻת לְמִשְׁמָרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Сыны Израиля питались маном сорок лет, пока не пришли в обитаемую землю: они ели ман, пока не достигли границ земли ханаанской.',
    verse_ivrit: 'וּבְנֵי יִשְׂרָאֵל אָכְלוּ אֶת-הַמָּן אַרְבָּעִים שָׁנָה עַד-בֹּאָם אֶל-אֶרֶץ נוֹשָׁבֶת אֶת-הַמָּן אָכְלוּ עַד-בֹּאָם אֶל-קְצֵה אֶרֶץ כְּנָעַן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 16,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'А омер — это десятая часть эфы. ',
    verse_ivrit: 'וְהָעֹמֶר עֲשִׂרִית הָאֵיפָה הוּא',
    comment: '',
  },
  //////////////////////////////////////           1717171717171717171717171717171717       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'По слову Господа вся община сынов Израиля двинулась из пустыни Син [и шла] дневными переходами. Они расположились станом в Рефидиме, но [там] не было воды, чтобы напоить народ.',
    verse_ivrit: 'וַיִּסְעוּ כָּל-עֲדַת בְּנֵי-יִשְׂרָאֵל מִמִּדְבַּר-סִין לְמַסְעֵיהֶם עַל-פִּי יְהוָה וַיַּחֲנוּ בִּרְפִידִים וְאֵין מַיִם לִשְׁתֹּת הָעָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Народ стал обвинять Моше. — Дайте нам воды, — говорили они, — мы хотим пить! Моше сказал им: — Зачем вы обвиняете меня? Зачем искушаете Господа?',
    verse_ivrit: 'וַיָּרֶב הָעָם עִם-מֹשֶׁה וַיֹּאמְרוּ תְּנוּ-לָנוּ מַיִם וְנִשְׁתֶּה וַיֹּאמֶר לָהֶם מֹשֶׁה מַה-תְּרִיבוּן עִמָּדִי מַה-תְּנַסּוּן אֶת-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Но народ страдал от жажды и роптал на Моше. — Ради чего ты вывел нас из Египта? — говорили они. — Чтобы уморить жаждой нас, наших детей и наш скот?',
    verse_ivrit: 'וַיִּצְמָא שָׁם הָעָם לַמַּיִם וַיָּלֶן הָעָם עַל-מֹשֶׁה וַיֹּאמֶר לָמָּה זֶּה הֶעֱלִיתָנוּ מִמִּצְרַיִם לְהָמִית אֹתִי וְאֶת-בָּנַי וְאֶת-מִקְנַי בַּצָּמָא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: '— Что мне делать с этим народом? — воззвал Моше к Господу. — Еще немного, и они побьют меня камнями!',
    verse_ivrit: 'וַיִּצְעַק מֹשֶׁה אֶל-יְהוָה לֵאמֹר מָה אֶעֱשֶׂה לָעָם הַזֶּה עוֹד מְעַט וּסְקָלֻנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '— Иди впереди народа, — ответил Господь Моше, — и пусть несколько старейшин Израиля пойдут с тобой. Возьми посох, которым ты ударил по Нилу, и ступай.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה עֲבֹר לִפְנֵי הָעָם וְקַח אִתְּךָ מִזִּקְנֵי יִשְׂרָאֵל וּמַטְּךָ אֲשֶׁר הִכִּיתָ בּוֹ אֶת-הַיְאֹר קַח בְּיָדְךָ וְהָלָכְתָּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Там, на Хореве, Я стану перед тобой на скале. Ты ударишь по скале, из нее потечет вода — и народ будет пить ее. Моше так и сделал, на глазах у старейшин Израиля.',
    verse_ivrit: 'הִנְנִי עֹמֵד לְפָנֶיךָ שָּׁם עַל-הַצּוּר בְּחֹרֵב וְהִכִּיתָ בַצּוּר וְיָצְאוּ מִמֶּנּוּ מַיִם וְשָׁתָה הָעָם וַיַּעַשׂ כֵּן מֹשֶׁה לְעֵינֵי זִקְנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Он нарек тому месту имя Маса и Мерива [“Испытание и Ссора”] — из-за ссоры сынов Израиля и потому что они искушали Господа, говоря: “Есть ли Господь среди нас или нет?” ',
    verse_ivrit: 'וַיִּקְרָא שֵׁם הַמָּקוֹם מַסָּה וּמְרִיבָה עַל-רִיב בְּנֵי יִשְׂרָאֵל וְעַל נַסֹּתָם אֶת-יְהוָה לֵאמֹר הֲיֵשׁ יְהוָה בְּקִרְבֵּנוּ אִם-אָיִן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Амалек пришел в Рефидим и напал на Израиль.',
    verse_ivrit: 'וַיָּבֹא עֲמָלֵק וַיִּלָּחֶם עִם-יִשְׂרָאֵל בִּרְפִידִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: '— Отбери нам воинов, — повелел Моше Йеѓошуа, — и иди, сразись с Амалеком. А я, с посохом Божьим в руке, стану завтра на вершине холма.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-יְהוֹשֻׁעַ בְּחַר-לָנוּ אֲנָשִׁים וְצֵא הִלָּחֵם בַּעֲמָלֵק מָחָר אָנֹכִי נִצָּב עַל-רֹאשׁ הַגִּבְעָה וּמַטֵּה הָאֱלֹהִים בְּיָדִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Йеѓошуа сделал, как сказал ему Моше, — вступил в бой с Амалеком. А Моше, Аѓарон и Хур поднялись на вершину холма.',
    verse_ivrit: 'וַיַּעַשׂ יְהוֹשֻׁעַ כַּאֲשֶׁר אָמַר-לוֹ מֹשֶׁה לְהִלָּחֵם בַּעֲמָלֵק וּמֹשֶׁה אַהֲרֹן וְחוּר עָלוּ רֹאשׁ הַגִּבְעָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'И всякий раз, когда Моше поднимал руку, одолевал Израиль, а когда он опускал руку, одолевал Амалек.',
    verse_ivrit: 'וְהָיָה כַּאֲשֶׁר יָרִים מֹשֶׁה יָדוֹ וְגָבַר יִשְׂרָאֵל וְכַאֲשֶׁר יָנִיחַ יָדוֹ וְגָבַר עֲמָלֵק',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Когда руки Моше устали, то принесли камень и положили, и Моше сел на камень, а Аѓарон и Хур поддерживали его руки с двух сторон — и до захода солнца его руки уже не опускались.',
    verse_ivrit: 'וִידֵי מֹשֶׁה כְּבֵדִים וַיִּקְחוּ-אֶבֶן וַיָּשִׂימוּ תַחְתָּיו וַיֵּשֶׁב עָלֶיהָ וְאַהֲרֹן וְחוּר תָּמְכוּ בְיָדָיו מִזֶּה אֶחָד וּמִזֶּה אֶחָד וַיְהִי יָדָיו אֱמוּנָה עַד-בֹּא הַשָּׁמֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Так Йеѓошуа победил Амалека и его народ, [сразив их] острием меча.',
    verse_ivrit: 'וַיַּחֲלֹשׁ יְהוֹשֻׁעַ אֶת-עֲמָלֵק וְאֶת-עַמּוֹ לְפִי-חָרֶב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Господь сказал Моше: — Сделай об этом памятную запись и прочти ее Йеѓошуа: бесследно сотру Я память об Амалеке под небесами!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה כְּתֹב זֹאת זִכָּרוֹן בַּסֵּפֶר וְשִׂים בְּאָזְנֵי יְהוֹשֻׁעַ כִּי-מָחֹה אֶמְחֶה אֶת-זֵכֶר עֲמָלֵק מִתַּחַת הַשָּׁמָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Моше построил жертвенник и нарек ему имя “Господь — мое знамя”.',
    verse_ivrit: 'וַיִּבֶן מֹשֶׁה מִזְבֵּחַ וַיִּקְרָא שְׁמוֹ יְהוָה נִסִּי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'БЕШАЛАХ(Когда отпустил)',
    id_book: 1,
    id_chapter: 17,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: '[Моше] провозгласил: “Вот рука на престоле Господа [с клятвой]: война у Господа с Амалеком из поколения в поколение!”',
    verse_ivrit: 'וַיֹּאמֶר כִּי-יָד עַל-כֵּס יָהּ מִלְחָמָה לַיהוָה בַּעֲמָלֵק מִדֹּר דֹּר',
    comment: '',
  },
  //////////////////////////////////////           181818181818181181818118181818181       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Итро, жрец Мидьяна, тесть Моше, услышал обо всем, что Бог сделал для Моше и для Своего народа, Израиля, — о том, что Господь вывел Израиль из Египта.',
    verse_ivrit: 'וַיִּשְׁמַע יִתְרוֹ כֹהֵן מִדְיָן חֹתֵן מֹשֶׁה אֵת כָּל-אֲשֶׁר עָשָׂה אֱלֹהִים לְמֹשֶׁה וּלְיִשְׂרָאֵל עַמּוֹ כִּי-הוֹצִיא יְהוָה אֶת-יִשְׂרָאֵל מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Тогда Итро, тесть Моше, взял Ципору, жену Моше, которую тот отослал [в дом отца],',
    verse_ivrit: 'וַיִּקַּח יִתְרוֹ חֹתֵן מֹשֶׁה אֶת-צִפֹּרָה אֵשֶׁת מֹשֶׁה אַחַר שִׁלּוּחֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'и двух ее сыновей; одного [из них] звали Гершом, потому что Моше сказал: “Я стал переселенцем в чужой стране”,',
    verse_ivrit: 'וְאֵת שְׁנֵי בָנֶיהָ אֲשֶׁר שֵׁם הָאֶחָד גֵּרְשֹׁם כִּי אָמַר גֵּר הָיִיתִי בְּאֶרֶץ נָכְרִיָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'а другого — Элиэзер, потому что [Моше сказал]: “Бог моего отца [пришел] мне на помощь, Он избавил меня от меча фараона”.',
    verse_ivrit: 'וְשֵׁם הָאֶחָד אֱלִיעֶזֶר כִּי-אֱלֹהֵי אָבִי בְּעֶזְרִי וַיַּצִּלֵנִי מֵחֶרֶב פַּרְעֹה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Итро, тесть Моше, сыновья Моше и его жена пришли к нему в пустыню, где он расположился станом у горы Божьей.',
    verse_ivrit: 'וַיָּבֹא יִתְרוֹ חֹתֵן מֹשֶׁה וּבָנָיו וְאִשְׁתּוֹ אֶל-מֹשֶׁה אֶל-הַמִּדְבָּר אֲשֶׁר-הוּא חֹנֶה שָׁם הַר הָאֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: '“Я, твой тесть Итро, иду к тебе, — известил он Моше, — и [со мной] твоя жена и оба ее сына”.',
    verse_ivrit: 'וַיֹּאמֶר אֶל-מֹשֶׁה אֲנִי חֹתֶנְךָ יִתְרוֹ בָּא אֵלֶיךָ וְאִשְׁתְּךָ וּשְׁנֵי בָנֶיהָ עִמָּהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Моше вышел навстречу своему тестю, поклонился и расцеловал его. Расспросив друг друга о благополучии, они вошли в шатер.',
    verse_ivrit: 'וַיֵּצֵא מֹשֶׁה לִקְרַאת חֹתְנוֹ וַיִּשְׁתַּחוּ וַיִּשַּׁק-לוֹ וַיִּשְׁאֲלוּ אִישׁ-לְרֵעֵהוּ לְשָׁלוֹם וַיָּבֹאוּ הָאֹהֱלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Моше рассказал своему тестю обо всем, что Господь сделал с фараоном и египтянами ради Израиля, обо всех трудностях, которые встретились им на пути, и о том, как Господь спасал их.',
    verse_ivrit: 'וַיְסַפֵּר מֹשֶׁה לְחֹתְנוֹ אֵת כָּל-אֲשֶׁר עָשָׂה יְהוָה לְפַרְעֹה וּלְמִצְרַיִם עַל אוֹדֹת יִשְׂרָאֵל אֵת כָּל-הַתְּלָאָה אֲשֶׁר מְצָאָתַם בַּדֶּרֶךְ וַיַּצִּלֵם יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Итро порадовался всему хорошему, что сделал Господь для Израиля, спасая их от египтян.',
    verse_ivrit: 'וַיִּחַדְּ יִתְרוֹ עַל כָּל-הַטּוֹבָה אֲשֶׁר-עָשָׂה יְהוָה לְיִשְׂרָאֵל אֲשֶׁר הִצִּילוֹ מִיַּד מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: '— Благословен Господь, — сказал Итро, — Который спас вас от египтян и фараона, избавив этот народ от власти Египта!',
    verse_ivrit: 'וַיֹּאמֶר יִתְרוֹ בָּרוּךְ יְהוָה אֲשֶׁר הִצִּיל אֶתְכֶם מִיַּד מִצְרַיִם וּמִיַּד פַּרְעֹה אֲשֶׁר הִצִּיל אֶת-הָעָם מִתַּחַת יַד-מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Теперь я знаю, что Господь превзошел всех богов в том, что они сами замыслили против народа.',
    verse_ivrit: 'עַתָּה יָדַעְתִּי כִּי-גָדוֹל יְהוָה מִכָּל-הָאֱלֹהִים כִּי בַדָּבָר אֲשֶׁר זָדוּ עֲלֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Итро, тесть Моше, взял жертву всесожжения и [иные] жертвы Богу. Аѓарон и все старейшины Израиля пришли есть с тестем Моше пред Богом.',
    verse_ivrit: 'וַיִּקַּח יִתְרוֹ חֹתֵן מֹשֶׁה עֹלָה וּזְבָחִים לֵאלֹהִים וַיָּבֹא אַהֲרֹן וְכֹל זִקְנֵי יִשְׂרָאֵל לֶאֱכָל-לֶחֶם עִם-חֹתֵן מֹשֶׁה לִפְנֵי הָאֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'А на следующий день Моше сел судить народ, и люди с утра до вечера стояли перед Моше [в ожидании суда].',
    verse_ivrit: 'וַיְהִי מִמָּחֳרָת וַיֵּשֶׁב מֹשֶׁה לִשְׁפֹּט אֶת-הָעָם וַיַּעֲמֹד הָעָם עַל-מֹשֶׁה מִן-הַבֹּקֶר עַד-הָעָרֶב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Когда тесть Моше увидел, как Моше поступает с народом, он сказал: — Что это ты делаешь с народом? Почему ты сидишь один, а весь народ толпится перед тобой с утра до вечера?',
    verse_ivrit: 'וַיַּרְא חֹתֵן מֹשֶׁה אֵת כָּל-אֲשֶׁר-הוּא עֹשֶׂה לָעָם וַיֹּאמֶר מָה-הַדָּבָר הַזֶּה אֲשֶׁר אַתָּה עֹשֶׂה לָעָם מַדּוּעַ אַתָּה יוֹשֵׁב לְבַדֶּךָ וְכָל-הָעָם נִצָּב עָלֶיךָ מִן-בֹּקֶר עַד-עָרֶב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Моше ответил своему тестю: — Потому что люди приходят ко мне вопрошать Бога.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה לְחֹתְנוֹ כִּי-יָבֹא אֵלַי הָעָם לִדְרֹשׁ אֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Если у них случается спор, то они излагают его мне, и я сужу между двумя сторонами и сообщаю [им] законы Бога и Его постановления.',
    verse_ivrit: 'כִּי-יִהְיֶה לָהֶם דָּבָר בָּא אֵלַי וְשָׁפַטְתִּי בֵּין אִישׁ וּבֵין רֵעֵהוּ וְהוֹדַעְתִּי אֶת-חֻקֵּי הָאֱלֹהִים וְאֶת-תּוֹרֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: '— Нехорошо то, что ты делаешь, — сказал Моше его тесть.',
    verse_ivrit: 'וַיֹּאמֶר חֹתֵן מֹשֶׁה אֵלָיו לֹא-טוֹב הַדָּבָר אֲשֶׁר אַתָּה עֹשֶׂה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: '— Совсем измучаешься и ты, и этот народ, который с тобою. Такое дело тебе не под силу, ты не справишься с ним один.',
    verse_ivrit: 'נָבֹל תִּבֹּל גַּם-אַתָּה גַּם-הָעָם הַזֶּה אֲשֶׁר עִמָּךְ כִּי-כָבֵד מִמְּךָ הַדָּבָר לֹא-תוּכַל עֲשֹׂהוּ לְבַדֶּךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Послушай же, что я тебе посоветую, и да будет с тобой Бог! Будь [представителем] народа пред Богом и передавай Богу тяжбы,',
    verse_ivrit: 'עַתָּה שְׁמַע בְּקֹלִי אִיעָצְךָ וִיהִי אֱלֹהִים עִמָּךְ הֱיֵה אַתָּה לָעָם מוּל הָאֱלֹהִים וְהֵבֵאתָ אַתָּה אֶת-הַדְּבָרִים אֶל-הָאֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'а народу разъясняй уставы и постановления, сообщая, по какому пути им идти и как поступать.',
    verse_ivrit: 'וְהִזְהַרְתָּה אֶתְהֶם אֶת-הַחֻקִּים וְאֶת-הַתּוֹרֹת וְהוֹדַעְתָּ לָהֶם אֶת-הַדֶּרֶךְ יֵלְכוּ בָהּ וְאֶת-הַמַּעֲשֶׂה אֲשֶׁר יַעֲשׂוּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Выбери из народа достойных людей — богобоязненных, правдивых и неподкупных — и назначь их [начальниками]: кого над тысячей, кого над сотней, кого над полусотней, кого над десятком [людей].',
    verse_ivrit: 'וְאַתָּה תֶחֱזֶה מִכָּל-הָעָם אַנְשֵׁי-חַיִל יִרְאֵי אֱלֹהִים אַנְשֵׁי אֱמֶת שֹׂנְאֵי בָצַע וְשַׂמְתָּ עֲלֵהֶם שָׂרֵי אֲלָפִים שָׂרֵי מֵאוֹת שָׂרֵי חֲמִשִּׁים וְשָׂרֵי עֲשָׂרֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Они будут судить народ в любое время: важное дело передадут тебе, а мелкое дело решат сами. Тебе будет легче, потому что они разделят с тобой [бремя].',
    verse_ivrit: 'וְשָׁפְטוּ אֶת-הָעָם בְּכָל-עֵת וְהָיָה כָּל-הַדָּבָר הַגָּדֹל יָבִיאוּ אֵלֶיךָ וְכָל-הַדָּבָר הַקָּטֹן יִשְׁפְּטוּ-הֵם וְהָקֵל מֵעָלֶיךָ וְנָשְׂאוּ אִתָּךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Если ты так поступишь, то Бог сообщит тебе Свою волю, и ты сможешь выдержать, и весь этот народ с миром разойдется по домам.',
    verse_ivrit: 'אִם אֶת-הַדָּבָר הַזֶּה תַּעֲשֶׂה וְצִוְּךָ אֱלֹהִים וְיָכָלְתָּ עֲמֹד וְגַם כָּל-הָעָם הַזֶּה עַל-מְקֹמוֹ יָבֹא בְשָׁלוֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Моше послушал совета своего тестя и сделал так, как тот сказал.',
    verse_ivrit: 'וַיִּשְׁמַע מֹשֶׁה לְקוֹל חֹתְנוֹ וַיַּעַשׂ כֹּל אֲשֶׁר אָמָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Он выбрал достойных людей из всего Израиля и поставил их главами над народом: над тысячами, сотнями, полусотнями и десятками.',
    verse_ivrit: 'וַיִּבְחַר מֹשֶׁה אַנְשֵׁי-חַיִל מִכָּל-יִשְׂרָאֵל וַיִּתֵּן אֹתָם רָאשִׁים עַל-הָעָם שָׂרֵי אֲלָפִים שָׂרֵי מֵאוֹת שָׂרֵי חֲמִשִּׁים וְשָׂרֵי עֲשָׂרֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Они судили народ в любое время: трудное дело передавали Моше, а во всяком мелком деле принимали решение сами.',
    verse_ivrit: 'וְשָׁפְטוּ אֶת-הָעָם בְּכָל-עֵת אֶת-הַדָּבָר הַקָּשֶׁה יְבִיאוּן אֶל-מֹשֶׁה וְכָל-הַדָּבָר הַקָּטֹן יִשְׁפּוּטוּ הֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 18,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Моше отпустил своего тестя, и тот ушел в свою страну.',
    verse_ivrit: 'וַיְשַׁלַּח מֹשֶׁה אֶת-חֹתְנוֹ וַיֵּלֶךְ לוֹ אֶל-אַרְצוֹ',
    comment: '',
  },
  //////////////////////////////////////           191919191191919119191191919119191919       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'На третий месяц после ухода сынов Израиля из земли египетской, в этот самый день, они пришли в пустыню Синай.',
    verse_ivrit: 'בַּחֹדֶשׁ הַשְּׁלִישִׁי לְצֵאת בְּנֵי-יִשְׂרָאֵל מֵאֶרֶץ מִצְרָיִם בַּיּוֹם הַזֶּה בָּאוּ מִדְבַּר סִינָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Они двинулись из Рефидима, пришли в пустыню Синай и разбили стан в пустыне. Израиль расположился там станом напротив горы.',
    verse_ivrit: 'וַיִּסְעוּ מֵרְפִידִים וַיָּבֹאוּ מִדְבַּר סִינַי וַיַּחֲנוּ בַּמִּדְבָּר וַיִּחַן-שָׁם יִשְׂרָאֵל נֶגֶד הָהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Моше поднялся к Богу, и Господь воззвал к нему с горы: — Скажи дому Яакова, передай сынам Израиля:',
    verse_ivrit: 'וּמֹשֶׁה עָלָה אֶל-הָאֱלֹהִים וַיִּקְרָא אֵלָיו יְהוָה מִן-הָהָר לֵאמֹר כֹּה תֹאמַר לְבֵית יַעֲקֹב וְתַגֵּיד לִבְנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: '“Вы видели, что Я сделал с Египтом! А вас Я поднял на орлиных крыльях и принес к Себе.',
    verse_ivrit: 'אַתֶּם רְאִיתֶם אֲשֶׁר עָשִׂיתִי לְמִצְרָיִם וָאֶשָּׂא אֶתְכֶם עַל-כַּנְפֵי נְשָׁרִים וָאָבִא אֶתְכֶם אֵלָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Поэтому, если вы будете слушать Меня и хранить союз со Мной, то вы будете Моим достоянием среди всех народов.',
    verse_ivrit: 'וְעַתָּה אִם-שָׁמוֹעַ תִּשְׁמְעוּ בְּקֹלִי וּשְׁמַרְתֶּם אֶת-בְּרִיתִי וִהְיִיתֶם לִי סְגֻלָּה מִכָּל-הָעַמִּים כִּי-לִי כָּל-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Вся земля принадлежит Мне, а вы будете у Меня царством священников и святым народом!” Вот эти слова передай сынам Израиля.',
    verse_ivrit: 'וְאַתֶּם תִּהְיוּ-לִי מַמְלֶכֶת כֹּהֲנִים וְגוֹי קָדוֹשׁ אֵלֶּה הַדְּבָרִים אֲשֶׁר תְּדַבֵּר אֶל-בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Моше вернулся, созвал старейшин народа и передал им все то, что Господь повелел ему.',
    verse_ivrit: 'וַיָּבֹא מֹשֶׁה וַיִּקְרָא לְזִקְנֵי הָעָם וַיָּשֶׂם לִפְנֵיהֶם אֵת כָּל-הַדְּבָרִים הָאֵלֶּה אֲשֶׁר צִוָּהוּ יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Весь народ, как один, ответил: “Все, что говорил Господь, мы исполним!” И Моше передал Господу ответ народа.',
    verse_ivrit: 'וַיַּעֲנוּ כָל-הָעָם יַחְדָּו וַיֹּאמְרוּ כֹּל אֲשֶׁר-דִּבֶּר יְהוָה נַעֲשֶׂה וַיָּשֶׁב מֹשֶׁה אֶת-דִּבְרֵי הָעָם אֶל-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Господь сказал Моше: — Я приду к тебе в густом облаке. Когда услышит народ, что Я говорю с тобой, то они поверят тебе навсегда. И Моше передал Господу слова народа.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה הִנֵּה אָנֹכִי בָּא אֵלֶיךָ בְּעַב הֶעָנָן בַּעֲבוּר יִשְׁמַע הָעָם בְּדַבְּרִי עִמָּךְ וְגַם-בְּךָ יַאֲמִינוּ לְעוֹלָם וַיַּגֵּד מֹשֶׁה אֶת-דִּבְרֵי הָעָם אֶל-יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Господь сказал Моше: “Иди к народу. Сегодня и завтра готовь их к освящению: пусть выстирают свои одежды',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה לֵךְ אֶל-הָעָם וְקִדַּשְׁתָּם הַיּוֹם וּמָחָר וְכִבְּסוּ שִׂמְלֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'и пусть будут готовы к третьему дню, потому что на третий день Господь сойдет на гору Синай на глазах у всего народа.',
    verse_ivrit: 'וְהָיוּ נְכֹנִים לַיּוֹם הַשְּׁלִישִׁי כִּי בַּיּוֹם הַשְּׁלִשִׁי יֵרֵד יְהוָה לְעֵינֵי כָל-הָעָם עַל-הַר סִינָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Проведи границу вокруг народа и скажи: ‘Берегитесь, не восходите на гору и не прикасайтесь к ее подножию. Каждый, кто прикоснется к горе, должен умереть.',
    verse_ivrit: 'וְהִגְבַּלְתָּ אֶת-הָעָם סָבִיב לֵאמֹר הִשָּׁמְרוּ לָכֶם עֲלוֹת בָּהָר וּנְגֹעַ בְּקָצֵהוּ כָּל-הַנֹּגֵעַ בָּהָר מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Но не касайтесь того человека рукой — пусть он будет побит камнями или застрелен из лука. Скот ли это, человек ли — не оставляйте его в живых’. Только когда затрубит рог, пусть поднимется народ на гору”.',
    verse_ivrit: 'לֹא-תִגַּע בּוֹ יָד כִּי-סָקוֹל יִסָּקֵל אוֹ-יָרֹה יִיָּרֶה אִם-בְּהֵמָה אִם-אִישׁ לֹא יִחְיֶה בִּמְשֹׁךְ הַיֹּבֵל הֵמָּה יַעֲלוּ בָהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Моше спустился с горы к народу и повелел народу освятиться, и они выстирали свои одежды.',
    verse_ivrit: 'וַיֵּרֶד מֹשֶׁה מִן-הָהָר אֶל-הָעָם וַיְקַדֵּשׁ אֶת-הָעָם וַיְכַבְּסוּ שִׂמְלֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: '“Будьте готовы к послезавтрашнему дню, — сказал он народу, — не приближайтесь к женщинам”.',
    verse_ivrit: 'וַיֹּאמֶר אֶל-הָעָם הֱיוּ נְכֹנִים לִשְׁלֹשֶׁת יָמִים אַל-תִּגְּשׁוּ אֶל-אִשָּׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'И вот наступило утро третьего дня: от громов и молний, густого облака на горе и громовых трубных звуков весь народ в стане содрогнулся.',
    verse_ivrit: 'וַיְהִי בַיּוֹם הַשְּׁלִישִׁי בִּהְיֹת הַבֹּקֶר וַיְהִי קֹלֹת וּבְרָקִים וְעָנָן כָּבֵד עַל-הָהָר וְקֹל שֹׁפָר חָזָק מְאֹד וַיֶּחֱרַד כָּל-הָעָם אֲשֶׁר בַּמַּחֲנֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Моше вывел народ из стана навстречу Богу, и они остановились под горой.',
    verse_ivrit: 'וַיּוֹצֵא מֹשֶׁה אֶת-הָעָם לִקְרַאת הָאֱלֹהִים מִן-הַמַּחֲנֶה וַיִּתְיַצְּבוּ בְּתַחְתִּית הָהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Вся гора Синай была окутана дымом, потому что Господь сошел на нее в огне. Дым поднимался от горы, как от жаровни, и вся гора сотрясалась.',
    verse_ivrit: 'וְהַר סִינַי עָשַׁן כֻּלּוֹ מִפְּנֵי אֲשֶׁר יָרַד עָלָיו יְהוָה בָּאֵשׁ וַיַּעַל עֲשָׁנוֹ כְּעֶשֶׁן הַכִּבְשָׁן וַיֶּחֱרַד כָּל-הָהָר מְאֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Звук шофара становился все сильнее и сильнее: Моше говорил, а Бог отвечал ему громовым голосом.',
    verse_ivrit: 'וַיְהִי קוֹל הַשֹּׁפָר הוֹלֵךְ וְחָזֵק מְאֹד מֹשֶׁה יְדַבֵּר וְהָאֱלֹהִים יַעֲנֶנּוּ בְקוֹל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Господь сошел на гору Синай, на самую вершину горы. Он призвал Моше, и Моше поднялся на вершину горы.',
    verse_ivrit: 'וַיֵּרֶד יְהוָה עַל-הַר סִינַי אֶל-רֹאשׁ הָהָר וַיִּקְרָא יְהוָה לְמֹשֶׁה אֶל-רֹאשׁ הָהָר וַיַּעַל מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Господь сказал Моше: — Спустись, предостереги народ, чтобы они не порывались увидеть Господа, иначе многие из них погибнут.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה רֵד הָעֵד בָּעָם פֶּן-יֶהֶרְסוּ אֶל-יְהוָה לִרְאוֹת וְנָפַל מִמֶּנּוּ רָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Даже священники, приближающиеся к Господу, должны освятиться, а иначе Господь поразит их.',
    verse_ivrit: 'וְגַם הַכֹּהֲנִים הַנִּגָּשִׁים אֶל-יְהוָה יִתְקַדָּשׁוּ פֶּן-יִפְרֹץ בָּהֶם יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: '— Народ не может взойти на гору Синай, — ответил Моше Господу, — потому что Ты предостерег нас, сказав: “Огради гору и освяти ее”.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-יְהוָה לֹא-יוּכַל הָעָם לַעֲלֹת אֶל-הַר סִינָי כִּי-אַתָּה הַעֵדֹתָה בָּנוּ לֵאמֹר הַגְבֵּל אֶת-הָהָר וְקִדַּשְׁתּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: '— Иди, спустись, — ответил ему Господь, — а [затем] поднимись вместе с Аѓароном. Священники же и народ пусть не порываются восходить к Господу, иначе Господь поразит их.',
    verse_ivrit: 'וַיֹּאמֶר אֵלָיו יְהוָה לֶךְ-רֵד וְעָלִיתָ אַתָּה וְאַהֲרֹן עִמָּךְ וְהַכֹּהֲנִים וְהָעָם אַל-יֶהֶרְסוּ לַעֲלֹת אֶל-יְהוָה פֶּן-יִפְרָץ-בָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 19,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Моше сошел к народу и предостерег их.',
    verse_ivrit: 'וַיֵּרֶד מֹשֶׁה אֶל-הָעָם וַיֹּאמֶר אֲלֵהֶם',
    comment: '',
  },
  //////////////////////////////////////           20202020202020202202020202020202020220202020       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Бог произнес такие слова:',
    verse_ivrit: 'וַיְדַבֵּר אֱלֹהִים אֵת כָּל-הַדְּבָרִים הָאֵלֶּה לֵאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '“Я — Господь, твой Бог, Который вывел тебя из земли египетской, из дома рабства. Пусть не будет у тебя других богов передо Мной.',
    verse_ivrit: 'אָנֹכִי יְהוָה אֱלֹהֶיךָ אֲשֶׁר הוֹצֵאתִיךָ מֵאֶרֶץ מִצְרַיִם מִבֵּית עֲבָדִים לֹא-יִהְיֶה לְךָ אֱלֹהִים אֲחֵרִים עַל-פָּנָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Не делай себе изваяния и никакого изображения того, что вверху на небе, и что внизу на земле, и что в воде, ниже земли.',
    verse_ivrit: 'לֹא-תַעֲשֶׂה לְךָ פֶסֶל וְכָל-תְּמוּנָה אֲשֶׁר בַּשָּׁמַיִם מִמַּעַל וַאֲשֶׁר בָּאָרֶץ מִתָּחַת וַאֲשֶׁר בַּמַּיִם מִתַּחַת לָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Не поклоняйся им и не служи им, ведь Я, Господь, твой Бог, — Бог-ревнитель, карающий детей за вину отцов до третьего и четвертого поколения у тех, кто ненавидит Меня,',
    verse_ivrit: 'לֹא-תִשְׁתַּחֲוֶה לָהֶם וְלֹא תָעָבְדֵם כִּי אָנֹכִי יְהוָה אֱלֹהֶיךָ אֵל קַנָּא פֹּקֵד עֲו‍ֹן אָבֹת עַל-בָּנִים עַל-שִׁלֵּשִׁים וְעַל-רִבֵּעִים לְשֹׂנְאָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'и творящий милость до тысячного поколения тем, кто любит Меня и соблюдает Мои заповеди.',
    verse_ivrit: 'וְעֹשֶׂה חֶסֶד לַאֲלָפִים לְאֹהֲבַי וּלְשֹׁמְרֵי מִצְו‍ֹתָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Не произноси попусту Имя Господа, твоего Бога, ведь Господь не простит того, кто попусту произносит Его Имя.',
    verse_ivrit: 'לֹא תִשָּׂא אֶת-שֵׁם-יְהוָה אֱלֹהֶיךָ לַשָּׁוְא כִּי לֹא יְנַקֶּה יְהוָה אֵת אֲשֶׁר-יִשָּׂא אֶת-שְׁמוֹ לַשָּׁוְא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Помни субботний день и освящай его.',
    verse_ivrit: 'זָכוֹר אֶת-יוֹם הַשַּׁבָּת לְקַדְּשׁוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Шесть дней работай и делай все свои дела,',
    verse_ivrit: 'שֵׁשֶׁת יָמִים תַּעֲבֹד וְעָשִׂיתָ כָּל-מְלַאכְתֶּךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'а седьмой день — суббота Господу, твоему Богу: не делай никакого дела ни ты, ни твой сын, ни твоя дочь, ни твой раб, ни твоя рабыня, ни твой скот, ни переселенец, что в твоих воротах.',
    verse_ivrit: 'וְיוֹם הַשְּׁבִיעִי שַׁבָּת לַיהוָה אֱלֹהֶיךָ לֹא-תַעֲשֶׂה כָל-מְלָאכָה אַתָּה וּבִנְךָ וּבִתֶּךָ עַבְדְּךָ וַאֲמָתְךָ וּבְהֶמְתֶּךָ וְגֵרְךָ אֲשֶׁר בִּשְׁעָרֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Ибо за шесть дней Господь создал небо, землю, море и все, что их наполняет, и отдыхал в седьмой день. Поэтому Господь благословил субботний день и освятил его.',
    verse_ivrit: 'כִּי שֵׁשֶׁת-יָמִים עָשָׂה יְהוָה אֶת-הַשָּׁמַיִם וְאֶת-הָאָרֶץ אֶת-הַיָּם וְאֶת-כָּל-אֲשֶׁר-בָּם וַיָּנַח בַּיּוֹם הַשְּׁבִיעִי עַל-כֵּן בֵּרַךְ יְהוָה אֶת-יוֹם הַשַּׁבָּת וַיְקַדְּשֵׁהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Чти отца и мать, чтобы продлились твои дни на земле, которую тебе дарует Господь, твой Бог.',
    verse_ivrit: 'כַּבֵּד אֶת-אָבִיךָ וְאֶת-אִמֶּךָ לְמַעַן יַאֲרִכוּן יָמֶיךָ עַל הָאֲדָמָה אֲשֶׁר-יְהוָה אֱלֹהֶיךָ נֹתֵן לָךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Не убивай.<br>Не прелюбодействуй.<br>Не кради.<br>Не давай ложных свидетельств о своем ближнем.',
    verse_ivrit: 'לֹא תִרְצָח<br>לֹא תִנְאָף<br>לֹא תִגְנֹב<br>לֹא-תַעֲנֶה בְרֵעֲךָ עֵד שָׁקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Не пожелай [присвоить] ни дом ближнего, ни жену ближнего, ни его раба, ни его рабыню, ни его быка, ни его осла ничего [из того], что принадлежит ближнему твоему”. ',
    verse_ivrit: 'לֹא תַחְמֹד בֵּית רֵעֶךָ לֹא-תַחְמֹד אֵשֶׁת רֵעֶךָ וְעַבְדּוֹ וַאֲמָתוֹ וְשׁוֹרוֹ וַחֲמֹרוֹ וְכֹל אֲשֶׁר לְרֵעֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Весь народ видел гром с пламенем, звук шофара и дымящуюся гору. Увидел народ, содрогнулся и стал поодаль.',
    verse_ivrit: 'וְכָל-הָעָם רֹאִים אֶת-הַקּוֹלֹת וְאֶת-הַלַּפִּידִם וְאֵת קוֹל הַשֹּׁפָר וְאֶת-הָהָר עָשֵׁן וַיַּרְא הָעָם וַיָּנֻעוּ וַיַּעַמְדוּ מֵרָחֹק',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: '— Лучше ты говори с нами, — сказали они Моше, — а мы будем слушать. Пусть Бог не говорит с нами, а то мы умрем.',
    verse_ivrit: 'וַיֹּאמְרוּ אֶל-מֹשֶׁה דַּבֵּר-אַתָּה עִמָּנוּ וְנִשְׁמָעָה וְאַל-יְדַבֵּר עִמָּנוּ אֱלֹהִים פֶּן-נָמוּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: '— Не бойтесь, — сказал Моше народу, — ведь Бог явился, дабы испытать вас и дабы страх Божий был в вас, чтобы вы не грешили.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-הָעָם אַל-תִּירָאוּ כִּי לְבַעֲבוּר נַסּוֹת אֶתְכֶם בָּא הָאֱלֹהִים וּבַעֲבוּר תִּהְיֶה יִרְאָתוֹ עַל-פְּנֵיכֶם לְבִלְתִּי תֶחֱטָאוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Народ стал поодаль, а Моше приблизился к густому облаку, где пребывал Бог. ',
    verse_ivrit: 'וַיַּעֲמֹד הָעָם מֵרָחֹק וּמֹשֶׁה נִגַּשׁ אֶל-הָעֲרָפֶל אֲשֶׁר-שָׁם הָאֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Господь сказал Моше: “Скажи сынам Израиля: Вы видели, что Я говорил с вами с небес!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה כֹּה תֹאמַר אֶל-בְּנֵי יִשְׂרָאֵל אַתֶּם רְאִיתֶם כִּי מִן-הַשָּׁמַיִם דִּבַּרְתִּי עִמָּכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Не делайте [богов] наравне со Мной: не делайте себе серебряных и золотых богов!',
    verse_ivrit: 'לֹא תַעֲשׂוּן אִתִּי אֱלֹהֵי כֶסֶף וֵאלֹהֵי זָהָב לֹא תַעֲשׂוּ לָכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Сделай Мне жертвенник из земли и приноси на нем жертвы всесожжения и мирные жертвы — мелкий и крупный скот. На всякое место, где разрешу Я упомянуть Мое Имя, Я приду к тебе, чтобы благословить тебя.',
    verse_ivrit: 'מִזְבַּח אֲדָמָה תַּעֲשֶׂה-לִּי וְזָבַחְתָּ עָלָיו אֶת-עֹלֹתֶיךָ וְאֶת-שְׁלָמֶיךָ אֶת-צֹאנְךָ וְאֶת-בְּקָרֶךָ בְּכָל-הַמָּקוֹם אֲשֶׁר אַזְכִּיר אֶת-שְׁמִי אָבוֹא אֵלֶיךָ וּבֵרַכְתִּיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Если будешь возводить Мне каменный жертвенник, то не делай его из тесаных камней. Если ты занес над камнем резец, то ты осквернил его.',
    verse_ivrit: 'וְאִם-מִזְבַּח אֲבָנִים תַּעֲשֶׂה-לִּי לֹא-תִבְנֶה אֶתְהֶן גָּזִית כִּי חַרְבְּךָ הֵנַפְתָּ עָלֶיהָ וַתְּחַלְלֶהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ИТРО(Итро)',
    id_book: 1,
    id_chapter: 20,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Не поднимайся на Мой жертвенник по ступеням, чтобы не открылась на нем твоя нагота. ',
    verse_ivrit: 'וְלֹא-תַעֲלֶה בְמַעֲלֹת עַל-מִזְבְּחִי אֲשֶׁר לֹא-תִגָּלֶה עֶרְוָתְךָ עָלָי',
    comment: '',
  },
  //////////////////////////////////////           2121212121212121212121212121212121212121       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Вот законы, которые ты должен предложить им:',
    verse_ivrit: 'וְאֵלֶּה הַמִּשְׁפָּטִים אֲשֶׁר תָּשִׂים לִפְנֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Если купишь раба-еврея, пусть он служит шесть лет, а на седьмой год он должен стать свободным без выкупа.',
    verse_ivrit: 'כִּי תִקְנֶה עֶבֶד עִבְרִי שֵׁשׁ שָׁנִים יַעֲבֹד וּבַשְּׁבִעִת יֵצֵא לַחָפְשִׁי חִנָּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Если он пришел один — пусть один и уходит, а если женат, то пусть вместе с ним уйдет и его жена.',
    verse_ivrit: 'אִם-בְּגַפּוֹ יָבֹא בְּגַפּוֹ יֵצֵא אִם-בַּעַל אִשָּׁה הוּא וְיָצְאָה אִשְׁתּוֹ עִמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Если хозяин дал ему жену и она родит ему сыновей или дочерей, то пусть жена и дети останутся у хозяина, а он пусть уходит один.',
    verse_ivrit: 'אִם-אֲדֹנָיו יִתֶּן-לוֹ אִשָּׁה וְיָלְדָה-לוֹ בָנִים אוֹ בָנוֹת הָאִשָּׁה וִילָדֶיהָ תִּהְיֶה לַאדֹנֶיהָ וְהוּא יֵצֵא בְגַפּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Но если раб заявит: “Я люблю своего хозяина, свою жену и детей — не пойду на волю”,',
    verse_ivrit: 'וְאִם-אָמֹר יֹאמַר הָעֶבֶד אָהַבְתִּי אֶת-אֲדֹנִי אֶת-אִשְׁתִּי וְאֶת-בָּנָי לֹא אֵצֵא חָפְשִׁי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'то пусть хозяин приведет его к судьям и подведет его к двери или к дверному косяку. Пусть хозяин проколет ему ухо шилом, и тот навеки останется его рабом.',
    verse_ivrit: 'וְהִגִּישׁוֹ אֲדֹנָיו אֶל-הָאֱלֹהִים וְהִגִּישׁוֹ אֶל-הַדֶּלֶת אוֹ אֶל-הַמְּזוּזָה וְרָצַע אֲדֹנָיו אֶת-אָזְנוֹ בַּמַּרְצֵעַ וַעֲבָדוֹ לְעֹלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Если человек продал свою дочь в рабство, то она не выйдет на свободу так, как выходят иные рабы.',
    verse_ivrit: 'וְכִי-יִמְכֹּר אִישׁ אֶת-בִּתּוֹ לְאָמָה לֹא תֵצֵא כְּצֵאת הָעֲבָדִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Если она стала неугодна господину, который предназначал ее себе, то пусть он позволит ее выкупить; он не властен продать ее чужому народу, после того как он отверг ее.',
    verse_ivrit: 'אִם-רָעָה בְּעֵינֵי אֲדֹנֶיהָ אֲשֶׁר-לא (לוֹ) יְעָדָהּ וְהֶפְדָּהּ לְעַם נָכְרִי לֹא-יִמְשֹׁל לְמָכְרָהּ בְּבִגְדוֹ-בָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Если он предназначил ее в жены своему сыну, то пусть поступит с нею по закону дочерей [Израиля].',
    verse_ivrit: 'וְאִם-לִבְנוֹ יִיעָדֶנָּה כְּמִשְׁפַּט הַבָּנוֹת יַעֲשֶׂה-לָּהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Если же он возьмет себе другую жену, то доля той в пище, одежде и близости не должна уменьшиться.',
    verse_ivrit: 'אִם-אַחֶרֶת יִקַּח-לוֹ שְׁאֵרָהּ כְּסוּתָהּ וְעֹנָתָהּ לֹא יִגְרָע',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'А если он не сохранит для нее эти три вещи, то она выйдет на свободу даром, без выкупа.',
    verse_ivrit: 'וְאִם-שְׁלָשׁ-אֵלֶּה לֹא יַעֲשֶׂה לָהּ וְיָצְאָה חִנָּם אֵין כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Если кто-нибудь ударит человека так, что тот умрет, пусть будет предан смерти.',
    verse_ivrit: 'מַכֵּה אִישׁ וָמֵת מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Но если он не замышлял зла, а это Бог подвел [того] ему под руку, то Я назначу вам место, куда ему следует бежать.',
    verse_ivrit: 'וַאֲשֶׁר לֹא צָדָה וְהָאֱלֹהִים אִנָּה לְיָדוֹ וְשַׂמְתִּי לְךָ מָקוֹם אֲשֶׁר יָנוּס שָׁמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Если же кто-то имел умысел против своего ближнего и умертвил его умышленно, то даже от Моего жертвенника забери его, чтобы предать смерти.',
    verse_ivrit: 'וְכִי-יָזִד אִישׁ עַל-רֵעֵהוּ לְהָרְגוֹ בְעָרְמָה מֵעִם מִזְבְּחִי תִּקָּחֶנּוּ לָמוּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Всякого, кто ударит отца и мать, следует предать смерти.',
    verse_ivrit: 'וּמַכֵּה אָבִיו וְאִמּוֹ מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Всякого, кто похитит человека и продаст его, либо тот будет найден у него, следует предать смерти.',
    verse_ivrit: 'וְגֹנֵב אִישׁ וּמְכָרוֹ וְנִמְצָא בְיָדוֹ מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Всякого, кто проклянет отца и мать, следует предать смерти.',
    verse_ivrit: 'וּמְקַלֵּל אָבִיו וְאִמּוֹ מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Если люди поссорятся и один человек ударит другого камнем или кулаком и тот не умрет, но сляжет в постель,',
    verse_ivrit: 'וְכִי-יְרִיבֻן אֲנָשִׁים וְהִכָּה-אִישׁ אֶת-רֵעֵהוּ בְּאֶבֶן אוֹ בְאֶגְרֹף וְלֹא יָמוּת וְנָפַל לְמִשְׁכָּב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'то если он встанет [после болезни] и выйдет из дому с костылем — ударивший будет невиновен, пусть только оплатит ему время бездействия и лечение.',
    verse_ivrit: 'אִם-יָקוּם וְהִתְהַלֵּךְ בַּחוּץ עַל-מִשְׁעַנְתּוֹ וְנִקָּה הַמַּכֶּה רַק שִׁבְתּוֹ יִתֵּן וְרַפֹּא יְרַפֵּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Если человек ударит своего раба или рабыню палкой и тот умрет на месте — [за умершего] следует отомстить.',
    verse_ivrit: 'וְכִי-יַכֶּה אִישׁ אֶת-עַבְדּוֹ אוֹ אֶת-אֲמָתוֹ בַּשֵּׁבֶט וּמֵת תַּחַת יָדוֹ נָקֹם יִנָּקֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Но если тот проживет еще день или два, то мстить не следует, потому что этот [раб] — его собственность.',
    verse_ivrit: 'אַךְ אִם-יוֹם אוֹ יוֹמַיִם יַעֲמֹד לֹא יֻקַּם כִּי כַסְפּוֹ הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Если люди будут драться и толкнут беременную женщину, так что у той случится выкидыш, но [ей самой] вреда не будет, то [с виновного] следует взять пеню: пусть заплатит столько, сколько потребует муж этой женщины, в соответствии с оценкой судей.',
    verse_ivrit: 'וְכִי-יִנָּצוּ אֲנָשִׁים וְנָגְפוּ אִשָּׁה הָרָה וְיָצְאוּ יְלָדֶיהָ וְלֹא יִהְיֶה אָסוֹן עָנוֹשׁ יֵעָנֵשׁ כַּאֲשֶׁר יָשִׁית עָלָיו בַּעַל הָאִשָּׁה וְנָתַן בִּפְלִלִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Но если вред будет, то взыщи жизнь за жизнь,',
    verse_ivrit: 'וְאִם-אָסוֹן יִהְיֶה וְנָתַתָּה נֶפֶשׁ תַּחַת נָפֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'глаз за глаз, зуб за зуб, руку за руку, ногу за ногу,',
    verse_ivrit: 'עַיִן תַּחַת עַיִן שֵׁן תַּחַת שֵׁן יָד תַּחַת יָד רֶגֶל תַּחַת רָגֶל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'ожог за ожог, рану за рану, ушиб за ушиб.',
    verse_ivrit: 'כְּוִיָּה תַּחַת כְּוִיָּה פֶּצַע תַּחַת פָּצַע חַבּוּרָה תַּחַת חַבּוּרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Если человек ударит своего раба или рабыню и повредит им глаз, то за этот глаз он должен отпустить их на волю.',
    verse_ivrit: 'וְכִי-יַכֶּה אִישׁ אֶת-עֵין עַבְדּוֹ אוֹ-אֶת-עֵין אֲמָתוֹ וְשִׁחֲתָהּ לַחָפְשִׁי יְשַׁלְּחֶנּוּ תַּחַת עֵינוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'И если он выбьет зуб своему рабу или рабыне, то за этот зуб он должен отпустить их на волю.',
    verse_ivrit: 'וְאִם-שֵׁן עַבְדּוֹ אוֹ-שֵׁן אֲמָתוֹ יַפִּיל לַחָפְשִׁי יְשַׁלְּחֶנּוּ תַּחַת שִׁנּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Если бык забодает мужчину или женщину насмерть, то быка нужно побить камнями, а его мясо есть нельзя. Хозяин быка — невиновен.',
    verse_ivrit: 'וְכִי-יִגַּח שׁוֹר אֶת-אִישׁ אוֹ אֶת-אִשָּׁה וָמֵת סָקוֹל יִסָּקֵל הַשּׁוֹר וְלֹא יֵאָכֵל אֶת-בְּשָׂרוֹ וּבַעַל הַשּׁוֹר נָקִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Но если бык и прежде был бодлив, а его хозяина предупреждали об этом, но он его не устерег и тот забодал мужчину или женщину, — то быка нужно побить камнями, а его хозяина следует предать смерти.',
    verse_ivrit: 'וְאִם שׁוֹר נַגָּח הוּא מִתְּמֹל שִׁלְשֹׁם וְהוּעַד בִּבְעָלָיו וְלֹא יִשְׁמְרֶנּוּ וְהֵמִית אִישׁ אוֹ אִשָּׁה הַשּׁוֹר יִסָּקֵל וְגַם-בְּעָלָיו יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Если на хозяина будет наложена пеня, то пусть он отдаст выкуп за свою жизнь, сколько ему будет определено.',
    verse_ivrit: 'אִם-כֹּפֶר יוּשַׁת עָלָיו וְנָתַן פִּדְיֹן נַפְשׁוֹ כְּכֹל אֲשֶׁר-יוּשַׁת עָלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Если [бык] забодает мальчика или девочку — с ним следует поступить по этому же закону.',
    verse_ivrit: 'אוֹ-בֵן יִגָּח אוֹ-בַת יִגָּח כַּמִּשְׁפָּט הַזֶּה יֵעָשֶׂה לּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Если бык забодает раба или рабыню, то [хозяин быка] должен заплатить их господину тридцать шекелей серебра, а быка следует побить камнями.',
    verse_ivrit: 'אִם-עֶבֶד יִגַּח הַשּׁוֹר אוֹ אָמָה כֶּסֶף שְׁלֹשִׁים שְׁקָלִים יִתֵּן לַאדֹנָיו וְהַשּׁוֹר יִסָּקֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Если кто-нибудь раскопает яму или выкопает яму, но не закроет ее, и туда свалится бык или осел,',
    verse_ivrit: 'וְכִי-יִפְתַּח אִישׁ בּוֹר אוֹ כִּי-יִכְרֶה אִישׁ בֹּר וְלֹא יְכַסֶּנּוּ וְנָפַל-שָׁמָּה שּׁוֹר אוֹ חֲמוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'то хозяин ямы должен заплатить. Пусть он возместит деньгами [ущерб] хозяину животного, а тушу возьмет себе.',
    verse_ivrit: 'בַּעַל הַבּוֹר יְשַׁלֵּם כֶּסֶף יָשִׁיב לִבְעָלָיו וְהַמֵּת יִהְיֶה-לּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Если чей-либо бык забодает чужого быка насмерть, то пусть живого быка продадут и разделят пополам [вырученные] за него деньги, а тушу убитого [быка] пусть также разделят пополам.',
    verse_ivrit: 'וְכִי-יִגֹּף שׁוֹר-אִישׁ אֶת-שׁוֹר רֵעֵהוּ וָמֵת וּמָכְרוּ אֶת-הַשּׁוֹר הַחַי וְחָצוּ אֶת-כַּסְפּוֹ וְגַם אֶת-הַמֵּת יֶחֱצוּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Но если известно, что бык и прежде был бодлив, а хозяин не устерег его, то он должен возместить стоимость быка, а тушу пусть возьмет себе.',
    verse_ivrit: 'אוֹ נוֹדַע כִּי שׁוֹר נַגָּח הוּא מִתְּמוֹל שִׁלְשֹׁם וְלֹא יִשְׁמְרֶנּוּ בְּעָלָיו שַׁלֵּם יְשַׁלֵּם שׁוֹר תַּחַת הַשּׁוֹר וְהַמֵּת יִהְיֶה-לּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 21,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'Если человек украдет быка или овцу и зарежет или продаст, то пять быков отдаст он за быка и четыре овцы — за овцу.',
    verse_ivrit: 'כִּי יִגְנֹב-אִישׁ שׁוֹר אוֹ-שֶׂה וּטְבָחוֹ אוֹ מְכָרוֹ חֲמִשָּׁה בָקָר יְשַׁלֵּם תַּחַת הַשּׁוֹר וְאַרְבַּע-צֹאן תַּחַת הַשֶּׂה',
    comment: '',
  },
  //////////////////////////////////////           222222222222222222222222222222222222222222       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Если вор пойман при подкопе и забит до смерти, за его кровь не взыскивают.',
    verse_ivrit: 'אִם-בַּמַּחְתֶּרֶת יִמָּצֵא הַגַּנָּב וְהֻכָּה וָמֵת אֵין לוֹ דָּמִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Но если над ним взошло солнце — за его кровь взыщется. Пусть [вор] заплатит, а если ему нечем [заплатить], то пусть его продадут в рабство за кражу.',
    verse_ivrit: 'אִם-זָרְחָה הַשֶּׁמֶשׁ עָלָיו דָּמִים לוֹ שַׁלֵּם יְשַׁלֵּם אִם-אֵין לוֹ וְנִמְכַּר בִּגְנֵבָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Если украденный бык, осел или овца будут найдены у него живыми, пусть заплатит вдвое.',
    verse_ivrit: 'אִם-הִמָּצֵא תִמָּצֵא בְיָדוֹ הַגְּנֵבָה מִשּׁוֹר עַד-חֲמוֹר עַד-שֶׂה חַיִּים שְׁנַיִם יְשַׁלֵּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Если человек допустил потраву поля или виноградника, либо послал свой скот пастись на чужое поле, то пусть отдаст за это лучшее, что есть у него в поле или винограднике.',
    verse_ivrit: 'כִּי יַבְעֶר-אִישׁ שָׂדֶה אוֹ-כֶרֶם וְשִׁלַּח אֶת-בְּעִירֹה וּבִעֵר בִּשְׂדֵה אַחֵר מֵיטַב שָׂדֵהוּ וּמֵיטַב כַּרְמוֹ יְשַׁלֵּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Если вспыхнувший огонь охватит кустарник, так что сгорят скирды, хлеб на корню или все поле, то виновник пожара должен заплатить.',
    verse_ivrit: 'כִּי-תֵצֵא אֵשׁ וּמָצְאָה קֹצִים וְנֶאֱכַל גָּדִישׁ אוֹ הַקָּמָה אוֹ הַשָּׂדֶה שַׁלֵּם יְשַׁלֵּם הַמַּבְעִר אֶת-הַבְּעֵרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Если человек отдаст своему ближнему на хранение деньги или вещи и они будут похищены из дома того человека, то, если вор будет пойман, пусть он заплатит вдвое.',
    verse_ivrit: 'כִּי-יִתֵּן אִישׁ אֶל-רֵעֵהוּ כֶּסֶף אוֹ-כֵלִים לִשְׁמֹר וְגֻנַּב מִבֵּית הָאִישׁ אִם-יִמָּצֵא הַגַּנָּב יְשַׁלֵּם שְׁנָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Если же вор не пойман, то пусть взявший на хранение предстанет перед судьями, чтобы те установили, не посягнул ли он на собственность ближнего.',
    verse_ivrit: 'אִם-לֹא יִמָּצֵא הַגַּנָּב וְנִקְרַב בַּעַל-הַבַּיִת אֶל-הָאֱלֹהִים אִם-לֹא שָׁלַח יָדוֹ בִּמְלֶאכֶת רֵעֵהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'В любом деле, касающемся кражи быка, осла, овцы, одежды — или всякой другой пропажи, о которой заявит одна из сторон, дело обеих сторон должно дойти до судей, и тот, кого судьи признают виновным, должен заплатить вдвое другой стороне.',
    verse_ivrit: 'עַל-כָּל-דְּבַר-פֶּשַׁע עַל-שׁוֹר עַל-חֲמוֹר עַל-שֶׂה עַל-שַׂלְמָה עַל-כָּל-אֲבֵדָה אֲשֶׁר יֹאמַר כִּי-הוּא זֶה עַד הָאֱלֹהִים יָבֹא דְּבַר-שְׁנֵיהֶם אֲשֶׁר יַרְשִׁיעֻן אֱלֹהִים יְשַׁלֵּם שְׁנַיִם לְרֵעֵהוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Если человек отдаст ближнему на хранение любую скотину — осла, быка или овцу — и та издохнет, покалечится или будет отнята, а очевидца этому нет,',
    verse_ivrit: 'כִּי-יִתֵּן אִישׁ אֶל-רֵעֵהוּ חֲמוֹר אוֹ-שׁוֹר אוֹ-שֶׂה וְכָל-בְּהֵמָה לִשְׁמֹר וּמֵת אוֹ-נִשְׁבַּר אוֹ-נִשְׁבָּה אֵין רֹאֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: '— клятва [Именем] Господа рассудит обоих, [чтобы подтвердилось], что взявший на хранение не посягнул на собственность ближнего. Хозяин должен принять [клятву], а [взявший на хранение] не платит.',
    verse_ivrit: 'שְׁבֻעַת יְהוָה תִּהְיֶה בֵּין שְׁנֵיהֶם אִם-לֹא שָׁלַח יָדוֹ בִּמְלֶאכֶת רֵעֵהוּ וְלָקַח בְּעָלָיו וְלֹא יְשַׁלֵּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Но если животное было у него украдено, то он должен заплатить его хозяину.',
    verse_ivrit: 'וְאִם-גָּנֹב יִגָּנֵב מֵעִמּוֹ יְשַׁלֵּם לִבְעָלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Если животное было растерзано, то пусть приведет свидетеля: за растерзанное животное он не должен платить.',
    verse_ivrit: 'אִם-טָרֹף יִטָּרֵף יְבִאֵהוּ עֵד הַטְּרֵפָה לֹא יְשַׁלֵּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Если человек одолжит у ближнего животное, а оно в отсутствие хозяина покалечится или издохнет, то взявший взаймы должен заплатить.',
    verse_ivrit: 'וְכִי-יִשְׁאַל אִישׁ מֵעִם רֵעֵהוּ וְנִשְׁבַּר אוֹ-מֵת בְּעָלָיו אֵין-עִמּוֹ שַׁלֵּם יְשַׁלֵּם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Если же хозяин был при этом, то платить не нужно. И если животное было взято в наем, то оно оплачено.',
    verse_ivrit: 'אִם-בְּעָלָיו עִמּוֹ לֹא יְשַׁלֵּם אִם-שָׂכִיר הוּא בָּא בִּשְׂכָרוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Если человек соблазнит необрученную девушку и ляжет с ней, то он должен отдать за нее выкуп и взять ее в жены.',
    verse_ivrit: 'וְכִי-יְפַתֶּה אִישׁ בְּתוּלָה אֲשֶׁר לֹא-אֹרָשָׂה וְשָׁכַב עִמָּהּ מָהֹר יִמְהָרֶנָּה לּוֹ לְאִשָּׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Если отец девушки отказывается выдавать ее за него, то пусть он отвесит серебро как выкуп за невесту.',
    verse_ivrit: 'אִם-מָאֵן יְמָאֵן אָבִיהָ לְתִתָּהּ לוֹ כֶּסֶף יִשְׁקֹל כְּמֹהַר הַבְּתוּלֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Колдунью не оставляй в живых.',
    verse_ivrit: 'מְכַשֵּׁפָה לֹא תְחַיֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Всякий скотоложец должен быть предан смерти.',
    verse_ivrit: 'כָּל-שֹׁכֵב עִם-בְּהֵמָה מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Всякий, кто приносит жертвы богам, помимо Господа, подлежит истреблению.',
    verse_ivrit: 'זֹבֵחַ לָאֱלֹהִים יָחֳרָם בִּלְתִּי לַיהוָה לְבַדּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Но переселенца не обижай и не притесняй, ведь и вы были переселенцами в земле египетской.',
    verse_ivrit: 'וְגֵר לֹא-תוֹנֶה וְלֹא תִלְחָצֶנּוּ כִּי-גֵרִים הֱיִיתֶם בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Не притесняйте ни вдову, ни сироту.',
    verse_ivrit: 'כָּל-אַלְמָנָה וְיָתוֹם לֹא תְעַנּוּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Если же ты будешь кого-то из них притеснять, а он воззовет ко Мне, то Я услышу его жалобу',
    verse_ivrit: 'אִם-עַנֵּה תְעַנֶּה אֹתוֹ כִּי אִם-צָעֹק יִצְעַק אֵלַי שָׁמֹעַ אֶשְׁמַע צַעֲקָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'и, разгневавшись, поражу вас мечом. И тогда ваши жены станут вдовами, а дети — сиротами!',
    verse_ivrit: 'וְחָרָה אַפִּי וְהָרַגְתִּי אֶתְכֶם בֶּחָרֶב וְהָיוּ נְשֵׁיכֶם אַלְמָנוֹת וּבְנֵיכֶם יְתֹמִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Если будешь ссужать деньги Моему народу — живущему среди вас бедняку — то не поступай с ним как заимодавец, не бери с него процентов!',
    verse_ivrit: 'אִם-כֶּסֶף תַּלְוֶה אֶת-עַמִּי אֶת-הֶעָנִי עִמָּךְ לֹא-תִהְיֶה לוֹ כְּנֹשֶׁה לֹא-תְשִׂימוּן עָלָיו נֶשֶׁךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Если возьмешь в залог одежду у ближнего твоего, возврати ему ее до захода солнца,',
    verse_ivrit: 'אִם-חָבֹל תַּחְבֹּל שַׂלְמַת רֵעֶךָ עַד-בֹּא הַשֶּׁמֶשׁ תְּשִׁיבֶנּוּ לוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'ведь это его единственный покров, одеяние для его тела. В чем ему спать? Если он воззовет ко Мне, то Я услышу, ведь Я милосерден!',
    verse_ivrit: 'כִּי הִוא כְסוּתֹה לְבַדָּהּ הִוא שִׂמְלָתוֹ לְעֹרוֹ בַּמֶּה יִשְׁכָּב וְהָיָה כִּי-יִצְעַק אֵלַי וְשָׁמַעְתִּי כִּי-חַנּוּן אָנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Судью не проклинай, главу твоего народа не кляни.',
    verse_ivrit: 'אֱלֹהִים לֹא תְקַלֵּל וְנָשִׂיא בְעַמְּךָ לֹא תָאֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Не запаздывай c [приношением] первых плодов твоего урожая. Первенца из твоих сыновей отдавай Мне.',
    verse_ivrit: 'מְלֵאָתְךָ וְדִמְעֲךָ לֹא תְאַחֵר בְּכוֹר בָּנֶיךָ תִּתֶּן-לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Так же поступай с твоим теленком и ягненком: семь дней пусть остается при своей матери, а на восьмой день отдай его Мне.',
    verse_ivrit: 'כֵּן-תַּעֲשֶׂה לְשֹׁרְךָ לְצֹאנֶךָ שִׁבְעַת יָמִים יִהְיֶה עִם-אִמּוֹ בַּיּוֹם הַשְּׁמִינִי תִּתְּנוֹ-לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 22,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Будьте для Меня святыми людьми: мясо животного, растерзанного в поле хищником, не ешьте — бросьте его псам.',
    verse_ivrit: 'וְאַנְשֵׁי-קֹדֶשׁ תִּהְיוּן לִי וּבָשָׂר בַּשָּׂדֶה טְרֵפָה לֹא תֹאכֵלוּ לַכֶּלֶב תַּשְׁלִכוּן אֹתוֹ',
    comment: '',
  },
  //////////////////////////////////////           23232323223232322323232232332323       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Не распространяй ложных слухов, дабы не пособлять злодею, давая преступные свидетельства.',
    verse_ivrit: 'לֹא תִשָּׂא שֵׁמַע שָׁוְא אַל-תָּשֶׁת יָדְךָ עִם-רָשָׁע לִהְיֹת עֵד חָמָס',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Не следуй за большинством в неправом деле и не свидетельствуй по тяжбе, склоняясь за большинством, пытаясь запутать дело.',
    verse_ivrit: 'לֹא-תִהְיֶה אַחֲרֵי-רַבִּים לְרָעֹת וְלֹא-תַעֲנֶה עַל-רִב לִנְטֹת אַחֲרֵי רַבִּים לְהַטֹּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Но и нищему не потворствуй в его тяжбе.',
    verse_ivrit: 'וְדָל לֹא תֶהְדַּר בְּרִיבוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Если ты встретишь заблудившегося быка или осла своего врага, ты должен возвратить их ему.',
    verse_ivrit: 'כִּי תִפְגַּע שׁוֹר אֹיִבְךָ אוֹ חֲמֹרוֹ תֹּעֶה הָשֵׁב תְּשִׁיבֶנּוּ לוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Если увидишь осла твоего врага лежащим под ношей, разве оставишь его без помощи? Развьючь вместе с ним!',
    verse_ivrit: 'כִּי-תִרְאֶה חֲמוֹר שֹׂנַאֲךָ רֹבֵץ תַּחַת מַשָּׂאוֹ וְחָדַלְתָּ מֵעֲזֹב לוֹ עָזֹב תַּעֲזֹב עִמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Не суди несправедливо бедняка в его тяжбе.',
    verse_ivrit: 'לֹא תַטֶּה מִשְׁפַּט אֶבְיֹנְךָ בְּרִיבוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Сторонись неправедного дела, дабы безвинного и правого не привести к смерти, ведь Я не оправдаю виновного!',
    verse_ivrit: 'מִדְּבַר-שֶׁקֶר תִּרְחָק וְנָקִי וְצַדִּיק אַל-תַּהֲרֹג כִּי לֹא-אַצְדִּיק רָשָׁע',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Взяток не бери. Взятка превращает зрячих в слепых, искажая правдивые показания.',
    verse_ivrit: 'וְשֹׁחַד לֹא תִקָּח כִּי הַשֹּׁחַד יְעַוֵּר פִּקְחִים וִיסַלֵּף דִּבְרֵי צַדִּיקִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Не притесняй переселенца. Вы знаете, каково приходится переселенцу, ведь вы сами были переселенцами в земле египетской.',
    verse_ivrit: 'וְגֵר לֹא תִלְחָץ וְאַתֶּם יְדַעְתֶּם אֶת-נֶפֶשׁ הַגֵּר כִּי-גֵרִים הֱיִיתֶם בְּאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Шесть лет засевай свою землю и собирай урожай.',
    verse_ivrit: 'וְשֵׁשׁ שָׁנִים תִּזְרַע אֶת-אַרְצֶךָ וְאָסַפְתָּ אֶת-תְּבוּאָתָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'А на седьмой год дай земле отдых и оставь ее [плоды], чтобы питались [ими] неимущие из твоего народа, а тем, что останется после них, пусть питаются звери полевые. Так же поступай с твоим виноградником и оливой.',
    verse_ivrit: 'וְהַשְּׁבִיעִת תִּשְׁמְטֶנָּה וּנְטַשְׁתָּהּ וְאָכְלוּ אֶבְיֹנֵי עַמֶּךָ וְיִתְרָם תֹּאכַל חַיַּת הַשָּׂדֶה כֵּן-תַּעֲשֶׂה לְכַרְמְךָ לְזֵיתֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Шесть дней занимайся трудами, а в седьмой день пребывай в покое, чтобы отдохнули твой бык и осел, сын твоей рабыни и переселенец.',
    verse_ivrit: 'שֵׁשֶׁת יָמִים תַּעֲשֶׂה מַעֲשֶׂיךָ וּבַיּוֹם הַשְּׁבִיעִי תִּשְׁבֹּת לְמַעַן יָנוּחַ שׁוֹרְךָ וַחֲמֹרֶךָ וְיִנָּפֵשׁ בֶּן-אֲמָתְךָ וְהַגֵּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Всего, что Я вам велел, придерживайтесь! Не упоминайте имен других богов: да не произнесут их ваши уста!',
    verse_ivrit: 'וּבְכֹל אֲשֶׁר-אָמַרְתִּי אֲלֵיכֶם תִּשָּׁמֵרוּ וְשֵׁם אֱלֹהִים אֲחֵרִים לֹא תַזְכִּירוּ לֹא יִשָּׁמַע עַל-פִּיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Трижды в год устраивай Мне праздник.',
    verse_ivrit: 'שָׁלֹשׁ רְגָלִים תָּחֹג לִי בַּשָּׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Соблюдай праздник мацы. Как Я и повелел тебе, семь дней ешь мацу — в положенное время, в месяце авив, ведь в этом месяце ты вышел из Египта. Да не появится [никто] предо Мной с пустыми [руками]!',
    verse_ivrit: 'אֶת-חַג הַמַּצּוֹת תִּשְׁמֹר שִׁבְעַת יָמִים תֹּאכַל מַצּוֹת כַּאֲשֶׁר צִוִּיתִךָ לְמוֹעֵד חֹדֶשׁ הָאָבִיב כִּי-בוֹ יָצָאתָ מִמִּצְרָיִם וְלֹא-יֵרָאוּ פָנַי רֵיקָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: '[Соблюдай] праздник жатвы первых плодов твоего труда, того, что ты посеял в поле, и праздник сбора плодов на исходе года, когда уберешь с поля плоды своих трудов.',
    verse_ivrit: 'וְחַג הַקָּצִיר בִּכּוּרֵי מַעֲשֶׂיךָ אֲשֶׁר תִּזְרַע בַּשָּׂדֶה וְחַג הָאָסִף בְּצֵאת הַשָּׁנָה בְּאָסְפְּךָ אֶת-מַעֲשֶׂיךָ מִן-הַשָּׂדֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Три раза в год все ваши мужчины должны представать перед Владыкой, Господом.',
    verse_ivrit: 'שָׁלֹשׁ פְּעָמִים בַּשָּׁנָה יֵרָאֶה כָּל-זְכוּרְךָ אֶל-פְּנֵי הָאָדֹן יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Не проливай кровь Моей жертвы на квасное, и пусть не остается жир Моей праздничной жертвы до утра.',
    verse_ivrit: 'לֹא-תִזְבַּח עַל-חָמֵץ דַּם-זִבְחִי וְלֹא-יָלִין חֵלֶב-חַגִּי עַד-בֹּקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Первые плоды урожая твоей земли приноси в храм Господа, твоего Бога. Не вари детеныша скота в молоке его матери.',
    verse_ivrit: 'רֵאשִׁית בִּכּוּרֵי אַדְמָתְךָ תָּבִיא בֵּית יְהוָה אֱלֹהֶיךָ לֹא-תְבַשֵּׁל גְּדִי בַּחֲלֵב אִמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Я пошлю перед вами ангела Своего, чтобы он хранил вас в пути и привел на то место, которое Я [вам] приготовил.',
    verse_ivrit: 'הִנֵּה אָנֹכִי שֹׁלֵחַ מַלְאָךְ לְפָנֶיךָ לִשְׁמָרְךָ בַּדָּרֶךְ וְלַהֲבִיאֲךָ אֶל-הַמָּקוֹם אֲשֶׁר הֲכִנֹתִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Будьте осторожны перед ним и слушайтесь его, не прекословьте ему, ведь он не простит ваши проступки, ибо в нем пребывает Мое Имя.',
    verse_ivrit: 'הִשָּׁמֶר מִפָּנָיו וּשְׁמַע בְּקֹלוֹ אַל-תַּמֵּר בּוֹ כִּי לֹא יִשָּׂא לְפִשְׁעֲכֶם כִּי שְׁמִי בְּקִרְבּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Если же вы будете слушаться его и сделаете все, что Я говорю, то Я стану врагом ваших врагов и недругом ваших недругов.',
    verse_ivrit: 'כִּי אִם-שָׁמוֹעַ תִּשְׁמַע בְּקֹלוֹ וְעָשִׂיתָ כֹּל אֲשֶׁר אֲדַבֵּר וְאָיַבְתִּי אֶת-אֹיְבֶיךָ וְצַרְתִּי אֶת-צֹרְרֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Мой ангел пойдет перед вами, и когда он приведет вас к амореям, хеттам, перизеям, ханаанеям, хивеям и евусеям, то Я истреблю их.',
    verse_ivrit: 'כִּי-יֵלֵךְ מַלְאָכִי לְפָנֶיךָ וֶהֱבִיאֲךָ אֶל-הָאֱמֹרִי וְהַחִתִּי וְהַפְּרִזִּי וְהַכְּנַעֲנִי הַחִוִּי וְהַיְבוּסִי וְהִכְחַדְתִּיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Не поклоняйтесь и не служите их богам, не подражайте их поступкам! Уничтожьте их и сокрушите их священные камни!',
    verse_ivrit: 'לֹא-תִשְׁתַּחֲוֶה לֵאלֹהֵיהֶם וְלֹא תָעָבְדֵם וְלֹא תַעֲשֶׂה כְּמַעֲשֵׂיהֶם כִּי הָרֵס תְּהָרְסֵם וְשַׁבֵּר תְּשַׁבֵּר מַצֵּבֹתֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Служите Господу, вашему Богу, и Он благословит ваш хлеб и воду. Я избавлю вас от болезней.',
    verse_ivrit: 'וַעֲבַדְתֶּם אֵת יְהוָה אֱלֹהֵיכֶם וּבֵרַךְ אֶת-לַחְמְךָ וְאֶת-מֵימֶיךָ וַהֲסִרֹתִי מַחֲלָה מִקִּרְבֶּךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Не будет выкидышей и бесплодия в твоей стране. Я дам [вам прожить] сполна отведенный вам срок.',
    verse_ivrit: 'לֹא תִהְיֶה מְשַׁכֵּלָה וַעֲקָרָה בְּאַרְצֶךָ אֶת-מִסְפַּר יָמֶיךָ אֲמַלֵּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Я нашлю ужас на всех, кто перед вами, и приведу в смятение каждый народ, на который вы нападете. Я заставлю всех врагов бежать от вас.',
    verse_ivrit: 'אֶת-אֵימָתִי אֲשַׁלַּח לְפָנֶיךָ וְהַמֹּתִי אֶת-כָּל-הָעָם אֲשֶׁר תָּבֹא בָּהֶם וְנָתַתִּי אֶת-כָּל-אֹיְבֶיךָ אֵלֶיךָ עֹרֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Шершней пошлю Я перед вами, и они погонят от вас прочь хивеев, ханаанеев и хеттов.',
    verse_ivrit: 'וְשָׁלַחְתִּי אֶת-הַצִּרְעָה לְפָנֶיךָ וְגֵרְשָׁה אֶת-הַחִוִּי אֶת-הַכְּנַעֲנִי וְאֶת-הַחִתִּי מִלְּפָנֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Но не в один год изгоню Я их от вас, чтобы земля не опустела и не одолели бы вас полевые звери.',
    verse_ivrit: 'לֹא אֲגָרְשֶׁנּוּ מִפָּנֶיךָ בְּשָׁנָה אֶחָת פֶּן-תִּהְיֶה הָאָרֶץ שְׁמָמָה וְרַבָּה עָלֶיךָ חַיַּת הַשָּׂדֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Я буду изгонять их постепенно, пока вы не умножитесь числом и не овладеете этой страной.',
    verse_ivrit: 'מְעַט מְעַט אֲגָרְשֶׁנּוּ מִפָּנֶיךָ עַד אֲשֶׁר תִּפְרֶה וְנָחַלְתָּ אֶת-הָאָרֶץ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Я установлю ваши границы от моря Суф и до моря филистимлян, от пустыни и до реки, а жителей этой страны отдам в ваши руки, и вы изгоните их.',
    verse_ivrit: 'וְשַׁתִּי אֶת-גְּבֻלְךָ מִיַּם-סוּף וְעַד-יָם פְּלִשְׁתִּים וּמִמִּדְבָּר עַד-הַנָּהָר כִּי אֶתֵּן בְּיֶדְכֶם אֵת יֹשְׁבֵי הָאָרֶץ וְגֵרַשְׁתָּמוֹ מִפָּנֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Не заключайте союза ни с ними, ни с их богами.',
    verse_ivrit: 'לֹא-תִכְרֹת לָהֶם וְלֵאלֹהֵיהֶם בְּרִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 23,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Пусть не живут они в вашей стране, чтобы они не склонили вас к греху против Меня. Если вы будете служить их богам, это станет для вас западней”. ',
    verse_ivrit: 'לֹא יֵשְׁבוּ בְּאַרְצְךָ פֶּן-יַחֲטִיאוּ אֹתְךָ לִי כִּי תַעֲבֹד אֶת-אֱלֹהֵיהֶם כִּי-יִהְיֶה לְךָ לְמוֹקֵשׁ',
    comment: '',
  },
  //////////////////////////////////////           2424242424242424242424242424       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: '— Поднимитесь к Господу, — сказал [Господь] Моше, — ты, Аѓарон, Надав, Авиѓу и семьдесят старейшин Израиля, и поклонитесь издали.',
    verse_ivrit: 'וְאֶל-מֹשֶׁה אָמַר עֲלֵה אֶל-יְהוָה אַתָּה וְאַהֲרֹן נָדָב וַאֲבִיהוּא וְשִׁבְעִים מִזִּקְנֵי יִשְׂרָאֵל וְהִשְׁתַּחֲוִיתֶם מֵרָחֹק',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Но пусть только Моше приблизится к Господу, а они пусть не приближаются. И народ пусть не поднимается с ними.',
    verse_ivrit: 'וְנִגַּשׁ מֹשֶׁה לְבַדּוֹ אֶל-יְהוָה וְהֵם לֹא יִגָּשׁוּ וְהָעָם לֹא יַעֲלוּ עִמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Моше пришел к народу и пересказал все слова Господа и все законы. Весь народ, как один, ответил так: “Все, что сказал Господь, исполним!”',
    verse_ivrit: 'וַיָּבֹא מֹשֶׁה וַיְסַפֵּר לָעָם אֵת כָּל-דִּבְרֵי יְהוָה וְאֵת כָּל-הַמִּשְׁפָּטִים וַיַּעַן כָּל-הָעָם קוֹל אֶחָד וַיֹּאמְרוּ כָּל-הַדְּבָרִים אֲשֶׁר-דִּבֶּר יְהוָה נַעֲשֶׂה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Моше записал все слова Господа. Рано утром он воздвиг под горой жертвенник и двенадцать памятных камней, [по числу] двенадцати колен Израиля,',
    verse_ivrit: 'וַיִּכְתֹּב מֹשֶׁה אֵת כָּל-דִּבְרֵי יְהוָה וַיַּשְׁכֵּם בַּבֹּקֶר וַיִּבֶן מִזְבֵּחַ תַּחַת הָהָר וּשְׁתֵּים עֶשְׂרֵה מַצֵּבָה לִשְׁנֵים עָשָׂר שִׁבְטֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'и послал юношей из сынов Израиля совершить всесожжения и принести быков в мирную жертву Господу.',
    verse_ivrit: 'וַיִּשְׁלַח אֶת-נַעֲרֵי בְּנֵי יִשְׂרָאֵל וַיַּעֲלוּ עֹלֹת וַיִּזְבְּחוּ זְבָחִים שְׁלָמִים לַיהוָה פָּרִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Половину крови Моше взял и перелил в чаши, а оставшейся кровью окропил жертвенник.',
    verse_ivrit: 'וַיִּקַּח מֹשֶׁה חֲצִי הַדָּם וַיָּשֶׂם בָּאַגָּנֹת וַחֲצִי הַדָּם זָרַק עַל-הַמִּזְבֵּחַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Он взял Книгу Союза и прочел во всеуслышание всему народу. “Все, что сказал Господь, мы сделаем и будем послушны”, — сказали они.',
    verse_ivrit: 'וַיִּקַּח סֵפֶר הַבְּרִית וַיִּקְרָא בְּאָזְנֵי הָעָם וַיֹּאמְרוּ כֹּל אֲשֶׁר-דִּבֶּר יְהוָה נַעֲשֶׂה וְנִשְׁמָע',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Моше взял кровь и окропил народ, возгласив: “Вот кровь союза, который Господь заключил с вами согласно всем этим словам!”',
    verse_ivrit: 'וַיִּקַּח מֹשֶׁה אֶת-הַדָּם וַיִּזְרֹק עַל-הָעָם וַיֹּאמֶר הִנֵּה דַם-הַבְּרִית אֲשֶׁר כָּרַת יְהוָה עִמָּכֶם עַל כָּל-הַדְּבָרִים הָאֵלֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Моше поднялся [к Господу], а с ним Аѓарон, Надав, Авиѓу и семьдесят старейшин Израиля.',
    verse_ivrit: 'וַיַּעַל מֹשֶׁה וְאַהֲרֹן נָדָב וַאֲבִיהוּא וְשִׁבְעִים מִזִּקְנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Они увидели Бога Израиля, и у Него под ногами было подобие сапфирового помоста, чистое, как само небо.',
    verse_ivrit: 'וַיִּרְאוּ אֵת אֱלֹהֵי יִשְׂרָאֵל וְתַחַת רַגְלָיו כְּמַעֲשֵׂה לִבְנַת הַסַּפִּיר וּכְעֶצֶם הַשָּׁמַיִם לָטֹהַר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'И не простер Господь руки Своей на знать сынов Израиля: они узрели Бога, ели и пили.',
    verse_ivrit: 'וְאֶל-אֲצִילֵי בְּנֵי יִשְׂרָאֵל לֹא שָׁלַח יָדוֹ וַיֶּחֱזוּ אֶת-הָאֱלֹהִים וַיֹּאכְלוּ וַיִּשְׁתּוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: '— Взойди ко Мне на гору и будь там, — сказал Господь Моше. — Я дам тебе каменные скрижали, учение и заповеди, которые Я написал, чтобы наставлять их.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה עֲלֵה אֵלַי הָהָרָה וֶהְיֵה-שָׁם וְאֶתְּנָה לְךָ אֶת-לֻחֹת הָאֶבֶן וְהַתּוֹרָה וְהַמִּצְוָה אֲשֶׁר כָּתַבְתִּי לְהוֹרֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Моше и его служитель Йеѓошуа поднялись, и Моше взошел на гору Божью.',
    verse_ivrit: 'וַיָּקָם מֹשֶׁה וִיהוֹשֻׁעַ מְשָׁרְתוֹ וַיַּעַל מֹשֶׁה אֶל-הַר הָאֱלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'А старейшинам он велел: — Побудьте здесь, пока мы не возвратимся к вам. С вами остаются Аѓарон и Хур. Тот, у кого будет какое-нибудь дело, пусть обращается к ним.',
    verse_ivrit: 'וְאֶל-הַזְּקֵנִים אָמַר שְׁבוּ-לָנוּ בָזֶה עַד אֲשֶׁר-נָשׁוּב אֲלֵיכֶם וְהִנֵּה אַהֲרֹן וְחוּר עִמָּכֶם מִי-בַעַל דְּבָרִים יִגַּשׁ אֲלֵהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Моше взошел на гору, и гору окутало облако.',
    verse_ivrit: 'וַיַּעַל מֹשֶׁה אֶל-הָהָר וַיְכַס הֶעָנָן אֶת-הָהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Слава Господа осенила гору Синай, и облако покрывало гору шесть дней, а на седьмой день Господь воззвал к Моше из облака.',
    verse_ivrit: 'וַיִּשְׁכֹּן כְּבוֹד-יְהוָה עַל-הַר סִינַי וַיְכַסֵּהוּ הֶעָנָן שֵׁשֶׁת יָמִים וַיִּקְרָא אֶל-מֹשֶׁה בַּיּוֹם הַשְּׁבִיעִי מִתּוֹךְ הֶעָנָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'А сынам Израиля Слава Господа предстала как всепожирающий огонь на вершине горы.',
    verse_ivrit: 'וּמַרְאֵה כְּבוֹד יְהוָה כְּאֵשׁ אֹכֶלֶת בְּרֹאשׁ הָהָר לְעֵינֵי בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'МИШПАТИМ(Законы)',
    id_book: 1,
    id_chapter: 24,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Моше вошел внутрь облака и поднялся на гору. Он пробыл на горе сорок дней и сорок ночей.',
    verse_ivrit: 'וַיָּבֹא מֹשֶׁה בְּתוֹךְ הֶעָנָן וַיַּעַל אֶל-הָהָר וַיְהִי מֹשֶׁה בָּהָר אַרְבָּעִים יוֹם וְאַרְבָּעִים לָיְלָה',
    comment: '',
  },
 //////////////////////////////////////           2525252525252525252252525225252522525       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '“Передай сынам Израиля, чтобы они собрали Мне приношение. От каждого, кто пожелает, принимайте приношение для Меня.',
    verse_ivrit: 'דַּבֵּר אֶל-בְּנֵי יִשְׂרָאֵל וְיִקְחוּ-לִי תְּרוּמָה מֵאֵת כָּל-אִישׁ אֲשֶׁר יִדְּבֶנּוּ לִבּוֹ תִּקְחוּ אֶת-תְּרוּמָתִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Принимайте от них такие приношения: золото, серебро и медь,',
    verse_ivrit: 'וְזֹאת הַתְּרוּמָה אֲשֶׁר תִּקְחוּ מֵאִתָּם זָהָב וָכֶסֶף וּנְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'синюю, багряную и пурпуровую нить, лен и козью шерсть,',
    verse_ivrit: 'וּתְכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ וְעִזִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'красные бараньи кожи, кожи тахашей и древесину акации,',
    verse_ivrit: 'וְעֹרֹת אֵילִם מְאָדָּמִים וְעֹרֹת תְּחָשִׁים וַעֲצֵי שִׁטִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'масло для освещения, благовония для масла помазания и для ароматных воскурений,',
    verse_ivrit: 'שֶׁמֶן לַמָּאֹר בְּשָׂמִים לְשֶׁמֶן הַמִּשְׁחָה וְלִקְטֹרֶת הַסַּמִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'ониксы и вставные камни для эфода и нагрудника.',
    verse_ivrit: 'אַבְנֵי-שֹׁהַם וְאַבְנֵי מִלֻּאִים לָאֵפֹד וְלַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Пусть они сделают Мне Святилище, и Я буду обитать среди них.',
    verse_ivrit: 'וְעָשׂוּ לִי מִקְדָּשׁ וְשָׁכַנְתִּי בְּתוֹכָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Сделайте все в точности по образцу Святилища и по образцам всей его утвари, которые Я покажу тебе.',
    verse_ivrit: 'כְּכֹל אֲשֶׁר אֲנִי מַרְאֶה אוֹתְךָ אֵת תַּבְנִית הַמִּשְׁכָּן וְאֵת תַּבְנִית כָּל-כֵּלָיו וְכֵן תַּעֲשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Пусть сделают ковчег из дерева акации: два с половиной локтя длиной, полтора локтя шириной и полтора локтя высотой.',
    verse_ivrit: 'וְעָשׂוּ אֲרוֹן עֲצֵי שִׁטִּים אַמָּתַיִם וָחֵצִי אָרְכּוֹ וְאַמָּה וָחֵצִי רָחְבּוֹ וְאַמָּה וָחֵצִי קֹמָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Покрой его чистым золотом — изнутри и снаружи, а вокруг него сделай золотую кайму.',
    verse_ivrit: 'וְצִפִּיתָ אֹתוֹ זָהָב טָהוֹר מִבַּיִת וּמִחוּץ תְּצַפֶּנּוּ וְעָשִׂיתָ עָלָיו זֵר זָהָב סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Отлей для него четыре золотых кольца и прикрепи их к четырем нижним углам: два кольца с одной его стороны и два кольца — с другой.',
    verse_ivrit: 'וְיָצַקְתָּ לּוֹ אַרְבַּע טַבְּעֹת זָהָב וְנָתַתָּה עַל אַרְבַּע פַּעֲמֹתָיו וּשְׁתֵּי טַבָּעֹת עַל-צַלְעוֹ הָאֶחָת וּשְׁתֵּי טַבָּעֹת עַל-צַלְעוֹ הַשֵּׁנִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Сделай шесты из дерева акации и покрой их золотом.',
    verse_ivrit: 'וְעָשִׂיתָ בַדֵּי עֲצֵי שִׁטִּים וְצִפִּיתָ אֹתָם זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Шесты вставь в кольца по обе стороны ковчега, чтобы носить его.',
    verse_ivrit: 'וְהֵבֵאתָ אֶת-הַבַּדִּים בַּטַּבָּעֹת עַל צַלְעֹת הָאָרֹן לָשֵׂאת אֶת-הָאָרֹן בָּהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Эти шесты должны оставаться в кольцах ковчега, их нельзя вынимать оттуда.',
    verse_ivrit: 'בְּטַבְּעֹת הָאָרֹן יִהְיוּ הַבַּדִּים לֹא יָסֻרוּ מִמֶּנּוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'В ковчег положи Свидетельство, которое Я дам тебе.',
    verse_ivrit: 'וְנָתַתָּ אֶל-הָאָרֹן אֵת הָעֵדֻת אֲשֶׁר אֶתֵּן אֵלֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Сделай крышку из чистого золота, два с половиной локтя длиной и полтора локтя шириной.',
    verse_ivrit: 'וְעָשִׂיתָ כַפֹּרֶת זָהָב טָהוֹר אַמָּתַיִם וָחֵצִי אָרְכָּהּ וְאַמָּה וָחֵצִי רָחְבָּהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Сделай двух керувов из золота, сделай их чеканными, на двух краях крышки:',
    verse_ivrit: 'וְעָשִׂיתָ שְׁנַיִם כְּרֻבִים זָהָב מִקְשָׁה תַּעֲשֶׂה אֹתָם מִשְּׁנֵי קְצוֹת הַכַּפֹּרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'одного керува сделай с одного края, а другого — с другого края. Крышку и керувов, каждого с его стороны, сделай как единое целое.',
    verse_ivrit: 'וַעֲשֵׂה כְּרוּב אֶחָד מִקָּצָה מִזֶּה וּכְרוּב-אֶחָד מִקָּצָה מִזֶּה מִן-הַכַּפֹּרֶת תַּעֲשׂוּ אֶת-הַכְּרֻבִים עַל-שְׁנֵי קְצוֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Керувы должны простирать крылья вверх, осеняя крышку. Лица керувов, обращенные друг к другу, должны быть наклонены к крышке.',
    verse_ivrit: 'וְהָיוּ הַכְּרֻבִים פֹּרְשֵׂי כְנָפַיִם לְמַעְלָה סֹכְכִים בְּכַנְפֵיהֶם עַל-הַכַּפֹּרֶת וּפְנֵיהֶם אִישׁ אֶל-אָחִיו אֶל-הַכַּפֹּרֶת יִהְיוּ פְּנֵי הַכְּרֻבִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Положи крышку сверху на ковчег, а в ковчег положи Свидетельство, которое Я дам тебе.',
    verse_ivrit: 'וְנָתַתָּ אֶת-הַכַּפֹּרֶת עַל-הָאָרֹן מִלְמָעְלָה וְאֶל-הָאָרֹן תִּתֵּן אֶת-הָעֵדֻת אֲשֶׁר אֶתֵּן אֵלֶיךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Там Я буду встречаться с тобой, и обо всем, что Я заповедую сынам Израиля, буду говорить тебе [из пространства] над крышкой между двух керувов, которые над ковчегом Свидетельства. ',
    verse_ivrit: 'וְנוֹעַדְתִּי לְךָ שָׁם וְדִבַּרְתִּי אִתְּךָ מֵעַל הַכַּפֹּרֶת מִבֵּין שְׁנֵי הַכְּרֻבִים אֲשֶׁר עַל-אֲרוֹן הָעֵדֻת אֵת כָּל-אֲשֶׁר אֲצַוֶּה אוֹתְךָ אֶל-בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Сделай стол из дерева акации, два локтя длиной, локоть шириной и полтора локтя высотой;',
    verse_ivrit: 'וְעָשִׂיתָ שֻׁלְחָן עֲצֵי שִׁטִּים אַמָּתַיִם אָרְכּוֹ וְאַמָּה רָחְבּוֹ וְאַמָּה וָחֵצִי קֹמָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'покрой его чистым золотом и сделай вокруг него золотую кайму.',
    verse_ivrit: 'וְצִפִּיתָ אֹתוֹ זָהָב טָהוֹר וְעָשִׂיתָ לּוֹ זֵר זָהָב סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Сделай вокруг него раму шириной в ладонь, а вокруг рамы — золотую кайму.',
    verse_ivrit: 'וְעָשִׂיתָ לּוֹ מִסְגֶּרֶת טֹפַח סָבִיב וְעָשִׂיתָ זֵר-זָהָב לְמִסְגַּרְתּוֹ סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Сделай для стола четыре золотых кольца и прикрепи их к углам возле четырех ножек.',
    verse_ivrit: 'וְעָשִׂיתָ לּוֹ אַרְבַּע טַבְּעֹת זָהָב וְנָתַתָּ אֶת-הַטַּבָּעֹת עַל אַרְבַּע הַפֵּאֹת אֲשֶׁר לְאַרְבַּע רַגְלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Кольца пусть примыкают к раме, чтобы вставлять в них шесты для переноски стола.',
    verse_ivrit: 'לְעֻמַּת הַמִּסְגֶּרֶת תִּהְיֶיןָ הַטַּבָּעֹת לְבָתִּים לְבַדִּים לָשֵׂאת אֶת-הַשֻּׁלְחָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Сделай шесты для переноски стола из дерева акации и покрой их золотом.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-הַבַּדִּים עֲצֵי שִׁטִּים וְצִפִּיתָ אֹתָם זָהָב וְנִשָּׂא-בָם אֶת-הַשֻּׁלְחָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Сделай подносы [для хлебов], чаши [для ладана], стержни и покрытия; сделай их из чистого золота.',
    verse_ivrit: 'וְעָשִׂיתָ קְּעָרֹתָיו וְכַפֹּתָיו וּקְשׂוֹתָיו וּמְנַקִּיֹּתָיו אֲשֶׁר יֻסַּךְ בָּהֵן זָהָב טָהוֹר תַּעֲשֶׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'На этот стол постоянно возлагай предо Мною хлеб предложения. ',
    verse_ivrit: 'וְנָתַתָּ עַל-הַשֻּׁלְחָן לֶחֶם פָּנִים לְפָנַי תָּמִיד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Сделай светильник из чистого золота. Светильник, его основание и ветви должны быть чеканными, а чашечки, завязи и цветы должны составлять с ним единое целое.',
    verse_ivrit: 'וְעָשִׂיתָ מְנֹרַת זָהָב טָהוֹר מִקְשָׁה תֵּעָשֶׂה הַמְּנוֹרָה יְרֵכָהּ וְקָנָהּ גְּבִיעֶיהָ כַּפְתֹּרֶיהָ וּפְרָחֶיהָ מִמֶּנָּה יִהְיוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Шесть ветвей должны отходить от его боков: три ветви светильника от одного бока и три от другого бока.',
    verse_ivrit: 'וְשִׁשָּׁה קָנִים יֹצְאִים מִצִּדֶּיהָ שְׁלֹשָׁה קְנֵי מְנֹרָה מִצִּדָּהּ הָאֶחָד וּשְׁלֹשָׁה קְנֵי מְנֹרָה מִצִּדָּהּ הַשֵּׁנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'На каждой из шести ветвей, отходящих от светильника, должно быть по три миндалевидные чашечки, каждая с завязью и цветками.',
    verse_ivrit: 'שְׁלֹשָׁה גְבִעִים מְשֻׁקָּדִים בַּקָּנֶה הָאֶחָד כַּפְתֹּר וָפֶרַח וּשְׁלֹשָׁה גְבִעִים מְשֻׁקָּדִים בַּקָּנֶה הָאֶחָד כַּפְתֹּר וָפָרַח כֵּן לְשֵׁשֶׁת הַקָּנִים הַיֹּצְאִים מִן-הַמְּנֹרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'А на [самом] светильнике — четыре миндалевидные чашечки с завязями и цветками.',
    verse_ivrit: 'וּבַמְּנֹרָה אַרְבָּעָה גְבִעִים מְשֻׁקָּדִים כַּפְתֹּרֶיהָ וּפְרָחֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'У шести ветвей, отходящих от светильника, — завязь под [первой] парой ветвей, завязь под [второй] парой ветвей и завязь под [третьей] парой ветвей.',
    verse_ivrit: 'וְכַפְתֹּר תַּחַת שְׁנֵי הַקָּנִים מִמֶּנָּה וְכַפְתֹּר תַּחַת שְׁנֵי הַקָּנִים מִמֶּנָּה וְכַפְתֹּר תַּחַת-שְׁנֵי הַקָּנִים מִמֶּנָּה לְשֵׁשֶׁת הַקָּנִים הַיֹּצְאִים מִן-הַמְּנֹרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Завязи и ветви должны составлять с ним единое целое: весь он должен быть выкован из цельного куска чистого золота.',
    verse_ivrit: 'כַּפְתֹּרֵיהֶם וּקְנֹתָם מִמֶּנָּה יִהְיוּ כֻּלָּהּ מִקְשָׁה אַחַת זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'Сделай семь ламп [для светильника] и установи их так, чтобы освещать пространство перед ним.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-נֵרֹתֶיהָ שִׁבְעָה וְהֶעֱלָה אֶת-נֵרֹתֶיהָ וְהֵאִיר עַל-עֵבֶר פָּנֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 38,
    verse: 'Щипцы и совки к нему [также сделай] из чистого золота.',
    verse_ivrit: 'וּמַלְקָחֶיהָ וּמַחְתֹּתֶיהָ זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 39,
    verse: 'Пусть сделают светильник из кикара чистого золота, со всеми этими принадлежностями.',
    verse_ivrit: 'זָהָב טָהוֹר יַעֲשֶׂה אֹתָהּ אֵת כָּל-הַכֵּלִים הָאֵלֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 25,
    id_chapter_two: 1,
    poemNumber: 40,
    verse: 'Смотри, сделай [все] по тому образцу, который был тебе показан на горе.',
    verse_ivrit: 'וּרְאֵה וַעֲשֵׂה בְּתַבְנִיתָם אֲשֶׁר-אַתָּה מָרְאֶה בָּהָר',
    comment: '',
  },
  //////////////////////////////////////           262626262626262626262626262626       \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Сделай Святилище из десяти полотнищ тонкого льна, сплетенного с синей, багряной и пурпуровой нитью, и вытки на них [изображения] керувов.',
    verse_ivrit: 'וְאֶת-הַמִּשְׁכָּן תַּעֲשֶׂה עֶשֶׂר יְרִיעֹת שֵׁשׁ מָשְׁזָר וּתְכֵלֶת וְאַרְגָּמָן וְתֹלַעַת שָׁנִי כְּרֻבִים מַעֲשֵׂה חֹשֵׁב תַּעֲשֶׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Длина каждого полотнища — двадцать восемь локтей, а ширина — четыре локтя. Все полотнища [должны быть] одинакового размера.',
    verse_ivrit: 'אֹרֶךְ הַיְרִיעָה הָאַחַת שְׁמֹנֶה וְעֶשְׂרִים בָּאַמָּה וְרֹחַב אַרְבַּע בָּאַמָּה הַיְרִיעָה הָאֶחָת מִדָּה אַחַת לְכָל-הַיְרִיעֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Пять полотнищ должны быть соединены друг с другом, и пять других полотнищ [тоже] должны быть соединены друг с другом.',
    verse_ivrit: 'חֲמֵשׁ הַיְרִיעֹת תִּהְיֶיןָ חֹבְרֹת אִשָּׁה אֶל-אֲחֹתָהּ וְחָמֵשׁ יְרִיעֹת חֹבְרֹת אִשָּׁה אֶל-אֲחֹתָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Сделай петли из синей нити по кромке первого полотнища одного из [шитых] кусков; то же сделай по кромке последнего полотнища второго [шитого] куска.',
    verse_ivrit: 'וְעָשִׂיתָ לֻלְאֹת תְּכֵלֶת עַל שְׂפַת הַיְרִיעָה הָאֶחָת מִקָּצָה בַּחֹבָרֶת וְכֵן תַּעֲשֶׂה בִּשְׂפַת הַיְרִיעָה הַקִּיצוֹנָה בַּמַּחְבֶּרֶת הַשֵּׁנִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Пятьдесят петель сделай на первом полотнище [одного шитого куска] и пятьдесят петель — по краю последнего полотнища второго [шитого] куска: петли должны находиться одна против другой.',
    verse_ivrit: 'חֲמִשִּׁים לֻלָאֹת תַּעֲשֶׂה בַּיְרִיעָה הָאֶחָת וַחֲמִשִּׁים לֻלָאֹת תַּעֲשֶׂה בִּקְצֵה הַיְרִיעָה אֲשֶׁר בַּמַּחְבֶּרֶת הַשֵּׁנִית מַקְבִּילֹת הַלֻּלָאֹת אִשָּׁה אֶל-אֲחֹתָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Сделай пятьдесят золотых застежек и соедини полотнища друг с другом застежками, и будет Святилище одним целым.',
    verse_ivrit: 'וְעָשִׂיתָ חֲמִשִּׁים קַרְסֵי זָהָב וְחִבַּרְתָּ אֶת-הַיְרִיעֹת אִשָּׁה אֶל-אֲחֹתָהּ בַּקְּרָסִים וְהָיָה הַמִּשְׁכָּן אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Сделай полотнища из козьей шерсти для шатра, [как покрывало] Святилища: сделай одиннадцать таких полотнищ.',
    verse_ivrit: 'וְעָשִׂיתָ יְרִיעֹת עִזִּים לְאֹהֶל עַל-הַמִּשְׁכָּן עַשְׁתֵּי-עֶשְׂרֵה יְרִיעֹת תַּעֲשֶׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Длина каждого полотнища тридцать локтей, а ширина — четыре локтя; одиннадцать полотнищ [должны быть] одинакового размера.',
    verse_ivrit: 'אֹרֶךְ הַיְרִיעָה הָאַחַת שְׁלֹשִׁים בָּאַמָּה וְרֹחַב אַרְבַּע בָּאַמָּה הַיְרִיעָה הָאֶחָת מִדָּה אַחַת לְעַשְׁתֵּי עֶשְׂרֵה יְרִיעֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Соедини пять полотнищ отдельно и шесть полотнищ отдельно, а шестое полотнище сверни рядом со входом в шатер.',
    verse_ivrit: 'וְחִבַּרְתָּ אֶת-חֲמֵשׁ הַיְרִיעֹת לְבָד וְאֶת-שֵׁשׁ הַיְרִיעֹת לְבָד וְכָפַלְתָּ אֶת-הַיְרִיעָה הַשִּׁשִּׁית אֶל-מוּל פְּנֵי הָאֹהֶל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Пятьдесят петель сделай по кромке первого крайнего полотнища одного [шитого] куска, и пятьдесят петель по кромке полотнища другого [шитого] куска.',
    verse_ivrit: 'וְעָשִׂיתָ חֲמִשִּׁים לֻלָאֹת עַל שְׂפַת הַיְרִיעָה הָאֶחָת הַקִּיצֹנָה בַּחֹבָרֶת וַחֲמִשִּׁים לֻלָאֹת עַל שְׂפַת הַיְרִיעָה הַחֹבֶרֶת הַשֵּׁנִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Сделай пятьдесят медных застежек, вдень крючки в петли и соедини [оба куска] в один цельный шатер.',
    verse_ivrit: 'וְעָשִׂיתָ קַרְסֵי נְחֹשֶׁת חֲמִשִּׁים וְהֵבֵאתָ אֶת-הַקְּרָסִים בַּלֻּלָאֹת וְחִבַּרְתָּ אֶת-הָאֹהֶל וְהָיָה אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Избыток полотнищ шатра — лишняя половина полотнища — пусть свешивается на задней стороне Святилища,',
    verse_ivrit: 'וְסֶרַח הָעֹדֵף בִּירִיעֹת הָאֹהֶל חֲצִי הַיְרִיעָה הָעֹדֶפֶת תִּסְרַח עַל אֲחֹרֵי הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'а избыток по длине полотнищ шатра — локоть с одной и локоть с другой [стороны] — пусть свешиваются с боковых сторон Святилища, покрывая его с той и другой стороны.',
    verse_ivrit: 'וְהָאַמָּה מִזֶּה וְהָאַמָּה מִזֶּה בָּעֹדֵף בְּאֹרֶךְ יְרִיעֹת הָאֹהֶל יִהְיֶה סָרוּחַ עַל-צִדֵּי הַמִּשְׁכָּן מִזֶּה וּמִזֶּה לְכַסֹּתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Сделай покрытие для шатра из красных бараньих кож, а поверх него — покрытие из кож тахашей. ',
    verse_ivrit: 'וְעָשִׂיתָ מִכְסֶה לָאֹהֶל עֹרֹת אֵילִם מְאָדָּמִים וּמִכְסֵה עֹרֹת תְּחָשִׁים מִלְמָעְלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Сделай брусья для Святилища из дерева акации [и поставь их] вертикально.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-הַקְּרָשִׁים לַמִּשְׁכָּן עֲצֵי שִׁטִּים עֹמְדִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Десять локтей — длина, и полтора локтя — ширина каждого бруса.',
    verse_ivrit: 'עֶשֶׂר אַמּוֹת אֹרֶךְ הַקָּרֶשׁ וְאַמָּה וַחֲצִי הָאַמָּה רֹחַב הַקֶּרֶשׁ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'По два шипа у каждого бруса, прикрепляющиеся друг к другу; так сделай все брусья Святилища.',
    verse_ivrit: 'שְׁתֵּי יָדוֹת לַקֶּרֶשׁ הָאֶחָד מְשֻׁלָּבֹת אִשָּׁה אֶל-אֲחֹתָהּ כֵּן תַּעֲשֶׂה לְכֹל קַרְשֵׁי הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Сделай брусья для Святилища. Для южной стороны — двадцать брусьев.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-הַקְּרָשִׁים לַמִּשְׁכָּן עֶשְׂרִים קֶרֶשׁ לִפְאַת נֶגְבָּה תֵימָנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Сорок серебряных подножий сделай под двадцать брусьев: два подножия под один брус для двух его шипов и два подножия под другой брус для двух его шипов.',
    verse_ivrit: 'וְאַרְבָּעִים אַדְנֵי-כֶסֶף תַּעֲשֶׂה תַּחַת עֶשְׂרִים הַקָּרֶשׁ שְׁנֵי אֲדָנִים תַּחַת-הַקֶּרֶשׁ הָאֶחָד לִשְׁתֵּי יְדֹתָיו וּשְׁנֵי אֲדָנִים תַּחַת-הַקֶּרֶשׁ הָאֶחָד לִשְׁתֵּי יְדֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'И для другой, северной стороны Святилища — двадцать брусьев.',
    verse_ivrit: 'וּלְצֶלַע הַמִּשְׁכָּן הַשֵּׁנִית לִפְאַת צָפוֹן עֶשְׂרִים קָרֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Под них — сорок серебряных подножий: два подножия под один брус и два подножия — под другой.',
    verse_ivrit: 'וְאַרְבָּעִים אַדְנֵיהֶם כָּסֶף שְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד וּשְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Для задней же, западной стороны Святилища сделай шесть брусьев,',
    verse_ivrit: 'וּלְיַרְכְּתֵי הַמִּשְׁכָּן יָמָּה תַּעֲשֶׂה שִׁשָּׁה קְרָשִׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'и [еще] два бруса сделай на задней стороне для углов Святилища:',
    verse_ivrit: 'וּשְׁנֵי קְרָשִׁים תַּעֲשֶׂה לִמְקֻצְעֹת הַמִּשְׁכָּן בַּיַּרְכָתָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'пусть они точно подходят друг к другу снизу и попарно соединяются одним кольцом сверху. Так должно быть с ними обоими — пусть будут они на обоих углах.',
    verse_ivrit: 'וְיִהְיוּ תֹאֲמִם מִלְּמַטָּה וְיַחְדָּו יִהְיוּ תַמִּים עַל-רֹאשׁוֹ אֶל-הַטַּבַּעַת הָאֶחָת כֵּן יִהְיֶה לִשְׁנֵיהֶם לִשְׁנֵי הַמִּקְצֹעֹת יִהְיוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Должно быть восемь брусьев и под них — шестнадцать серебряных подножий: два подножия под каждый из брусьев.',
    verse_ivrit: 'וְהָיוּ שְׁמֹנָה קְרָשִׁים וְאַדְנֵיהֶם כֶּסֶף שִׁשָּׁה עָשָׂר אֲדָנִים שְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד וּשְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Сделай стержни из дерева акации: пять [стержней] для брусьев одной стороны Святилища,',
    verse_ivrit: 'וְעָשִׂיתָ בְרִיחִם עֲצֵי שִׁטִּים חֲמִשָּׁה לְקַרְשֵׁי צֶלַע-הַמִּשְׁכָּן הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'пять стержней — для брусьев другой стороны Святилища, и [еще] пять стержней — для брусьев задней, западной стороны Святилища.',
    verse_ivrit: 'וַחֲמִשָּׁה בְרִיחִם לְקַרְשֵׁי צֶלַע-הַמִּשְׁכָּן הַשֵּׁנִית וַחֲמִשָּׁה בְרִיחִם לְקַרְשֵׁי צֶלַע הַמִּשְׁכָּן לַיַּרְכָתַיִם יָמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_tw: 28,
    verse: 'Средний же стержень должен проходить внутри брусьев — от одного конца до другого.',
    verse_ivrit: 'וְהַבְּרִיחַ הַתִּיכֹן בְּתוֹךְ הַקְּרָשִׁים מַבְרִחַ מִן-הַקָּצֶה אֶל-הַקָּצֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Покрой брусья золотом, их кольца — вместилища для стержней — сделай из золота, и стержни покрой золотом.',
    verse_ivrit: 'וְאֶת-הַקְּרָשִׁים תְּצַפֶּה זָהָב וְאֶת-טַבְּעֹתֵיהֶם תַּעֲשֶׂה זָהָב בָּתִּים לַבְּרִיחִם וְצִפִּיתָ אֶת-הַבְּרִיחִם זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Собери Святилище согласно тому порядку, который был указан тебе на горе.',
    verse_ivrit: 'וַהֲקֵמֹתָ אֶת-הַמִּשְׁכָּן כְּמִשְׁפָּטוֹ אֲשֶׁר הָרְאֵיתָ בָּהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Сделай полог из синей, багряной и пурпуровой нити и скрученного тонкого льна и вытки на нем [изображения] керувов.',
    verse_ivrit: 'וְעָשִׂיתָ פָרֹכֶת תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר מַעֲשֵׂה חֹשֵׁב יַעֲשֶׂה אֹתָהּ כְּרֻבִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Повесь его на четыре столба из [дерева] акации, покрытые золотом, с золотыми крючками на них. [Эти столбы должны стоять] на четырех серебряных подножиях.',
    verse_ivrit: 'וְנָתַתָּה אֹתָהּ עַל-אַרְבָּעָה עַמּוּדֵי שִׁטִּים מְצֻפִּים זָהָב וָוֵיהֶם זָהָב עַל-אַרְבָּעָה אַדְנֵי-כָסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Прикрепи полог под застежками и внеси туда, за полог, ковчег Свидетельства, и полог будет отделять Святилище от Святая святых.',
    verse_ivrit: 'וְנָתַתָּה אֶת-הַפָּרֹכֶת תַּחַת הַקְּרָסִים וְהֵבֵאתָ שָׁמָּה מִבֵּית לַפָּרֹכֶת אֵת אֲרוֹן הָעֵדוּת וְהִבְדִּילָה הַפָּרֹכֶת לָכֶם בֵּין הַקֹּדֶשׁ וּבֵין קֹדֶשׁ הַקֳּדָשִׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Положи крышку на ковчег Свидетельства в Святая святых.',
    verse_ivrit: 'וְנָתַתָּ אֶת-הַכַּפֹּרֶת עַל אֲרוֹן הָעֵדֻת בְּקֹדֶשׁ הַקֳּדָשִׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Поставь стол перед пологом, а напротив стола, у южной стороны Святилища, — светильник, стол же установи у северной стороны.',
    verse_ivrit: 'וְשַׂמְתָּ אֶת-הַשֻּׁלְחָן מִחוּץ לַפָּרֹכֶת וְאֶת-הַמְּנֹרָה נֹכַח הַשֻּׁלְחָן עַל צֶלַע הַמִּשְׁכָּן תֵּימָנָה וְהַשֻּׁלְחָן תִּתֵּן עַל-צֶלַע צָפוֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Сделай завесу у входа в шатер из синей, багряной и пурпуровой нити и скрученного тонкого льна, работы вышивальщика.',
    verse_ivrit: 'וְעָשִׂיתָ מָסָךְ לְפֶתַח הָאֹהֶל תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר מַעֲשֵׂה רֹקֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 26,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'А для завесы сделай пять столбов из акации. Покрой их золотом; их крючки [также пусть будут] золотыми. И отлей для них пять медных подножий.',
    verse_ivrit: 'וְעָשִׂיתָ לַמָּסָךְ חֲמִשָּׁה עַמּוּדֵי שִׁטִּים וְצִפִּיתָ אֹתָם זָהָב וָוֵיהֶם זָהָב וְיָצַקְתָּ לָהֶם חֲמִשָּׁה אַדְנֵי נְחֹשֶׁת',
    comment: '',
  },
  //////////////////////////////////////           2727272727272727272727272727272727272727          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Сделай жертвенник из дерева акации; жертвенник должен быть квадратным — пять локтей длиной, пять локтей шириной и три локтя высотой.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-הַמִּזְבֵּחַ עֲצֵי שִׁטִּים חָמֵשׁ אַמּוֹת אֹרֶךְ וְחָמֵשׁ אַמּוֹת רֹחַב רָבוּעַ יִהְיֶה הַמִּזְבֵּחַ וְשָׁלֹשׁ אַמּוֹת קֹמָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'По четырем его углам сделай выступы — они должны составлять с ним единое целое — и покрой его медью.',
    verse_ivrit: 'וְעָשִׂיתָ קַרְנֹתָיו עַל אַרְבַּע פִּנֹּתָיו מִמֶּנּוּ תִּהְיֶיןָ קַרְנֹתָיו וְצִפִּיתָ אֹתוֹ נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Сделай к нему горшки, чтобы убирать с него золу, [сделай] лопатки, чаши, вилки и совки: все принадлежности для него сделай из меди.',
    verse_ivrit: 'וְעָשִׂיתָ סִּירֹתָיו לְדַשְּׁנוֹ וְיָעָיו וּמִזְרְקֹתָיו וּמִזְלְגֹתָיו וּמַחְתֹּתָיו לְכָל-כֵּלָיו תַּעֲשֶׂה נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Сделай к нему решетку — медную сетку — и к четырем ее углам прикрепи четыре медных кольца.',
    verse_ivrit: 'וְעָשִׂיתָ לּוֹ מִכְבָּר מַעֲשֵׂה רֶשֶׁת נְחֹשֶׁת וְעָשִׂיתָ עַל-הָרֶשֶׁת אַרְבַּע טַבְּעֹת נְחֹשֶׁת עַל אַרְבַּע קְצוֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Помести ее под нижним краем жертвенника, чтобы сетка доходила до половины жертвенника.',
    verse_ivrit: 'וְנָתַתָּה אֹתָהּ תַּחַת כַּרְכֹּב הַמִּזְבֵּחַ מִלְּמָטָּה וְהָיְתָה הָרֶשֶׁת עַד חֲצִי הַמִּזְבֵּחַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Сделай шесты для жертвенника из дерева акации и покрой их медью.',
    verse_ivrit: 'וְעָשִׂיתָ בַדִּים לַמִּזְבֵּחַ בַּדֵּי עֲצֵי שִׁטִּים וְצִפִּיתָ אֹתָם נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'При его переноске шесты будут продеваться в кольца по обеим сторонам жертвенника.',
    verse_ivrit: 'וְהוּבָא אֶת-בַּדָּיו בַּטַּבָּעֹת וְהָיוּ הַבַּדִּים עַל-שְׁתֵּי צַלְעֹת הַמִּזְבֵּחַ בִּשְׂאֵת אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Сделай его полым, из досок. Как [было] показано тебе на горе, пусть так и сделают.',
    verse_ivrit: 'נְבוּב לֻחֹת תַּעֲשֶׂה אֹתוֹ כַּאֲשֶׁר הֶרְאָה אֹתְךָ בָּהָר כֵּן יַעֲשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Сделай двор Святилища: с южной стороны будет занавес для двора из скрученного тонкого льна — в сто локтей длиной с одной стороны.',
    verse_ivrit: 'וְעָשִׂיתָ אֵת חֲצַר הַמִּשְׁכָּן לִפְאַת נֶגֶב-תֵּימָנָה קְלָעִים לֶחָצֵר שֵׁשׁ מָשְׁזָר מֵאָה בָאַמָּה אֹרֶךְ לַפֵּאָה הָאֶחָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Столбов для него — двадцать, и медных подножий под них — двадцать, а крючки столбов и пояски на них будут из серебра.',
    verse_ivrit: 'וְעַמֻּדָיו עֶשְׂרִים וְאַדְנֵיהֶם עֶשְׂרִים נְחֹשֶׁת וָוֵי הָעַמֻּדִים וַחֲשֻׁקֵיהֶם כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'И с северной стороны будет занавес в сто локтей длиной, столбов для него — двадцать, и медных подножий под них — двадцать, а крючки столбов и пояски на них будут из серебра.',
    verse_ivrit: 'וְכֵן לִפְאַת צָפוֹן בָּאֹרֶךְ קְלָעִים מֵאָה אֹרֶךְ וְעַמֻּדָו עֶשְׂרִים וְאַדְנֵיהֶם עֶשְׂרִים נְחֹשֶׁת וָוֵי הָעַמֻּדִים וַחֲשֻׁקֵיהֶם כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'А по ширине двора с западной стороны будет занавес в пятьдесят локтей, для него — десять столбов и под них — десять подножий.',
    verse_ivrit: 'וְרֹחַב הֶחָצֵר לִפְאַת-יָם קְלָעִים חֲמִשִּׁים אַמָּה עַמֻּדֵיהֶם עֲשָׂרָה וְאַדְנֵיהֶם עֲשָׂרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Ширина двора с передней, восточной стороны — пятьдесят локтей:',
    verse_ivrit: 'וְרֹחַב הֶחָצֵר לִפְאַת קֵדְמָה מִזְרָחָה חֲמִשִּׁים אַמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'занавес в пятнадцать локтей для одной стороны, к нему три столба и под них три подножия,',
    verse_ivrit: 'וַחֲמֵשׁ עֶשְׂרֵה אַמָּה קְלָעִים לַכָּתֵף עַמֻּדֵיהֶם שְׁלֹשָׁה וְאַדְנֵיהֶם שְׁלֹשָׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'а для другой стороны — [также] занавес в пятнадцать локтей, к нему три столба и под них три подножия.',
    verse_ivrit: 'וְלַכָּתֵף הַשֵּׁנִית חֲמֵשׁ עֶשְׂרֵה קְלָעִים עַמֻּדֵיהֶם שְׁלֹשָׁה וְאַדְנֵיהֶם שְׁלֹשָׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'А завеса для ворот двора — в двадцать локтей, из синей, багряной и пурпуровой нити и скрученного тонкого льна, работы вышивальщика; к ней четыре столба и под них четыре подножия.',
    verse_ivrit: 'וּלְשַׁעַר הֶחָצֵר מָסָךְ עֶשְׂרִים אַמָּה תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר מַעֲשֵׂה רֹקֵם עַמֻּדֵיהֶם אַרְבָּעָה וְאַדְנֵיהֶם אַרְבָּעָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Все столбы вокруг двора [должны быть] охвачены поясками из серебра; их крючки — из серебра, а подножия под них — из меди.',
    verse_ivrit: 'כָּל-עַמּוּדֵי הֶחָצֵר סָבִיב מְחֻשָּׁקִים כֶּסֶף וָוֵיהֶם כָּסֶף וְאַדְנֵיהֶם נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Длина двора — сто локтей, ширина — пятьдесят, а высота — пять локтей: [это завесы] из скрученного тонкого льна.',
    verse_ivrit: 'אֹרֶךְ הֶחָצֵר מֵאָה בָאַמָּה וְרֹחַב חֲמִשִּׁים בַּחֲמִשִּׁים וְקֹמָה חָמֵשׁ אַמּוֹת שֵׁשׁ מָשְׁזָר וְאַדְנֵיהֶם נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТРУМА(Приношение)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Подножия [под столбы] — из меди. Все прочие принадлежности Святилища — все его колья, [которыми прикрепляют полотнища к земле,] и колья двора — медные. ',
    verse_ivrit: 'לְכֹל כְּלֵי הַמִּשְׁכָּן בְּכֹל עֲבֹדָתוֹ וְכָל-יְתֵדֹתָיו וְכָל-יִתְדֹת הֶחָצֵר נְחֹשֶׁת',
    comment: '',
  },
  //////////////////////////////////////           2727272727272272727227272727272272727272727227272727          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 2,
    poemNumber: 20,
    verse: 'Ты же вели сынам Израиля, чтобы они доставляли тебе чистое масло, выбитое из маслин, для освещения, чтобы зажигать лампу, горящую постоянно.',
    verse_ivrit: 'וְאַתָּה תְּצַוֶּה אֶת-בְּנֵי יִשְׂרָאֵל וְיִקְחוּ אֵלֶיךָ שֶׁמֶן זַיִת זָךְ כָּתִית לַמָּאוֹר לְהַעֲלֹת נֵר תָּמִיד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 27,
    id_chapter_two: 2,
    poemNumber: 21,
    verse: 'Пусть следят за ней Аѓарон и его сыновья с вечера до утра, пред Господом, в Шатре Встречи, вне полога, что перед [ковчегом] Свидетельства. Это вечный устав для всех поколений [священников, служащих Богу] от имени сынов Израиля.',
    verse_ivrit: 'בְּאֹהֶל מוֹעֵד מִחוּץ לַפָּרֹכֶת אֲשֶׁר עַל-הָעֵדֻת יַעֲרֹךְ אֹתוֹ אַהֲרֹן וּבָנָיו מֵעֶרֶב עַד-בֹּקֶר לִפְנֵי יְהוָה חֻקַּת עוֹלָם לְדֹרֹתָם מֵאֵת בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
//////////////////////////////////////           28282828282828282282828228282882828282288282828282822828          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Ты же приблизь к себе из числа сынов Израиля твоего брата Аѓарона, а с ним — и его сыновей, чтобы они были Мне священниками: Аѓарона [и] его сыновей — Надава и Авиѓу, Эльазара и Итамара.',
    verse_ivrit: 'וְאַתָּה הַקְרֵב אֵלֶיךָ אֶת-אַהֲרֹן אָחִיךָ וְאֶת-בָּנָיו אִתּוֹ מִתּוֹךְ בְּנֵי יִשְׂרָאֵל לְכַהֲנוֹ-לִי אַהֲרֹן נָדָב וַאֲבִיהוּא אֶלְעָזָר וְאִיתָמָר בְּנֵי אַהֲרֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Сделай твоему брату Аѓарону священные одежды для почета и великолепия.',
    verse_ivrit: 'וְעָשִׂיתָ בִגְדֵי-קֹדֶשׁ לְאַהֲרֹן אָחִיךָ לְכָבוֹד וּלְתִפְאָרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Ты должен поговорить со всеми мудрыми сердцем, которых Я наполнил духом мудрости: пусть они сделают Аѓарону одежды, чтобы освятить его на священство Мне.',
    verse_ivrit: 'וְאַתָּה תְּדַבֵּר אֶל-כָּל-חַכְמֵי-לֵב אֲשֶׁר מִלֵּאתִיו רוּחַ חָכְמָה וְעָשׂוּ אֶת-בִּגְדֵי אַהֲרֹן לְקַדְּשׁוֹ לְכַהֲנוֹ-לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Вот одежды, которые они должны изготовить: нагрудник, эфод, плащ, ячеистая рубаха, тюрбан и пояс. Пусть сделают священные одежды твоему брату Аѓарону и его сыновьям, чтобы он стал Моим священником,',
    verse_ivrit: 'וְאֵלֶּה הַבְּגָדִים אֲשֶׁר יַעֲשׂוּ חֹשֶׁן וְאֵפוֹד וּמְעִיל וּכְתֹנֶת תַּשְׁבֵּץ מִצְנֶפֶת וְאַבְנֵט וְעָשׂוּ בִגְדֵי-קֹדֶשׁ לְאַהֲרֹן אָחִיךָ וּלְבָנָיו לְכַהֲנוֹ-לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'и пусть возьмут [для этого] золото, синюю, багряную и пурпуровую нить и льняную пряжу. ',
    verse_ivrit: 'וְהֵם יִקְחוּ אֶת-הַזָּהָב וְאֶת-הַתְּכֵלֶת וְאֶת-הָאַרְגָּמָן וְאֶת-תּוֹלַעַת הַשָּׁנִי וְאֶת-הַשֵּׁשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Пусть сделают эфод из золотой, синей, багряной и пурпуровой нити и скрученного тонкого льна, с вытканными изображениями.',
    verse_ivrit: 'וְעָשׂוּ אֶת-הָאֵפֹד זָהָב תְּכֵלֶת וְאַרְגָּמָן תּוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר מַעֲשֵׂה חֹשֵׁב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Два соединяющих оплечья будут у него на обоих углах, и он будет пришит [к ним].',
    verse_ivrit: 'שְׁתֵּי כְתֵפֹת חֹבְרֹת יִהְיֶה-לּוֹ אֶל-שְׁנֵי קְצוֹתָיו וְחֻבָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Кушак поверх эфода должен составлять с ним единое целое, будучи соткан подобно ему — из золотой, синей, багряной и пурпуровой нити и скрученного тонкого льна.',
    verse_ivrit: 'וְחֵשֶׁב אֲפֻדָּתוֹ אֲשֶׁר עָלָיו כְּמַעֲשֵׂהוּ מִמֶּנּוּ יִהְיֶה זָהָב תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Возьми два оникса и выгравируй на них имена сынов Израиля:',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-שְׁתֵּי אַבְנֵי-שֹׁהַם וּפִתַּחְתָּ עֲלֵיהֶם שְׁמוֹת בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'на одном камне — шесть имен, а имена шести остальных — на другом камне, в порядке старшинства.',
    verse_ivrit: 'שִׁשָּׁה מִשְּׁמֹתָם עַל הָאֶבֶן הָאֶחָת וְאֶת-שְׁמוֹת הַשִּׁשָּׁה הַנּוֹתָרִים עַל-הָאֶבֶן הַשֵּׁנִית כְּתוֹלְדֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Работой резчика по камню, как вырезают на печатях, выгравируй на обоих камнях имена сынов Израиля; вставь [камни] в золотые оправы.',
    verse_ivrit: 'מַעֲשֵׂה חָרַשׁ אֶבֶן פִּתּוּחֵי חֹתָם תְּפַתַּח אֶת-שְׁתֵּי הָאֲבָנִים עַל-שְׁמֹת בְּנֵי יִשְׂרָאֵל מֻסַבֹּת מִשְׁבְּצוֹת זָהָב תַּעֲשֶׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Прикрепи эти два камня к оплечьям эфода; [это будут] камни для напоминания о сынах Израиля. Пусть Аѓарон носит пред Господом их имена на своих плечах — как напоминание.',
    verse_ivrit: 'וְשַׂמְתָּ אֶת-שְׁתֵּי הָאֲבָנִים עַל כִּתְפֹת הָאֵפֹד אַבְנֵי זִכָּרֹן לִבְנֵי יִשְׂרָאֵל וְנָשָׂא אַהֲרֹן אֶת-שְׁמוֹתָם לִפְנֵי יְהוָה עַל-שְׁתֵּי כְתֵפָיו לְזִכָּרֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Сделай золотые оправы.',
    verse_ivrit: 'וְעָשִׂיתָ מִשְׁבְּצֹת זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Сделай две цепочки из чистого золота — витые, как шнуры, — и соедини эти витые цепочки с оправами.',
    verse_ivrit: 'וּשְׁתֵּי שַׁרְשְׁרֹת זָהָב טָהוֹר מִגְבָּלֹת תַּעֲשֶׂה אֹתָם מַעֲשֵׂה עֲבֹת וְנָתַתָּה אֶת-שַׁרְשְׁרֹת הָעֲבֹתֹת עַל-הַמִּשְׁבְּצֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Сделай нагрудник, [служащий для] суда, с вытканными изображениями, такой же работы, как и эфод, — из золотой, синей, багряной и пурпуровой нити и скрученного тонкого льна.',
    verse_ivrit: 'וְעָשִׂיתָ חֹשֶׁן מִשְׁפָּט מַעֲשֵׂה חֹשֵׁב כְּמַעֲשֵׂה אֵפֹד תַּעֲשֶׂנּוּ זָהָב תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר תַּעֲשֶׂה אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Он должен быть квадратным, сдвоенным, в пядь длиной и в пядь шириной.',
    verse_ivrit: 'רָבוּעַ יִהְיֶה כָּפוּל זֶרֶת אָרְכּוֹ וְזֶרֶת רָחְבּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Заполни [сделанные] в нем [гнезда] четырьмя рядами камней: первый ряд — рубин, топаз и изумруд,',
    verse_ivrit: 'וּמִלֵּאתָ בוֹ מִלֻּאַת אֶבֶן אַרְבָּעָה טוּרִים אָבֶן טוּר אֹדֶם פִּטְדָה וּבָרֶקֶת הַטּוּר הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'второй ряд — бирюза, сапфир и алмаз,',
    verse_ivrit: 'וְהַטּוּר הַשֵּׁנִי נֹפֶךְ סַפִּיר וְיָהֲלֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'третий ряд — опал, агат и аметист,',
    verse_ivrit: 'וְהַטּוּר הַשְּׁלִישִׁי לֶשֶׁם שְׁבוֹ וְאַחְלָמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'четвертый ряд — хризолит, оникс и яшма. [Камни] будут оправлены золотом.',
    verse_ivrit: 'וְהַטּוּר הָרְבִיעִי תַּרְשִׁישׁ וְשֹׁהַם וְיָשְׁפֵה מְשֻׁבָּצִים זָהָב יִהְיוּ בְּמִלּוּאֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Эти камни будут [названы] по именам сынов Израиля — двенадцать [камней], по именам [их]; на каждом [камне] должно быть вырезано, как на печати, имя одного из двенадцати колен.',
    verse_ivrit: 'וְהָאֲבָנִים תִּהְיֶיןָ עַל-שְׁמֹת בְּנֵי-יִשְׂרָאֵל שְׁתֵּים עֶשְׂרֵה עַל-שְׁמֹתָם פִּתּוּחֵי חוֹתָם אִישׁ עַל-שְׁמוֹ תִּהְיֶיןָ לִשְׁנֵי עָשָׂר שָׁבֶט',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Присоедини к нагруднику витые цепочки из чистого золота.',
    verse_ivrit: 'וְעָשִׂיתָ עַל-הַחֹשֶׁן שַׁרְשֹׁת גַּבְלֻת מַעֲשֵׂה עֲבֹת זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Сделай на нагруднике два золотых кольца, прикрепи оба этих кольца к двум углам нагрудника',
    verse_ivrit: 'וְעָשִׂיתָ עַל-הַחֹשֶׁן שְׁתֵּי טַבְּעוֹת זָהָב וְנָתַתָּ אֶת-שְׁתֵּי הַטַּבָּעוֹת עַל-שְׁנֵי קְצוֹת הַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'и вдень две золотые витые [цепочки] в два кольца на углах нагрудника.',
    verse_ivrit: 'וְנָתַתָּה אֶת-שְׁתֵּי עֲבֹתֹת הַזָּהָב עַל-שְׁתֵּי הַטַּבָּעֹת אֶל-קְצוֹת הַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'А два конца двух витых [цепочек] вдень в две оправы и прикрепи к оплечьям эфода на лицевой стороне.',
    verse_ivrit: 'וְאֵת שְׁתֵּי קְצוֹת שְׁתֵּי הָעֲבֹתֹת תִּתֵּן עַל-שְׁתֵּי הַמִּשְׁבְּצוֹת וְנָתַתָּה עַל-כִּתְפוֹת הָאֵפֹד אֶל-מוּל פָּנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Сделай два золотых кольца и прикрепи их к двум углам нагрудника с внутренней стороны, обращенной к эфоду.',
    verse_ivrit: 'וְעָשִׂיתָ שְׁתֵּי טַבְּעוֹת זָהָב וְשַׂמְתָּ אֹתָם עַל-שְׁנֵי קְצוֹת הַחֹשֶׁן עַל-שְׂפָתוֹ אֲשֶׁר אֶל-עֵבֶר הָאֵפֹד בָּיְתָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Сделай два золотых кольца и прикрепи их к двум оплечьям эфода, снизу, на лицевой стороне, около шва над кушаком эфода.',
    verse_ivrit: 'וְעָשִׂיתָ שְׁתֵּי טַבְּעוֹת זָהָב וְנָתַתָּה אֹתָם עַל-שְׁתֵּי כִתְפוֹת הָאֵפוֹד מִלְּמַטָּה מִמּוּל פָּנָיו לְעֻמַּת מַחְבַּרְתּוֹ מִמַּעַל לְחֵשֶׁב הָאֵפוֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Пусть кольца нагрудника будут соединены с кольцами эфода шнуром из синей нити, так чтобы нагрудник, находясь над кушаком эфода, вплотную прилегал к эфоду.',
    verse_ivrit: 'וְיִרְכְּסוּ אֶת-הַחֹשֶׁן מִטַּבְּעֹתָו אֶל-טַבְּעֹת הָאֵפוֹד בִּפְתִיל תְּכֵלֶת לִהְיוֹת עַל-חֵשֶׁב הָאֵפוֹד וְלֹא-יִזַּח הַחֹשֶׁן מֵעַל הָאֵפוֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Аѓарон будет носить имена сынов Израиля на нагруднике, [служащем для] суда, — на своем сердце — когда будет входить в Святилище, как постоянное напоминание пред Господом.',
    verse_ivrit: 'וְנָשָׂא אַהֲרֹן אֶת-שְׁמוֹת בְּנֵי-יִשְׂרָאֵל בְּחֹשֶׁן הַמִּשְׁפָּט עַל-לִבּוֹ בְּבֹאוֹ אֶל-הַקֹּדֶשׁ לְזִכָּרֹן לִפְנֵי-יְהוָה תָּמִיד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Вложи в нагрудник, [служащий для] суда, урим и тумим: они будут на сердце Аѓарона, когда он будет представать пред Господом. Пусть Аѓарон постоянно носит указания сынам Израиля на своем сердце пред Господом.',
    verse_ivrit: 'וְנָתַתָּ אֶל-חֹשֶׁן הַמִּשְׁפָּט אֶת-הָאוּרִים וְאֶת-הַתֻּמִּים וְהָיוּ עַל-לֵב אַהֲרֹן בְּבֹאוֹ לִפְנֵי יְהוָה וְנָשָׂא אַהֲרֹן אֶת-מִשְׁפַּט בְּנֵי-יִשְׂרָאֵל עַל-לִבּוֹ לִפְנֵי יְהוָה תָּמִיד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Сделай плащ к эфоду, весь из синей пряжи.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-מְעִיל הָאֵפוֹד כְּלִיל תְּכֵלֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Посредине пусть будет отверстие для головы; ворот вокруг отверстия должен быть соткан так, как делают ворот кольчуги, чтобы не рвалось.',
    verse_ivrit: 'וְהָיָה פִי-רֹאשׁוֹ בְּתוֹכוֹ שָׂפָה יִהְיֶה לְפִיו סָבִיב מַעֲשֵׂה אֹרֵג כְּפִי תַחְרָא יִהְיֶה-לּוֹ לֹא יִקָּרֵעַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'А по краю [плаща] сделай [подвески в виде] гранатовых плодов, из синей, багряной и пурпуровой нити, вдоль края, а между ними — золотые колокольчики.',
    verse_ivrit: 'וְעָשִׂיתָ עַל-שׁוּלָיו רִמֹּנֵי תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי עַל-שׁוּלָיו סָבִיב וּפַעֲמֹנֵי זָהָב בְּתוֹכָם סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Золотой колокольчик и гранатовый плод, золотой колокольчик и гранатовый плод [должны чередоваться] по всему краю плаща.',
    verse_ivrit: 'פַּעֲמֹן זָהָב וְרִמּוֹן פַּעֲמֹן זָהָב וְרִמּוֹן עַל-שׁוּלֵי הַמְּעִיל סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: '[Этот плащ] должен быть на Аѓароне при служении, чтобы звон был слышен, когда он будет входить в Святилище к Господу и выходить [оттуда], чтобы ему не умереть.',
    verse_ivrit: 'וְהָיָה עַל-אַהֲרֹן לְשָׁרֵת וְנִשְׁמַע קוֹלוֹ בְּבֹאוֹ אֶל-הַקֹּדֶשׁ לִפְנֵי יְהוָה וּבְצֵאתוֹ וְלֹא יָמוּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Сделай пластину из чистого золота и выгравируй на ней, как на печати: “Святыня Господу”.',
    verse_ivrit: 'וְעָשִׂיתָ צִּיץ זָהָב טָהוֹר וּפִתַּחְתָּ עָלָיו פִּתּוּחֵי חֹתָם קֹדֶשׁ לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'Прикрепи ее к синему шнуру, чтобы присоединить ее к тюрбану, — она должна быть на тюрбане спереди, у Аѓарона на лбу.',
    verse_ivrit: 'וְשַׂמְתָּ אֹתוֹ עַל-פְּתִיל תְּכֵלֶת וְהָיָה עַל-הַמִּצְנָפֶת אֶל-מוּל פְּנֵי-הַמִּצְנֶפֶת יִהְיֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 38,
    verse: 'Аѓарон примет на себя [всю возможную] вину [за грех осквернения] святынь, которые сыны Израиля посвящают [Богу] во всех [видах] своих священных приношений. [Пластина] должна быть постоянно у него на лбу, чтобы Господь благоволил им.',
    verse_ivrit: 'וְהָיָה עַל-מֵצַח אַהֲרֹן וְנָשָׂא אַהֲרֹן אֶת-עֲו‍ֹן הַקֳּדָשִׁים אֲשֶׁר יַקְדִּישׁוּ בְּנֵי יִשְׂרָאֵל לְכָל-מַתְּנֹת קָדְשֵׁיהֶם וְהָיָה עַל-מִצְחוֹ תָּמִיד לְרָצוֹן לָהֶם לִפְנֵי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 39,
    verse: 'Сделай ячеистую льняную рубаху, льняной тюрбан и пояс ткацкой работы.',
    verse_ivrit: 'וְשִׁבַּצְתָּ הַכְּתֹנֶת שֵׁשׁ וְעָשִׂיתָ מִצְנֶפֶת שֵׁשׁ וְאַבְנֵט תַּעֲשֶׂה מַעֲשֵׂה רֹקֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 40,
    verse: 'Сделай рубахи для сыновей Аѓарона и сделай им пояса и головные повязки, для славы и великолепия.',
    verse_ivrit: 'וְלִבְנֵי אַהֲרֹן תַּעֲשֶׂה כֻתֳּנֹת וְעָשִׂיתָ לָהֶם אַבְנֵטִים וּמִגְבָּעוֹת תַּעֲשֶׂה לָהֶם לְכָבוֹד וּלְתִפְאָרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 41,
    verse: 'Облачи в это твоего брата Аѓарона, а с ним — и его сыновей, и соверши помазание их [оливковым маслом], посвяти их [в священники] и освяти их — для священного [служения] Мне.',
    verse_ivrit: 'וְהִלְבַּשְׁתָּ אֹתָם אֶת-אַהֲרֹן אָחִיךָ וְאֶת-בָּנָיו אִתּוֹ וּמָשַׁחְתָּ אֹתָם וּמִלֵּאתָ אֶת-יָדָם וְקִדַּשְׁתָּ אֹתָם וְכִהֲנוּ לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 42,
    verse: 'Сделай им льняные штаны от пояса до колен, чтобы прикрывать наготу тела.',
    verse_ivrit: 'וַעֲשֵׂה לָהֶם מִכְנְסֵי-בָד לְכַסּוֹת בְּשַׂר עֶרְוָה מִמָּתְנַיִם וְעַד-יְרֵכַיִם יִהְיוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 28,
    id_chapter_two: 1,
    poemNumber: 43,
    verse: 'Аѓарон и его сыновья должны носить их, когда входят в Шатер Встречи или подходят к жертвеннику, чтобы служить в Святилище, а иначе на них ляжет вина и они должны будут умереть. Это вечный закон для [Аѓарона] и для его потомков после него.',
    verse_ivrit: 'וְהָיוּ עַל-אַהֲרֹן וְעַל-בָּנָיו בְּבֹאָם אֶל-אֹהֶל מוֹעֵד אוֹ בְגִשְׁתָּם אֶל-הַמִּזְבֵּחַ לְשָׁרֵת בַּקֹּדֶשׁ וְלֹא-יִשְׂאוּ עָו‍ֹן וָמֵתוּ חֻקַּת עוֹלָם לוֹ וּלְזַרְעוֹ אַחֲרָיו',
    comment: '',
  },
  //////////////////////////////////////           292929292929292929292929292929292929292929          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Вот что ты должен сделать с ними, чтобы посвятить их для служения Мне. Возьми одного теленка и двух баранов без порока,',
    verse_ivrit: 'וְזֶה הַדָּבָר אֲשֶׁר-תַּעֲשֶׂה לָהֶם לְקַדֵּשׁ אֹתָם לְכַהֵן לִי לְקַח פַּר אֶחָד בֶּן-בָּקָר וְאֵילִם שְׁנַיִם תְּמִימִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'пресные хлебы, пресные лепешки, замешанные на [оливковом] масле, и пресные коржи, смазанные маслом; все это испеки из мелко смолотой пшеничной муки.',
    verse_ivrit: 'וְלֶחֶם מַצּוֹת וְחַלֹּת מַצֹּת בְּלוּלֹת בַּשֶּׁמֶן וּרְקִיקֵי מַצּוֹת מְשֻׁחִים בַּשָּׁמֶן סֹלֶת חִטִּים תַּעֲשֶׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Сложи это в одну корзину и принеси в этой корзине; [возьми] также теленка и двух баранов.',
    verse_ivrit: 'וְנָתַתָּ אוֹתָם עַל-סַל אֶחָד וְהִקְרַבְתָּ אֹתָם בַּסָּל וְאֶת-הַפָּר וְאֵת שְׁנֵי הָאֵילִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Приведи Аѓарона и его сыновей ко входу в Шатер Встречи и омой их водой.',
    verse_ivrit: 'וְאֶת-אַהֲרֹן וְאֶת-בָּנָיו תַּקְרִיב אֶל-פֶּתַח אֹהֶל מוֹעֵד וְרָחַצְתָּ אֹתָם בַּמָּיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Возьми одежды и надень на Аѓарона рубаху, накидку под эфод, [сам] эфод и нагрудник. Опояшь его кушаком эфода.',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-הַבְּגָדִים וְהִלְבַּשְׁתָּ אֶת-אַהֲרֹן אֶת-הַכֻּתֹּנֶת וְאֵת מְעִיל הָאֵפֹד וְאֶת-הָאֵפֹד וְאֶת-הַחֹשֶׁן וְאָפַדְתָּ לוֹ בְּחֵשֶׁב הָאֵפֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Возложи тюрбан на его голову и прикрепи к тюрбану священный венец.',
    verse_ivrit: 'וְשַׂמְתָּ הַמִּצְנֶפֶת עַל-רֹאשׁוֹ וְנָתַתָּ אֶת-נֵזֶר הַקֹּדֶשׁ עַל-הַמִּצְנָפֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Возьми масло для помазания и возлей ему на голову — соверши помазание.',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-שֶׁמֶן הַמִּשְׁחָה וְיָצַקְתָּ עַל-רֹאשׁוֹ וּמָשַׁחְתָּ אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Подведи его сыновей и надень на них рубахи.',
    verse_ivrit: 'וְאֶת-בָּנָיו תַּקְרִיב וְהִלְבַּשְׁתָּם כֻּתֳּנֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Опояшь их поясами — Аѓарона и его сыновей — и повяжи им головные повязки. И священство станет для них вечным законом. Так ты посвятишь Аѓарона и его сыновей [для служения Мне].',
    verse_ivrit: 'וְחָגַרְתָּ אֹתָם אַבְנֵט אַהֲרֹן וּבָנָיו וְחָבַשְׁתָּ לָהֶם מִגְבָּעֹת וְהָיְתָה לָהֶם כְּהֻנָּה לְחֻקַּת עוֹלָם וּמִלֵּאתָ יַד-אַהֲרֹן וְיַד-בָּנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Подведи теленка к Шатру Встречи, и пусть Аѓарон и его сыновья возложат руки на голову этого теленка.',
    verse_ivrit: 'וְהִקְרַבְתָּ אֶת-הַפָּר לִפְנֵי אֹהֶל מוֹעֵד וְסָמַךְ אַהֲרֹן וּבָנָיו אֶת-יְדֵיהֶם עַל-רֹאשׁ הַפָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Зарежь теленка пред Господом у входа в Шатер Встречи.',
    verse_ivrit: 'וְשָׁחַטְתָּ אֶת-הַפָּר לִפְנֵי יְהוָה פֶּתַח אֹהֶל מוֹעֵד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Возьми кровь теленка и нанеси ее пальцем на выступы по углам жертвенника, а всю [оставшуюся] кровь вылей к подножию жертвенника.',
    verse_ivrit: 'וְלָקַחְתָּ מִדַּם הַפָּר וְנָתַתָּה עַל-קַרְנֹת הַמִּזְבֵּחַ בְּאֶצְבָּעֶךָ וְאֶת-כָּל-הַדָּם תִּשְׁפֹּךְ אֶל-יְסוֹד הַמִּזְבֵּחַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Возьми весь жир, покрывающий внутренности, и сальник с печени, и обе почки с жиром — и сожги на жертвеннике.',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-כָּל-הַחֵלֶב הַמְכַסֶּה אֶת-הַקֶּרֶב וְאֵת הַיֹּתֶרֶת עַל-הַכָּבֵד וְאֵת שְׁתֵּי הַכְּלָיֹת וְאֶת-הַחֵלֶב אֲשֶׁר עֲלֵיהֶן וְהִקְטַרְתָּ הַמִּזְבֵּחָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'А мясо теленка, его кожу и его нечистоты предай огню вне стана — это очистительная жертва.',
    verse_ivrit: 'וְאֶת-בְּשַׂר הַפָּר וְאֶת-עֹרוֹ וְאֶת-פִּרְשׁוֹ תִּשְׂרֹף בָּאֵשׁ מִחוּץ לַמַּחֲנֶה חַטָּאת הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Возьми одного из баранов, и пусть Аѓарон и его сыновья возложат руки на голову этого барана.',
    verse_ivrit: 'וְאֶת-הָאַיִל הָאֶחָד תִּקָּח וְסָמְכוּ אַהֲרֹן וּבָנָיו אֶת-יְדֵיהֶם עַל-רֹאשׁ הָאָיִל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Зарежь барана, возьми его кровь и окропи жертвенник со всех сторон.',
    verse_ivrit: 'וְשָׁחַטְתָּ אֶת-הָאָיִל וְלָקַחְתָּ אֶת-דָּמוֹ וְזָרַקְתָּ עַל-הַמִּזְבֵּחַ סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Рассеки [тушу] барана на части. Вымой его внутренности и голени и положи их поверх головы и рассеченных частей.',
    verse_ivrit: 'וְאֶת-הָאַיִל תְּנַתֵּחַ לִנְתָחָיו וְרָחַצְתָּ קִרְבּוֹ וּכְרָעָיו וְנָתַתָּ עַל-נְתָחָיו וְעַל-רֹאשׁוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'И сожги всего барана на жертвеннике. Это всесожжение Господу, приятное благоухание — огненное жертвоприношение Господу.',
    verse_ivrit: 'וְהִקְטַרְתָּ אֶת-כָּל-הָאַיִל הַמִּזְבֵּחָה עֹלָה הוּא לַיהוָה רֵיחַ נִיחוֹחַ אִשֶּׁה לַיהוָה הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Возьми другого барана, и пусть Аѓарон с сыновьями возложат руки на голову этого барана.',
    verse_ivrit: 'וְלָקַחְתָּ אֵת הָאַיִל הַשֵּׁנִי וְסָמַךְ אַהֲרֹן וּבָנָיו אֶת-יְדֵיהֶם עַל-רֹאשׁ הָאָיִל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Зарежь барана, возьми его кровь и помажь мочку правого уха Аѓарону и его сыновьям, а также большой палец правой руки и большой палец правой ноги каждого из них. Окропи жертвенник кровью со всех сторон.',
    verse_ivrit: 'וְשָׁחַטְתָּ אֶת-הָאַיִל וְלָקַחְתָּ מִדָּמוֹ וְנָתַתָּה עַל-תְּנוּךְ אֹזֶן אַהֲרֹן וְעַל-תְּנוּךְ אֹזֶן בָּנָיו הַיְמָנִית וְעַל-בֹּהֶן יָדָם הַיְמָנִית וְעַל-בֹּהֶן רַגְלָם הַיְמָנִית וְזָרַקְתָּ אֶת-הַדָּם עַל-הַמִּזְבֵּחַ סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Возьми кровь с жертвенника и масло для помазания и окропи ими Аѓарона и его одежды, а вместе с ним — его сыновей и их одежды. И будут они освящены — он и его одежды, а с ним — его сыновья и их одежды.',
    verse_ivrit: 'וְלָקַחְתָּ מִן-הַדָּם אֲשֶׁר עַל-הַמִּזְבֵּחַ וּמִשֶּׁמֶן הַמִּשְׁחָה וְהִזֵּיתָ עַל-אַהֲרֹן וְעַל-בְּגָדָיו וְעַל-בָּנָיו וְעַל-בִּגְדֵי בָנָיו אִתּוֹ וְקָדַשׁ הוּא וּבְגָדָיו וּבָנָיו וּבִגְדֵי בָנָיו אִתּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Возьми также жир этого барана, курдюк, жир с внутренностей, сальник с печени, обе почки с жиром, правую голень (ибо это баран для посвящения),',
    verse_ivrit: 'וְלָקַחְתָּ מִן-הָאַיִל הַחֵלֶב וְהָאַלְיָה וְאֶת-הַחֵלֶב הַמְכַסֶּה אֶת-הַקֶּרֶב וְאֵת יֹתֶרֶת הַכָּבֵד וְאֵת שְׁתֵּי הַכְּלָיֹת וְאֶת-הַחֵלֶב אֲשֶׁר עֲלֵיהֶן וְאֵת שׁוֹק הַיָּמִין כִּי אֵיל מִלֻּאִים הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'один целый хлеб, одну лепешку хлеба, [замешанную на оливковом] масле, и один корж из той корзины мацы, что пред Господом.',
    verse_ivrit: 'וְכִכַּר לֶחֶם אַחַת וְחַלַּת לֶחֶם שֶׁמֶן אַחַת וְרָקִיק אֶחָד מִסַּל הַמַּצּוֹת אֲשֶׁר לִפְנֵי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Положи все это на ладони Аѓарона и на ладони его сыновей — и соверши возношение пред Господом.',
    verse_ivrit: 'וְשַׂמְתָּ הַכֹּל עַל כַּפֵּי אַהֲרֹן וְעַל כַּפֵּי בָנָיו וְהֵנַפְתָּ אֹתָם תְּנוּפָה לִפְנֵי יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Затем возьми все это из их рук и сожги на жертвеннике, вместе с жертвой всесожжения, как приятное благоухание пред Господом. Это огненное жертвоприношение Господу.',
    verse_ivrit: 'וְלָקַחְתָּ אֹתָם מִיָּדָם וְהִקְטַרְתָּ הַמִּזְבֵּחָה עַל-הָעֹלָה לְרֵיחַ נִיחוֹחַ לִפְנֵי יְהוָה אִשֶּׁה הוּא לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Возьми грудину барана, [принесенного в жертву при] посвящении Аѓарона, и соверши возношение пред Господом.',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-הֶחָזֶה מֵאֵיל הַמִּלֻּאִים אֲשֶׁר לְאַהֲרֹן וְהֵנַפְתָּ אֹתוֹ תְּנוּפָה לִפְנֵי יְהוָה וְהָיָה לְךָ לְמָנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Это будет твоя доля. Грудину для возношения и голень для приношения, что взяты от барана, [принесенного в жертву при] посвящении Аѓарона и его сыновей, считай священными.',
    verse_ivrit: 'וְקִדַּשְׁתָּ אֵת חֲזֵה הַתְּנוּפָה וְאֵת שׁוֹק הַתְּרוּמָה אֲשֶׁר הוּנַף וַאֲשֶׁר הוּרָם מֵאֵיל הַמִּלֻּאִים מֵאֲשֶׁר לְאַהֲרֹן וּמֵאֲשֶׁר לְבָנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Это будет [всегда принадлежать] Аѓарону и его сыновьям; [таков] вечный закон для сынов Израиля, потому что это — приношение. Это будет приношением сынов Израиля Господу от их мирных жертв.',
    verse_ivrit: 'וְהָיָה לְאַהֲרֹן וּלְבָנָיו לְחָק-עוֹלָם מֵאֵת בְּנֵי יִשְׂרָאֵל כִּי תְרוּמָה הוּא וּתְרוּמָה יִהְיֶה מֵאֵת בְּנֵי-יִשְׂרָאֵל מִזִּבְחֵי שַׁלְמֵיהֶם תְּרוּמָתָם לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Священные одежды Аѓарона должны перейти от него к его потомкам: в них будут помазывать их и посвящать [для служения].',
    verse_ivrit: 'וּבִגְדֵי הַקֹּדֶשׁ אֲשֶׁר לְאַהֲרֹן יִהְיוּ לְבָנָיו אַחֲרָיו לְמָשְׁחָה בָהֶם וּלְמַלֵּא-בָם אֶת-יָדָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Когда священник из его потомков, заступающий на его место, будет входить в Шатер Встречи для служения в Святилище, он должен носить их семь дней.',
    verse_ivrit: 'שִׁבְעַת יָמִים יִלְבָּשָׁם הַכֹּהֵן תַּחְתָּיו מִבָּנָיו אֲשֶׁר יָבֹא אֶל-אֹהֶל מוֹעֵד לְשָׁרֵת בַּקֹּדֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Возьми барана, [принесенного в жертву при] посвящении, и свари его мясо на святом месте.',
    verse_ivrit: 'וְאֵת אֵיל הַמִּלֻּאִים תִּקָּח וּבִשַּׁלְתָּ אֶת-בְּשָׂרוֹ בְּמָקֹם קָדֹשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Пусть Аѓарон и его сыновья едят мясо барана с тем хлебом, что в корзине у входа в Шатер Встречи.',
    verse_ivrit: 'וְאָכַל אַהֲרֹן וּבָנָיו אֶת-בְּשַׂר הָאַיִל וְאֶת-הַלֶּחֶם אֲשֶׁר בַּסָּל פֶּתַח אֹהֶל מוֹעֵד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Пусть [лишь] они едят то, [что было принесено в жертву при] их искуплении, для посвящения их на служение и для их освящения. Посторонний же не должен есть этого, потому что это святыня.',
    verse_ivrit: 'וְאָכְלוּ אֹתָם אֲשֶׁר כֻּפַּר בָּהֶם לְמַלֵּא אֶת-יָדָם לְקַדֵּשׁ אֹתָם וְזָר לֹא-יֹאכַל כִּי-קֹדֶשׁ הֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Если мясо [жертвы, принесенной при] посвящении, и хлеб останутся до утра, то сожги этот остаток в огне. Есть его нельзя, потому что это святыня.',
    verse_ivrit: 'וְאִם-יִוָּתֵר מִבְּשַׂר הַמִּלֻּאִים וּמִן-הַלֶּחֶם עַד-הַבֹּקֶר וְשָׂרַפְתָּ אֶת-הַנּוֹתָר בָּאֵשׁ לֹא יֵאָכֵל כִּי-קֹדֶשׁ הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'С Аѓароном и его сыновьями поступи именно так, как Я тебе повелел: семь дней совершай их посвящение.',
    verse_ivrit: 'וְעָשִׂיתָ לְאַהֲרֹן וּלְבָנָיו כָּכָה כְּכֹל אֲשֶׁר-צִוִּיתִי אֹתָכָה שִׁבְעַת יָמִים תְּמַלֵּא יָדָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Ежедневно приноси теленка как очистительную жертву в искупление, очищай жертвенник этим искупительным [приношением] и совершай помазание, чтобы освятить его.',
    verse_ivrit: 'וּפַר חַטָּאת תַּעֲשֶׂה לַיּוֹם עַל-הַכִּפֻּרִים וְחִטֵּאתָ עַל-הַמִּזְבֵּחַ בְּכַפֶּרְךָ עָלָיו וּמָשַׁחְתָּ אֹתוֹ לְקַדְּשׁוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'Семь дней очищай жертвенник, а когда ты его освятишь, жертвенник станет святыней святынь. Все, что коснется жертвенника, освятится.',
    verse_ivrit: 'שִׁבְעַת יָמִים תְּכַפֵּר עַל-הַמִּזְבֵּחַ וְקִדַּשְׁתָּ אֹתוֹ וְהָיָה הַמִּזְבֵּחַ קֹדֶשׁ קָדָשִׁים כָּל-הַנֹּגֵעַ בַּמִּזְבֵּחַ יִקְדָּשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 38,
    verse: 'Вот что ты должен постоянно приносить на жертвеннике — двух годовалых ягнят в день.',
    verse_ivrit: 'וְזֶה אֲשֶׁר תַּעֲשֶׂה עַל-הַמִּזְבֵּחַ כְּבָשִׂים בְּנֵי-שָׁנָה שְׁנַיִם לַיּוֹם תָּמִיד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 39,
    verse: 'Одного ягненка приноси [в жертву] поутру, а другого ягненка приноси в сумерки.',
    verse_ivrit: 'אֶת-הַכֶּבֶשׂ הָאֶחָד תַּעֲשֶׂה בַבֹּקֶר וְאֵת הַכֶּבֶשׂ הַשֵּׁנִי תַּעֲשֶׂה בֵּין הָעַרְבָּיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 40,
    verse: 'Десятая часть [эйфы] мелко смолотой муки, смешанная с четвертью ѓина выбитого [оливкового] масла, и возлияние четверти ѓина вина — на одного ягненка.',
    verse_ivrit: 'וְעִשָּׂרֹן סֹלֶת בָּלוּל בְּשֶׁמֶן כָּתִית רֶבַע הַהִין וְנֵסֶךְ רְבִיעִת הַהִין יָיִן לַכֶּבֶשׂ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 41,
    verse: 'А другого ягненка приноси [в жертву] в сумерки — с таким же приношением и возлиянием, как и утром. Это — приятное благоухание, огненное жертвоприношение Господу.',
    verse_ivrit: 'וְאֵת הַכֶּבֶשׂ הַשֵּׁנִי תַּעֲשֶׂה בֵּין הָעַרְבָּיִם כְּמִנְחַת הַבֹּקֶר וּכְנִסְכָּהּ תַּעֲשֶׂה-לָּהּ לְרֵיחַ נִיחֹחַ אִשֶּׁה לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 42,
    verse: '[Это] постоянная жертва всесожжения, из поколения в поколение. [Приноси ее] у входа в Шатер Встречи, пред Господом, — там, где Я буду являться вам и говорить с тобою.',
    verse_ivrit: 'עֹלַת תָּמִיד לְדֹרֹתֵיכֶם פֶּתַח אֹהֶל-מוֹעֵד לִפְנֵי יְהוָה אֲשֶׁר אִוָּעֵד לָכֶם שָׁמָּה לְדַבֵּר אֵלֶיךָ שָׁם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 43,
    verse: 'Там Я буду являться сынам Израиля — и освятится [то место] Моей славой.',
    verse_ivrit: 'וְנֹעַדְתִּי שָׁמָּה לִבְנֵי יִשְׂרָאֵל וְנִקְדַּשׁ בִּכְבֹדִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 44,
    verse: 'Я освящу Шатер Встречи и жертвенник, освящу Аѓарона и его сыновей, чтобы они служили Мне.',
    verse_ivrit: 'וְקִדַּשְׁתִּי אֶת-אֹהֶל מוֹעֵד וְאֶת-הַמִּזְבֵּחַ וְאֶת-אַהֲרֹן וְאֶת-בָּנָיו אֲקַדֵּשׁ לְכַהֵן לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 45,
    verse: 'Я буду обитать среди сынов Израиля и буду им Богом.',
    verse_ivrit: 'וְשָׁכַנְתִּי בְּתוֹךְ בְּנֵי יִשְׂרָאֵל וְהָיִיתִי לָהֶם לֵאלֹהִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 29,
    id_chapter_two: 1,
    poemNumber: 46,
    verse: 'И узнают они, что Я — Господь, их Бог, Который вывел их из земли египетской, чтобы обитать среди них. Я — Господь, их Бог.',
    verse_ivrit: 'וְיָדְעוּ כִּי אֲנִי יְהוָה אֱלֹהֵיהֶם אֲשֶׁר הוֹצֵאתִי אֹתָם מֵאֶרֶץ מִצְרַיִם לְשָׁכְנִי בְתוֹכָם אֲנִי יְהוָה אֱלֹהֵיהֶם',
    comment: '',
  },
  //////////////////////////////////////           303030303030330303030033030330303030303030          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Сделай жертвенник для воскурения благовоний — сделай его из дерева акации.',
    verse_ivrit: 'וְעָשִׂיתָ מִזְבֵּחַ מִקְטַר קְטֹרֶת עֲצֵי שִׁטִּים תַּעֲשֶׂה אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Он должен быть квадратным: локоть в длину, локоть в ширину, а в высоту — два локтя. Выступы на его углах должны составлять с ним единое целое.',
    verse_ivrit: 'אַמָּה אָרְכּוֹ וְאַמָּה רָחְבּוֹ רָבוּעַ יִהְיֶה וְאַמָּתַיִם קֹמָתוֹ מִמֶּנּוּ קַרְנֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Покрой его чистым золотом — верх, стороны вокруг и выступы — и сделай вокруг него золотую кайму.',
    verse_ivrit: 'וְצִפִּיתָ אֹתוֹ זָהָב טָהוֹר אֶת-גַּגּוֹ וְאֶת-קִירֹתָיו סָבִיב וְאֶת-קַרְנֹתָיו וְעָשִׂיתָ לּוֹ זֵר זָהָב סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Приделай к нему два золотых кольца — под каймой, на двух его сторонах, с обоих боков, — чтобы вставлять в них шесты и носить его на них.',
    verse_ivrit: 'וּשְׁתֵּי טַבְּעֹת זָהָב תַּעֲשֶׂה-לּוֹ מִתַּחַת לְזֵרוֹ עַל שְׁתֵּי צַלְעֹתָיו תַּעֲשֶׂה עַל-שְׁנֵי צִדָּיו וְהָיָה לְבָתִּים לְבַדִּים לָשֵׂאת אֹתוֹ בָּהֵמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Сделай шесты из дерева акации и покрой их золотом.',
    verse_ivrit: 'וְעָשִׂיתָ אֶת-הַבַּדִּים עֲצֵי שִׁטִּים וְצִפִּיתָ אֹתָם זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Поставь [этот жертвенник] перед пологом, за которым находится ковчег Свидетельства, — перед крышкой, что над Свидетельством, где Я буду являться тебе.',
    verse_ivrit: 'וְנָתַתָּה אֹתוֹ לִפְנֵי הַפָּרֹכֶת אֲשֶׁר עַל-אֲרֹן הָעֵדֻת לִפְנֵי הַכַּפֹּרֶת אֲשֶׁר עַל-הָעֵדֻת אֲשֶׁר אִוָּעֵד לְךָ שָׁמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Аѓарон будет воскурять на нем благовония. Он будет воскурять их каждое утро, [приходя] приводить в порядок лампы.',
    verse_ivrit: 'וְהִקְטִיר עָלָיו אַהֲרֹן קְטֹרֶת סַמִּים בַּבֹּקֶר בַּבֹּקֶר בְּהֵיטִיבוֹ אֶת-הַנֵּרֹת יַקְטִירֶנָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'И в сумерки, когда Аѓарон станет зажигать лампы, он будет воскурять [благовония]. Это — постоянное воскурение пред Господом, из поколения в поколение.',
    verse_ivrit: 'וּבְהַעֲלֹת אַהֲרֹן אֶת-הַנֵּרֹת בֵּין הָעַרְבַּיִם יַקְטִירֶנָּה קְטֹרֶת תָּמִיד לִפְנֵי יְהוָה לְדֹרֹתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Ни чуждого воскурения, ни всесожжения, ни приношения не совершайте на [этом жертвеннике] — и не делайте на нем возлияния.',
    verse_ivrit: 'לֹא-תַעֲלוּ עָלָיו קְטֹרֶת זָרָה וְעֹלָה וּמִנְחָה וְנֵסֶךְ לֹא תִסְּכוּ עָלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТЕЦАВЕ(Повели)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Один раз в год Аѓарон будет совершать [обряд] искупления над выступами [жертвенника]. Раз в год, из поколения в поколение, он будет совершать на нем искупление кровью очистительной жертвы искупления. [Этот жертвенник] — святыня святынь у Господа”. ',
    verse_ivrit: 'וְכִפֶּר אַהֲרֹן עַל-קַרְנֹתָיו אַחַת בַּשָּׁנָה מִדַּם חַטַּאת הַכִּפֻּרִים אַחַת בַּשָּׁנָה יְכַפֵּר עָלָיו לְדֹרֹתֵיכֶם קֹדֶשׁ-קָדָשִׁים הוּא לַיהוָה',
    comment: '',
  },
  //////////////////////////////////////           303030303030330303030033030330303030303030          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 11,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 12,
    verse: '“Когда будешь делать перепись сынов Израиля и пересчитывать их, то пусть при пересчете каждый даст выкуп Господу за свою душу, чтобы не было среди них мора при пересчете.',
    verse_ivrit: 'כִּי תִשָּׂא אֶת-רֹאשׁ בְּנֵי-יִשְׂרָאֵל לִפְקֻדֵיהֶם וְנָתְנוּ אִישׁ כֹּפֶר נַפְשׁוֹ לַיהוָה בִּפְקֹד אֹתָם וְלֹא-יִהְיֶה בָהֶם נֶגֶף בִּפְקֹד אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 13,
    verse: 'Пусть каждый проходящий подсчет отдает половину шекеля, по [весу] священных шекелей (в таком шекеле — двадцать гер). Эта половина шекеля — дар Господу.',
    verse_ivrit: 'זֶה יִתְּנוּ כָּל-הָעֹבֵר עַל-הַפְּקֻדִים מַחֲצִית הַשֶּׁקֶל בְּשֶׁקֶל הַקֹּדֶשׁ עֶשְׂרִים גֵּרָה הַשֶּׁקֶל מַחֲצִית הַשֶּׁקֶל תְּרוּמָה לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 14,
    verse: 'Всякий, кто подлежит пересчету, — от двадцати лет и старше — должен принести дар Господу.',
    verse_ivrit: 'כֹּל הָעֹבֵר עַל-הַפְּקֻדִים מִבֶּן עֶשְׂרִים שָׁנָה וָמָעְלָה יִתֵּן תְּרוּמַת יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 15,
    verse: 'Богатый не должен давать больше, а бедный — меньше, чем половина шекеля; [так вы] принесете дар Господу для искупления ваших душ.',
    verse_ivrit: 'הֶעָשִׁיר לֹא-יַרְבֶּה וְהַדַּל לֹא יַמְעִיט מִמַּחֲצִית הַשָּׁקֶל לָתֵת אֶת-תְּרוּמַת יְהוָה לְכַפֵּר עַל-נַפְשֹׁתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 16,
    verse: 'Возьми у сынов Израиля серебро выкупа и передай его на служение в Шатре Встречи. Пусть это будет напоминанием пред Господом о сынах Израиля, чтобы искупить ваши души”. ',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-כֶּסֶף הַכִּפֻּרִים מֵאֵת בְּנֵי יִשְׂרָאֵל וְנָתַתָּ אֹתוֹ עַל-עֲבֹדַת אֹהֶל מוֹעֵד וְהָיָה לִבְנֵי יִשְׂרָאֵל לְזִכָּרוֹן לִפְנֵי יְהוָה לְכַפֵּר עַל-נַפְשֹׁתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 17,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 18,
    verse: '“Сделай для омовения медный чан, а под него — медную подставку, и помести его между Шатром Встречи и жертвенником, и налей в него воду.',
    verse_ivrit: 'וְעָשִׂיתָ כִּיּוֹר נְחֹשֶׁת וְכַנּוֹ נְחֹשֶׁת לְרָחְצָה וְנָתַתָּ אֹתוֹ בֵּין-אֹהֶל מוֹעֵד וּבֵין הַמִּזְבֵּחַ וְנָתַתָּ שָׁמָּה מָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 19,
    verse: 'Пусть Аѓарон и его сыновья омывают из него свои руки и ноги.',
    verse_ivrit: 'וְרָחֲצוּ אַהֲרֹן וּבָנָיו מִמֶּנּוּ אֶת-יְדֵיהֶם וְאֶת-רַגְלֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 20,
    verse: 'Они должны омываться водой, чтобы не умереть, когда будут входить в Шатер Встречи или подходить к жертвеннику для служения — для свершения огненных жертвоприношений Господу.',
    verse_ivrit: 'בְּבֹאָם אֶל-אֹהֶל מוֹעֵד יִרְחֲצוּ-מַיִם וְלֹא יָמֻתוּ אוֹ בְגִשְׁתָּם אֶל-הַמִּזְבֵּחַ לְשָׁרֵת לְהַקְטִיר אִשֶּׁה לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 21,
    verse: 'Пусть они омывают руки и ноги, чтобы не умереть. Это будет вечным законом для них — для [Аѓарона] и его потомков — из поколения в поколение”.',
    verse_ivrit: 'וְרָחֲצוּ יְדֵיהֶם וְרַגְלֵיהֶם וְלֹא יָמֻתוּ וְהָיְתָה לָהֶם חָק-עוֹלָם לוֹ וּלְזַרְעוֹ לְדֹרֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 22,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 23,
    verse: '“Возьми себе лучшие благовония: текучей мирры — пятьсот [шекелей], благовонной корицы вдвое меньше — двести пятьдесят [шекелей], благовонного тростника — двести пятьдесят,',
    verse_ivrit: 'וְאַתָּה קַח-לְךָ בְּשָׂמִים רֹאשׁ מָר-דְּרוֹר חֲמֵשׁ מֵאוֹת וְקִנְּמָן-בֶּשֶׂם מַחֲצִיתוֹ חֲמִשִּׁים וּמָאתָיִם וּקְנֵה-בֹשֶׂם חֲמִשִּׁים וּמָאתָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 24,
    verse: 'кассии — пятьсот, [всё] в священных шекелях, а также ѓин оливкового масла.',
    verse_ivrit: 'וְקִדָּה חֲמֵשׁ מֵאוֹת בְּשֶׁקֶל הַקֹּדֶשׁ וְשֶׁמֶן זַיִת הִין',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 25,
    verse: 'Из всего этого сделай масло для священного помазания, [масло] смешанного состава, работы [искусного] составителя смесей. Это будет масло для священного помазания.',
    verse_ivrit: 'וְעָשִׂיתָ אֹתוֹ שֶׁמֶן מִשְׁחַת-קֹדֶשׁ רֹקַח מִרְקַחַת מַעֲשֵׂה רֹקֵחַ שֶׁמֶן מִשְׁחַת-קֹדֶשׁ יִהְיֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 26,
    verse: 'Помажь им Шатер Встречи и ковчег Свидетельства,',
    verse_ivrit: 'וּמָשַׁחְתָּ בוֹ אֶת-אֹהֶל מוֹעֵד וְאֵת אֲרוֹן הָעֵדֻת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 27,
    verse: 'стол и все его принадлежности, светильник и все его принадлежности, жертвенник для воскурений,',
    verse_ivrit: 'וְאֶת-הַשֻּׁלְחָן וְאֶת-כָּל-כֵּלָיו וְאֶת-הַמְּנֹרָה וְאֶת-כֵּלֶיהָ וְאֵת מִזְבַּח הַקְּטֹרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 28,
    verse: 'жертвенник для всесожжений и все его принадлежности, чан и подставку под него.',
    verse_ivrit: 'וְאֶת-מִזְבַּח הָעֹלָה וְאֶת-כָּל-כֵּלָיו וְאֶת-הַכִּיֹּר וְאֶת-כַּנּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 29,
    verse: 'Освяти их, и они станут святыней святынь: все, что их коснется, освятится.',
    verse_ivrit: 'וְקִדַּשְׁתָּ אֹתָם וְהָיוּ קֹדֶשׁ קָדָשִׁים כָּל-הַנֹּגֵעַ בָּהֶם יִקְדָּשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 30,
    verse: 'Помажь Аѓарона с сыновьями и освяти их для священства Мне.',
    verse_ivrit: 'וְאֶת-אַהֲרֹן וְאֶת-בָּנָיו תִּמְשָׁח וְקִדַּשְׁתָּ אֹתָם לְכַהֵן לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 31,
    verse: 'А сынам Израиля передай: ‘Это будет у Меня маслом для священного помазания, из поколения в поколение.',
    verse_ivrit: 'וְאֶל-בְּנֵי יִשְׂרָאֵל תְּדַבֵּר לֵאמֹר שֶׁמֶן מִשְׁחַת-קֹדֶשׁ יִהְיֶה זֶה לִי לְדֹרֹתֵיכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 32,
    verse: 'Не возливайте его на тело человека и подобного ему по составу не делайте. Свято оно, пусть оно будет для вас святыней.',
    verse_ivrit: 'עַל-בְּשַׂר אָדָם לֹא יִיסָךְ וּבְמַתְכֻּנְתּוֹ לֹא תַעֲשׂוּ כָּמֹהוּ קֹדֶשׁ הוּא קֹדֶשׁ יִהְיֶה לָכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 33,
    verse: 'Тот, кто составит ему подобное или умастит им чужого, будет истреблен из своего народа’”.',
    verse_ivrit: 'אִישׁ אֲשֶׁר יִרְקַח כָּמֹהוּ וַאֲשֶׁר יִתֵּן מִמֶּנּוּ עַל-זָר וְנִכְרַת מֵעַמָּיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 34,
    verse: 'Господь сказал Моше: “Возьми себе благовоний — бальзама, ониха и гальбана, пряностей и чистого ладана — поровну,',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה קַח-לְךָ סַמִּים נָטָף וּשְׁחֵלֶת וְחֶלְבְּנָה סַמִּים וּלְבֹנָה זַכָּה בַּד בְּבַד יִהְיֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 35,
    verse: 'и сделай из этого искусно приготовленное воскурение, смешанное с солью, чистое, святое.',
    verse_ivrit: 'וְעָשִׂיתָ אֹתָהּ קְטֹרֶת רֹקַח מַעֲשֵׂה רוֹקֵחַ מְמֻלָּח טָהוֹר קֹדֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 36,
    verse: 'Мелко истолки часть его и положи перед Свидетельством в Шатре Встречи, где Я буду являться тебе. Да будет это для вас святыней святынь.',
    verse_ivrit: 'וְשָׁחַקְתָּ מִמֶּנָּה הָדֵק וְנָתַתָּה מִמֶּנָּה לִפְנֵי הָעֵדֻת בְּאֹהֶל מוֹעֵד אֲשֶׁר אִוָּעֵד לְךָ שָׁמָּה קֹדֶשׁ קָדָשִׁים תִּהְיֶה לָכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 37,
    verse: 'Не делайте для себя воскурений с подобным составом: да будет оно у тебя святыней Господней.',
    verse_ivrit: 'וְהַקְּטֹרֶת אֲשֶׁר תַּעֲשֶׂה בְּמַתְכֻּנְתָּהּ לֹא תַעֲשׂוּ לָכֶם קֹדֶשׁ תִּהְיֶה לְךָ לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 30,
    id_chapter_two: 2,
    poemNumber: 38,
    verse: 'Тот, кто сделает подобное ему, чтобы самому обонять его [запах], — истребится из своего народа”.',
    verse_ivrit: 'אִישׁ אֲשֶׁר-יַעֲשֶׂה כָמוֹהָ לְהָרִיחַ בָּהּ וְנִכְרַת מֵעַמָּיו',
    comment: '',
  },
  //////////////////////////////////////           31313131313131313131313131313131313131331          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '“Смотри, Я вызвал именно Бецалеля, сына Ури, сына Хура, из колена Йеѓуды.',
    verse_ivrit: 'רְאֵה קָרָאתִי בְשֵׁם בְּצַלְאֵל בֶּן-אוּרִי בֶן-חוּר לְמַטֵּה יְהוּדָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Я исполнил его духом Божьим — [дал ему] мудрость, понимание, знание и [мастерство] во всяком ремесле,',
    verse_ivrit: 'וָאֲמַלֵּא אֹתוֹ רוּחַ אֱלֹהִים בְּחָכְמָה וּבִתְבוּנָה וּבְדַעַת וּבְכָל-מְלָאכָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: '[способность] вынашивать замыслы, работать по золоту, серебру и меди,',
    verse_ivrit: 'לַחְשֹׁב מַחֲשָׁבֹת לַעֲשׂוֹת בַּזָּהָב וּבַכֶּסֶף וּבַנְּחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'резать по камню для вставки [в оправу] и резать по дереву — заниматься любой работой.',
    verse_ivrit: 'וּבַחֲרֹשֶׁת אֶבֶן לְמַלֹּאת וּבַחֲרֹשֶׁת עֵץ לַעֲשׂוֹת בְּכָל-מְלָאכָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Я дал ему [в помощники] Оѓолиава, сына Ахисамаха, из колена Дана, и вложил мудрость в сердце всякого мудрого сердцем. Пусть они сделают все, что Я тебе повелел, —',
    verse_ivrit: 'וַאֲנִי הִנֵּה נָתַתִּי אִתּוֹ אֵת אָהֳלִיאָב בֶּן-אֲחִיסָמָךְ לְמַטֵּה-דָן וּבְלֵב כָּל-חֲכַם-לֵב נָתַתִּי חָכְמָה וְעָשׂוּ אֵת כָּל-אֲשֶׁר צִוִּיתִךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Шатер Встречи, ковчег Свидетельства, крышку на нем, все принадлежности Шатра,',
    verse_ivrit: 'אֵת אֹהֶל מוֹעֵד וְאֶת-הָאָרֹן לָעֵדֻת וְאֶת-הַכַּפֹּרֶת אֲשֶׁר עָלָיו וְאֵת כָּל-כְּלֵי הָאֹהֶל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'стол и все его принадлежности, светильник из чистого [золота] и все его принадлежности, жертвенник для воскурений,',
    verse_ivrit: 'וְאֶת-הַשֻּׁלְחָן וְאֶת-כֵּלָיו וְאֶת-הַמְּנֹרָה הַטְּהֹרָה וְאֶת-כָּל-כֵּלֶיהָ וְאֵת מִזְבַּח הַקְּטֹרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'жертвенник для всесожжений и все его принадлежности, чан и подставку под него,',
    verse_ivrit: 'וְאֶת-מִזְבַּח הָעֹלָה וְאֶת-כָּל-כֵּלָיו וְאֶת-הַכִּיּוֹר וְאֶת-כַּנּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'священные одежды Аѓарона-священника и одежды его сыновей для служения,',
    verse_ivrit: 'וְאֵת בִּגְדֵי הַשְּׂרָד וְאֶת-בִּגְדֵי הַקֹּדֶשׁ לְאַהֲרֹן הַכֹּהֵן וְאֶת-בִּגְדֵי בָנָיו לְכַהֵן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'масло для помазания и благовония для Святилища. Пусть они все сделают так, как Я тебе повелел”.',
    verse_ivrit: 'וְאֵת שֶׁמֶן הַמִּשְׁחָה וְאֶת-קְטֹרֶת הַסַּמִּים לַקֹּדֶשׁ כְּכֹל אֲשֶׁר-צִוִּיתִךָ יַעֲשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: '“А ты передай сынам Израиля: ‘Субботы же Мои соблюдайте, ведь это — знак между Мною и вами, из поколения в поколение, чтобы знали [вы], что Я — Господь, освящающий вас.',
    verse_ivrit: 'וְאַתָּה דַּבֵּר אֶל-בְּנֵי יִשְׂרָאֵל לֵאמֹר אַךְ אֶת-שַׁבְּתֹתַי תִּשְׁמֹרוּ כִּי אוֹת הִוא בֵּינִי וּבֵינֵיכֶם לְדֹרֹתֵיכֶם לָדַעַת כִּי אֲנִי יְהוָה מְקַדִּשְׁכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Соблюдайте субботу, ведь она для вас — святыня. Тот, кто ее нарушит, должен быть предан смерти. Душа каждого, кто станет работать в этот день, будет отторгнута от своего народа’.',
    verse_ivrit: 'וּשְׁמַרְתֶּם אֶת-הַשַּׁבָּת כִּי קֹדֶשׁ הִוא לָכֶם מְחַלְלֶיהָ מוֹת יוּמָת כִּי כָּל-הָעֹשֶׂה בָהּ מְלָאכָה וְנִכְרְתָה הַנֶּפֶשׁ הַהִוא מִקֶּרֶב עַמֶּיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Шесть дней следует делать работу, а седьмой день — суббота покоя, [этот день] свят для Господа. Каждый, кто делает работу в субботний день, должен быть предан смерти.',
    verse_ivrit: 'שֵׁשֶׁת יָמִים יֵעָשֶׂה מְלָאכָה וּבַיּוֹם הַשְּׁבִיעִי שַׁבַּת שַׁבָּתוֹן קֹדֶשׁ לַיהוָה כָּל-הָעֹשֶׂה מְלָאכָה בְּיוֹם הַשַּׁבָּת מוֹת יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Пусть сыны Израиля соблюдают субботу, сделав субботу вечным союзом [со Мною] для всех поколений.',
    verse_ivrit: 'וְשָׁמְרוּ בְנֵי-יִשְׂרָאֵל אֶת-הַשַּׁבָּת לַעֲשׂוֹת אֶת-הַשַּׁבָּת לְדֹרֹתָם בְּרִית עוֹלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Между Мной и сынами Израиля она — знак навеки, ведь шесть дней Господь создавал небо и землю, а на седьмой день прекратил [созидание] и отдыхал”.',
    verse_ivrit: 'בֵּינִי וּבֵין בְּנֵי יִשְׂרָאֵל אוֹת הִוא לְעֹלָם כִּי-שֵׁשֶׁת יָמִים עָשָׂה יְהוָה אֶת-הַשָּׁמַיִם וְאֶת-הָאָרֶץ וּבַיּוֹם הַשְּׁבִיעִי שָׁבַת וַיִּנָּפַשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 31,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Закончив говорить с Моше на горе Синай, [Господь] дал ему две скрижали Свидетельства — каменные скрижали, исписанные перстом Божьим.',
    verse_ivrit: 'וַיִּתֵּן אֶל-מֹשֶׁה כְּכַלֹּתוֹ לְדַבֵּר אִתּוֹ בְּהַר סִינַי שְׁנֵי לֻחֹת הָעֵדֻת לֻחֹת אֶבֶן כְּתֻבִים בְּאֶצְבַּע אֱלֹהִים',
    comment: '',
  },
  //////////////////////////////////////           3232322323232323232323232323232323232323232          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Народ увидел, что Моше медлит спуститься с горы. Обступил народ Аѓарона, и сказали они ему: — Приступи [к действиям]! Сделай нам бога, который пойдет перед нами, [ведя нас за собой]. А этот человек, Моше, который вывел нас из земли египетской, — мы не знаем, что с ним!',
    verse_ivrit: 'וַיַּרְא הָעָם כִּי-בֹשֵׁשׁ מֹשֶׁה לָרֶדֶת מִן-הָהָר וַיִּקָּהֵל הָעָם עַל-אַהֲרֹן וַיֹּאמְרוּ אֵלָיו קוּם עֲשֵׂה-לָנוּ אֱלֹהִים אֲשֶׁר יֵלְכוּ לְפָנֵינוּ כִּי-זֶה מֹשֶׁה הָאִישׁ אֲשֶׁר הֶעֱלָנוּ מֵאֶרֶץ מִצְרַיִם לֹא יָדַעְנוּ מֶה-הָיָה לוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Сказал им Аѓарон: — Снимите золотые серьги, которые в ушах ваших жен, сыновей и дочерей, и принесите мне.',
    verse_ivrit: 'וַיֹּאמֶר אֲלֵהֶם אַהֲרֹן פָּרְקוּ נִזְמֵי הַזָּהָב אֲשֶׁר בְּאָזְנֵי נְשֵׁיכֶם בְּנֵיכֶם וּבְנֹתֵיכֶם וְהָבִיאוּ אֵלָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Люди вынули золотые серьги из своих ушей и принесли Аѓарону.',
    verse_ivrit: 'וַיִּתְפָּרְקוּ כָּל-הָעָם אֶת-נִזְמֵי הַזָּהָב אֲשֶׁר בְּאָזְנֵיהֶם וַיָּבִיאוּ אֶל-אַהֲרֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Тот взял у них [золото], обработал его резцом и сделал из него литого тельца. А они сказали: “Вот твой бог, Израиль, [бог,] который вывел тебя из земли египетской!”',
    verse_ivrit: 'וַיִּקַּח מִיָּדָם וַיָּצַר אֹתוֹ בַּחֶרֶט וַיַּעֲשֵׂהוּ עֵגֶל מַסֵּכָה וַיֹּאמְרוּ אֵלֶּה אֱלֹהֶיךָ יִשְׂרָאֵל אֲשֶׁר הֶעֱלוּךָ מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Увидел Аѓарон [все это], построил перед [тельцом] жертвенник и провозгласил: “Завтра — праздник Господу!”',
    verse_ivrit: 'וַיַּרְא אַהֲרֹן וַיִּבֶן מִזְבֵּחַ לְפָנָיו וַיִּקְרָא אַהֲרֹן וַיֹּאמַר חַג לַיהוָה מָחָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'На следующий день они встали рано утром и принесли жертвы всесожжения и мирные жертвы. Люди сели, стали есть и пить, а потом принялись веселиться. ',
    verse_ivrit: 'וַיַּשְׁכִּימוּ מִמָּחֳרָת וַיַּעֲלוּ עֹלֹת וַיַּגִּשׁוּ שְׁלָמִים וַיֵּשֶׁב הָעָם לֶאֱכֹל וְשָׁתוֹ וַיָּקֻמוּ לְצַחֵק',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Господь сказал Моше: — Ступай, спустись [c горы], ибо развратился твой народ, который ты вывел из земли египетской.',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֶךְ-רֵד כִּי שִׁחֵת עַמְּךָ אֲשֶׁר הֶעֱלֵיתָ מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Быстро же они уклонились от пути, который им заповедан, — сделали себе литого тельца, поклонились ему, принесли ему жертвы и сказали: “Вот твой бог, Израиль, [бог,] который вывел тебя из земли египетской!”',
    verse_ivrit: 'סָרוּ מַהֵר מִן-הַדֶּרֶךְ אֲשֶׁר צִוִּיתִם עָשׂוּ לָהֶם עֵגֶל מַסֵּכָה וַיִּשְׁתַּחֲווּ-לוֹ וַיִּזְבְּחוּ-לוֹ וַיֹּאמְרוּ אֵלֶּה אֱלֹהֶיךָ יִשְׂרָאֵל אֲשֶׁר הֶעֱלוּךָ מֵאֶרֶץ מִצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Господь сказал Моше: — Я вижу, что это строптивый народ!',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה רָאִיתִי אֶת-הָעָם הַזֶּה וְהִנֵּה עַם-קְשֵׁה-עֹרֶף הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'А теперь оставь Меня — и Мой гнев возгорится на них, и Я их истреблю, а тебя сделаю великим народом.',
    verse_ivrit: 'וְעַתָּה הַנִּיחָה לִּי וְיִחַר-אַפִּי בָהֶם וַאֲכַלֵּם וְאֶעֱשֶׂה אוֹתְךָ לְגוֹי גָּדוֹל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Но Моше стал умолять Господа, своего Бога, и сказал: — Зачем, о Господь, Ты разгневался на Свой народ, который Ты увел из земли египетской великой мощью и крепкой рукой?',
    verse_ivrit: 'וַיְחַל מֹשֶׁה אֶת-פְּנֵי יְהוָה אֱלֹהָיו וַיֹּאמֶר לָמָה יְהוָה יֶחֱרֶה אַפְּךָ בְּעַמֶּךָ אֲשֶׁר הוֹצֵאתָ מֵאֶרֶץ מִצְרַיִם בְּכֹחַ גָּדוֹל וּבְיָד חֲזָקָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Зачем [нужно], чтобы египтяне говорили: “Со злым [умыслом] Он увел их — чтобы погубить их в горах и истребить с лица земли”? Отвратись же от Своего гнева и откажись [делать] зло Твоему народу!',
    verse_ivrit: 'לָמָּה יֹאמְרוּ מִצְרַיִם לֵאמֹר בְּרָעָה הוֹצִיאָם לַהֲרֹג אֹתָם בֶּהָרִים וּלְכַלֹּתָם מֵעַל פְּנֵי הָאֲדָמָה שׁוּב מֵחֲרוֹן אַפֶּךָ וְהִנָּחֵם עַל-הָרָעָה לְעַמֶּךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Вспомни о рабах Твоих — Авраѓаме, Ицхаке и Израиле, — которым Ты поклялся Самим Собою и говорил им: “Ваше потомство Я сделаю многочисленным, как звезды небесные, а всю эту землю, о которой Я говорил, отдам вашему потомству в вечное владение!”',
    verse_ivrit: 'זְכֹר לְאַבְרָהָם לְיִצְחָק וּלְיִשְׂרָאֵל עֲבָדֶיךָ אֲשֶׁר נִשְׁבַּעְתָּ לָהֶם בָּךְ וַתְּדַבֵּר אֲלֵהֶם אַרְבֶּה אֶת-זַרְעֲכֶם כְּכוֹכְבֵי הַשָּׁמָיִם וְכָל-הָאָרֶץ הַזֹּאת אֲשֶׁר אָמַרְתִּי אֶתֵּן לְזַרְעֲכֶם וְנָחֲלוּ לְעֹלָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'И Господь отказался причинять зло, которым Он угрожал Своему народу. ',
    verse_ivrit: 'וַיִּנָּחֶם יְהוָה עַל-הָרָעָה אֲשֶׁר דִּבֶּר לַעֲשׂוֹת לְעַמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Моше повернулся и спустился с горы, [держа] в руках две скрижали Свидетельства, скрижали с письменами на обеих сторонах — они были исписаны и с той, и с другой [стороны].',
    verse_ivrit: 'וַיִּפֶן וַיֵּרֶד מֹשֶׁה מִן-הָהָר וּשְׁנֵי לֻחֹת הָעֵדֻת בְּיָדוֹ לֻחֹת כְּתֻבִים מִשְּׁנֵי עֶבְרֵיהֶם מִזֶּה וּמִזֶּה הֵם כְּתֻבִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Эти скрижали были деянием Бога, а письмена [на них] — письменами Бога, начертанными на скрижалях.',
    verse_ivrit: 'וְהַלֻּחֹת מַעֲשֵׂה אֱלֹהִים הֵמָּה וְהַמִּכְתָּב מִכְתַּב אֱלֹהִים הוּא חָרוּת עַל-הַלֻּחֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Йеѓошуа услышал шум буйствующего народа и сказал Моше: “В стане — звуки битвы!”',
    verse_ivrit: 'וַיִּשְׁמַע יְהוֹשֻׁעַ אֶת-קוֹל הָעָם בְּרֵעֹה וַיֹּאמֶר אֶל-מֹשֶׁה קוֹל מִלְחָמָה בַּמַּחֲנֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Но тот сказал: “Это не победные клики и не вопли поражения. Я слышу глумливые крики!”',
    verse_ivrit: 'וַיֹּאמֶר אֵין קוֹל עֲנוֹת גְּבוּרָה וְאֵין קוֹל עֲנוֹת חֲלוּשָׁה קוֹל עַנּוֹת אָנֹכִי שֹׁמֵעַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Приблизившись к стану, [Моше] увидел тельца и пляски. Возгорелся гнев Моше, и он бросил [наземь] скрижали, [которые нес] в руках, и разбил их у подножия горы.',
    verse_ivrit: 'וַיְהִי כַּאֲשֶׁר קָרַב אֶל-הַמַּחֲנֶה וַיַּרְא אֶת-הָעֵגֶל וּמְחֹלֹת וַיִּחַר-אַף מֹשֶׁה וַיַּשְׁלֵךְ מִיָּדָו אֶת-הַלֻּחֹת וַיְשַׁבֵּר אֹתָם תַּחַת הָהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Он взял тельца, которого они сделали, сжег его в огне, [оставшееся] растер в порошок — и, рассеяв по воде, дал пить сынам Израиля.',
    verse_ivrit: 'וַיִּקַּח אֶת-הָעֵגֶל אֲשֶׁר עָשׂוּ וַיִּשְׂרֹף בָּאֵשׁ וַיִּטְחַן עַד אֲשֶׁר-דָּק וַיִּזֶר עַל-פְּנֵי הַמַּיִם וַיַּשְׁקְ אֶת-בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Моше сказал Аѓарону: — Что сделал тебе этот народ, что ты вовлек его в [столь] великий грех?',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-אַהֲרֹן מֶה-עָשָׂה לְךָ הָעָם הַזֶּה כִּי-הֵבֵאתָ עָלָיו חֲטָאָה גְדֹלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: '— Да не возгорится гнев моего господина! — ответил Аѓарон. — Ты же знаешь, что этот народ [склонен] ко злу.',
    verse_ivrit: 'וַיֹּאמֶר אַהֲרֹן אַל-יִחַר אַף אֲדֹנִי אַתָּה יָדַעְתָּ אֶת-הָעָם כִּי בְרָע הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Они мне сказали: “Сделай нам бога, который шел бы перед нами, [ведя нас за собой], ведь этот человек, Моше, который вывел нас из земли египетской, — мы не знаем, что с ним!”',
    verse_ivrit: 'וַיֹּאמְרוּ לִי עֲשֵׂה-לָנוּ אֱלֹהִים אֲשֶׁר יֵלְכוּ לְפָנֵינוּ כִּי-זֶה מֹשֶׁה הָאִישׁ אֲשֶׁר הֶעֱלָנוּ מֵאֶרֶץ מִצְרַיִם לֹא יָדַעְנוּ מֶה-הָיָה לוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'И я сказал им: “У кого есть золото, снимите с себя”. Они отдали мне [золото], я бросил его в огонь — и получился этот телец!',
    verse_ivrit: '',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Моше увидел, что народ развращен, что Аѓарон распустил его — врагам на посмешище.',
    verse_ivrit: 'וַיַּרְא מֹשֶׁה אֶת-הָעָם כִּי פָרֻעַ הוּא כִּי-פְרָעֹה אַהֲרֹן לְשִׁמְצָה בְּקָמֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Тогда Моше стал в воротах стана и воззвал: “Кто за Господа — ко мне!” И к нему собрались все сыны Леви.',
    verse_ivrit: 'וַיַּעֲמֹד מֹשֶׁה בְּשַׁעַר הַמַּחֲנֶה וַיֹּאמֶר מִי לַיהוָה אֵלָי וַיֵּאָסְפוּ אֵלָיו כָּל-בְּנֵי לֵוִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Сказал он им: — Так говорит Господь, Бог Израиля: “Пусть каждый повесит меч на бедро — и пройдите по стану, туда и обратно, от ворот к воротам. Убивайте своих братьев, друзей и соседей!”',
    verse_ivrit: 'וַיֹּאמֶר לָהֶם כֹּה-אָמַר יְהוָה אֱלֹהֵי יִשְׂרָאֵל שִׂימוּ אִישׁ-חַרְבּוֹ עַל-יְרֵכוֹ עִבְרוּ וָשׁוּבוּ מִשַּׁעַר לָשַׁעַר בַּמַּחֲנֶה וְהִרְגוּ אִישׁ-אֶת-אָחִיו וְאִישׁ אֶת-רֵעֵהוּ וְאִישׁ אֶת-קְרֹבוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Сыны Леви поступили по слову Моше, и в тот день из народа было убито около трех тысяч человек.',
    verse_ivrit: 'וַיַּעֲשׂוּ בְנֵי-לֵוִי כִּדְבַר מֹשֶׁה וַיִּפֹּל מִן-הָעָם בַּיּוֹם הַהוּא כִּשְׁלֹשֶׁת אַלְפֵי אִישׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'И сказал Моше: — Сегодня посвятите ваши руки Господу, каждый — своим сыном и братом — чтобы ныне удостоиться благословения.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה מִלְאוּ יֶדְכֶם הַיּוֹם לַיהוָה כִּי אִישׁ בִּבְנוֹ וּבְאָחִיו וְלָתֵת עֲלֵיכֶם הַיּוֹם בְּרָכָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'На следующий день Моше сказал народу: — Вы совершили великий грех. Теперь я взойду к Господу; может быть, мне [удастся] искупить ваш грех.',
    verse_ivrit: 'וַיְהִי מִמָּחֳרָת וַיֹּאמֶר מֹשֶׁה אֶל-הָעָם אַתֶּם חֲטָאתֶם חֲטָאָה גְדֹלָה וְעַתָּה אֶעֱלֶה אֶל-יְהוָה אוּלַי אֲכַפְּרָה בְּעַד חַטַּאתְכֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Моше вернулся к Господу и сказал: — Этот народ совершил великий грех: они сделали себе бога из золота.',
    verse_ivrit: 'וַיָּשָׁב מֹשֶׁה אֶל-יְהוָה וַיֹּאמַר אָנָּא חָטָא הָעָם הַזֶּה חֲטָאָה גְדֹלָה וַיַּעֲשׂוּ לָהֶם אֱלֹהֵי זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Простишь ли Ты их грех? А если нет, то сотри меня из Твоей книги, которую Ты написал!',
    verse_ivrit: 'וְעַתָּה אִם-תִּשָּׂא חַטָּאתָם וְאִם-אַיִן מְחֵנִי נָא מִסִּפְרְךָ אֲשֶׁר כָּתָבְתָּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Господь сказал Моше: — Я сотру из Моей книги того, кто согрешил предо Мною.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה מִי אֲשֶׁר חָטָא-לִי אֶמְחֶנּוּ מִסִּפְרִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'А теперь иди, веди народ туда, куда Я тебе говорил. Мой ангел пойдет перед тобою. Но в день взыскания Я накажу их за грех.',
    verse_ivrit: 'וְעַתָּה לֵךְ נְחֵה אֶת-הָעָם אֶל אֲשֶׁר-דִּבַּרְתִּי לָךְ הִנֵּה מַלְאָכִי יֵלֵךְ לְפָנֶיךָ וּבְיוֹם פָּקְדִי וּפָקַדְתִּי עֲלֵהֶם חַטָּאתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 32,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Господь покарал народ за то, что они сделали тельца — того, которого изготовил Аѓарон.',
    verse_ivrit: 'וַיִּגֹּף יְהוָה אֶת-הָעָם עַל אֲשֶׁר עָשׂוּ אֶת-הָעֵגֶל אֲשֶׁר עָשָׂה אַהֲרֹן',
    comment: '',
  },
  //////////////////////////////////////           333333333333333333333333333333333333333          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Поднимись и, вместе с народом, который ты увел из земли египетской, иди в страну, о которой Я клялся Авраѓаму, Ицхаку и Яакову, говоря: “Твоему потомству Я отдам ее”.',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵךְ עֲלֵה מִזֶּה אַתָּה וְהָעָם אֲשֶׁר הֶעֱלִיתָ מֵאֶרֶץ מִצְרָיִם אֶל-הָאָרֶץ אֲשֶׁר נִשְׁבַּעְתִּי לְאַבְרָהָם לְיִצְחָק וּלְיַעֲקֹב לֵאמֹר לְזַרְעֲךָ אֶתְּנֶנָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Я пошлю перед тобой [Моего] ангела и изгоню ханаанеев, эмореев, хеттов, перизеев, хивеев и евусеев.',
    verse_ivrit: 'וְשָׁלַחְתִּי לְפָנֶיךָ מַלְאָךְ וְגֵרַשְׁתִּי אֶת-הַכְּנַעֲנִי הָאֱמֹרִי וְהַחִתִּי וְהַפְּרִזִּי הַחִוִּי וְהַיְבוּסִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: '[Идите же] в землю, источающую молоко и мед! Но Я с вами не пойду, чтобы не уничтожить вас в пути, ведь вы — строптивый народ!',
    verse_ivrit: 'אֶל-אֶרֶץ זָבַת חָלָב וּדְבָשׁ כִּי לֹא אֶעֱלֶה בְּקִרְבְּךָ כִּי עַם-קְשֵׁה-עֹרֶף אַתָּה פֶּן-אֲכֶלְךָ בַּדָּרֶךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Услышав эту дурную весть, народ погрузился в скорбь, и никто из них не надел своих украшений.',
    verse_ivrit: 'וַיִּשְׁמַע הָעָם אֶת-הַדָּבָר הָרָע הַזֶּה וַיִּתְאַבָּלוּ וְלֹא-שָׁתוּ אִישׁ עֶדְיוֹ עָלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Господь сказал Моше: — Скажи сынам Израиля: “Вы — строптивый народ! Если бы Я шел среди вас, хотя бы одно мгновение, Я бы вас уничтожил! Снимите свои украшения. Я решу, как Мне с вами поступить”.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה אֱמֹר אֶל-בְּנֵי-יִשְׂרָאֵל אַתֶּם עַם-קְשֵׁה-עֹרֶף רֶגַע אֶחָד אֶעֱלֶה בְקִרְבְּךָ וְכִלִּיתִיךָ וְעַתָּה הוֹרֵד עֶדְיְךָ מֵעָלֶיךָ וְאֵדְעָה מָה אֶעֱשֶׂה-לָּךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Сыны Израиля сняли свои украшения у горы Хорев.',
    verse_ivrit: 'וַיִּתְנַצְּלוּ בְנֵי-יִשְׂרָאֵל אֶת-עֶדְיָם מֵהַר חוֹרֵב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Моше взял шатер, поставил его поодаль, вне стана, и назвал его Шатром Встречи. Каждый, кто желал вопросить Господа, выходил к Шатру Встречи, что вне стана.',
    verse_ivrit: 'וּמֹשֶׁה יִקַּח אֶת-הָאֹהֶל וְנָטָה-לוֹ מִחוּץ לַמַּחֲנֶה הַרְחֵק מִן-הַמַּחֲנֶה וְקָרָא לוֹ אֹהֶל מוֹעֵד וְהָיָה כָּל-מְבַקֵּשׁ יְהוָה יֵצֵא אֶל-אֹהֶל מוֹעֵד אֲשֶׁר מִחוּץ לַמַּחֲנֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'А когда Моше выходил к Шатру, то весь народ вставал, каждый стоял у входа в свой шатер, и они смотрели вслед Моше, пока тот не входил в Шатер.',
    verse_ivrit: 'וְהָיָה כְּצֵאת מֹשֶׁה אֶל-הָאֹהֶל יָקוּמוּ כָּל-הָעָם וְנִצְּבוּ אִישׁ פֶּתַח אָהֳלוֹ וְהִבִּיטוּ אַחֲרֵי מֹשֶׁה עַד-בֹּאוֹ הָאֹהֱלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Как только Моше входил в Шатер, спускался облачный столп — и стоял у входа в Шатер, [пока Господь] говорил с Моше.',
    verse_ivrit: 'וְהָיָה כְּבֹא מֹשֶׁה הָאֹהֱלָה יֵרֵד עַמּוּד הֶעָנָן וְעָמַד פֶּתַח הָאֹהֶל וְדִבֶּר עִם-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Когда народ видел облачный столп у входа в Шатер, все падали ниц, каждый у входа в свой шатер.',
    verse_ivrit: 'וְרָאָה כָל-הָעָם אֶת-עַמּוּד הֶעָנָן עֹמֵד פֶּתַח הָאֹהֶל וְקָם כָּל-הָעָם וְהִשְׁתַּחֲווּ אִישׁ פֶּתַח אָהֳלוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Господь говорил с Моше лицом к лицу, как человек говорит со своим другом. [Затем Моше] возвращался в стан. Но юноша Йеѓошуа, сын Нуна, его слуга, не отлучался от Шатра. ',
    verse_ivrit: 'וְדִבֶּר יְהוָה אֶל-מֹשֶׁה פָּנִים אֶל-פָּנִים כַּאֲשֶׁר יְדַבֵּר אִישׁ אֶל-רֵעֵהוּ וְשָׁב אֶל-הַמַּחֲנֶה וּמְשָׁרְתוֹ יְהוֹשֻׁעַ בִּן-נוּן נַעַר לֹא יָמִישׁ מִתּוֹךְ הָאֹהֶל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Моше сказал Господу: — Смотри, вот Ты говоришь мне: “Веди этот народ!” Но Ты не сказал мне, кого Ты пошлешь со мной. А ведь Ты говорил: “Я знаю тебя по имени, и ты угоден Мне”.',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-יְהוָה רְאֵה אַתָּה אֹמֵר אֵלַי הַעַל אֶת-הָעָם הַזֶּה וְאַתָּה לֹא הוֹדַעְתַּנִי אֵת אֲשֶׁר-תִּשְׁלַח עִמִּי וְאַתָּה אָמַרְתָּ יְדַעְתִּיךָ בְשֵׁם וְגַם-מָצָאתָ חֵן בְּעֵינָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Так вот, если я Тебе угоден, то открой же мне Твои пути, дабы я познал Тебя — и заслужил Твое благоволение. Ведь эти люди — Твой народ!',
    verse_ivrit: 'וְעַתָּה אִם-נָא מָצָאתִי חֵן בְּעֵינֶיךָ הוֹדִעֵנִי נָא אֶת-דְּרָכֶךָ וְאֵדָעֲךָ לְמַעַן אֶמְצָא-חֵן בְּעֵינֶיךָ וּרְאֵה כִּי עַמְּךָ הַגּוֹי הַזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: '[Господь] сказал: — Я Сам пойду, чтобы успокоить тебя.',
    verse_ivrit: 'וַיֹּאמַר פָּנַי יֵלֵכוּ וַהֲנִחֹתִי לָךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: '— Если Ты Сам не пойдешь, — сказал Ему [Моше], — то и не уводи нас отсюда!',
    verse_ivrit: 'וַיֹּאמֶר אֵלָיו אִם-אֵין פָּנֶיךָ הֹלְכִים אַל-תַּעֲלֵנוּ מִזֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Как можно будет узнать, что мы — я и Твой народ — угодны Тебе, если не по тому, что Ты пойдешь с нами? Этим мы — я и Твой народ — будем отличены от всех народов на земле! ',
    verse_ivrit: 'וּבַמֶּה יִוָּדַע אֵפוֹא כִּי-מָצָאתִי חֵן בְּעֵינֶיךָ אֲנִי וְעַמֶּךָ הֲלוֹא בְּלֶכְתְּךָ עִמָּנוּ וְנִפְלִינוּ אֲנִי וְעַמְּךָ מִכָּל-הָעָם אֲשֶׁר עַל-פְּנֵי הָאֲדָמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Господь сказал Моше: — Я сделаю то, о чем ты просишь, ибо ты угоден Мне и Я знаю тебя по имени.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה גַּם אֶת-הַדָּבָר הַזֶּה אֲשֶׁר דִּבַּרְתָּ אֶעֱשֶׂה כִּי-מָצָאתָ חֵן בְּעֵינַי וָאֵדָעֲךָ בְּשֵׁם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: '[Моше] сказал: — Покажи мне Твою Славу.',
    verse_ivrit: 'וַיֹּאמַר הַרְאֵנִי נָא אֶת-כְּבֹדֶךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: '[Господь] сказал: — Я проведу перед тобой все Мое благо и провозглашу перед тобой Имя “Господь”. Я оказываю милость тому, кого [хочу] помиловать, и проявляю жалость к тому, кого [хочу] пожалеть.',
    verse_ivrit: 'וַיֹּאמֶר אֲנִי אַעֲבִיר כָּל-טוּבִי עַל-פָּנֶיךָ וְקָרָאתִי בְשֵׁם יְהוָה לְפָנֶיךָ וְחַנֹּתִי אֶת-אֲשֶׁר אָחֹן וְרִחַמְתִּי אֶת-אֲשֶׁר אֲרַחֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: '— Но ты не сможешь увидеть Мой лик, — сказал [Господь]. — Человек не может увидеть Меня и остаться в живых.',
    verse_ivrit: 'וַיֹּאמֶר לֹא תוּכַל לִרְאֹת אֶת-פָּנָי כִּי לֹא-יִרְאַנִי הָאָדָם וָחָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: '— Вот место возле Меня, — сказал Господь. — Стань на этой скале.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה הִנֵּה מָקוֹם אִתִּי וְנִצַּבְתָּ עַל-הַצּוּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Когда Моя Слава будет проходить мимо, Я спрячу тебя в расщелине скалы и накрою тебя Своей рукой.',
    verse_ivrit: 'וְהָיָה בַּעֲבֹר כְּבֹדִי וְשַׂמְתִּיךָ בְּנִקְרַת הַצּוּר וְשַׂכֹּתִי כַפִּי עָלֶיךָ עַד-עָבְרִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 33,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'А когда пройду, то уберу Мою руку — и ты увидишь Меня сзади, но лик Мой не будет видим.',
    verse_ivrit: 'וַהֲסִרֹתִי אֶת-כַּפִּי וְרָאִיתָ אֶת-אֲחֹרָי וּפָנַי לֹא יֵרָאוּ',
    comment: '',
  },
  //////////////////////////////////////           34343434343434343434343343434343434343343434343434          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше: — Вытеши себе из камня две скрижали, подобные прежним, и Я напишу на них те же слова, что были на тех скрижалях, которые ты разбил.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה פְּסָל-לְךָ שְׁנֵי-לֻחֹת אֲבָנִים כָּרִאשֹׁנִים וְכָתַבְתִּי עַל-הַלֻּחֹת אֶת-הַדְּבָרִים אֲשֶׁר הָיוּ עַל-הַלֻּחֹת הָרִאשֹׁנִים אֲשֶׁר שִׁבַּרְתָּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Будь готов к утру. Утром взойди на гору Синай и предстань там предо Мною на вершине горы.',
    verse_ivrit: 'וֶהְיֵה נָכוֹן לַבֹּקֶר וְעָלִיתָ בַבֹּקֶר אֶל-הַר סִינַי וְנִצַּבְתָּ לִי שָׁם עַל-רֹאשׁ הָהָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Пусть никто не поднимается с тобою, пусть никто не появляется на горе — даже скот, мелкий или крупный, пусть не пасется около этой горы.',
    verse_ivrit: 'וְאִישׁ לֹא-יַעֲלֶה עִמָּךְ וְגַם-אִישׁ אַל-יֵרָא בְּכָל-הָהָר גַּם-הַצֹּאן וְהַבָּקָר אַל-יִרְעוּ אֶל-מוּל הָהָר הַהוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Моше вытесал из камня две скрижали, подобные прежним, встал рано утром, взял в руки две каменные скрижали и взошел на гору Синай, как повелел ему Господь.',
    verse_ivrit: 'וַיִּפְסֹל שְׁנֵי-לֻחֹת אֲבָנִים כָּרִאשֹׁנִים וַיַּשְׁכֵּם מֹשֶׁה בַבֹּקֶר וַיַּעַל אֶל-הַר סִינַי כַּאֲשֶׁר צִוָּה יְהוָה אֹתוֹ וַיִּקַּח בְּיָדוֹ שְׁנֵי לֻחֹת אֲבָנִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Господь сошел в облаке, встал возле него там и провозгласил Имя Господа.',
    verse_ivrit: 'וַיֵּרֶד יְהוָה בֶּעָנָן וַיִּתְיַצֵּב עִמּוֹ שָׁם וַיִּקְרָא בְשֵׁם יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Господь прошел перед ним и возгласил: — Господь, Господь, Бог милосердный и милостивый, долготерпеливый! Великий в любви и верности!',
    verse_ivrit: 'וַיַּעֲבֹר יְהוָה עַל-פָּנָיו וַיִּקְרָא יְהוָה יְהוָה אֵל רַחוּם וְחַנּוּן אֶרֶךְ אַפַּיִם וְרַב-חֶסֶד וֶאֱמֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Хранящий милость для тысяч, прощающий вину, преступление и грех, но не оставляющий без наказания, взыскивающий за вину отцов с детей и с внуков — до третьего и четвертого поколения!',
    verse_ivrit: 'נֹצֵר חֶסֶד לָאֲלָפִים נֹשֵׂא עָו‍ֹן וָפֶשַׁע וְחַטָּאָה וְנַקֵּה לֹא יְנַקֶּה פֹּקֵד עֲו‍ֹן אָבוֹת עַל-בָּנִים וְעַל-בְּנֵי בָנִים עַל-שִׁלֵּשִׁים וְעַל-רִבֵּעִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Моше поспешно склонился до земли, простерся ниц',
    verse_ivrit: 'וַיְמַהֵר מֹשֶׁה וַיִּקֹּד אַרְצָה וַיִּשְׁתָּחוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'и сказал: — Если я угоден Тебе, о Господь, то пусть Господь идет среди нас. Этот народ строптив, но Ты прости нашу вину и наш грех и сделай нас Твоим наследием!',
    verse_ivrit: 'וַיֹּאמֶר אִם-נָא מָצָאתִי חֵן בְּעֵינֶיךָ אֲדֹנָי יֵלֶךְ-נָא אֲדֹנָי בְּקִרְבֵּנוּ כִּי עַם-קְשֵׁה-עֹרֶף הוּא וְסָלַחְתָּ לַעֲו‍ֹנֵנוּ וּלְחַטָּאתֵנוּ וּנְחַלְתָּנוּ',
    comment: '',
  },{
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: '“Я заключаю [с вами] союз, — сказал [Господь]. — Перед всем твоим народом Я совершу необычное, подобного чему не было нигде на земле, ни у каких народов! Пусть весь народ, который тебя окружает, увидит, сколь грозны деяния Господа, которые Я совершу для тебя!',
    verse_ivrit: 'וַיֹּאמֶר הִנֵּה אָנֹכִי כֹּרֵת בְּרִית נֶגֶד כָּל-עַמְּךָ אֶעֱשֶׂה נִפְלָאֹת אֲשֶׁר לֹא-נִבְרְאוּ בְכָל-הָאָרֶץ וּבְכָל-הַגּוֹיִם וְרָאָה כָל-הָעָם אֲשֶׁר-אַתָּה בְקִרְבּוֹ אֶת-מַעֲשֵׂה יְהוָה כִּי-נוֹרָא הוּא אֲשֶׁר אֲנִי עֹשֶׂה עִמָּךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Соблюдайте все, что Я повелеваю вам ныне. Я изгоню от вас прочь эмореев, ханаанеев, хеттов, перизеев, хивеев и евусеев.',
    verse_ivrit: 'שְׁמָר-לְךָ אֵת אֲשֶׁר אָנֹכִי מְצַוְּךָ הַיּוֹם הִנְנִי גֹרֵשׁ מִפָּנֶיךָ אֶת-הָאֱמֹרִי וְהַכְּנַעֲנִי וְהַחִתִּי וְהַפְּרִזִּי וְהַחִוִּי וְהַיְבוּסִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Остерегайтесь заключать союзы с жителями страны, в которую войдете, — иначе они станут для вас западней.',
    verse_ivrit: 'הִשָּׁמֶר לְךָ פֶּן-תִּכְרֹת בְּרִית לְיוֹשֵׁב הָאָרֶץ אֲשֶׁר אַתָּה בָּא עָלֶיהָ פֶּן-יִהְיֶה לְמוֹקֵשׁ בְּקִרְבֶּךָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Разрушьте их жертвенники! Сокрушите их священные камни и срубите их священные деревья!',
    verse_ivrit: 'כִּי אֶת-מִזְבְּחֹתָם תִּתֹּצוּן וְאֶת-מַצֵּבֹתָם תְּשַׁבֵּרוּן וְאֶת-אֲשֵׁרָיו תִּכְרֹתוּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Вам не следует поклоняться иным божествам, потому что Имя Господа — ‘Ревнитель’. Он — Бог-Ревнитель!',
    verse_ivrit: 'כִּי לֹא תִשְׁתַּחֲוֶה לְאֵל אַחֵר כִּי יְהוָה קַנָּא שְׁמוֹ אֵל קַנָּא הוּא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Не заключайте союзов с жителями той страны — иначе они, совершая блуд со своими богами и принося своим богам жертвы, позовут и вас, и вы будете есть [мясо] их жертв.',
    verse_ivrit: 'פֶּן-תִּכְרֹת בְּרִית לְיוֹשֵׁב הָאָרֶץ וְזָנוּ אַחֲרֵי אֱלֹהֵיהֶם וְזָבְחוּ לֵאלֹהֵיהֶם וְקָרָא לְךָ וְאָכַלְתָּ מִזִּבְחוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'А если вы станете брать их дочерей [в жены] вашим сыновьям, то когда их дочери будут блудить со своими богами, они вовлекут в блуд со своими богами и ваших сыновей.',
    verse_ivrit: 'וְלָקַחְתָּ מִבְּנֹתָיו לְבָנֶיךָ וְזָנוּ בְנֹתָיו אַחֲרֵי אֱלֹהֵיהֶן וְהִזְנוּ אֶת-בָּנֶיךָ אַחֲרֵי אֱלֹהֵיהֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Не делай себе литых божеств.',
    verse_ivrit: 'אֱלֹהֵי מַסֵּכָה לֹא תַעֲשֶׂה-לָּךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Соблюдай праздник мацы: семь дней, как Я повелел тебе, ешь мацу — в назначенное время, в месяце авиве, потому что в месяце авиве ты ушел из Египта.',
    verse_ivrit: 'אֶת-חַג הַמַּצּוֹת תִּשְׁמֹר שִׁבְעַת יָמִים תֹּאכַל מַצּוֹת אֲשֶׁר צִוִּיתִךָ לְמוֹעֵד חֹדֶשׁ הָאָבִיב כִּי בְּחֹדֶשׁ הָאָבִיב יָצָאתָ מִמִּצְרָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Каждый первый плод материнского лона [принадлежит] Мне, как и все первенцы мужского пола у скота — телята или ягнята.',
    verse_ivrit: 'כָּל-פֶּטֶר רֶחֶם לִי וְכָל-מִקְנְךָ תִּזָּכָר פֶּטֶר שׁוֹר וָשֶׂה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Осленка, вышедшего первым из материнского лона, выкупи, [отдав за него] ягненка, а если не выкупишь — проломи ему затылок. За каждого первенца из твоих сыновей отдавай выкуп. Никто не должен представать предо Мной с пустыми руками!',
    verse_ivrit: 'וּפֶטֶר חֲמוֹר תִּפְדֶּה בְשֶׂה וְאִם-לֹא תִפְדֶּה וַעֲרַפְתּוֹ כֹּל בְּכוֹר בָּנֶיךָ תִּפְדֶּה וְלֹא-יֵרָאוּ פָנַי רֵיקָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Шесть дней работай, а в седьмой день прекращай работу — прекращай работу [даже] во время пахоты и жатвы.',
    verse_ivrit: 'שֵׁשֶׁת יָמִים תַּעֲבֹד וּבַיּוֹם הַשְּׁבִיעִי תִּשְׁבֹּת בֶּחָרִישׁ וּבַקָּצִיר תִּשְׁבֹּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Справляй праздник Шавуот — [праздник] первой жатвы пшеницы, а также праздник сбора плодов в конце года.',
    verse_ivrit: 'וְחַג שָׁבֻעֹת תַּעֲשֶׂה לְךָ בִּכּוּרֵי קְצִיר חִטִּים וְחַג הָאָסִיף תְּקוּפַת הַשָּׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Три раза в году все ваши мужчины должны представать пред Владыкой — Господом, Богом Израиля.',
    verse_ivrit: 'שָׁלֹשׁ פְּעָמִים בַּשָּׁנָה יֵרָאֶה כָּל-זְכוּרְךָ אֶת-פְּנֵי הָאָדֹן יְהוָה אֱלֹהֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'А Я изгоню перед тобою народы и расширю твои пределы, и никто не возжаждет твоей земли — если ты будешь три раза в год восходить и являться пред Господом, твоим Богом.',
    verse_ivrit: 'כִּי-אוֹרִישׁ גּוֹיִם מִפָּנֶיךָ וְהִרְחַבְתִּי אֶת-גְּבֻלֶךָ וְלֹא-יַחְמֹד אִישׁ אֶת-אַרְצְךָ בַּעֲלֹתְךָ לֵרָאוֹת אֶת-פְּנֵי יְהוָה אֱלֹהֶיךָ שָׁלֹשׁ פְּעָמִים בַּשָּׁנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Не проливай кровь Моей жертвы на квасное, и пусть [мясо] пасхальной жертвы не остается до утра.',
    verse_ivrit: 'לֹא-תִשְׁחַט עַל-חָמֵץ דַּם-זִבְחִי וְלֹא-יָלִין לַבֹּקֶר זֶבַח חַג הַפָּסַח',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Первые плоды твоей земли приноси в дом Господа, твоего Бога. Не вари детеныша скота в молоке его матери”. ',
    verse_ivrit: 'רֵאשִׁית בִּכּוּרֵי אַדְמָתְךָ תָּבִיא בֵּית יְהוָה אֱלֹהֶיךָ לֹא-תְבַשֵּׁל גְּדִי בַּחֲלֵב אִמּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Господь сказал Моше: — Запиши для себя эти слова, ведь согласно этим словам Я заключил союз с тобой и с Израилем.',
    verse_ivrit: 'וַיֹּאמֶר יְהוָה אֶל-מֹשֶׁה כְּתָב-לְךָ אֶת-הַדְּבָרִים הָאֵלֶּה כִּי עַל-פִּי הַדְּבָרִים הָאֵלֶּה כָּרַתִּי אִתְּךָ בְּרִית וְאֶת-יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Сорок дней и сорок ночей [Моше] был с Господом, хлеба не ел и воды не пил. Он писал на скрижалях слова союза — Десять речений.',
    verse_ivrit: 'וַיְהִי-שָׁם עִם-יְהוָה אַרְבָּעִים יוֹם וְאַרְבָּעִים לַיְלָה לֶחֶם לֹא אָכַל וּמַיִם לֹא שָׁתָה וַיִּכְתֹּב עַל-הַלֻּחֹת אֵת דִּבְרֵי הַבְּרִית עֲשֶׂרֶת הַדְּבָרִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'А когда Моше спускался с горы Синай, — и обе скрижали Свидетельства были в руках Моше, когда он спускался с горы, — то не знал Моше, что из-за разговора с [Господом] его лицо лучится.',
    verse_ivrit: 'וַיְהִי בְּרֶדֶת מֹשֶׁה מֵהַר סִינַי וּשְׁנֵי לֻחֹת הָעֵדֻת בְּיַד-מֹשֶׁה בְּרִדְתּוֹ מִן-הָהָר וּמֹשֶׁה לֹא-יָדַע כִּי קָרַן עוֹר פָּנָיו בְּדַבְּרוֹ אִתּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Аѓарон и все сыны Израиля увидели, что лицо Моше лучится, и побоялись подойти к нему.',
    verse_ivrit: 'וַיַּרְא אַהֲרֹן וְכָל-בְּנֵי יִשְׂרָאֵל אֶת-מֹשֶׁה וְהִנֵּה קָרַן עוֹר פָּנָיו וַיִּירְאוּ מִגֶּשֶׁת אֵלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Но Моше позвал их. Аѓарон и все вожди общины вернулись к нему, и Моше говорил с ними.',
    verse_ivrit: 'וַיִּקְרָא אֲלֵהֶם מֹשֶׁה וַיָּשֻׁבוּ אֵלָיו אַהֲרֹן וְכָל-הַנְּשִׂאִים בָּעֵדָה וַיְדַבֵּר מֹשֶׁה אֲלֵהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'А затем подошли все сыны Израиля, и он заповедал им все, что Господь сказал ему на горе Синай.',
    verse_ivrit: 'וְאַחֲרֵי-כֵן נִגְּשׁוּ כָּל-בְּנֵי יִשְׂרָאֵל וַיְצַוֵּם אֵת כָּל-אֲשֶׁר דִּבֶּר יְהוָה אִתּוֹ בְּהַר סִינָי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Когда Моше закончил говорить с ними, он опустил на свое лицо покров.',
    verse_ivrit: 'וַיְכַל מֹשֶׁה מִדַּבֵּר אִתָּם וַיִּתֵּן עַל-פָּנָיו מַסְוֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Но всякий раз, когда Моше представал пред Господом, чтобы говорить с Ним, он снимал покров — пока не [наступал час] уходить. А вернувшись, он рассказывал сынам Израиля все, что ему было заповедано.',
    verse_ivrit: 'וּבְבֹא מֹשֶׁה לִפְנֵי יְהוָה לְדַבֵּר אִתּוֹ יָסִיר אֶת-הַמַּסְוֶה עַד-צֵאתוֹ וְיָצָא וְדִבֶּר אֶל-בְּנֵי יִשְׂרָאֵל אֵת אֲשֶׁר יְצֻוֶּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ТИСА(Когда поднимешь)',
    id_book: 1,
    id_chapter: 34,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Сыны Израиля видели лицо Моше — [видели], что кожа его лица сияла. И вновь [опускал] Моше покров на лицо — до тех пор, пока не представал, чтобы говорить с [Господом]. ',
    verse_ivrit: 'וְרָאוּ בְנֵי-יִשְׂרָאֵל אֶת-פְּנֵי מֹשֶׁה כִּי קָרַן עוֹר פְּנֵי מֹשֶׁה וְהֵשִׁיב מֹשֶׁה אֶת-הַמַּסְוֶה עַל-פָּנָיו עַד-בֹּאוֹ לְדַבֵּר אִתּוֹ',
    comment: '',
  },
  //////////////////////////////////////           353535535353535353535353535353535353535535353          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Моше собрал всю общину сынов Израиля и сказал им: “Вот что Господь повелел исполнять.',
    verse_ivrit: 'וַיַּקְהֵל מֹשֶׁה אֶת-כָּל-עֲדַת בְּנֵי יִשְׂרָאֵל וַיֹּאמֶר אֲלֵהֶם אֵלֶּה הַדְּבָרִים אֲשֶׁר-צִוָּה יְהוָה לַעֲשֹׂת אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Шесть дней можно делать работу, а седьмой день — свят, это суббота покоя Господу. Всякий, кто делает [в этот день] работу, должен быть предан смерти.',
    verse_ivrit: 'שֵׁשֶׁת יָמִים תֵּעָשֶׂה מְלָאכָה וּבַיּוֹם הַשְּׁבִיעִי יִהְיֶה לָכֶם קֹדֶשׁ שַׁבַּת שַׁבָּתוֹן לַיהוָה כָּל-הָעֹשֶׂה בוֹ מְלָאכָה יוּמָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'В день субботний не разводите огня во всех ваших жилищах”.',
    verse_ivrit: 'לֹא-תְבַעֲרוּ אֵשׁ בְּכֹל מֹשְׁבֹתֵיכֶם בְּיוֹם הַשַּׁבָּת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Моше сказал всей общине сынов Израиля: “Господь повелел вот что:',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-כָּל-עֲדַת בְּנֵי-יִשְׂרָאֵל לֵאמֹר זֶה הַדָּבָר אֲשֶׁר-צִוָּה יְהוָה לֵאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: '‘Соберите у себя приношения Господу. Каждый, кого побуждает сердце, пусть доставит Господу приношение — золото, серебро, медь,',
    verse_ivrit: 'קְחוּ מֵאִתְּכֶם תְּרוּמָה לַיהוָה כֹּל נְדִיב לִבּוֹ יְבִיאֶהָ אֵת תְּרוּמַת יְהוָה זָהָב וָכֶסֶף וּנְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'синюю, багряную и пурпуровую нить, лен и козью [шерсть],',
    verse_ivrit: 'וּתְכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ וְעִזִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'красные бараньи кожи, кожи тахашей и древесину акации,',
    verse_ivrit: 'וְעֹרֹת אֵילִם מְאָדָּמִים וְעֹרֹת תְּחָשִׁים וַעֲצֵי שִׁטִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'масло для освещения, благовония для масла помазания и для ароматных воскурений,',
    verse_ivrit: 'וְשֶׁמֶן לַמָּאוֹר וּבְשָׂמִים לְשֶׁמֶן הַמִּשְׁחָה וְלִקְטֹרֶת הַסַּמִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'ониксы и вставные камни для эфода и нагрудника.',
    verse_ivrit: 'וְאַבְנֵי-שֹׁהַם וְאַבְנֵי מִלֻּאִים לָאֵפוֹד וְלַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Пусть каждый из вас, кто мудр сердцем, придет и сделает все, что повелел Господь, -',
    verse_ivrit: 'וְכָל-חֲכַם-לֵב בָּכֶם יָבֹאוּ וְיַעֲשׂוּ אֵת כָּל-אֲשֶׁר צִוָּה יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Святилище, его шатер и покров с застежками, брусья, стержни, столбы и подножия,',
    verse_ivrit: 'אֶת-הַמִּשְׁכָּן אֶת-אָהֳלוֹ וְאֶת-מִכְסֵהוּ אֶת-קְרָסָיו וְאֶת-קְרָשָׁיו אֶת-בְּרִיחָו אֶת-עַמֻּדָיו וְאֶת-אֲדָנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'ковчег с шестами, крышку и полог завесы,',
    verse_ivrit: 'אֶת-הָאָרֹן וְאֶת-בַּדָּיו אֶת-הַכַּפֹּרֶת וְאֵת פָּרֹכֶת הַמָּסָךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'стол с шестами и всеми его принадлежностями, хлеб предложения,',
    verse_ivrit: 'אֶת-הַשֻּׁלְחָן וְאֶת-בַּדָּיו וְאֶת-כָּל-כֵּלָיו וְאֵת לֶחֶם הַפָּנִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'светильник для освещения с его принадлежностями, лампы и масло для освещения,',
    verse_ivrit: 'וְאֶת-מְנֹרַת הַמָּאוֹר וְאֶת-כֵּלֶיהָ וְאֶת-נֵרֹתֶיהָ וְאֵת שֶׁמֶן הַמָּאוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'жертвенник для воскурений с шестами, масло помазания и ароматные воскурения, входную завесу при входе в Святилище,',
    verse_ivrit: 'וְאֶת-מִזְבַּח הַקְּטֹרֶת וְאֶת-בַּדָּיו וְאֵת שֶׁמֶן הַמִּשְׁחָה וְאֵת קְטֹרֶת הַסַּמִּים וְאֶת-מָסַךְ הַפֶּתַח לְפֶתַח הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'жертвенник для всесожжений и к нему — медную решетку, шесты и все принадлежности, чан с подставкой,',
    verse_ivrit: 'אֵת מִזְבַּח הָעֹלָה וְאֶת-מִכְבַּר הַנְּחֹשֶׁת אֲשֶׁר-לוֹ אֶת-בַּדָּיו וְאֶת-כָּל-כֵּלָיו אֶת-הַכִּיֹּר וְאֶת-כַּנּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'занавесы для двора, столбы и подножия, завесу при входе во двор,',
    verse_ivrit: 'אֵת קַלְעֵי הֶחָצֵר אֶת-עַמֻּדָיו וְאֶת-אֲדָנֶיהָ וְאֵת מָסַךְ שַׁעַר הֶחָצֵר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'колья для Святилища, колья для двора со шнурами,',
    verse_ivrit: 'אֶת-יִתְדֹת הַמִּשְׁכָּן וְאֶת-יִתְדֹת הֶחָצֵר וְאֶת-מֵיתְרֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'служебные одежды для служения в Святилище, священные одежды Аѓарона-священника и одежды его сыновей для служения’”.',
    verse_ivrit: 'אֶת-בִּגְדֵי הַשְּׂרָד לְשָׁרֵת בַּקֹּדֶשׁ אֶת-בִּגְדֵי הַקֹּדֶשׁ לְאַהֲרֹן הַכֹּהֵן וְאֶת-בִּגְדֵי בָנָיו לְכַהֵן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Вся община сынов Израиля вышла от Моше,',
    verse_ivrit: 'וַיֵּצְאוּ כָּל-עֲדַת בְּנֵי-יִשְׂרָאֵל מִלִּפְנֵי מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: '[и затем] все, кого влекло сердце и чей дух был расположен, пришли и принесли дары Господу для постройки Шатра Встречи, для всякого служения в нем и для священных одежд.',
    verse_ivrit: 'וַיָּבֹאוּ כָּל-אִישׁ אֲשֶׁר-נְשָׂאוֹ לִבּוֹ וְכֹל אֲשֶׁר נָדְבָה רוּחוֹ אֹתוֹ הֵבִיאוּ אֶת-תְּרוּמַת יְהוָה לִמְלֶאכֶת אֹהֶל מוֹעֵד וּלְכָל-עֲבֹדָתוֹ וּלְבִגְדֵי הַקֹּדֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Приходили мужчины и женщины; каждый, у кого сердце было расположено, приносил кольца, серьги, перстни и подвески, всякие золотые вещи — [так поступал] каждый, совершающий приношение золота Господу.',
    verse_ivrit: 'וַיָּבֹאוּ הָאֲנָשִׁים עַל-הַנָּשִׁים כֹּל נְדִיב לֵב הֵבִיאוּ חָח וָנֶזֶם וְטַבַּעַת וְכוּמָז כָּל-כְּלִי זָהָב וְכָל-אִישׁ אֲשֶׁר הֵנִיף תְּנוּפַת זָהָב לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Каждый, у кого нашлось, нес синюю, багряную и пурпуровую шерсть или лен, козью шерсть, красные бараньи кожи или кожи тахашей.',
    verse_ivrit: 'וְכָל-אִישׁ אֲשֶׁר-נִמְצָא אִתּוֹ תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ וְעִזִּים וְעֹרֹת אֵילִם מְאָדָּמִים וְעֹרֹת תְּחָשִׁים הֵבִיאוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Каждый, кто делал приношение серебром или медью, жертвовал это как дар Господу. Каждый, у кого была древесина акации, пригодная для той или иной работы, приносил [ее].',
    verse_ivrit: 'כָּל-מֵרִים תְּרוּמַת כֶּסֶף וּנְחֹשֶׁת הֵבִיאוּ אֵת תְּרוּמַת יְהוָה וְכֹל אֲשֶׁר נִמְצָא אִתּוֹ עֲצֵי שִׁטִּים לְכָל-מְלֶאכֶת הָעֲבֹדָה הֵבִיאוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Каждая мудрая сердцем женщина собственноручно пряла и приносила пряжу из синей, багряной и пурпуровой нити и льна.',
    verse_ivrit: 'וְכָל-אִשָּׁה חַכְמַת-לֵב בְּיָדֶיהָ טָווּ וַיָּבִיאוּ מַטְוֶה אֶת-הַתְּכֵלֶת וְאֶת-הָאַרְגָּמָן אֶת-תּוֹלַעַת הַשָּׁנִי וְאֶת-הַשֵּׁשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Каждая побуждаемая мудрым сердцем женщина пряла козью [шерсть].',
    verse_ivrit: 'וְכָל-הַנָּשִׁים אֲשֶׁר נָשָׂא לִבָּן אֹתָנָה בְּחָכְמָה טָווּ אֶת-הָעִזִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Вожди же приносили ониксы и вставные камни для эфода и нагрудника,',
    verse_ivrit: 'וְהַנְּשִׂאִם הֵבִיאוּ אֵת אַבְנֵי הַשֹּׁהַם וְאֵת אַבְנֵי הַמִּלֻּאִים לָאֵפוֹד וְלַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'благовония и масло — для освещения, помазания и ароматных воскурений.',
    verse_ivrit: 'וְאֶת-הַבֹּשֶׂם וְאֶת-הַשָּׁמֶן לְמָאוֹר וּלְשֶׁמֶן הַמִּשְׁחָה וְלִקְטֹרֶת הַסַּמִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Все мужчины и женщины — сыны Израиля, — которых сердце побуждало принести что-нибудь для какой-либо работы, которую Господь повелел сделать через Моше, приносили это как добровольный дар Господу. ',
    verse_ivrit: 'כָּל-אִישׁ וְאִשָּׁה אֲשֶׁר נָדַב לִבָּם אֹתָם לְהָבִיא לְכָל-הַמְּלָאכָה אֲשֶׁר צִוָּה יְהוָה לַעֲשׂוֹת בְּיַד-מֹשֶׁה הֵבִיאוּ בְנֵי-יִשְׂרָאֵל נְדָבָה לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Моше сказал сынам Израиля: “Вот, Господь призвал именно Бецалеля, сына Ури, внука Хура, из колена Йеѓуды,',
    verse_ivrit: 'וַיֹּאמֶר מֹשֶׁה אֶל-בְּנֵי יִשְׂרָאֵל רְאוּ קָרָא יְהוָה בְּשֵׁם בְּצַלְאֵל בֶּן-אוּרִי בֶן-חוּר לְמַטֵּה יְהוּדָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'и наполнил его духом Божьим, [дал ему] мудрость, разумение, знание и мастерство во всем,',
    verse_ivrit: 'וַיְמַלֵּא אֹתוֹ רוּחַ אֱלֹהִים בְּחָכְמָה בִּתְבוּנָה וּבְדַעַת וּבְכָל-מְלָאכָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: '[так что] он владеет искусством работы по золоту, серебру и меди,',
    verse_ivrit: 'וְלַחְשֹׁב מַחֲשָׁבֹת לַעֲשֹׂת בַּזָּהָב וּבַכֶּסֶף וּבַנְּחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: '[умеет] резать камни для вставки [в оправу], а также резать дерево для любой художественной работы.',
    verse_ivrit: 'וּבַחֲרֹשֶׁת אֶבֶן לְמַלֹּאת וּבַחֲרֹשֶׁת עֵץ לַעֲשׂוֹת בְּכָל-מְלֶאכֶת מַחֲשָׁבֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: '[Господь] вложил в его сердце — и [в сердце] Оѓолиава, сына Ахисамаха, из колена Дана, — способность обучать других.',
    verse_ivrit: 'וּלְהוֹרֹת נָתַן בְּלִבּוֹ הוּא וְאָהֳלִיאָב בֶּן-אֲחִיסָמָךְ לְמַטֵּה-דָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 35,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Он наполнил их сердца мудростью, чтобы [они могли] делать всякую работу — [работу] резчика, искусника, ткача и вышивальщика по синей, багряной и пурпуровой пряже и по льняным тканям — чтобы исполнять всякую работу как мастера и умельцы.',
    verse_ivrit: 'מִלֵּא אֹתָם חָכְמַת-לֵב לַעֲשׂוֹת כָּל-מְלֶאכֶת חָרָשׁ וְחֹשֵׁב וְרֹקֵם בַּתְּכֵלֶת וּבָאַרְגָּמָן בְּתוֹלַעַת הַשָּׁנִי וּבַשֵּׁשׁ וְאֹרֵג עֹשֵׂי כָּל-מְלָאכָה וְחֹשְׁבֵי מַחֲשָׁבֹת',
    comment: '',
  },
  //////////////////////////////////////           363636363363636363363633636336363636363363636          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Бецалель, Оѓолиав и все мудрые сердцем, которых Господь наделил мудростью и разумением, знающие, как исполнить все дело священной работы, — должны работать так, как повелел Господь”.',
    verse_ivrit: 'וְעָשָׂה בְצַלְאֵל וְאָהֳלִיאָב וְכֹל אִישׁ חֲכַם-לֵב אֲשֶׁר נָתַן יְהוָה חָכְמָה וּתְבוּנָה בָּהֵמָּה לָדַעַת לַעֲשֹׂת אֶת-כָּל-מְלֶאכֶת עֲבֹדַת הַקֹּדֶשׁ לְכֹל אֲשֶׁר-צִוָּה יְהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Моше призвал Бецалеля, Оѓолиава и всех мудрых сердцем, всех, кого Господь наделил мудростью, каждого, кого сердце побудило приступить к работе и исполнить ее, —',
    verse_ivrit: 'וַיִּקְרָא מֹשֶׁה אֶל-בְּצַלְאֵל וְאֶל-אָהֳלִיאָב וְאֶל כָּל-אִישׁ חֲכַם-לֵב אֲשֶׁר נָתַן יְהוָה חָכְמָה בְּלִבּוֹ כֹּל אֲשֶׁר נְשָׂאוֹ לִבּוֹ לְקָרְבָה אֶל-הַמְּלָאכָה לַעֲשֹׂת אֹתָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'и они взяли у Моше все те приношения, которые сыны Израиля доставили для дела священной работы, чтобы исполнить ее. Но те каждое утро приносили [Моше все новые] добровольные дары.',
    verse_ivrit: 'וַיִּקְחוּ מִלִּפְנֵי מֹשֶׁה אֵת כָּל-הַתְּרוּמָה אֲשֶׁר הֵבִיאוּ בְּנֵי יִשְׂרָאֵל לִמְלֶאכֶת עֲבֹדַת הַקֹּדֶשׁ לַעֲשֹׂת אֹתָהּ וְהֵם הֵבִיאוּ אֵלָיו עוֹד נְדָבָה בַּבֹּקֶר בַּבֹּקֶר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Тогда пришли все мудрые исполнители этого священного дела, каждый [оставив] ту работу, которой занимался,',
    verse_ivrit: 'וַיָּבֹאוּ כָּל-הַחֲכָמִים הָעֹשִׂים אֵת כָּל-מְלֶאכֶת הַקֹּדֶשׁ אִישׁ-אִישׁ מִמְּלַאכְתּוֹ אֲשֶׁר-הֵמָּה עֹשִׂים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'и сказали Моше: “Народ приносит больше, чем нужно для работы, которую повелел сделать Господь”.',
    verse_ivrit: 'וַיֹּאמְרוּ אֶל-מֹשֶׁה לֵּאמֹר מַרְבִּים הָעָם לְהָבִיא מִדֵּי הָעֲבֹדָה לַמְּלָאכָה אֲשֶׁר-צִוָּה יְהוָה לַעֲשֹׂת אֹתָהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Тогда Моше приказал провозгласить по всему стану: “Пусть мужчины и женщины больше не совершают священных приношений”. И народ перестал приносить [дары].',
    verse_ivrit: 'וַיְצַו מֹשֶׁה וַיַּעֲבִירוּ קוֹל בַּמַּחֲנֶה לֵאמֹר אִישׁ וְאִשָּׁה אַל-יַעֲשׂוּ-עוֹד מְלָאכָה לִתְרוּמַת הַקֹּדֶשׁ וַיִּכָּלֵא הָעָם מֵהָבִיא',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Собранного было с излишком достаточно для выполнения всех работ.',
    verse_ivrit: 'וְהַמְּלָאכָה הָיְתָה דַיָּם לְכָל-הַמְּלָאכָה לַעֲשׂוֹת אֹתָהּ וְהוֹתֵר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Все мудрые сердцем, исполнявшие работу, сделали для Святилища десять полотнищ из тонкого льняного полотна, синей, багряной и пурпуровой нити, и выткали на них [изображения] керувов.',
    verse_ivrit: 'וַיַּעֲשׂוּ כָל-חֲכַם-לֵב בְּעֹשֵׂי הַמְּלָאכָה אֶת-הַמִּשְׁכָּן עֶשֶׂר יְרִיעֹת שֵׁשׁ מָשְׁזָר וּתְכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי כְּרֻבִים מַעֲשֵׂה חֹשֵׁב עָשָׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Длина каждого полотнища была двадцать восемь локтей, а ширина каждого полотнища — четыре локтя. Все полотнища были одинакового размера.',
    verse_ivrit: 'אֹרֶךְ הַיְרִיעָה הָאַחַת שְׁמֹנֶה וְעֶשְׂרִים בָּאַמָּה וְרֹחַב אַרְבַּע בָּאַמָּה הַיְרִיעָה הָאֶחָת מִדָּה אַחַת לְכָל-הַיְרִיעֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Пять полотнищ сшили вместе, и пять других полотнищ [также] сшили вместе.',
    verse_ivrit: 'וַיְחַבֵּר אֶת-חֲמֵשׁ הַיְרִיעֹת אַחַת אֶל-אֶחָת וְחָמֵשׁ יְרִיעֹת חִבַּר אַחַת אֶל-אֶחָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'По кромке первого полотнища одного из [шитых] кусков сделали петли из голубой нити; то же сделали по кромке последнего полотнища второго [шитого] куска.',
    verse_ivrit: 'וַיַּעַשׂ לֻלְאֹת תְּכֵלֶת עַל שְׂפַת הַיְרִיעָה הָאֶחָת מִקָּצָה בַּמַּחְבָּרֶת כֵּן עָשָׂה בִּשְׂפַת הַיְרִיעָה הַקִּיצוֹנָה בַּמַּחְבֶּרֶת הַשֵּׁנִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Пятьдесят петель сделали на одном полотнище и пятьдесят петель сделали на краю полотнища в другом [шитом] куске, петля напротив петли.',
    verse_ivrit: 'חֲמִשִּׁים לֻלָאֹת עָשָׂה בַּיְרִיעָה הָאֶחָת וַחֲמִשִּׁים לֻלָאֹת עָשָׂה בִּקְצֵה הַיְרִיעָה אֲשֶׁר בַּמַּחְבֶּרֶת הַשֵּׁנִית מַקְבִּילֹת הַלֻּלָאֹת אַחַת אֶל-אֶחָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Сделали пятьдесят золотых застежек, и соединили полотнища друг с другом застежками, и стало Святилище одним [целым].',
    verse_ivrit: 'וַיַּעַשׂ חֲמִשִּׁים קַרְסֵי זָהָב וַיְחַבֵּר אֶת-הַיְרִיעֹת אַחַת אֶל-אַחַת בַּקְּרָסִים וַיְהִי הַמִּשְׁכָּן אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Из козьей шерсти сделали полотнища для шатра поверх Святилища; всего сделали одиннадцать таких полотнищ.',
    verse_ivrit: 'וַיַּעַשׂ יְרִיעֹת עִזִּים לְאֹהֶל עַל-הַמִּשְׁכָּן עַשְׁתֵּי-עֶשְׂרֵה יְרִיעֹת עָשָׂה אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Длина каждого полотнища была тридцать локтей, а ширина каждого полотнища — четыре локтя; [все] одиннадцать полотнищ были одинакового размера.',
    verse_ivrit: 'אֹרֶךְ הַיְרִיעָה הָאַחַת שְׁלֹשִׁים בָּאַמָּה וְאַרְבַּע אַמּוֹת רֹחַב הַיְרִיעָה הָאֶחָת מִדָּה אַחַת לְעַשְׁתֵּי עֶשְׂרֵה יְרִיעֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Пять полотнищ сшили отдельно, а шесть [остальных] полотнищ — отдельно.',
    verse_ivrit: 'וַיְחַבֵּר אֶת-חֲמֵשׁ הַיְרִיעֹת לְבָד וְאֶת-שֵׁשׁ הַיְרִיעֹת לְבָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'По кромке крайнего полотнища сделали пятьдесят петель в [одном шитом] куске, и пятьдесят петель сделали по кромке второго [шитого] куска.',
    verse_ivrit: 'וַיַּעַשׂ לֻלָאֹת חֲמִשִּׁים עַל שְׂפַת הַיְרִיעָה הַקִּיצֹנָה בַּמַּחְבָּרֶת וַחֲמִשִּׁים לֻלָאֹת עָשָׂה עַל-שְׂפַת הַיְרִיעָה הַחֹבֶרֶת הַשֵּׁנִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Сделали пятьдесят медных застежек для соединения шатра, чтобы [его покров] был единым.',
    verse_ivrit: 'וַיַּעַשׂ קַרְסֵי נְחֹשֶׁת חֲמִשִּׁים לְחַבֵּר אֶת-הָאֹהֶל לִהְיֹת אֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Покрытие для шатра сделали из красных бараньих кож, а поверх него — покрытие из кож тахашей.',
    verse_ivrit: 'וַיַּעַשׂ מִכְסֶה לָאֹהֶל עֹרֹת אֵילִם מְאָדָּמִים וּמִכְסֵה עֹרֹת תְּחָשִׁים מִלְמָעְלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Из дерева акации сделали стоячие брусья для Святилища.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַקְּרָשִׁים לַמִּשְׁכָּן עֲצֵי שִׁטִּים עֹמְדִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Каждый брус был десять локтей длиной и полтора локтя шириной.',
    verse_ivrit: 'עֶשֶׂר אַמֹּת אֹרֶךְ הַקָּרֶשׁ וְאַמָּה וַחֲצִי הָאַמָּה רֹחַב הַקֶּרֶשׁ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'У каждого из брусьев было по два прикрепленных друг к другу шипа. Так сделали все брусья Святилища.',
    verse_ivrit: 'שְׁתֵּי יָדֹת לַקֶּרֶשׁ הָאֶחָד מְשֻׁלָּבֹת אַחַת אֶל-אֶחָת כֵּן עָשָׂה לְכֹל קַרְשֵׁי הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Сделали брусья для Святилища: для южной стороны — двадцать брусьев.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַקְּרָשִׁים לַמִּשְׁכָּן עֶשְׂרִים קְרָשִׁים לִפְאַת נֶגֶב תֵּימָנָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Под эти двадцать брусьев сделали сорок серебряных подножий: два подножия под один брус, для двух его шипов, и два подножия под другой брус, для двух его шипов.',
    verse_ivrit: 'וְאַרְבָּעִים אַדְנֵי-כֶסֶף עָשָׂה תַּחַת עֶשְׂרִים הַקְּרָשִׁים שְׁנֵי אֲדָנִים תַּחַת-הַקֶּרֶשׁ הָאֶחָד לִשְׁתֵּי יְדֹתָיו וּשְׁנֵי אֲדָנִים תַּחַת-הַקֶּרֶשׁ הָאֶחָד לִשְׁתֵּי יְדֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'И для другой, северной стороны Святилища сделали двадцать брусьев,',
    verse_ivrit: 'וּלְצֶלַע הַמִּשְׁכָּן הַשֵּׁנִית לִפְאַת צָפוֹן עָשָׂה עֶשְׂרִים קְרָשִׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'а под них — сорок серебряных подножий: два подножия под один брус и два подножия под другой брус.',
    verse_ivrit: 'וְאַרְבָּעִים אַדְנֵיהֶם כָּסֶף שְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד וּשְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Для задней же, западной стороны Святилища сделали шесть брусьев,',
    verse_ivrit: 'וּלְיַרְכְּתֵי הַמִּשְׁכָּן יָמָּה עָשָׂה שִׁשָּׁה קְרָשִׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'и [еще] два бруса сделали на задней стороне для углов Святилища.',
    verse_ivrit: 'וּשְׁנֵי קְרָשִׁים עָשָׂה לִמְקֻצְעֹת הַמִּשְׁכָּן בַּיַּרְכָתָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Они точно соответствовали друг другу снизу и попарно были соединены одним кольцом сверху; так сделали с ними обоими на обоих углах.',
    verse_ivrit: 'וְהָיוּ תוֹאֲמִם מִלְּמַטָּה וְיַחְדָּו יִהְיוּ תַמִּים אֶל-רֹאשׁוֹ אֶל-הַטַּבַּעַת הָאֶחָת כֵּן עָשָׂה לִשְׁנֵיהֶם לִשְׁנֵי הַמִּקְצֹעֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Всего было [сделано] восемь брусьев и под них — шестнадцать серебряных подножий: по два подножия под каждый брус.',
    verse_ivrit: 'וְהָיוּ שְׁמֹנָה קְרָשִׁים וְאַדְנֵיהֶם כֶּסֶף שִׁשָּׁה עָשָׂר אֲדָנִים שְׁנֵי אֲדָנִים שְׁנֵי אֲדָנִים תַּחַת הַקֶּרֶשׁ הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Из дерева акации сделали стержни: пять [стержней] для брусьев одной стороны Святилища,',
    verse_ivrit: 'וַיַּעַשׂ בְּרִיחֵי עֲצֵי שִׁטִּים חֲמִשָּׁה לְקַרְשֵׁי צֶלַע-הַמִּשְׁכָּן הָאֶחָת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'пять стержней — для брусьев другой стороны Святилища и [еще] пять стержней — для брусьев задней, западной стороны Святилища.',
    verse_ivrit: 'וַחֲמִשָּׁה בְרִיחִם לְקַרְשֵׁי צֶלַע-הַמִּשְׁכָּן הַשֵּׁנִית וַחֲמִשָּׁה בְרִיחִם לְקַרְשֵׁי הַמִּשְׁכָּן לַיַּרְכָתַיִם יָמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Сделали средний стержень — так, чтобы он проходил внутри брусьев, от одного конца до другого.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַבְּרִיחַ הַתִּיכֹן לִבְרֹחַ בְּתוֹךְ הַקְּרָשִׁים מִן-הַקָּצֶה אֶל-הַקָּצֶה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Брусья покрыли золотом, и их кольца — вместилища для стержней — сделали из золота, и стержни покрыли золотом.',
    verse_ivrit: 'וְאֶת-הַקְּרָשִׁים צִפָּה זָהָב וְאֶת-טַבְּעֹתָם עָשָׂה זָהָב בָּתִּים לַבְּרִיחִם וַיְצַף אֶת-הַבְּרִיחִם זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Сделали полог из синей, багряной и пурпуровой нити и скрученного тонкого льна и выткали на нем [изображения] керувов.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַפָּרֹכֶת תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר מַעֲשֵׂה חֹשֵׁב עָשָׂה אֹתָהּ כְּרֻבִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Для него сделали четыре столба из акации и покрыли их золотом, [сделали] золотые крючки на них и отлили [для столбов] четыре серебряных подножия.',
    verse_ivrit: 'וַיַּעַשׂ לָהּ אַרְבָּעָה עַמּוּדֵי שִׁטִּים וַיְצַפֵּם זָהָב וָוֵיהֶם זָהָב וַיִּצֹק לָהֶם אַרְבָּעָה אַדְנֵי-כָסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'У входа в шатер сделали завесу — из синей, багряной и пурпуровой нити и скрученного тонкого льна, работы вышивальщика.',
    verse_ivrit: 'וַיַּעַשׂ מָסָךְ לְפֶתַח הָאֹהֶל תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר מַעֲשֵׂה רֹקֵם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 36,
    id_chapter_two: 1,
    poemNumber: 38,
    verse: '[Сделали] для нее пять столбов, с крючками; их верхушки и пояски покрыли золотом и [сделали] под них пять медных подножий. ',
    verse_ivrit: 'וְאֶת-עַמּוּדָיו חֲמִשָּׁה וְאֶת-וָוֵיהֶם וְצִפָּה רָאשֵׁיהֶם וַחֲשֻׁקֵיהֶם זָהָב וְאַדְנֵיהֶם חֲמִשָּׁה נְחֹשֶׁת',
    comment: '',
  },
  //////////////////////////////////////           3737373737373737373737373737373733737373737          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Бецалель сделал из древесины акации ковчег: два с половиной локтя длиной, полтора шириной и полтора — высотой.',
    verse_ivrit: 'וַיַּעַשׂ בְּצַלְאֵל אֶת-הָאָרֹן עֲצֵי שִׁטִּים אַמָּתַיִם וָחֵצִי אָרְכּוֹ וְאַמָּה וָחֵצִי רָחְבּוֹ וְאַמָּה וָחֵצִי קֹמָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Он покрыл [ковчег] изнутри и снаружи чистым золотом и сделал вокруг него золотую кайму,',
    verse_ivrit: 'וַיְצַפֵּהוּ זָהָב טָהוֹר מִבַּיִת וּמִחוּץ וַיַּעַשׂ לוֹ זֵר זָהָב סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'отлил для него четыре золотых кольца, [прикрепив их] на четырех углах: два кольца на одной стороне и два — на другой.',
    verse_ivrit: 'וַיִּצֹק לוֹ אַרְבַּע טַבְּעֹת זָהָב עַל אַרְבַּע פַּעֲמֹתָיו וּשְׁתֵּי טַבָּעֹת עַל-צַלְעוֹ הָאֶחָת וּשְׁתֵּי טַבָּעֹת עַל-צַלְעוֹ הַשֵּׁנִית',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Он сделал из древесины акации шесты,',
    verse_ivrit: 'וַיַּעַשׂ בַּדֵּי עֲצֵי שִׁטִּים וַיְצַף אֹתָם זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'покрыл их золотом и вложил шесты в кольца по обеим сторонам ковчега, чтобы носить его.',
    verse_ivrit: 'וַיָּבֵא אֶת-הַבַּדִּים בַּטַּבָּעֹת עַל צַלְעֹת הָאָרֹן לָשֵׂאת אֶת-הָאָרֹן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Он сделал крышку из чистого золота, два с половиной локтя длиной и полтора локтя шириной,',
    verse_ivrit: 'וַיַּעַשׂ כַּפֹּרֶת זָהָב טָהוֹר אַמָּתַיִם וָחֵצִי אָרְכָּהּ וְאַמָּה וָחֵצִי רָחְבָּהּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'и сделал из золота двух керувов. Чеканной работы сделал он их, на обоих краях крышки:',
    verse_ivrit: 'וַיַּעַשׂ שְׁנֵי כְרֻבִים זָהָב מִקְשָׁה עָשָׂה אֹתָם מִשְּׁנֵי קְצוֹת הַכַּפֹּרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'одного керува с одного края, а другого — с другого края, и сделал так, что крышка и керувы составляли единое целое.',
    verse_ivrit: 'כְּרוּב-אֶחָד מִקָּצָה מִזֶּה וּכְרוּב-אֶחָד מִקָּצָה מִזֶּה מִן-הַכַּפֹּרֶת עָשָׂה אֶת-הַכְּרֻבִים מִשְּׁנֵי קצוותו (קְצוֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Крылья керувов были подняты вверх и распростерты над крышкой. Лица керувов, обращенные друг к другу, были наклонены к крышке.',
    verse_ivrit: 'וַיִּהְיוּ הַכְּרֻבִים פֹּרְשֵׂי כְנָפַיִם לְמַעְלָה סֹכְכִים בְּכַנְפֵיהֶם עַל-הַכַּפֹּרֶת וּפְנֵיהֶם אִישׁ אֶל-אָחִיו אֶל-הַכַּפֹּרֶת הָיוּ פְּנֵי הַכְּרֻבִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Он сделал стол из древесины акации — два локтя длиной, локоть шириной и полтора локтя высотой, —',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַשֻּׁלְחָן עֲצֵי שִׁטִּים אַמָּתַיִם אָרְכּוֹ וְאַמָּה רָחְבּוֹ וְאַמָּה וָחֵצִי קֹמָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'покрыл его чистым золотом и сделал вокруг него золотую кайму.',
    verse_ivrit: 'וַיְצַף אֹתוֹ זָהָב טָהוֹר וַיַּעַשׂ לוֹ זֵר זָהָב סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Вокруг [стола] он сделал раму шириной в ладонь, а вокруг рамы —',
    verse_ivrit: 'וַיַּעַשׂ לוֹ מִסְגֶּרֶת טֹפַח סָבִיב וַיַּעַשׂ זֵר-זָהָב לְמִסְגַּרְתּוֹ סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'золотую кайму, и отлил для [стола] четыре золотых кольца, и прикрепил эти кольца к углам, возле четырех ножек.',
    verse_ivrit: 'וַיִּצֹק לוֹ אַרְבַּע טַבְּעֹת זָהָב וַיִּתֵּן אֶת-הַטַּבָּעֹת עַל אַרְבַּע הַפֵּאֹת אֲשֶׁר לְאַרְבַּע רַגְלָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Кольца были [прикреплены] рядом с рамой, чтобы вставлять в них шесты для переноски стола.',
    verse_ivrit: 'לְעֻמַּת הַמִּסְגֶּרֶת הָיוּ הַטַּבָּעֹת בָּתִּים לַבַּדִּים לָשֵׂאת אֶת-הַשֻּׁלְחָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Он сделал из древесины акации шесты для переноски стола и покрыл их золотом.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַבַּדִּים עֲצֵי שִׁטִּים וַיְצַף אֹתָם זָהָב לָשֵׂאת אֶת-הַשֻּׁלְחָן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Он сделал утварь для стола: подносы [для хлебов], чаши [для ладана], стержни и покрытия, [всё] из чистого золота.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַכֵּלִים אֲשֶׁר עַל-הַשֻּׁלְחָן אֶת-קְעָרֹתָיו וְאֶת-כַּפֹּתָיו וְאֵת מְנַקִּיֹּתָיו וְאֶת-הַקְּשָׂו‍ֹת אֲשֶׁר יֻסַּךְ בָּהֵן זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Он сделал светильник из чистого золота. Светильник этот он сделал чеканным: его основание, ветви, чашечки, завязи и цветки составляли единое целое.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַמְּנֹרָה זָהָב טָהוֹר מִקְשָׁה עָשָׂה אֶת-הַמְּנֹרָה יְרֵכָהּ וְקָנָהּ גְּבִיעֶיהָ כַּפְתֹּרֶיהָ וּפְרָחֶיהָ מִמֶּנָּה הָיוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'От его боков отходили шесть ветвей: три ветви светильника — от одного бока, три ветви светильника — от другого бока.',
    verse_ivrit: 'וְשִׁשָּׁה קָנִים יֹצְאִים מִצִּדֶּיהָ שְׁלֹשָׁה קְנֵי מְנֹרָה מִצִּדָּהּ הָאֶחָד וּשְׁלֹשָׁה קְנֵי מְנֹרָה מִצִּדָּהּ הַשֵּׁנִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'На каждой из шести ветвей, отходящих от светильника, было по три миндалевидные чашечки с завязями и цветками.',
    verse_ivrit: 'שְׁלֹשָׁה גְבִעִים מְשֻׁקָּדִים בַּקָּנֶה הָאֶחָד כַּפְתֹּר וָפֶרַח וּשְׁלֹשָׁה גְבִעִים מְשֻׁקָּדִים בְּקָנֶה אֶחָד כַּפְתֹּר וָפָרַח כֵּן לְשֵׁשֶׁת הַקָּנִים הַיֹּצְאִים מִן-הַמְּנֹרָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'И на самом светильнике было четыре миндалевидные чашечки с завязями и цветками.',
    verse_ivrit: 'וּבַמְּנֹרָה אַרְבָּעָה גְבִעִים מְשֻׁקָּדִים כַּפְתֹּרֶיהָ וּפְרָחֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'У шести ветвей, отходящих от светильника, — завязь под [первой] парой ветвей, завязь под [второй] парой ветвей и завязь под [третьей] парой ветвей.',
    verse_ivrit: 'וְכַפְתֹּר תַּחַת שְׁנֵי הַקָּנִים מִמֶּנָּה וְכַפְתֹּר תַּחַת שְׁנֵי הַקָּנִים מִמֶּנָּה וְכַפְתֹּר תַּחַת-שְׁנֵי הַקָּנִים מִמֶּנָּה לְשֵׁשֶׁת הַקָּנִים הַיֹּצְאִים מִמֶּנָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Их завязи и ветви составляли [со светильником] единое целое: весь он был выкован из цельного куска чистого золота.',
    verse_ivrit: 'כַּפְתֹּרֵיהֶם וּקְנֹתָם מִמֶּנָּה הָיוּ כֻּלָּהּ מִקְשָׁה אַחַת זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: '[Для светильника] он сделал семь ламп, щипцы и совки — [также] из чистого золота.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-נֵרֹתֶיהָ שִׁבְעָה וּמַלְקָחֶיהָ וּמַחְתֹּתֶיהָ זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'Из кикара чистого золота сделал он [светильник] и все его принадлежности.',
    verse_ivrit: 'כִּכָּר זָהָב טָהוֹר עָשָׂה אֹתָהּ וְאֵת כָּל-כֵּלֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Он сделал из древесины акации жертвенник для воскурений — квадратный, локоть длиной, локоть шириной и два локтя высотой. Выступы по углам [жертвенника] составляли с ним единое целое.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-מִזְבַּח הַקְּטֹרֶת עֲצֵי שִׁטִּים אַמָּה אָרְכּוֹ וְאַמָּה רָחְבּוֹ רָבוּעַ וְאַמָּתַיִם קֹמָתוֹ מִמֶּנּוּ הָיוּ קַרְנֹתָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Его покрыли чистым золотом — верх, стены вокруг и выступы — и сделали вокруг него золотую кайму.',
    verse_ivrit: 'וַיְצַף אֹתוֹ זָהָב טָהוֹר אֶת-גַּגּוֹ וְאֶת-קִירֹתָיו סָבִיב וְאֶת-קַרְנֹתָיו וַיַּעַשׂ לוֹ זֵר זָהָב סָבִיב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'К [жертвеннику] он приделал два золотых кольца — под каймой, на двух его сторонах, с обоих его боков, — как вместилища для шестов, на которых его должны носить.',
    verse_ivrit: 'וּשְׁתֵּי טַבְּעֹת זָהָב עָשָׂה-לוֹ מִתַּחַת לְזֵרוֹ עַל שְׁתֵּי צַלְעֹתָיו עַל שְׁנֵי צִדָּיו לְבָתִּים לְבַדִּים לָשֵׂאת אֹתוֹ בָּהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'Из древесины акации он сделал шесты и покрыл их золотом.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַבַּדִּים עֲצֵי שִׁטִּים וַיְצַף אֹתָם זָהָב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 37,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Он сделал священное масло для помазания и искусно составленную чистую смесь благовоний для воскурения.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-שֶׁמֶן הַמִּשְׁחָה קֹדֶשׁ וְאֶת-קְטֹרֶת הַסַּמִּים טָהוֹר מַעֲשֵׂה רֹקֵחַ',
    comment: '',
  },
  //////////////////////////////////////           383838383833838338383838838          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Он сделал из древесины акации жертвенник для всесожжений — квадратный, пять локтей длиной, пять локтей шириной и три локтя высотой.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-מִזְבַּח הָעֹלָה עֲצֵי שִׁטִּים חָמֵשׁ אַמּוֹת אָרְכּוֹ וְחָמֵשׁ-אַמּוֹת רָחְבּוֹ רָבוּעַ וְשָׁלֹשׁ אַמּוֹת קֹמָתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'По четырем его углам он сделал выступы — они составляли с [жертвенником] единое целое — и покрыл его медью.',
    verse_ivrit: 'וַיַּעַשׂ קַרְנֹתָיו עַל אַרְבַּע פִּנֹּתָיו מִמֶּנּוּ הָיוּ קַרְנֹתָיו וַיְצַף אֹתוֹ נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Он сделал все принадлежности жертвенника — горшки, лопатки, чаши, вилки и совки; все эти принадлежности сделали из меди.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-כָּל-כְּלֵי הַמִּזְבֵּחַ אֶת-הַסִּירֹת וְאֶת-הַיָּעִים וְאֶת-הַמִּזְרָקֹת אֶת-הַמִּזְלָגֹת וְאֶת-הַמַּחְתֹּת כָּל-כֵּלָיו עָשָׂה נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Он сделал для жертвенника решетку — медную сетку — под нижним краем жертвенника, чтобы сетка доходила до половины жертвенника.',
    verse_ivrit: 'וַיַּעַשׂ לַמִּזְבֵּחַ מִכְבָּר מַעֲשֵׂה רֶשֶׁת נְחֹשֶׁת תַּחַת כַּרְכֻּבּוֹ מִלְּמַטָּה עַד-חֶצְיוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Он отлил четыре кольца на четырех углах медной решетки как вместилища для шестов,',
    verse_ivrit: 'וַיִּצֹק אַרְבַּע טַבָּעֹת בְּאַרְבַּע הַקְּצָו‍ֹת לְמִכְבַּר הַנְּחֹשֶׁת בָּתִּים לַבַּדִּים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'сделал шесты из древесины акации и покрыл их медью,',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַבַּדִּים עֲצֵי שִׁטִּים וַיְצַף אֹתָם נְחֹשֶׁת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'вложил шесты в кольца по бокам жертвенника, чтобы носить его на них, [а жертвенник] сделал полым, из досок.',
    verse_ivrit: 'וַיָּבֵא אֶת-הַבַּדִּים בַּטַּבָּעֹת עַל צַלְעֹת הַמִּזְבֵּחַ לָשֵׂאת אֹתוֹ בָּהֶם נְבוּב לֻחֹת עָשָׂה אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Сделал он медный чан и медную подставку под него — из зеркал [женщин], которые собирались у входа в Шатер Встречи.',
    verse_ivrit: 'וַיַּעַשׂ אֵת הַכִּיּוֹר נְחֹשֶׁת וְאֵת כַּנּוֹ נְחֹשֶׁת בְּמַרְאֹת הַצֹּבְאֹת אֲשֶׁר צָבְאוּ פֶּתַח אֹהֶל מוֹעֵד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Он сделал двор. С южной стороны — занавес из скрученного тонкого льна в сто локтей;',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הֶחָצֵר לִפְאַת נֶגֶב תֵּימָנָה קַלְעֵי הֶחָצֵר שֵׁשׁ מָשְׁזָר מֵאָה בָּאַמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'столбов для него — двадцать, медных подножий под них — двадцать, а крючки у столбов и пояски на них — из серебра.',
    verse_ivrit: 'עַמּוּדֵיהֶם עֶשְׂרִים וְאַדְנֵיהֶם עֶשְׂרִים נְחֹשֶׁת וָוֵי הָעַמּוּדִים וַחֲשֻׁקֵיהֶם כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'С северной стороны — [занавес] в сто локтей; столбов для него — двадцать, медных подножий под них — двадцать, а крючки у столбов и пояски на них — из серебра.',
    verse_ivrit: 'וְלִפְאַת צָפוֹן מֵאָה בָאַמָּה עַמּוּדֵיהֶם עֶשְׂרִים וְאַדְנֵיהֶם עֶשְׂרִים נְחֹשֶׁת וָוֵי הָעַמּוּדִים וַחֲשֻׁקֵיהֶם כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'С западной стороны — занавес в пятьдесят локтей; столбов для него — десять, подножий под них — десять, а крючки столбов и пояски на них — из серебра.',
    verse_ivrit: 'וְלִפְאַת-יָם קְלָעִים חֲמִשִּׁים בָּאַמָּה עַמּוּדֵיהֶם עֲשָׂרָה וְאַדְנֵיהֶם עֲשָׂרָה וָוֵי הָעַמֻּדִים וַחֲשׁוּקֵיהֶם כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Передняя, восточная сторона — в пятьдесят локтей:',
    verse_ivrit: 'וְלִפְאַת קֵדְמָה מִזְרָחָה חֲמִשִּׁים אַמָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'занавес в пятнадцать локтей для одной стороны, столбов для него — три, подножий под них — три,',
    verse_ivrit: 'קְלָעִים חֲמֵשׁ-עֶשְׂרֵה אַמָּה אֶל-הַכָּתֵף עַמּוּדֵיהֶם שְׁלֹשָׁה וְאַדְנֵיהֶם שְׁלֹשָׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'и для другой стороны, [то есть] по обе стороны ворот двора, — занавес в пятнадцать локтей, столбов к нему — три, подножий под них — три.',
    verse_ivrit: 'וְלַכָּתֵף הַשֵּׁנִית מִזֶּה וּמִזֶּה לְשַׁעַר הֶחָצֵר קְלָעִים חֲמֵשׁ עֶשְׂרֵה אַמָּה עַמֻּדֵיהֶם שְׁלֹשָׁה וְאַדְנֵיהֶם שְׁלֹשָׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Все занавесы вокруг двора — из скрученного тонкого льна.',
    verse_ivrit: 'כָּל-קַלְעֵי הֶחָצֵר סָבִיב שֵׁשׁ מָשְׁזָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Подножия для столбов — из меди, крючки же столбов и пояски на них — из серебра, и облицовка их верхушек — из серебра; все столбы двора были окружены серебряными поясками.',
    verse_ivrit: 'וְהָאֲדָנִים לָעַמֻּדִים נְחֹשֶׁת וָוֵי הָעַמּוּדִים וַחֲשׁוּקֵיהֶם כֶּסֶף וְצִפּוּי רָאשֵׁיהֶם כָּסֶף וְהֵם מְחֻשָּׁקִים כֶּסֶף כֹּל עַמֻּדֵי הֶחָצֵר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'А завеса для ворот двора — работы вышивальщика, из синей, багряной и пурпуровой нити и скрученного тонкого льна, в двадцать локтей длиной, а высотой, при ширине в пять локтей, соответственно завесам двора.',
    verse_ivrit: 'וּמָסַךְ שַׁעַר הֶחָצֵר מַעֲשֵׂה רֹקֵם תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר וְעֶשְׂרִים אַמָּה אֹרֶךְ וְקוֹמָה בְרֹחַב חָמֵשׁ אַמּוֹת לְעֻמַּת קַלְעֵי הֶחָצֵר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Столбов к ней — четыре, и медных подножий под них — четыре; их крючки — из серебра, облицовка их верхушек и пояски на них — из серебра.',
    verse_ivrit: 'וְעַמֻּדֵיהֶם אַרְבָּעָה וְאַדְנֵיהֶם אַרְבָּעָה נְחֹשֶׁת וָוֵיהֶם כֶּסֶף וְצִפּוּי רָאשֵׁיהֶם וַחֲשֻׁקֵיהֶם כָּסֶף',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ВАЯКГЕЛЬ(И собрал общину)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Все колья для Святилища и вокруг двора были медными.',
    verse_ivrit: 'וְכָל-הַיְתֵדֹת לַמִּשְׁכָּן וְלֶחָצֵר סָבִיב נְחֹשֶׁת',
    comment: '',
  },
  //////////////////////////////////////           383838383833838338383838838          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 21,
    verse: 'Вот перечень [расходов] на Святилище, Святилище Свидетельства, который был составлен по распоряжению Моше левитами под началом Итамара, сына Аѓарона, священника.',
    verse_ivrit: 'אֵלֶּה פְקוּדֵי הַמִּשְׁכָּן מִשְׁכַּן הָעֵדֻת אֲשֶׁר פֻּקַּד עַל-פִּי מֹשֶׁה עֲבֹדַת הַלְוִיִּם בְּיַד אִיתָמָר בֶּן-אַהֲרֹן הַכֹּהֵן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 22,
    verse: 'Бецалель же, сын Ури, сын Хура, из колена Йеѓуды, сделал все, что Господь повелел через Моше.',
    verse_ivrit: 'וּבְצַלְאֵל בֶּן-אוּרִי בֶן-חוּר לְמַטֵּה יְהוּדָה עָשָׂה אֵת כָּל-אֲשֶׁר-צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 23,
    verse: 'С ним был Оѓолиав, сын Ахисамаха, из колена Дана, — резчик, искусный ткач и вышивальщик, [работавший] с синей, багряной и пурпуровой нитью и льняной тканью.',
    verse_ivrit: 'וְאִתּוֹ אָהֳלִיאָב בֶּן-אֲחִיסָמָךְ לְמַטֵּה-דָן חָרָשׁ וְחֹשֵׁב וְרֹקֵם בַּתְּכֵלֶת וּבָאַרְגָּמָן וּבְתוֹלַעַת הַשָּׁנִי וּבַשֵּׁשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 24,
    verse: 'Золото, использованное в этом священном труде, составило: золото приношений — двадцать девять кикаров и семьсот тридцать шекелей, в священных шекелях.',
    verse_ivrit: 'כָּל-הַזָּהָב הֶעָשׂוּי לַמְּלָאכָה בְּכֹל מְלֶאכֶת הַקֹּדֶשׁ וַיְהִי זְהַב הַתְּנוּפָה תֵּשַׁע וְעֶשְׂרִים כִּכָּר וּשְׁבַע מֵאוֹת וּשְׁלֹשִׁים שֶׁקֶל בְּשֶׁקֶל הַקֹּדֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 25,
    verse: 'А также серебро, [собранное] при исчислении общины, — сто кикаров и тысяча семьсот семьдесят пять шекелей, в священных шекелях.',
    verse_ivrit: 'וְכֶסֶף פְּקוּדֵי הָעֵדָה מְאַת כִּכָּר וְאֶלֶף וּשְׁבַע מֵאוֹת וַחֲמִשָּׁה וְשִׁבְעִים שֶׁקֶל בְּשֶׁקֶל הַקֹּדֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 26,
    verse: 'С каждого, кто подлежал переписи, — от двадцатилетнего возраста и старше, с шестисот трех тысяч пятисот пятидесяти [человек, — взяли] по [одной] беке с головы, [то есть] по половине шекеля, в священных шекелях.',
    verse_ivrit: 'בֶּקַע לַגֻּלְגֹּלֶת מַחֲצִית הַשֶּׁקֶל בְּשֶׁקֶל הַקֹּדֶשׁ לְכֹל הָעֹבֵר עַל-הַפְּקֻדִים מִבֶּן עֶשְׂרִים שָׁנָה וָמַעְלָה לְשֵׁשׁ-מֵאוֹת אֶלֶף וּשְׁלֹשֶׁת אֲלָפִים וַחֲמֵשׁ מֵאוֹת וַחֲמִשִּׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 27,
    verse: 'На литье подножий Святилища и подножий для полога пошло сто кикаров серебра: сто кикаров — на сто подножий, по кикару на подножие.',
    verse_ivrit: 'וַיְהִי מְאַת כִּכַּר הַכֶּסֶף לָצֶקֶת אֵת אַדְנֵי הַקֹּדֶשׁ וְאֵת אַדְנֵי הַפָּרֹכֶת מְאַת אֲדָנִים לִמְאַת הַכִּכָּר כִּכָּר לָאָדֶן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 28,
    verse: 'Тысяча семьсот семьдесят пять [шекелей серебра] пошли на крючки к столбам, на облицовку верхушек [столбов] и на пояски для них.',
    verse_ivrit: 'וְאֶת-הָאֶלֶף וּשְׁבַע הַמֵּאוֹת וַחֲמִשָּׁה וְשִׁבְעִים עָשָׂה וָוִים לָעַמּוּדִים וְצִפָּה רָאשֵׁיהֶם וְחִשַּׁק אֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 29,
    verse: 'Медь приношений составила семьдесят кикаров и две тысячи четыреста шекелей.',
    verse_ivrit: 'וּנְחֹשֶׁת הַתְּנוּפָה שִׁבְעִים כִּכָּר וְאַלְפַּיִם וְאַרְבַּע-מֵאוֹת שָׁקֶל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 30,
    verse: 'Из нее сделали подножия для входа в Шатер Встречи, медный жертвенник, медную решетку к нему и все принадлежности жертвенника,',
    verse_ivrit: 'וַיַּעַשׂ בָּהּ אֶת-אַדְנֵי פֶּתַח אֹהֶל מוֹעֵד וְאֵת מִזְבַּח הַנְּחֹשֶׁת וְאֶת-מִכְבַּר הַנְּחֹשֶׁת אֲשֶׁר-לוֹ וְאֵת כָּל-כְּלֵי הַמִּזְבֵּחַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 38,
    id_chapter_two: 2,
    poemNumber: 31,
    verse: 'подножия для [столбов] вокруг двора, подножия для ворот двора, все колья Святилища и все колья вокруг двора.',
    verse_ivrit: 'וְאֶת-אַדְנֵי הֶחָצֵר סָבִיב וְאֶת-אַדְנֵי שַׁעַר הֶחָצֵר וְאֵת כָּל-יִתְדֹת הַמִּשְׁכָּן וְאֶת-כָּל-יִתְדֹת הֶחָצֵר סָבִיב',
    comment: '',
  },
  //////////////////////////////////////           39393939393393933939339393939939393939          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Из синей, багряной и пурпуровой нити сделали служебные одежды для служения в Святилище и изготовили священные одежды Аѓарону, как повелел Моше Господь. ',
    verse_ivrit: 'וּמִן-הַתְּכֵלֶת וְהָאַרְגָּמָן וְתוֹלַעַת הַשָּׁנִי עָשׂוּ בִגְדֵי-שְׂרָד לְשָׁרֵת בַּקֹּדֶשׁ וַיַּעֲשׂוּ אֶת-בִּגְדֵי הַקֹּדֶשׁ אֲשֶׁר לְאַהֲרֹן כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: 'Сделали эфод из золота, синей, багряной и пурпуровой нити и скрученного тонкого льна,',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הָאֵפֹד זָהָב תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'раскатали листы из золота и разрезали их на нити, чтобы выткать изображения по [тканям из] синей, багряной и пурпуровой нити и льну.',
    verse_ivrit: 'וַיְרַקְּעוּ אֶת-פַּחֵי הַזָּהָב וְקִצֵּץ פְּתִילִם לַעֲשׂוֹת בְּתוֹךְ הַתְּכֵלֶת וּבְתוֹךְ הָאַרְגָּמָן וּבְתוֹךְ תּוֹלַעַת הַשָּׁנִי וּבְתוֹךְ הַשֵּׁשׁ מַעֲשֵׂה חֹשֵׁב',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Приделали к [эфоду] соединяющие оплечья; он был пришит [к ним] на обоих углах.',
    verse_ivrit: 'כְּתֵפֹת עָשׂוּ-לוֹ חֹבְרֹת עַל-שְׁנֵי קצוותו (קְצוֹתָיו) חֻבָּר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Кушак поверх эфода составлял с ним единое целое, будучи соткан подобно ему — из золота, синей, багряной и пурпуровой нити и скрученного тонкого льна, как повелел Моше Господь.',
    verse_ivrit: 'וְחֵשֶׁב אֲפֻדָּתוֹ אֲשֶׁר עָלָיו מִמֶּנּוּ הוּא כְּמַעֲשֵׂהוּ זָהָב תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Приготовили ониксы, обрамленные золотыми оправами, с выгравированными, как резьба на печатях, именами сынов Израиля.',
    verse_ivrit: 'וַיַּעֲשׂוּ אֶת-אַבְנֵי הַשֹּׁהַם מֻסַבֹּת מִשְׁבְּצֹת זָהָב מְפֻתָּחֹת פִּתּוּחֵי חוֹתָם עַל-שְׁמוֹת בְּנֵי יִשְׂרָאֵל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Поместили их — камни для напоминания о сынах Израиля — на оплечья эфода, как повелел Моше Господь.',
    verse_ivrit: 'וַיָּשֶׂם אֹתָם עַל כִּתְפֹת הָאֵפֹד אַבְנֵי זִכָּרוֹן לִבְנֵי יִשְׂרָאֵל כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Нагрудник сделали так же, как и эфод, — с вытканными изображениями, из золота, синей, багряной и пурпуровой нити и скрученного тонкого льна.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-הַחֹשֶׁן מַעֲשֵׂה חֹשֵׁב כְּמַעֲשֵׂה אֵפֹד זָהָב תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי וְשֵׁשׁ מָשְׁזָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Нагрудник был квадратным; его сделали сдвоенным. [Он был] в пядь длиной и в пядь шириной, сдвоенный.',
    verse_ivrit: 'רָבוּעַ הָיָה כָּפוּל עָשׂוּ אֶת-הַחֹשֶׁן זֶרֶת אָרְכּוֹ וְזֶרֶת רָחְבּוֹ כָּפוּל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'В него вставили четыре ряда камней. Один ряд: рубин, топаз и изумруд.',
    verse_ivrit: 'וַיְמַלְאוּ-בוֹ אַרְבָּעָה טוּרֵי אָבֶן טוּר אֹדֶם פִּטְדָה וּבָרֶקֶת הַטּוּר הָאֶחָד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Второй ряд: бирюза, сапфир и алмаз.',
    verse_ivrit: 'וְהַטּוּר הַשֵּׁנִי נֹפֶךְ סַפִּיר וְיָהֲלֹם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Третий ряд: опал, агат и аметист.',
    verse_ivrit: 'וְהַטּוּר הַשְּׁלִישִׁי לֶשֶׁם שְׁבוֹ וְאַחְלָמָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Четвертый ряд: хризолит, оникс и яшма.',
    verse_ivrit: 'וְהַטּוּר הָרְבִיעִי תַּרְשִׁישׁ שֹׁהַם וְיָשְׁפֵה מוּסַבֹּת מִשְׁבְּצֹת זָהָב בְּמִלֻּאֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Камни эти — по именам сынов Израиля. Их было двенадцать, согласно именам. На каждом [камне, как на] печати, было выгравировано имя одного из двенадцати колен.',
    verse_ivrit: 'וְהָאֲבָנִים עַל-שְׁמֹת בְּנֵי-יִשְׂרָאֵל הֵנָּה שְׁתֵּים עֶשְׂרֵה עַל-שְׁמֹתָם פִּתּוּחֵי חֹתָם אִישׁ עַל-שְׁמוֹ לִשְׁנֵים עָשָׂר שָׁבֶט',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Сделали к нагруднику цепочки, плетеной работы, из чистого золота.',
    verse_ivrit: 'וַיַּעֲשׂוּ עַל-הַחֹשֶׁן שַׁרְשְׁרֹת גַּבְלֻת מַעֲשֵׂה עֲבֹת זָהָב טָהוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Сделали две оправы из золота и два золотых кольца и прикрепили два кольца к двум углам нагрудника.',
    verse_ivrit: 'וַיַּעֲשׂוּ שְׁתֵּי מִשְׁבְּצֹת זָהָב וּשְׁתֵּי טַבְּעֹת זָהָב וַיִּתְּנוּ אֶת-שְׁתֵּי הַטַּבָּעֹת עַל-שְׁנֵי קְצוֹת הַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Продели две золотые плетеные [цепочки] в два кольца на углах нагрудника.',
    verse_ivrit: 'וַיִּתְּנוּ שְׁתֵּי הָעֲבֹתֹת הַזָּהָב עַל-שְׁתֵּי הַטַּבָּעֹת עַל-קְצוֹת הַחֹשֶׁן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Два конца двух плетеных [цепочек] продели в две оправы, прикрепив их к оплечьям эфода на лицевой стороне.',
    verse_ivrit: 'וְאֵת שְׁתֵּי קְצוֹת שְׁתֵּי הָעֲבֹתֹת נָתְנוּ עַל-שְׁתֵּי הַמִּשְׁבְּצֹת וַיִּתְּנֻם עַל-כִּתְפֹת הָאֵפֹד אֶל-מוּל פָּנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: 'Сделали два золотых кольца и прикрепили их к двум углам нагрудника, с внутренней стороны, обращенной к эфоду.',
    verse_ivrit: 'וַיַּעֲשׂוּ שְׁתֵּי טַבְּעֹת זָהָב וַיָּשִׂימוּ עַל-שְׁנֵי קְצוֹת הַחֹשֶׁן עַל-שְׂפָתוֹ אֲשֶׁר אֶל-עֵבֶר הָאֵפֹד בָּיְתָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Сделали два золотых кольца и прикрепили их к двум оплечьям эфода, снизу, на лицевой стороне, около шва над кушаком эфода.',
    verse_ivrit: 'וַיַּעֲשׂוּ שְׁתֵּי טַבְּעֹת זָהָב וַיִּתְּנֻם עַל-שְׁתֵּי כִתְפֹת הָאֵפֹד מִלְּמַטָּה מִמּוּל פָּנָיו לְעֻמַּת מַחְבַּרְתּוֹ מִמַּעַל לְחֵשֶׁב הָאֵפֹד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'Соединили кольца нагрудника с кольцами эфода шнуром из синей нити — так, чтобы нагрудник, находясь над кушаком эфода, вплотную прилегал к эфоду, — как повелел Моше Господь. ',
    verse_ivrit: 'וַיִּרְכְּסוּ אֶת-הַחֹשֶׁן מִטַּבְּעֹתָיו אֶל-טַבְּעֹת הָאֵפֹד בִּפְתִיל תְּכֵלֶת לִהְיֹת עַל-חֵשֶׁב הָאֵפֹד וְלֹא-יִזַּח הַחֹשֶׁן מֵעַל הָאֵפֹד כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'Сделали плащ к эфоду, тканой работы, весь из синей пряжи.',
    verse_ivrit: 'וַיַּעַשׂ אֶת-מְעִיל הָאֵפֹד מַעֲשֵׂה אֹרֵג כְּלִיל תְּכֵלֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Отверстие плаща было соткано так, как делают ворот кольчуги, чтобы не рвалось.',
    verse_ivrit: 'וּפִי-הַמְּעִיל בְּתוֹכוֹ כְּפִי תַחְרָא שָׂפָה לְפִיו סָבִיב לֹא יִקָּרֵעַ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'По краю плаща сделали [подвески в виде] гранатовых плодов из синей, багряной и пурпуровой нити и тонкого льна.',
    verse_ivrit: 'וַיַּעֲשׂוּ עַל-שׁוּלֵי הַמְּעִיל רִמּוֹנֵי תְּכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי מָשְׁזָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'Сделали колокольчики из чистого золота и подвесили эти колокольчики между гранатовыми плодами по краю плаща.',
    verse_ivrit: 'וַיַּעֲשׂוּ פַעֲמֹנֵי זָהָב טָהוֹר וַיִּתְּנוּ אֶת-הַפַּעֲמֹנִים בְּתוֹךְ הָרִמֹּנִים עַל-שׁוּלֵי הַמְּעִיל סָבִיב בְּתוֹךְ הָרִמֹּנִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'Колокольчик и гранатовый плод, колокольчик и гранатовый плод — [так они чередовались] по краю плаща, [предназначенного] для служения, как повелел Моше Господь.',
    verse_ivrit: 'פַּעֲמֹן וְרִמֹּן פַּעֲמֹן וְרִמֹּן עַל-שׁוּלֵי הַמְּעִיל סָבִיב לְשָׁרֵת כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'Сделали льняную рубаху, ткацкой работы, — для Аѓарона и для его сыновей —',
    verse_ivrit: 'וַיַּעֲשׂוּ אֶת-הַכָּתְנֹת שֵׁשׁ מַעֲשֵׂה אֹרֵג לְאַהֲרֹן וּלְבָנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'и льняной тюрбан, и льняные головные повязки, и льняные штаны (из тонкой льняной ткани),',
    verse_ivrit: 'וְאֵת הַמִּצְנֶפֶת שֵׁשׁ וְאֶת-פַּאֲרֵי הַמִּגְבָּעֹת שֵׁשׁ וְאֶת-מִכְנְסֵי הַבָּד שֵׁשׁ מָשְׁזָר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'а также пояс из скрученного тонкого льна и синей, багряной и пурпуровой нити, ткацкой работы, как повелел Моше Господь.',
    verse_ivrit: 'וְאֶת-הָאַבְנֵט שֵׁשׁ מָשְׁזָר וּתְכֵלֶת וְאַרְגָּמָן וְתוֹלַעַת שָׁנִי מַעֲשֵׂה רֹקֵם כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Сделали [налобную] пластину — священный венец — из чистого золота и начертали на ней, выгравировав, как на печати: “Святыня Господу”.',
    verse_ivrit: 'וַיַּעֲשׂוּ אֶת-צִיץ נֵזֶר-הַקֹּדֶשׁ זָהָב טָהוֹר וַיִּכְתְּבוּ עָלָיו מִכְתַּב פִּתּוּחֵי חוֹתָם קֹדֶשׁ לַיהוָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Прикрепили к ней шнур из синей нити, чтобы налагать на тюрбан сверху, как повелел Моше Господь.',
    verse_ivrit: 'וַיִּתְּנוּ עָלָיו פְּתִיל תְּכֵלֶת לָתֵת עַל-הַמִּצְנֶפֶת מִלְמָעְלָה כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Этим кончилась вся работа для Святилища — Шатра Встречи. Сыны Израиля сделали все так, как повелел Моше Господь, — [все точно] так и сделали. ',
    verse_ivrit: 'וַתֵּכֶל כָּל-עֲבֹדַת מִשְׁכַּן אֹהֶל מוֹעֵד וַיַּעֲשׂוּ בְּנֵי יִשְׂרָאֵל כְּכֹל אֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה כֵּן עָשׂוּ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Принесли Святилище к Моше: шатер и все его принадлежности — застежки, брусья, стержни, столбы и подножия,',
    verse_ivrit: 'וַיָּבִיאוּ אֶת-הַמִּשְׁכָּן אֶל-מֹשֶׁה אֶת-הָאֹהֶל וְאֶת-כָּל-כֵּלָיו קְרָסָיו קְרָשָׁיו בְּרִיחָו וְעַמֻּדָיו וַאֲדָנָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'покров из красных бараньих кож, покров из кож тахашей и полог завесы,',
    verse_ivrit: 'וְאֶת-מִכְסֵה עוֹרֹת הָאֵילִם הַמְאָדָּמִים וְאֶת-מִכְסֵה עֹרֹת הַתְּחָשִׁים וְאֵת פָּרֹכֶת הַמָּסָךְ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'ковчег Свидетельства, его шесты и крышку,',
    verse_ivrit: 'אֶת-אֲרוֹן הָעֵדֻת וְאֶת-בַּדָּיו וְאֵת הַכַּפֹּרֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'стол со всеми его принадлежностями и хлеб предложения,',
    verse_ivrit: 'אֶת-הַשֻּׁלְחָן אֶת-כָּל-כֵּלָיו וְאֵת לֶחֶם הַפָּנִים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'светильник из чистого [золота], лампы, которые должны стоять на нем, все его принадлежности и масло для освещения,',
    verse_ivrit: 'אֶת-הַמְּנֹרָה הַטְּהֹרָה אֶת-נֵרֹתֶיהָ נֵרֹת הַמַּעֲרָכָה וְאֶת-כָּל-כֵּלֶיהָ וְאֵת שֶׁמֶן הַמָּאוֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 38,
    verse: 'масло для помазания, благовония для воскурений и завесу для входа в шатер,',
    verse_ivrit: 'וְאֵת מִזְבַּח הַזָּהָב וְאֵת שֶׁמֶן הַמִּשְׁחָה וְאֵת קְטֹרֶת הַסַּמִּים וְאֵת מָסַךְ פֶּתַח הָאֹהֶל',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 39,
    verse: 'медный жертвенник и его медную решетку, его шесты и все его принадлежности, занавесы двора, его столбы и подножия к ним, чан и подставку под него,',
    verse_ivrit: 'אֵת מִזְבַּח הַנְּחֹשֶׁת וְאֶת-מִכְבַּר הַנְּחֹשֶׁת אֲשֶׁר-לוֹ אֶת-בַּדָּיו וְאֶת-כָּל-כֵּלָיו אֶת-הַכִּיֹּר וְאֶת-כַּנּוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 40,
    verse: 'занавесы двора, его столбы и подножия к ним, завесу для ворот двора, его веревки, колья и всю утварь для служения в Святилище — Шатре Встречи,',
    verse_ivrit: 'אֵת קַלְעֵי הֶחָצֵר אֶת-עַמֻּדֶיהָ וְאֶת-אֲדָנֶיהָ וְאֶת-הַמָּסָךְ לְשַׁעַר הֶחָצֵר אֶת-מֵיתָרָיו וִיתֵדֹתֶיהָ וְאֵת כָּל-כְּלֵי עֲבֹדַת הַמִּשְׁכָּן לְאֹהֶל מוֹעֵד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 41,
    verse: 'служебные одежды для служения в Святилище, священные одежды Аѓарона-священника и одежды его сыновей для служения.',
    verse_ivrit: 'אֶת-בִּגְדֵי הַשְּׂרָד לְשָׁרֵת בַּקֹּדֶשׁ אֶת-בִּגְדֵי הַקֹּדֶשׁ לְאַהֲרֹן הַכֹּהֵן וְאֶת-בִּגְדֵי בָנָיו לְכַהֵן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 42,
    verse: 'Сыны Израиля сделали всю работу в точности так, как повелел Моше Господь.',
    verse_ivrit: 'כְּכֹל אֲשֶׁר-צִוָּה יְהוָה אֶת-מֹשֶׁה כֵּן עָשׂוּ בְּנֵי יִשְׂרָאֵל אֵת כָּל-הָעֲבֹדָה',
    comment: '',
  },
  
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 39,
    id_chapter_two: 1,
    poemNumber: 43,
    verse: 'Моше осмотрел всю работу, и оказалось, что они сделали все так, как повелел Господь, — [точно] так они и сделали. И Моше благословил их. ',
    verse_ivrit: 'וַיַּרְא מֹשֶׁה אֶת-כָּל-הַמְּלָאכָה וְהִנֵּה עָשׂוּ אֹתָהּ כַּאֲשֶׁר צִוָּה יְהוָה כֵּן עָשׂוּ וַיְבָרֶךְ אֹתָם מֹשֶׁה',
    comment: '',
  },
  //////////////////////////////////////           404040404040404040404040404040404040404          \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    main: 1,
    main_name: 'schmot',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 1,
    verse: 'Господь сказал Моше:',
    verse_ivrit: 'וַיְדַבֵּר יְהוָה אֶל-מֹשֶׁה לֵּאמֹר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 2,
    verse: '“В первый день первого месяца поставь Святилище — Шатер Встречи.',
    verse_ivrit: 'בְּיוֹם-הַחֹדֶשׁ הָרִאשׁוֹן בְּאֶחָד לַחֹדֶשׁ תָּקִים אֶת-מִשְׁכַּן אֹהֶל מוֹעֵד',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 3,
    verse: 'Помести там ковчег Свидетельства и повесь перед ним полог.',
    verse_ivrit: 'וְשַׂמְתָּ שָׁם אֵת אֲרוֹן הָעֵדוּת וְסַכֹּתָ עַל-הָאָרֹן אֶת-הַפָּרֹכֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 4,
    verse: 'Внеси стол и расставь на нем все, что нужно, внеси светильник и зажги его лампы.',
    verse_ivrit: 'וְהֵבֵאתָ אֶת-הַשֻּׁלְחָן וְעָרַכְתָּ אֶת-עֶרְכּוֹ וְהֵבֵאתָ אֶת-הַמְּנֹרָה וְהַעֲלֵיתָ אֶת-נֵרֹתֶיהָ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 5,
    verse: 'Поставь золотой жертвенник для воскурений перед ковчегом Свидетельства и повесь завесу на входе в Святилище.',
    verse_ivrit: 'וְנָתַתָּה אֶת-מִזְבַּח הַזָּהָב לִקְטֹרֶת לִפְנֵי אֲרוֹן הָעֵדֻת וְשַׂמְתָּ אֶת-מָסַךְ הַפֶּתַח לַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 6,
    verse: 'Поставь жертвенник для всесожжений перед входом в Святилище — Шатер Встречи. Между Шатром Встречи и жертвенником поставь чан и налей в него воды.',
    verse_ivrit: 'וְנָתַתָּה אֵת מִזְבַּח הָעֹלָה לִפְנֵי פֶּתַח מִשְׁכַּן אֹהֶל-מוֹעֵד. ז וְנָתַתָּ אֶת-הַכִּיֹּר בֵּין-אֹהֶל מוֹעֵד וּבֵין הַמִּזְבֵּחַ וְנָתַתָּ שָׁם מָיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 7,
    verse: 'Вокруг устрой двор и повесь завесу в воротах двора.',
    verse_ivrit: 'וְשַׂמְתָּ אֶת-הֶחָצֵר סָבִיב וְנָתַתָּ אֶת-מָסַךְ שַׁעַר הֶחָצֵר',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 8,
    verse: 'Возьми масло помазания и помажь Святилище и все, что в нем, — освяти его и всю его утварь, и станет оно святым.',
    verse_ivrit: 'וְלָקַחְתָּ אֶת-שֶׁמֶן הַמִּשְׁחָה וּמָשַׁחְתָּ אֶת-הַמִּשְׁכָּן וְאֶת-כָּל-אֲשֶׁר-בּוֹ וְקִדַּשְׁתָּ אֹתוֹ וְאֶת-כָּל-כֵּלָיו וְהָיָה קֹדֶשׁ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 9,
    verse: 'Помажь жертвенник для всесожжений и всю его утварь — освяти жертвенник, и станет он святыней святынь.',
    verse_ivrit: 'וּמָשַׁחְתָּ אֶת-מִזְבַּח הָעֹלָה וְאֶת-כָּל-כֵּלָיו וְקִדַּשְׁתָּ אֶת-הַמִּזְבֵּחַ וְהָיָה הַמִּזְבֵּחַ קֹדֶשׁ קָדָשִׁים',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 10,
    verse: 'Помажь чан и подставку под него — освяти их.',
    verse_ivrit: 'וּמָשַׁחְתָּ אֶת-הַכִּיֹּר וְאֶת-כַּנּוֹ וְקִדַּשְׁתָּ אֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 11,
    verse: 'Приведи Аѓарона и его сыновей ко входу в Шатер Встречи и омой их водою.',
    verse_ivrit: 'וְהִקְרַבְתָּ אֶת-אַהֲרֹן וְאֶת-בָּנָיו אֶל-פֶּתַח אֹהֶל מוֹעֵד וְרָחַצְתָּ אֹתָם בַּמָּיִם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 12,
    verse: 'Одень Аѓарона в священные одежды, помажь его и освяти для служения Мне.',
    verse_ivrit: 'וְהִלְבַּשְׁתָּ אֶת-אַהֲרֹן אֵת בִּגְדֵי הַקֹּדֶשׁ וּמָשַׁחְתָּ אֹתוֹ וְקִדַּשְׁתָּ אֹתוֹ וְכִהֵן לִי',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 13,
    verse: 'Подведи его сыновей и надень на них рубахи.',
    verse_ivrit: 'וְאֶת-בָּנָיו תַּקְרִיב וְהִלְבַּשְׁתָּ אֹתָם כֻּתֳּנֹת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 14,
    verse: 'Помажь их, как помазал их отца, для служения Мне. И будет так: помазание навеки закрепит за ними право священства — из поколения в поколение”.',
    verse_ivrit: 'וּמָשַׁחְתָּ אֹתָם כַּאֲשֶׁר מָשַׁחְתָּ אֶת-אֲבִיהֶם וְכִהֲנוּ לִי וְהָיְתָה לִהְיֹת לָהֶם מָשְׁחָתָם לִכְהֻנַּת עוֹלָם לְדֹרֹתָם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 15,
    verse: 'Моше сделал все так, как повелел ему Господь, — [точно] так он и сделал.',
    verse_ivrit: 'וַיַּעַשׂ מֹשֶׁה כְּכֹל אֲשֶׁר צִוָּה יְהוָה אֹתוֹ כֵּן עָשָׂה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 16,
    verse: 'Итак, в первый месяц второго года, в первый день месяца, Святилище было поставлено.',
    verse_ivrit: 'וַיְהִי בַּחֹדֶשׁ הָרִאשׁוֹן בַּשָּׁנָה הַשֵּׁנִית בְּאֶחָד לַחֹדֶשׁ הוּקַם הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 17,
    verse: 'Моше поставил Святилище: положил подножия, поставил брусья, вложил стержни и установил столбы.',
    verse_ivrit: 'וַיָּקֶם מֹשֶׁה אֶת-הַמִּשְׁכָּן וַיִּתֵּן אֶת-אֲדָנָיו וַיָּשֶׂם אֶת-קְרָשָׁיו וַיִּתֵּן אֶת-בְּרִיחָיו וַיָּקֶם אֶת-עַמּוּדָיו',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 18,
    verse: 'Он накрыл Святилище шатром, а сверху положил покрытие шатра, как повелел Моше Господь.',
    verse_ivrit: 'וַיִּפְרֹשׂ אֶת-הָאֹהֶל עַל-הַמִּשְׁכָּן וַיָּשֶׂם אֶת-מִכְסֵה הָאֹהֶל עָלָיו מִלְמָעְלָה כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 19,
    verse: '[Моше] взял Свидетельство и положил его в ковчег, [в кольца] ковчега продел шесты, а сверху положил на ковчег крышку.',
    verse_ivrit: 'וַיִּקַּח וַיִּתֵּן אֶת-הָעֵדֻת אֶל-הָאָרֹן וַיָּשֶׂם אֶת-הַבַּדִּים עַל-הָאָרֹן וַיִּתֵּן אֶת-הַכַּפֹּרֶת עַל-הָאָרֹן מִלְמָעְלָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 20,
    verse: 'Он внес ковчег в Святилище и повесил полог завесы, закрыв [ею] ковчег Свидетельства, как повелел Моше Господь.',
    verse_ivrit: 'וַיָּבֵא אֶת-הָאָרֹן אֶל-הַמִּשְׁכָּן וַיָּשֶׂם אֵת פָּרֹכֶת הַמָּסָךְ וַיָּסֶךְ עַל אֲרוֹן הָעֵדוּת כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 21,
    verse: 'На северной стороне Святилища — в Шатре Встречи — [Моше] поставил перед завесой стол',
    verse_ivrit: 'וַיִּתֵּן אֶת-הַשֻּׁלְחָן בְּאֹהֶל מוֹעֵד עַל יֶרֶךְ הַמִּשְׁכָּן צָפֹנָה מִחוּץ לַפָּרֹכֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 22,
    verse: 'и разложил на нем по порядку хлебы пред Господом, как повелел Моше Господь.',
    verse_ivrit: 'וַיַּעֲרֹךְ עָלָיו עֵרֶךְ לֶחֶם לִפְנֵי יְהוָה כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 23,
    verse: 'Напротив стола, на южной стороне Святилища — в Шатре Встречи — он поставил светильник',
    verse_ivrit: 'וַיָּשֶׂם אֶת-הַמְּנֹרָה בְּאֹהֶל מוֹעֵד נֹכַח הַשֻּׁלְחָן עַל יֶרֶךְ הַמִּשְׁכָּן נֶגְבָּה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 24,
    verse: 'и вознес пред Господом лампы, как повелел Моше Господь.',
    verse_ivrit: 'וַיַּעַל הַנֵּרֹת לִפְנֵי יְהוָה כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 25,
    verse: 'В Шатре Встречи, перед пологом, он поставил золотой жертвенник',
    verse_ivrit: 'וַיָּשֶׂם אֶת-מִזְבַּח הַזָּהָב בְּאֹהֶל מוֹעֵד לִפְנֵי הַפָּרֹכֶת',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 26,
    verse: 'и воскурил на нем благовонное курение, как повелел Моше Господь.',
    verse_ivrit: 'וַיַּקְטֵר עָלָיו קְטֹרֶת סַמִּים כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 27,
    verse: 'На входе в Святилище он повесил завесу.',
    verse_ivrit: 'וַיָּשֶׂם אֶת-מָסַךְ הַפֶּתַח לַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 28,
    verse: 'У входа в Святилище — в Шатер Встречи — он поставил жертвенник для всесожжений и принес на нем жертву всесожжения и приношение, как повелел Моше Господь.',
    verse_ivrit: 'וְאֵת מִזְבַּח הָעֹלָה שָׂם פֶּתַח מִשְׁכַּן אֹהֶל-מוֹעֵד וַיַּעַל עָלָיו אֶת-הָעֹלָה וְאֶת-הַמִּנְחָה כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 29,
    verse: 'Между Шатром Встречи и жертвенником он поставил чан и налил в него воду для омовений.',
    verse_ivrit: 'וַיָּשֶׂם אֶת-הַכִּיֹּר בֵּין-אֹהֶל מוֹעֵד וּבֵין הַמִּזְבֵּחַ וַיִּתֵּן שָׁמָּה מַיִם לְרָחְצָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 30,
    verse: 'Моше, Аѓарон и его сыновья омывали в нем руки и ноги.',
    verse_ivrit: 'וְרָחֲצוּ מִמֶּנּוּ מֹשֶׁה וְאַהֲרֹן וּבָנָיו אֶת-יְדֵיהֶם וְאֶת-רַגְלֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 31,
    verse: 'Когда они входили в Шатер Встречи или подходили к жертвеннику, то омывались, как повелел Моше Господь.',
    verse_ivrit: 'בְּבֹאָם אֶל-אֹהֶל מוֹעֵד וּבְקָרְבָתָם אֶל-הַמִּזְבֵּחַ יִרְחָצוּ כַּאֲשֶׁר צִוָּה יְהוָה אֶת-מֹשֶׁה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 32,
    verse: 'Вокруг Святилища и жертвенника он поставил двор и повесил завесу в воротах двора. ',
    verse_ivrit: 'וַיָּקֶם אֶת-הֶחָצֵר סָבִיב לַמִּשְׁכָּן וְלַמִּזְבֵּחַ וַיִּתֵּן אֶת-מָסַךְ שַׁעַר הֶחָצֵר וַיְכַל מֹשֶׁה אֶת-הַמְּלָאכָה',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 33,
    verse: 'Когда Моше закончил работу, облако окутало Шатер Встречи,',
    verse_ivrit: 'וַיְכַס הֶעָנָן אֶת-אֹהֶל מוֹעֵד וּכְבוֹד יְהוָה מָלֵא אֶת-הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 34,
    verse: 'Слава Господа наполнила Святилище. И Моше не мог войти в Шатер Встречи, когда на нем пребывало облако и Слава Господа наполняла Святилище.',
    verse_ivrit: 'וְלֹא-יָכֹל מֹשֶׁה לָבוֹא אֶל-אֹהֶל מוֹעֵד כִּי-שָׁכַן עָלָיו הֶעָנָן וּכְבוֹד יְהוָה מָלֵא אֶת-הַמִּשְׁכָּן',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 35,
    verse: 'Когда облако поднималось над Святилищем, то сыны Израиля отправлялись в путь, переходя на следующее место стоянки.',
    verse_ivrit: 'וּבְהֵעָלוֹת הֶעָנָן מֵעַל הַמִּשְׁכָּן יִסְעוּ בְּנֵי יִשְׂרָאֵל בְּכֹל מַסְעֵיהֶם',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 36,
    verse: 'Если же облако не поднималось, то они не отправлялись в путь, [а ждали], пока оно поднимется.',
    verse_ivrit: 'וְאִם-לֹא יֵעָלֶה הֶעָנָן וְלֹא יִסְעוּ עַד-יוֹם הֵעָלֹתוֹ',
    comment: '',
  },
  {
    name: 'ШМОТ',
    chapter: 'ПЕКУДЕЙ(Отчёты)',
    id_book: 1,
    id_chapter: 40,
    id_chapter_two: 1,
    poemNumber: 37,
    verse: 'Ибо днем над Святилищем стояло облако Господа, а ночью [в облаке пылал] огонь — на глазах всего дома Израиля, во время всех их переходов. ',
    verse_ivrit: 'כִּי עֲנַן יְהוָה עַל-הַמִּשְׁכָּן יוֹמָם וְאֵשׁ תִּהְיֶה לַיְלָה בּוֹ לְעֵינֵי כָל-בֵּית-יִשְׂרָאֵל בְּכָל-מַסְעֵיהֶם',
    comment: '',
  },
];
