import React from 'react';

const Start = () => {
  return (
    <div className="start">
      <div className="start__text">Краткие тезисы общины</div>
    </div>
  );
};

export default Start;
