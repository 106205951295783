import React from 'react';
import '../tora.scss';

const Vaikra = () => {
  return (
    <div className="book__fals">
      <div className="book__fals-one">VAIKRA</div>
    </div>
  );
};

export default Vaikra;
