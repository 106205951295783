import React from 'react';
import '../tora.scss';

const Shmot = () => {
  return (
    <>
      <div className="book__fals">
        <div className="book__fals-one">
          SHMOT
        </div>          
      </div>
    </>
  );
};

export default  Shmot;
