import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import './sidebar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { showHeight } from '../../slices/heightSlice';
import UseOutsideAlerter from '../listenerClick/UseOutsideAlerter';

const Sidebar = () => {
  const [isOpend, setIsOpend] = useState(false);
  const [h, setH] = useState(0);
  const [w, setW] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const sliceHeightHeader = useSelector((state) => state.par.heightHeader);
  const { ref, isShow, setIsShow } = UseOutsideAlerter(false);
  let scrollTop = 0;
  const [openIndex, setOpenIndex] = useState(null);
  const [chapters, setChapters] = useState([]);

  const menuItem = [
    {
      path: '/tora/beresheet',
      name: 'BERESHEET',
      icon: '/images/sidebar/bereshit.png',
    },
    {
      path: '/tora/schmot',
      name: 'SHMOT',
      icon: '/images/sidebar/shmot.png',
    },
    {
      path: '/tora/vaikra',
      name: 'VAIKRA',
      icon: '/images/sidebar/vaikra.png',
    },
    {
      path: '/tora/bemitbar',
      name: 'BEMITBAR',
      icon: '/images/sidebar/bemitbar.png',
    },
    {
      path: '/tora/dvarim',
      name: 'DVARIM',
      icon: '/images/sidebar/dvarim.png',
    },
  ];

  const toogle = () => {
    setIsOpend(!isOpend);
    setIsShow(!isShow);
  };
  const close = () => {
    if (isOpend) {
      setIsOpend(!isOpend);
      setIsShow(!isShow);
    }
  };

  useEffect(() => {
    if (!isShow) setIsOpend(false);
  }, [isShow]);

  window.onscroll = function () {
    scrollTop = window.pageYOffset
      ? window.pageYOffset
      : document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
    setH(scrollTop);
    if (scrollTop >= 120) dispatch(showHeight(scrollTop));
  };

  useEffect(() => {
    const handleResize = () => {
      setW(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDropdown = (index) => { // для того чтобы  при клики на главу не открывались все глави одновременно и также чтобы закрывались при нажатии на другую. Как идентивфикатор
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const searchChapter = (index) => {
    const data = JSON.parse(localStorage.getItem('tanahArr'));
    let arrChapter = []

    for (let i = 0; i < data.length; i++) {
      if (data[i].id_book === index && data[i].main === 1) {
        arrChapter.push({
          id_book: index,
          id_chapter: data[i].id_chapter,
          id_chapter_two: data[i].id_chapter_two,
          chapter: data[i].chapter,
          main_name: data[i].main_name,
        })
      }
    }
    setChapters(arrChapter)
  };

  return (
    <div
     
      id="sidebarr"
      className="sidebar"
      style={{
        opacity: w > 450 && '0.9',
        width: w <= 450 && isOpend && '100%',
        height: w <= 450 && isOpend && '100%',
        zIndex: w <= 450 && isOpend ? '1' : '0',
        position: w <= 450 ? 'fixed' : 'static',
        background: w <= 450 && !isOpend && 'transparent',
        top:
          w <= 450 && sliceHeightHeader - h >= 0
            ? sliceHeightHeader - h
            : '0px',
      }}
    >
      <div>
        <div
          style={{
            paddingLeft: isOpend ? '-10px' : '11px',
            color: w <= 450 && !isOpend && 'black',
          }}
          className="favbar"
          onClick={toogle}
        >
          <FaBars />
        </div>
        <div
          className="link"
          style={{
            display: w <= 450 && !isOpend ? 'none' : 'block',
          }}
        >
          {menuItem.map((item, index) => (
            <>
              <div
                key={index}
                className="sidebar-link"
                onClick={() => {
                  toggleDropdown(index);
                  searchChapter(index);
                }
                }
                activeclassName="sidebar__active"
                style={{
                  padding: w <= 450 && isOpend ? '20px 40px' : '20px 10px',
                  padding:
                    w >= 450 && isOpend ? '20px 50px 20px 25px' : '20px 10px',
                  backgroundColor: openIndex === index ? '#81a6d6' : '',
                }}
              >
                <div className="sidebar-icon">
                  <img
                    src={item.icon}
                    className="sidebar-icon-img"
                    alt="icon"
                  ></img>
                </div>
                <div
                  style={{ display: w > 450 && isOpend ? 'block' : 'none' }}
                  className="link-text"
                >
                  {item.name}
                </div>

              </div>
              {openIndex === index && (
                <div className="dropdown-list"
                  
                >
                  {chapters.map((itemm, i) => (
                    <NavLink
                      to={`/tora/${itemm.main_name}/${itemm.id_chapter}`}
                      key={i}
                      className="dropdown-navlink"
                      onClick={toogle}
                      style={{
                        display: w < 450 && !isOpend ? 'none' : 'block',
                      }}
                    >
                      <div className="dropdown-item">
                        <div className="dropdown-item-name">{itemm.chapter}</div>
                        <div className="dropdown-item-num">{itemm.id_chapter}</div>
                      </div>
                    </NavLink>
                  ))}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
