import React from 'react';
import '../tora.scss';

const Dvarim = () => {
  return (
    <>
      <div className="book__fals">
        <div className="book__fals-one">DVARIM</div>
      </div>
    </>
  );
};

export default Dvarim;
